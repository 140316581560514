
import '../../assets/css/style.css';
import './style.css'
import React from 'react'
import styles from '../Steps/styles/styles';
import { Spinner, ButtonToolbar, ToggleButton, ToggleButtonGroup } from  'react-bootstrap'
import $ from 'jquery'



const NeoButtonNav = (props) => {
    let classe = `s3-step-navigation inline externalSpace ${props.classe?props.classe:''}`
    return(
        <div className={classe}>
            {props.color == 'green'?
               <a  className="s3-btn s3-btn-round green  space pointer" onClick={props.callback}>{props.text}</a>
               :
               <a  className="s3-btn s3-btn-round white  space pointer" onClick={props.callback} >{props.text}</a>
            }
        </div>
)}

const NeoSubmit = props => {
    
    let classe = `s3-step-navigation inline space ${props.classe?props.classe:''}`
    return (
    <div className={classe}>
        <button className="s3-btn s3-btn-round green  space pointer" type="submit" onClick={props.callback} >
           {
               props.loading?
                <Spinner animation="border" role="status" variant="light" className="whiteColor">
                    <span className="sr-only">Carregando...</span>
                </Spinner>
               : props.text
           }
            

        </button>
    </div>
)}

const NeoButtonChoice = (props) => (
    <div className="s3-choice inline">
       <a  className="s3-option" onClick={props.callback}>{props.text}</a>
    </div>
)

class NeoRadio extends React.Component {

    constructor(){
            super()
            this.returnValue = this.returnValue.bind(this);
    }

    returnValue = function(e){
        
        this.props.value(e, this.props.name);
    }

    render(){
            return (
                <div>
                <ButtonToolbar>
                    <ToggleButtonGroup type="radio" name="options" defaultValue={this.props.defaultValue} onChange={this.returnValue}>

                        <ToggleButton value={1} > {this.props.opt1}</ToggleButton>
                        
                        {this.props.opt2?
                        <ToggleButton value={2} > {this.props.opt2}</ToggleButton>
                        :null}
                        {this.props.opt3?
                        <ToggleButton value={3} > {this.props.opt3}</ToggleButton>
                        :null}
                    </ToggleButtonGroup>
                </ButtonToolbar>
                </div>
            )
    }
}

class NeoCheckbox extends React.Component {

    constructor(){
            super()
            this.returnValue = this.returnValue.bind(this);
    }

    returnValue = function(e){
        this.props.value(e);
    }

    render(){
            return (
                <div>
                <ButtonToolbar>
                    <ToggleButtonGroup type="checkbox" name="options" defaultValue={this.props.defaultValue} onChange={this.returnValue}>

                        <ToggleButton value={1}> {this.props.opt1}</ToggleButton>
                        
                        {this.props.opt2?
                        <ToggleButton value={2}> {this.props.opt2}</ToggleButton>
                        :null}
                        {this.props.opt3?
                        <ToggleButton value={3}> {this.props.opt3}</ToggleButton>
                        :null}
                    </ToggleButtonGroup>
                </ButtonToolbar>
                </div>
            )
    }
}

const NeoLink = (props) => (
    <div className="neoLink">
        <a  onClick={props.callback}>{props.texto}</a>
    </div>
)

export {
    NeoButtonNav,
    NeoButtonChoice,
    NeoRadio,
    NeoSubmit,
    NeoLink,
    NeoCheckbox
} 