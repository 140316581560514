import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import styles from './styles/styles';
import StepTitle from './StepTitle';
import { NeoAlert, NeoLoading } from '../NeoAlert/NeoModal';
import { GLOBAL } from '../../config/global';
import { CONFIG } from '../../config/index';
import { NeoPercent } from '../NeoPercent/NeoPercent';

class Step_Final_Inves extends Component {
  constructor() {
    super()
    this.alertControl = this.alertControl.bind(this);
    this.state = {
      username: null,
      cpf: null,
      telefone: null,
      email: null,
      dataNascimento: null,
      userId: GLOBAL.getCookie('_uf_neoId'),
      enviouEmail: null,
      vendasMensais: null,
      corretoras: null,
      decImpostRenda: null,
      telaCarregou: false,
      dadosCliente: false
    }
  }

  static displayName = 'ui-RegisterForm'

  //roda assim q a tela abre
  componentDidMount() {
    document.getElementById('s3-cadastro').classList.add('contratos')
    let self = this;
    this.setState({
      userId: GLOBAL.getCookie('_uf_neoId')
    })

    //verifica se o id tá salvo no cookie
    let userId = GLOBAL.getCookie('_uf_neoId');
    if (userId && userId != "undefined") {

      this.loadingControl(true, 'Aguarde, não atualize esta página');
      //Consulta pra preencher os dados da tela assim que entra       
      let url = CONFIG.API_PREFIX + '/users/finduser';
      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          userLogin: {
            _id: userId
          }
        })
      }).then(response => response.json())
        .then(responseJson => {
          if (responseJson.success) {

            let dados = responseJson.data;

            // console.log(`dado-finduser`);
            // console.log(dados)

            this.setState({
              cpf: dados.cpf,
              email: dados.email,
              username: dados.name,
              enviouEmail: dados.emailEnviadoSucesso,
            })

            if (!dados.emailEnviadoSucesso) {
              self.loadingControl(false, '');
              self.enviaEmail(function (err, resultEmpregador) {
                // console.log(resultEmpregador)
                self.loadingControl(false, '');
              });
            } else {
              // console.log("não enviou email")
              self.loadingControl(false, '');
            }

            self.loadingControl(true, 'Aguarde, não atualize esta página');

            self.recuperaDadosEmpregador((err, returnDados) => {
              if (!err) {

                this.setState({
                  cpf: returnDados.cpf,
                  email: returnDados.email,
                  telefone: returnDados.telefone,
                  userName: returnDados.name,
                  enviouEmail: returnDados.emailEnviadoSucesso,
                  vendasMensais: returnDados.vendasMensais,
                  decImpostRenda: returnDados.decImpostRenda,
                  corretoras: returnDados.corretoras,
                  telaCarregou: true,
                  dadosCliente: true
                })
                document.getElementById('s3-cadastro').classList.add('totalHeight');
                document.getElementById('s3-cadastro').style.height = '100%';
                self.loadingControl(false, '');
              }
              else {
                this.setState({
                  dadosCliente: false
                });
                self.loadingControl(false, '');
              }
            })

          } else {
            // console.log("Deu ruim");
            // console.log(responseJson.success);
            this.setState({
              telaCarregou: true
            })
            self.loadingControl(false, '');
          }
        }).catch(err => {
          console.error(err);
          self.loadingControl(false, '');
          self.setState({
            telaCarregou: true
          })
        });


    }
  }

  recuperaDadosEmpregador(callback) {
    let self = this;
    this.setState({
      userId: GLOBAL.getCookie('_uf_neoId')
    })

    //verifica se o id tá salvo no cookie
    let userId = GLOBAL.getCookie('_uf_neoId');
    if (userId && userId != "undefined") {

      //Consulta pra preencher os dados da tela assim que entra       
      let url = CONFIG.API_PREFIX + '/users/finduser';
      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          userLogin: {
            _id: userId
          }
        })
      }).then(response => response.json())
        .then(responseJson => {
          if (responseJson.success) {
            callback(false, responseJson.data)
          } else {
            callback(true, "Erro ao buscar dados")
          }
          this.loadingControl(false, '');
        }).catch(err => {
          console.error(err);
          this.loadingControl(false, '');
          callback(false, err);
        });
    }
  }


  enviaEmail(callback) {
    let url = CONFIG.API_PREFIX + '/users/enviaEmails/' + this.state.cpf;
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      },

    }).then(response => {
        let responseJson = response.json()
        if (responseJson.success) { 
          callback(false, responseJson.data)
        } else {
          callback(true, "Erro ao buscar dados")
        }
        this.loadingControl(false, '');
      }).catch(err => {
        console.error('ERRO NO ENVIO DE EMAIL');
        console.error(err);
        this.loadingControl(false, '');
        this.setState({
          telaCarregou: true
        })
        callback(false, err);
      });
  }


  //Controla a visibilidade do alerta
  alertControl() {
    this.setState(state => ({
      showAlert: !state.showAlert
    }))
  }

  //Controla o loading
  loadingControl(visible, title) {
    this.setState({
      loading: visible,
      modalTitle: title
    })
  }


  render() {

    const textoTitulo = {
      titulo: 'Parabéns! Agora você é um Investidor assessorado por ',
      produto: ' profissionais qualificados!',
      subtitulo: 'Portanto, agora é só aguardar que nossa equipe vai auditar seus dados em até 48 horas e você receberá os primeiros relatórios de registro.',
      alinhamento: 'center'
    }

    // console.log(this.state)
    let data = this.state;

    

    return (

      <div className="s3-step stepFinal" data-step="Z">
        <NeoAlert
          show={data.showAlert}
          title={data.modalTitle}
          text={data.modalText}
          close={this.alertControl}
        />

        <NeoLoading title={data.modalTitle} show={data.loading} />

        <div className="s3-wrapper">

          {/* //className = "s3-signup s3-body"> */}

          <Row >
            <Row style={styles.alignContentCenter}>
              <StepTitle texto={textoTitulo} />
            </Row>
            {
              this.state.telaCarregou && this.state.dadosCliente ?

                <div className="containerGeral">

                  <Row>
                    <div className="containerDocumentos">
                      <h4>Suas informações, como Investidor:</h4>

                      <Col xs={12} md={12}>
                        <div className="listaDocumentos">
                          <ul>
                            <li><span><strong>Nome:  </strong></span>{data.username}</li>
                            <li><span><strong>Cpf:  </strong></span>{data.cpf}</li>
                            <li><span><strong>Email:  </strong></span>{data.email}</li>
                            <li><span><strong>Telefone:  </strong></span>{data.telefone}</li>
                            <li><span><strong>Realiza vendas mensais:  </strong></span>{data.vendasMensais == 1? 'Sim' : 'Não'}</li>
                            <li><span><strong>Corretoras em que atua:  </strong></span>{data.corretoras}</li>
                            <li><span><strong>Declarou o imposto de renda deste ano:  </strong></span>{data.decImpostRenda == 1? 'Sim' : 'Não'}</li>
                          </ul>
                        </div>
                      </Col>
                    </div>
                  </Row>
                </div>
                : null
            }
          </Row>
        </div>
        <NeoPercent text="100%" />
      </div>
    );
  }
}

export default Step_Final_Inves;