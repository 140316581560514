import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';

import { Button } from 'react-bootstrap';
import { GLOBAL } from '../../config/global';
import { CONFIG } from '../../config/index';
import styles from './styles/styles';
import './styles/styles.css';
import '../../assets/css/style.css';
import StepTitle from './StepTitle';
import { NeoInput, NeoInputRequired, NeoSelect } from '../NeoInputs/NeoInputs'
import { NeoRadio } from '../NeoButtons/NeoButtons'
import { ValidatorForm } from 'react-form-validator-core';
import { NeoButtonNav, NeoSubmit } from '../NeoButtons/NeoButtons'
import { NeoAlert, NeoAlertDecision, NeoLoading } from '../NeoAlert/NeoModal';
import NeoTooltip from '../NeoTooltip/NeoTooltip'
import { NeoPercent } from '../NeoPercent/NeoPercent';

const stepAtual = 'H1'

class Step_H1 extends Component {

    constructor() {
        super()
        this.gravarDados = this.gravarDados.bind(this);
        this.updateValue = this.updateValue.bind(this);
        this.checkboxControl = this.checkboxControl.bind(this);
        this.alertDecisionControl = this.alertDecisionControl.bind(this);
        this.alertControl = this.alertControl.bind(this);

        this.state = {
            choiceVisible: true,
            userId: '',
            loading: false,
            loadingButton: false,
            pis: '',
            escolaridade: '',
            escolaridadeLabel: '',
            funcao: '',
            funcaoLabel: '',
            dataAdmissao: '',
            possuiValeTransporte: '',
            possuiDependentes: '',
            telaCarregou: false,
            showAlertDecision: false,
            modalTitle: '',
            modalText: '',
            modalEsconderBotao: false,
            quantFuncCadastrados: 0,
            modalClass: null,
        }
    }

    static displayName = 'ui-RegisterForm'

    //roda assim q a tela abre
    componentDidMount() {

        this.setState({
            userId: GLOBAL.getCookie('_uf_neoId')
        })

        //verifica se o id tá salvo no cookie
        let userId = GLOBAL.getCookie('_uf_neoId');

        if (userId && userId != "undefined") {

            this.loadingControl(true, 'Buscando dados, aguarde');

            // Consulta para buscar numero do funcionario que esta sendo cadastrado     
            let url = CONFIG.API_PREFIX + '/users/finduser';

            fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    userLogin: {
                        _id: userId
                    }
                })
            }).then(response => response.json())
                .then(responseJson => {

                    if (responseJson.success) {

                        let dados = responseJson.data;

                        if (!isNaN(dados.quantFuncCadastrados)) {

                            let quantFuncCadastrados = dados.quantFuncCadastrados;

                            this.setState(() => ({
                                quantFuncCadastrados: dados.quantFuncCadastrados,
                            }))

                            //Consulta pra preencher os dados da tela assim que entra       
                            let url = CONFIG.API_PREFIX + '/funcionarios/findfuncionario';

                            fetch(url, {
                                method: "POST",
                                headers: {
                                    "Content-Type": "application/json"
                                },
                                body: JSON.stringify({
                                    userLogin: {
                                        idEmpregador: userId,
                                        numeroFuncionario: quantFuncCadastrados
                                    }
                                })
                            }).then(response => response.json())
                                .then(responseJson => {

                                    if (responseJson.success) {

                                        let dados = responseJson.data;
                                        console.log('DADOS', dados.possuiValeTransporte, dados.possuiDependentes)
                                        this.setState(() => ({
                                            pis: dados.pis ? dados.pis : '',
                                            escolaridade: dados.escolaridade ? dados.escolaridade : '',
                                            escolaridadeLabel: dados.escolaridadeLabel ? dados.escolaridadeLabel : '',
                                            funcao: dados.funcao ? dados.funcao : '',
                                            funcaoLabel: dados.funcaoLabel ? dados.funcaoLabel : '',
                                            dataAdmissao: dados.dataAdmissao ? dados.dataAdmissao : '',
                                            possuiValeTransporte: dados.possuiValeTransporte ? Number(dados.possuiValeTransporte) : 2,
                                            possuiDependentes: dados.possuiDependentes ? Number(dados.possuiDependentes) : 2,
                                            telaCarregou: true
                                        }))

                                        // console.log(dados);
                                    }

                                    else {

                                        this.setState(() => ({
                                            possuiValeTransporte: 2,
                                            possuiDependentes: 2,
                                            telaCarregou: true
                                        }))

                                        // console.log(responseJson.success);
                                    }

                                    this.loadingControl(false, '');
                                }).catch(err => {

                                    this.setState(() => ({
                                        possuiValeTransporte: 2,
                                        possuiDependentes: 2,
                                        telaCarregou: true
                                    }))

                                    console.error(err);
                                    this.loadingControl(false, '');
                                });
                        }

                        else {
                            // console.log('ELSE1')
                            this.loadingControl(false, '');

                            this.setState(() => ({
                                modalTitle: 'Ops!',
                                modalText: 'Ocorreu um erro ao buscar os dados, atualize a página ou entre em contato com o administrador do sistema.',
                                modalEsconderBotao: true,
                                telaCarregou: true,
                            }))

                            this.alertControl('diminuir-modal');
                        }

                        //controla o histórico de steps
                        let historicoStep = responseJson.data.historicoStep;
                        if (historicoStep[historicoStep.length - 1] === stepAtual) historicoStep.pop();
                        this.setState({
                            historicoStep: historicoStep,
                        })

                    }

                    else {

                        this.loadingControl(false, '');

                        this.setState(() => ({
                            modalTitle: 'Ops!',
                            modalText: 'Ocorreu um erro ao buscar os dados, atualize a página ou entre em contato com o administrador do sistema.',
                            modalEsconderBotao: true,
                            telaCarregou: true,
                        }))

                        this.alertControl('diminuir-modal');

                        // console.log(responseJson.success);
                    }
                }).catch(err => {

                    console.error(err);

                    this.loadingControl(false, '');

                    this.setState(() => ({
                        modalTitle: 'Ops!',
                        modalText: 'Ocorreu um erro ao buscar os dados, atualize a página ou entre em contato com o administrador do sistema.',
                        modalEsconderBotao: true,
                        telaCarregou: true,
                    }))

                    this.alertControl('diminuir-modal');
                });
        }

        else {

            this.setState(() => ({
                modalTitle: 'Ops!',
                modalText: 'Ocorreu um erro ao buscar os dados, atualize a página ou entre em contato com o administrador do sistema.',
                modalEsconderBotao: true,
                telaCarregou: true,
            }))

            this.alertControl('diminuir-modal');
        }
    }

    changeVisibility = () => {
        this.setState(state => ({
            choiceVisible: !state.choiceVisible
        }))
    }

    gravarDados = function () {

        var self = this;
        let historicoStep = self.state.historicoStep;
        historicoStep.push(stepAtual);

        self.loadingControl(true, 'Salvando dados, aguarde');

        self.setState({
            loadingButton: true,
        })

        let dataToSend = {
            idEmpregador: self.state.userId,
            numeroFuncionario: self.state.quantFuncCadastrados,
            params: {
                pis: self.state.pis,
                escolaridade: self.state.escolaridade,
                escolaridadeLabel: self.state.escolaridadeLabel,
                funcao: self.state.funcao,
                funcaoLabel: self.state.funcaoLabel,
                dataAdmissao: self.state.dataAdmissao,
                possuiValeTransporte: self.state.possuiValeTransporte,
                possuiDependentes: self.state.possuiDependentes,
            }
        }

        let url = CONFIG.API_PREFIX + '/funcionarios/updateFuncionario';

        fetch(url, {
            method: "PUT",
            headers: {
                "Origin": "*",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                data: dataToSend
            })
        }).then(response => response.json())
            .then(responseJson => {

                if (responseJson.success) {

                    // console.log(responseJson);

                    dataToSend = {
                        _id: self.state.userId,
                        params: {
                            historicoStep: historicoStep,
                            step: 'H2',
                        }
                    }

                    url = CONFIG.API_PREFIX + '/users/updateUser';

                    fetch(url, {
                        method: "PUT",
                        headers: {
                            "Origin": "*",
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify({
                            data: dataToSend
                        })
                    }).then(response => response.json())
                        .then(responseJson => {

                            if (responseJson.success) {

                                // console.log(responseJson);

                                self.props.nextScreen();

                                self.loadingControl(false, '');
                            }

                            else {

                                // console.log(responseJson.success);

                                self.loadingControl(false, '');

                                self.setState({
                                    modalTitle: 'Ops!',
                                    modalText: 'Ocorreu um erro ao salvar os dados',
                                })
                                self.alertControl();
                            }

                            self.setState({
                                loadingButton: false,
                            })

                        }).catch(err => {

                            console.error(err);

                            self.loadingControl(false, '');

                            self.setState({
                                modalTitle: 'Ops!',
                                modalText: 'Ocorreu um erro ao salvar os dados',
                                loadingButton: false,
                            })

                            self.alertControl();
                        });
                }

                else {

                    // console.log(responseJson.success);

                    self.loadingControl(false, '');

                    self.setState({
                        modalTitle: 'Ops!',
                        modalText: 'Ocorreu um erro ao salvar os dados',
                    })
                    self.alertControl();
                }

                self.setState({
                    loadingButton: false,
                })

            }).catch(err => {

                console.error(err);

                self.loadingControl(false, '');

                self.setState({
                    modalTitle: 'Ops!',
                    modalText: 'Ocorreu um erro ao salvar os dados',
                    loadingButton: false,
                })

                self.alertControl();
            });
    }

    //Atualiza os states
    updateValue = function (name, evt, value = null, labelSelect = null) {

        let valor = value;

        if (evt) {

            valor = evt.target.value
        }

        if (!labelSelect) this.setState(state => ({ [name]: valor }))

        else {

            this.setState(state => ({
                [name]: valor,
                [name + 'Label']: labelSelect
            }))
        }
    }

    //Controla o valor do checkbox
    checkboxControl(e, name) {

        let val = e;

        this.updateValue(name, null, val);
    }

    //Controla o loading
    loadingControl(visible, title) {
        this.setState({
            loading: visible,
            modalTitle: title
        })
    }

    //Controla a visibilidade do alerta decisivo
    alertDecisionControl(acao, titulo, mensagem, evt) {

        this.setState(state => ({
            showAlertDecision: !state.showAlertDecision,
            modalTitle: titulo,
            modalText: mensagem,
        }))

        if (acao == 'continuar') this.gravarDadosVoltar();
    }

    gravarDadosVoltar = function () {
        let lastStep = this.state.historicoStep[this.state.historicoStep.length - 1];

        var self = this;

        self.loadingControl(true, 'Aguarde');

        let dataToSend = {
            _id: self.state.userId,
            params: {
                step: lastStep,
                historicoStep: this.state.historicoStep,
                quantFuncCadastrados: Number(this.state.quantFuncCadastrados) - 1,
            }
        }

        let url = CONFIG.API_PREFIX + '/users/updateUser';

        fetch(url, {
            method: "PUT",
            headers: {
                "Origin": "*",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                data: dataToSend
            })
        }).then(response => response.json())
            .then(responseJson => {

                if (responseJson.success) {

                    // console.log(responseJson);

                    self.props.lastScreen(lastStep);

                    self.loadingControl(false, '');
                }

                else {

                    // console.log(responseJson.success);

                    self.loadingControl(false, '');

                    self.setState({
                        modalTitle: 'Ops!',
                        modalText: 'Ocorreu um erro ao buscar os dados',
                    })
                    self.alertControl();
                }

                self.setState({
                    loadingButton: false,
                })

            }).catch(err => {

                console.error(err);

                self.loadingControl(false, '');

                self.setState({
                    modalTitle: 'Ops!',
                    modalText: 'Ocorreu um erro ao buscar os dados',
                    loadingButton: false,
                })

                self.alertControl();
            });
    }

    //Controla a visibilidade do alerta
    alertControl(classe = '') {
        this.setState(state => ({
            modalClass: classe,
            showAlert: !state.showAlert
        }))
    }

    handleKeyDown = (event) => {

        if (event.key === 'Enter') {

            event.preventDefault();
        }
    };

    render() {

        const textoTitulo = {
            titulo: 'Agora, precisamos de mais algumas informações do funcionário',
            produto: '',
            subtitulo: '',
            alinhamento: 'center'
        }

        return (
            <div>

                <NeoLoading title={this.state.modalTitle} show={this.state.loading} />

                <NeoAlertDecision
                    show={this.state.showAlertDecision}
                    title={this.state.modalTitle}
                    text={this.state.modalText}
                    sim={this.alertDecisionControl.bind(this, "continuar")}
                    nao={this.alertDecisionControl.bind(this, "cancelar")}
                />

                <NeoAlert
                    show={this.state.showAlert}
                    title={this.state.modalTitle}
                    text={this.state.modalText}
                    close={this.alertControl}
                    modalEsconderBotao={this.state.modalEsconderBotao}
                    class={this.state.modalClass}
                />

                <ValidatorForm ref="form" onSubmit={this.gravarDados} onKeyDown={e => { this.handleKeyDown(e) }}>
                    <div className="s3-step" data-step="H1">
                        <div className="s3-wrapper">

                            <Row >
                                <StepTitle texto={textoTitulo} />
                                <div className="s3-content col-md-12" style={{ padding: "0" }}>
                                    <div className="s3-signup" style={{ margin: "0" }}>

                                        <Row>
                                            <div className="s3-form-item clearfix col-md-12" style={{ padding: "0" }}>
                                                {/* Begin Register Form */}
                                                < div className="register-content" style={{ marginTop: "10px" }}>
                                                    <div className="register-body">

                                                        <Row style={styles.rowSpace} className="verticalAlign">
                                                            <Col xs={12} md={6}>
                                                                <label htmlFor="pis" className="form-control-label">Qual o PIS do funcionário?</label>
                                                            </Col>
                                                            <Col xs={12} md={6}>
                                                                <NeoInputRequired
                                                                    changeCallback={this.updateValue.bind(this, "pis")}
                                                                    name="pis"
                                                                    type="text"
                                                                    value={this.state.pis}
                                                                    validators={['required', 'isPIS']}
                                                                    errorMessages={['Obrigatório', 'PIS inválido']}
                                                                    attr={{
                                                                        mask: 'pis',
                                                                        ref: 'pis',
                                                                        className: 'form-control',
                                                                        id: 'pis',
                                                                        placeholder: 'PIS',
                                                                        obrigatorio: true
                                                                    }}
                                                                />
                                                            </Col>
                                                        </Row>

                                                        <Row style={styles.rowSpace} className="verticalAlign">
                                                            <Col xs={12} md={6}>
                                                                <label htmlFor="escolaridade" className="form-control-label">Qual a escolaridade?</label>
                                                            </Col>
                                                            <Col xs={12} md={6}>{this.state.telaCarregou ?
                                                                <NeoSelect
                                                                    value={this.updateValue.bind(this, 'escolaridade', null)}
                                                                    placeholder="Selecione um grau de escolaridade"
                                                                    defaultValue={this.state.escolaridade}
                                                                    defaultLabel={this.state.escolaridadeLabel}
                                                                    name="escolaridade"
                                                                    inputValue={this.state.escolaridade}
                                                                    maxHeight={220}
                                                                    options={[
                                                                        'Analfabeto, inclusive o que, embora tenha recebido instrução, não se alfabetizou',
                                                                        'Até o 5º ano incompleto do Ensino Fundamental (antiga 4ª série) ou que se tenha alfabetizado sem ter frequentado escola regular',
                                                                        '5º ano completo do Ensino Fundamental',
                                                                        'do 6º ao 9º ano do Ensino Fundamental incompleto (antiga 5ª a 8ª série)',
                                                                        'Ensino Fundamental Completo',
                                                                        'Ensino Medio incompleto',
                                                                        'Ensino Medio completo',
                                                                        'Educação Superior incompleta',
                                                                        'Educação Superior completa',
                                                                        'Pós-Graduação completa',
                                                                        'Mestrado completo',
                                                                        'Doutorado completo',
                                                                    ]}
                                                                />
                                                                : null
                                                            }
                                                            </Col>
                                                        </Row>

                                                        <Row style={styles.rowSpace} className="verticalAlign">
                                                            <Col xs={12} md={6}>
                                                                <label htmlFor="funcao" className="form-control-label">Qual a função do funcionário?</label>
                                                            </Col>
                                                            <Col xs={12} md={6}>{this.state.telaCarregou ?
                                                                <NeoSelect
                                                                    value={this.updateValue.bind(this, 'funcao', null)}
                                                                    placeholder="Selecione uma função"
                                                                    defaultValue={this.state.funcao}
                                                                    defaultLabel={this.state.funcaoLabel}
                                                                    name="funcao"
                                                                    inputValue={this.state.funcao}
                                                                    maxHeight={180}
                                                                    options={[
                                                                        'Empregado doméstico nos serviços gerais',
                                                                        'Empregado doméstico arrumador',
                                                                        'Empregador doméstico faxineiro',
                                                                        'Cozinheiro do serviço doméstico',
                                                                        'Babá',
                                                                        'Administradores',
                                                                        'Assistente social',
                                                                        'Atendente de enfermagem no serviço doméstico',
                                                                        'Auxiliar de enfermagem',
                                                                        'Caseiro',
                                                                        'Chefe de cozinha',
                                                                        'Contadores e afins',
                                                                        'Cuidador de pessoas idosas e dependentes',
                                                                        'Dama de companhia',
                                                                        'Enfermeiro',
                                                                        'Fisioterapeutas',
                                                                        'Fonoaudiólogos',
                                                                        'Garçom',
                                                                        'Governanta da Residência',
                                                                        'Jardineiro',
                                                                        'Lavandeiro, em geral',
                                                                        'Mordomo da Residência',
                                                                        'Motorista no serviço doméstico',
                                                                        'Motorista particular',
                                                                        'Médicos, em geral',
                                                                        'Nutricionistas',
                                                                        'Passador de roupas',
                                                                        'Porteiro',
                                                                        'Preparador Físico',
                                                                        'Professores de educação infantil',
                                                                        'Professores de ensino fundamental',
                                                                        'Professores de ensino médio',
                                                                        'Psicólogos ou psicanalistas',
                                                                        'Secretário, em  geral',
                                                                        'Subchefe de cozinha',
                                                                        'Supervisor de cozinha',
                                                                        'Terapeutas ocupacionais e afins',
                                                                        'Técnico de Enfermagem',
                                                                        'Vigia'
                                                                    ]}
                                                                />
                                                                : null
                                                            }
                                                            </Col>
                                                        </Row>

                                                        <Row style={styles.rowSpace} className="verticalAlign">
                                                            <Col xs={12} md={6}>
                                                                <label htmlFor="dataAdmissao" className="form-control-label">Qual é a data de admissão?</label>
                                                            </Col>
                                                            <Col xs={12} md={6}>
                                                                <NeoInputRequired
                                                                    changeCallback={this.updateValue.bind(this, "dataAdmissao")}
                                                                    name="dataAdmissao"
                                                                    type="text"
                                                                    value={this.state.dataAdmissao}
                                                                    validators={['required', 'isAdmissionDate']}
                                                                    errorMessages={['Obrigatório', 'Data inválida']}
                                                                    attr={{
                                                                        mask: 'date',
                                                                        ref: 'dataAdmissao',
                                                                        className: 'form-control',
                                                                        id: 'dataAdmissao',
                                                                        placeholder: '__/__/____',
                                                                        obrigatorio: true
                                                                    }}
                                                                />
                                                            </Col>
                                                        </Row>

                                                        <Row style={styles.rowSpace}>
                                                            <Col xs={10} md={5}>
                                                                <label htmlFor="possuiValeTransporte" className="form-control-label">Você fornecerá vale transporte e descontará do funcionário?</label>
                                                            </Col>

                                                            <Col xs={2} md={1}>
                                                                <NeoTooltip position="top" text="Caso você forneça vale-transporte e queira descontar 6% do salário do funcionário, conforme legislação, responda sim" />
                                                            </Col>

                                                            <Col xs={12} md={6}>{
                                                                this.state.possuiValeTransporte == '' ? null :
                                                                    <NeoRadio
                                                                        opt1="Sim"
                                                                        opt2="Não"
                                                                        name="possuiValeTransporte"
                                                                        value={this.checkboxControl}
                                                                        defaultValue={this.state.possuiValeTransporte}
                                                                    />
                                                            }
                                                            </Col>
                                                        </Row>

                                                        <Row style={styles.rowSpace}>
                                                            <Col xs={10} md={5}>
                                                                <label htmlFor="possuiDependentes" className="form-control-label">Seu funcionário tem dependentes?</label>
                                                            </Col>

                                                            <Col xs={2} md={1}>
                                                                <NeoTooltip position="top" text="Dependente é o filho(a) menor de 21 anos com CPF" />
                                                            </Col>

                                                            <Col xs={12} md={6}>{
                                                                this.state.possuiDependentes == '' ? null :
                                                                    <NeoRadio
                                                                        opt1="Sim"
                                                                        opt2="Não"
                                                                        name="possuiDependentes"
                                                                        value={this.checkboxControl}
                                                                        defaultValue={this.state.possuiDependentes}
                                                                    />
                                                            }
                                                            </Col>
                                                        </Row>

                                                    </div>
                                                </div >
                                                {/* Begin Register Form */}
                                            </div>
                                        </Row>

                                    </div>
                                </div>
                            </Row>

                        </div>
                    </div>
                    <NeoButtonNav text="Voltar" callback={this.alertDecisionControl.bind(this, 'abrir', 'Atenção', 'Ao voltar os dados preenchidos serão perdidos, deseja continuar?')} />
                    <NeoSubmit text="Continuar" color="green" loading={this.state.loadingButton} />
                    <NeoPercent text="80%" />
                </ValidatorForm>

            </div>
        );
    }
}

export default Step_H1;