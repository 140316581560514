import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';

import { GLOBAL } from '../../config/global';
import { CONFIG } from '../../config/index';
import styles from './styles/styles';
import './styles/styles.css';
import '../../assets/css/style.css';
import StepTitle from './StepTitle';
import { NeoInputRequired, NeoInputFile } from '../NeoInputs/NeoInputs'
import { ValidatorForm } from 'react-form-validator-core';
import { NeoAlert, NeoLoading, NeoAlertDecision } from '../NeoAlert/NeoModal';
import { NeoButtonNav, NeoSubmit } from '../NeoButtons/NeoButtons';
import { NeoPercent } from '../NeoPercent/NeoPercent';

const stepAtual = 'F1'

class Step_F1 extends Component {
    constructor() {
        super()
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onlyNumber = this.onlyNumber.bind(this);
        this.inputFileControl = this.inputFileControl.bind(this);
        this.alertControl = this.alertControl.bind(this);
        this.alertDecisionControl = this.alertDecisionControl.bind(this);
        // this.updateValue = this.updateValue.bind(this);

        this.state = {
            choiceVisible: true,
            anexo: '',
            numTituloEleitor: '',
            zona: '',
            secao: '',
            cpf: '',
            userId: '',
            anexo: {
                base64: null,
                nomeOriginal: null,
                size: null
            },
            anexoSalvoBanco: null,
            telaCarregou: false,
            modalEsconderBotao: false,
        }
    }

    static displayName = 'ui-RegisterForm'

    //roda assim q a tela abre
    componentDidMount() {
        document.getElementById('s3-cadastro').classList.add('contratos')

        this.setState({
            userId: GLOBAL.getCookie('_uf_neoId')
        })

        //verifica se o id tá salvo no cookie
        let userId = GLOBAL.getCookie('_uf_neoId');
        if (userId && userId != "undefined") {

            this.loadingControl(true, 'Aguarde');
            //Consulta pra preencher os dados da tela assim que entra       
            let url = CONFIG.API_PREFIX + '/users/finduser';
            fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    userLogin: {
                        _id: userId
                    }
                })
            }).then(response => response.json())
                .then(responseJson => {
                    if (responseJson.success) {
                        let dados = responseJson.data;
                        if (dados.tituloEleitor) {
                            this.setState(() => ({
                                numTituloEleitor: dados.tituloEleitor.numTituloEleitor,
                                zona: dados.tituloEleitor.zona,
                                secao: dados.tituloEleitor.secao,
                                anexoSalvoBanco: dados.tituloEleitor.anexos,
                                telaCarregou: true
                            }))
                        }
                        this.setState({
                            cpf: dados.cpf,
                            telaCarregou: true
                        })

                        //controla o histórico de steps
                        let historicoStep = responseJson.data.historicoStep;
                        if (historicoStep[historicoStep.length - 1] === stepAtual) historicoStep.pop();
                        this.setState({
                            historicoStep: historicoStep,
                        })

                    } else {
                        // console.log("Deu ruim");
                        // console.log(responseJson.success);
                        this.setState({
                            telaCarregou: true
                        })
                    }
                    this.loadingControl(false, '');
                }).catch(err => {
                    console.error(err);
                    this.loadingControl(false, '');
                    this.setState({
                        telaCarregou: true,
                        modalTitle: "Ops!",
                        modalText: "Ocorreu um erro ao buscar os dados. Entre em contato com o administrador do sistema",
                        modalEsconderBotao: true,
                    })
                    this.alertControl();
                });
        }
    }

    changeVisibility = () => {
        this.setState(state => ({
            choiceVisible: !state.choiceVisible,
            numTituloEleitor: '',
            zona: '',
            secao: '',
            anexo: '',
            showAlert: false,
            loading: false,
            modalText: '',
            modalTitle: '',
        }))
    }

    /*
    Grava dados no banco
    */
    gravaDocumento(callback) {
        let self = this;
        let fileData = {
            "fileName": "Empregador_TituloEleitor." + this.state.anexo.extensao,
            "folderName": this.state.cpf,
            "base64": this.state.anexo.base64
        }

        this.loadingControl(true, 'Salvando Dados');
        let url = CONFIG.API_PREFIX + '/files/saveFile';
        fetch(url, {
            method: "POST",
            headers: {
                "Origin": "*",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(
                { "fileData": fileData }
            )

        }).then(response => response.json())
            .then(responseJson => {
                if (responseJson.success) {
                    callback(self);
                } else {
                    // console.log("Deu ruim");
                    // console.log(responseJson.success);
                    this.setState({
                        modalTitle: 'Ops!',
                        modalText: 'Ocorreu um erro ao salvar os dados',
                    })
                    this.alertControl();
                }
                this.loadingControl(false, '');

            }).catch(err => {
                console.error(err);
                this.loadingControl(false, '');
                this.setState({
                    modalTitle: 'Ops!',
                    modalText: 'Ocorreu um erro ao salvar os dados',
                })
                this.alertControl();
            });
    }
    gravarDados = function (self) {
        let anexoToSend;
        let historicoStep = self.state.historicoStep;
        historicoStep.push(stepAtual);
        if (self.state.anexo.base64) {
            anexoToSend = {
                caminho: self.state.anexo ? `${self.state.cpf}/Empregador_TituloEleitor.${self.state.anexo.extensao}` : null,
                nomeOriginal: self.state.anexo.name,
                tamanho: self.state.anexo.size
            }
        } else {
            anexoToSend = this.state.anexoSalvoBanco
        }
        let dataToSend = {
            _id: self.state.userId,
            params: {
                step: 'G',
                historicoStep: historicoStep,
                tituloEleitor: {
                    numTituloEleitor: self.state.numTituloEleitor,
                    zona: self.state.zona,
                    secao: self.state.secao,
                    anexos: anexoToSend
                }
            }
        }
        self.loadingControl(true, 'Salvando Dados');
        let url = CONFIG.API_PREFIX + '/users/updateUser';
        fetch(url, {
            method: "PUT",
            headers: {
                "Origin": "*",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                data: dataToSend
            })

        }).then(response => response.json())
            .then(responseJson => {
                if (responseJson.success) {
                    // console.log(responseJson)
                    self.props.nextScreen();
                } else {
                    // console.log("Deu ruim");
                    // console.log(responseJson.success);
                    self.setState({
                        modalTitle: 'Ops!',
                        modalText: 'Ocorreu um erro ao salvar os dados',
                    })
                    self.alertControl();
                }
                self.loadingControl(false, '');

            }).catch(err => {
                console.error(err);
                self.loadingControl(false, '');
                self.setState({
                    modalTitle: 'Ops!',
                    modalText: 'Ocorreu um erro ao salvar os dados',
                })
                self.alertControl();
            });
    }

    handleSubmit(e) {
        // console.log('submit')
        let numTituloEleitor = this.state.numTituloEleitor;
        let zona = this.state.zona;
        let secao = this.state.secao;
        let anexo = this.state.anexo;
        let self = this;
        // console.log(this.state)
        if (!(numTituloEleitor && zona && secao) && !(anexo.base64 || this.state.anexoSalvoBanco)) {
            this.setState({
                modalTitle: 'Ops!',
                modalText: 'É necessário preencher todos os campos ou anexar um arquivo referente ao título de eleitor.',
            })
            this.alertControl();
        } else {
            if (this.state.anexo.base64) {
                // console.log(this.state)
                this.gravaDocumento(this.gravarDados);
            } else {
                this.gravarDados(self);
            }
        }
    }

    //Bloqueia o campo de texto para que seja possível aceitar só números
    onlyNumber = function (name, evt) {
        let valor = evt.target.value
        this.setState(state => ({ [name]: valor.replace(/\W+/g, "").replace(/[_A-Z-a-z]/g, '') }))
    }

    //Tratativa do input file
    inputFileControl(e) {

        let acceptedTypes = ['pdf', 'jpeg', 'png', 'jpg'];

        if (e.erro == true) {
            this.setState({
                modalTitle: 'Ops!',
                modalText: 'Ocorreu um erro ao anexar o arquivo',
            })
        }
        else if (e.size > 10000000 && e.size) {
            this.setState({
                modalTitle: 'Ops!',
                modalText: 'O arquivo selecionado é muito grande. Anexe um documento com até 10mb.',
            })
            this.alertControl();

        } else if (!acceptedTypes.includes(e.extensao) && e.extensao) {
            this.setState({
                modalTitle: 'Ops!',
                modalText: 'O formato do arquivo selecionado é inválido.',
            })
            this.alertControl();
        } else {
            this.setState(() => ({
                anexo: e,
            }))
        }
        if (!e.extensao && !e.size) {
            this.setState(() => ({
                anexoSalvoBanco: null,
            }))
        }
    }

    //Controla a visibilidade do alerta
    alertControl() {
        this.setState(state => ({
            showAlert: !state.showAlert
        }))
    }

    //Controla o loading
    loadingControl(visible, title) {
        this.setState({
            loading: visible,
            modalTitle: title
        })
    }

    //Controla a visibilidade do alerta decisivo
    alertDecisionControl(acao, titulo, mensagem, evt) {

        this.setState(state => ({
            showAlertDecision: !state.showAlertDecision,
            modalTitle: titulo,
            modalText: mensagem,
        }))

        if (acao == 'continuar') this.gravarDadosVoltar();
    }

    gravarDadosVoltar = function () {

        var self = this;

        self.loadingControl(true, 'Aguarde');

        let dataToSend = {
            _id: self.state.userId,
            params: {
                step: this.state.historicoStep[this.state.historicoStep.length - 1],
            }
        }

        let url = CONFIG.API_PREFIX + '/users/updateUser';

        fetch(url, {
            method: "PUT",
            headers: {
                "Origin": "*",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                data: dataToSend
            })
        }).then(response => response.json())
            .then(responseJson => {

                if (responseJson.success) {

                    // console.log(responseJson);

                    self.props.lastScreen(this.state.historicoStep[this.state.historicoStep.length - 1]);

                    self.loadingControl(false, '');
                }

                else {

                    // console.log(responseJson.success);

                    self.loadingControl(false, '');

                    self.setState({
                        modalTitle: 'Ops!',
                        modalText: 'Ocorreu um erro ao buscar os dados',
                    })
                    self.alertControl();
                }

                self.setState({
                    loadingButton: false,
                })

            }).catch(err => {

                console.error(err);

                self.loadingControl(false, '');

                self.setState({
                    modalTitle: 'Ops!',
                    modalText: 'Ocorreu um erro ao buscar os dados',
                    loadingButton: false,
                })

                self.alertControl();
            });
    }

    handleKeyDown = (event) => {

        if (event.key === 'Enter') {

            event.preventDefault();
        }
    };

    render() {

        const textoTitulo = {
            titulo: 'Você nunca declarou o ',
            produto: 'IRPF',
            subtitulo: 'Então nos diga os dados do seu Título de Eleitor',
            alinhamento: 'center'
        }

        return (

            <div>

                <NeoAlert
                    show={this.state.showAlert}
                    title={this.state.modalTitle}
                    text={this.state.modalText}
                    close={this.alertControl}
                    modalEsconderBotao={this.state.modalEsconderBotao}
                />

                <NeoLoading title={this.state.modalTitle} show={this.state.loading} />

                <NeoAlertDecision
                    show={this.state.showAlertDecision}
                    title={this.state.modalTitle}
                    text={this.state.modalText}
                    sim={this.alertDecisionControl.bind(this, "continuar")}
                    nao={this.alertDecisionControl.bind(this, "cancelar")}
                />

                {/* como a validação dessa tela não é padrão, existe a funlçao de  */}
                <ValidatorForm ref="form" onSubmit={() => { return }} onKeyDown={e => { this.handleKeyDown(e) }}>

                    <div className="s3-step" data-step="F1">
                        <div className="s3-wrapper">

                            <Row >
                                <StepTitle texto={textoTitulo} />
                                <div className="s3-content col-md-12" style={{ padding: "0" }}>
                                    <div className="s3-signup" style={{ margin: "0" }}>
                                        <Row>
                                            <div className="s3-form-item clearfix col-md-12" style={{ padding: "0" }}>
                                                {/* Begin Register Form */}
                                                < div className="register-content" style={{ marginTop: "10px" }}>

                                                    <div className="register-body">

                                                        <Row style={styles.rowSpace} className="verticalAlign">
                                                            <Col xs={12} md={6}>
                                                                <label htmlFor="numTituloEleitor" className="form-control-label">Informe os dados do seu Título de Eleitor ou anexe uma imagem digitalizada dele</label>
                                                            </Col>
                                                            <Col xs={12} md={6}>
                                                                <NeoInputRequired

                                                                    changeCallback={this.onlyNumber.bind(this, "numTituloEleitor")}
                                                                    name="numTituloEleitor"
                                                                    type="text"
                                                                    value={this.state.numTituloEleitor}
                                                                    validators={['isTitulo']}
                                                                    errorMessages={['Título inválido']}

                                                                    attr={{
                                                                        mask: 'numTituloEleitor',
                                                                        ref: 'numTituloEleitor',
                                                                        className: 'form-control',
                                                                        id: 'numTituloEleitor',
                                                                        placeholder: 'Número',
                                                                        obrigatorio: true
                                                                    }} />
                                                            </Col>
                                                        </Row>
                                                        <Row style={styles.rowSpace} className="verticalAlign">
                                                            <Col xs={12} md={6}></Col>
                                                            <Col xs={12} md={3}>
                                                                <NeoInputRequired
                                                                    changeCallback={this.onlyNumber.bind(this, "secao")}
                                                                    name="secao"
                                                                    type="text"
                                                                    value={this.state.secao}
                                                                    validators={['isSecao']}
                                                                    errorMessages={['Seção inválida']}


                                                                    attr={{

                                                                        mask: 'zonaTituloEleitor',
                                                                        ref: 'secao',
                                                                        className: 'form-control',
                                                                        id: 'secao',
                                                                        placeholder: 'Seção',
                                                                        obrigatorio: true
                                                                    }} />
                                                            </Col>
                                                            <Col xs={12} md={3}>
                                                                <NeoInputRequired

                                                                    changeCallback={this.onlyNumber.bind(this, "zona")}
                                                                    name="zona"
                                                                    type="text"
                                                                    value={this.state.zona}
                                                                    validators={['isZona']}
                                                                    errorMessages={['Zona inválida']}

                                                                    attr={{

                                                                        mask: 'secaoTituloEleitor',
                                                                        ref: 'zona',
                                                                        className: 'form-control',
                                                                        id: 'zona',
                                                                        placeholder: 'Zona',
                                                                        obrigatorio: true
                                                                    }} />
                                                            </Col>
                                                        </Row>
                                                        <Row style={styles.rowSpace} className="verticalAlign">
                                                            <Col xs={12} md={6}></Col>
                                                            <Col xs={12} md={3}>
                                                                <label htmlFor="numTituloEleitor" className="form-control-label">Ou</label>
                                                            </Col>
                                                        </Row>
                                                        {
                                                            this.state.telaCarregou ?
                                                                <Row style={styles.rowSpace} className="verticalAlign">
                                                                    <Col xs={12} md={6}></Col>
                                                                    <Col xs={12} md={6}>
                                                                        <NeoInputFile value={this.inputFileControl}
                                                                            callback={this.inputFileControl}
                                                                            data={this.state.anexoSalvoBanco}
                                                                            id="tituloEleitor"
                                                                            attr={{
                                                                                multiple: false,
                                                                                texto: 'Título de Eleitor',
                                                                            }} />
                                                                    </Col>
                                                                </Row>
                                                                : null
                                                        }
                                                    </div>
                                                </div >
                                                {/* Begin Register Form */}

                                            </div>
                                        </Row>
                                    </div>
                                </div>
                            </Row>
                        </div>
                    </div>
                    <NeoButtonNav text="Voltar" callback={this.alertDecisionControl.bind(this, 'abrir', 'Atenção', 'Ao voltar os dados preenchidos serão perdidos, deseja continuar?')} />
                    <NeoSubmit text="Continuar" color="green" callback={this.handleSubmit} loading={this.state.loading} />
                    <NeoPercent text="60%" />
                </ValidatorForm>
            </div>


        );
    }
}

export default Step_F1;