import React, { Component } from 'react';
import logo from '../images/the-logo-color.png';
import { CONFIG } from '../config/index';
import { GLOBAL } from '../config/global';
import { auth } from '../auth';
import NeoNavbar2 from '../components/NeoNavbar/NeoNavbar2'
import { NeoInputRequired } from '../components/NeoInputs/NeoInputs'
import { ValidatorForm } from 'react-form-validator-core';
import { NeoSubmit } from '../components/NeoButtons/NeoButtons'
import { NeoAlert, NeoLoading } from '../components/NeoAlert/NeoModal'
import { faGrinTongueSquint } from '@fortawesome/free-solid-svg-icons';

class LoginForm extends Component {

    constructor() {
        super();
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.updateValue = this.updateValue.bind(this);
        this.showPassword = this.showPassword.bind(this);
        this.alertControl = this.alertControl.bind(this);
        this.formatLogin = this.formatLogin.bind(this);

        this.state = {
            username: undefined,
            login: '',
            password: '',
            passwordType: 'password',
            signUp: {
                success: undefined,
                message: undefined
            },
            logged: false,
            users: undefined,
            error: undefined,
            userId: GLOBAL.getCookie('_uf_neoId'),

        }
    }

    static displayName = 'ui-LoginForm'

    componentDidMount() {
        if (this.state.userId && this.state.userId != 'undefined' && this.state.userId != "null") {
            window.location = '/register'
        }
        // this.verifytoken();
    }

    verifytoken() {
        let url = CONFIG.API_PREFIX + '/auth/verifytoken';
        let token = localStorage.getItem(CONFIG.TOKEN_NAME);

        if (!token) {
            this.setState({
                error: 'No token defined. Please Login.'
            })
            return
        }

        fetch(url, {
            method: "GET",
            body: undefined,
            headers: {
                "Content-Type": "application/json",
                "authorization": `Bearer ${token}`
            }
        }).then(response => response.json())
            .then(responseJson => {
                if (responseJson.success) {
                    this.setState({
                        logged: responseJson.success,
                        error: undefined
                    })
                } else {
                    this.setState({
                        error: responseJson.error.message
                    })
                }
            }).catch(err => this.setState({ error: err }));
    }

    loadUsers() {
        let url = CONFIG.API_PREFIX + '/users/listusers';
        let token = localStorage.getItem(CONFIG.TOKEN_NAME);
        if (!token) {
            this.setState({
                error: 'No token defined. Please Login.'
            })
            return
        }

        fetch(url, {
            method: "POST",
            body: undefined,
            headers: {
                "Content-Type": "application/json",
                "authorization": `Bearer ${token}`
            }
        }).then(response => response.json())
            .then(responseJson => {
                this.setState({
                    users: responseJson.data,
                    error: undefined
                })
            }).catch(err => this.setState({ error: err }));
    }

    showAuthorizedArea() {
        if (auth.session.logged) {
            this.props.history.push({
                pathname: '/blog-overview'
            })
            // return (
            //     <div>
            //         <button type="button" className="btn btn-primary btn-block" data-toggle="modal" data-target="#authenticatedModal" data-whatever="@mdo" >Call Authenticated only API</button>
            //         <small id="emailHelp" className="form-text text-muted">Only registered and logged users can call and see the list. Plese click the button above to call the API.</small>
            //     </div>
            // );
        }
    }

    /*
    Login Form area 
    */

    handleSubmit(e) {
        // console.log("submit")
        e.preventDefault();
        let self = this;
        let dataToSend = {
            userData: {
                email: this.state.login.includes('@') ? this.state.login : this.state.login.replace(/[\.-]/g, ''),
                password: this.state.password
            }
        };
        this.setState({
            loadingTitle: 'Aguarde, consultando cadastro',
            loading: true
        })
        auth.login(dataToSend, function (ERRO, MENSAGEM) {
            // console.log("login")
            if (ERRO == 0) {
                self.setState({
                    logged: true,
                    error: undefined,
                    loadingTitle: '',
                    loading: false
                })
                window.location = '/register'
            } else if (ERRO == 1) {
                self.setState({
                    logged: false,
                    error: MENSAGEM,
                    loadingTitle: '',
                    loading: false,
                    modalTitle: 'Ops',
                    modalText: 'Não foi possível encontrar o usuário informado'
                })
                self.alertControl();
            } else {
                self.setState({
                    logged: false,
                    error: MENSAGEM,
                    loadingTitle: '',
                    loading: false,
                    modalTitle: 'Ops',
                    modalText: 'Ocorreu um erro ao tentar efetuar o login... Tente novamente mais tarde.'
                })
                self.alertControl();
            }
        })

    }

    showPassword = function (name, evt) {
        if (this.state[name] == 'password') {
            this.setState(state => ({
                [name]: 'text'
            }))
        } else {
            this.setState(state => ({
                [name]: 'password'
            }))
        }
    }

    updateValue = function (name, evt) {
        let valor = evt.target.value
        this.setState(state => ({ [name]: valor }))
    }


    //Controla a o alerta
    alertControl() {
        this.setState(state => ({
            showAlert: !state.showAlert
        }))
    }

    formatLogin() {
        let login = this.state.login;

        if (!isNaN(login.replace(/[\.-]/g, '')) &&
            login.replace(/[\.-]/g, '').length === 11
        ) {
            login = login.replace(/^(\d{3})(\d{3})(\d{3})(\d{2}).*/, '$1.$2.$3-$4');
        }

        this.setState({
            login: login
        })
    }

    handleKeyDown = (event) => {

        if (event.key === 'Enter') {

            event.preventDefault();
        }
    };

    render() {
        return (
            <div className="s3-login page-login" style={{ height: '100vh' }}>
                <div id="mainWrapperLogin" style={{ position: 'absolute', width: '100%', top: '0', height: '85vh' }}>
                    <NeoNavbar2 />

                    <NeoAlert
                        show={this.state.showAlert}
                        title={this.state.modalTitle}
                        text={this.state.modalText}
                        close={this.alertControl}
                        class="modalFullScreen"
                    />

                    <NeoLoading
                        title={this.state.loadingTitle}
                        show={this.state.loading}
                        class="modalFullScreen"
                    />

                    <div className="container" style={{ height: '100%' }}>
                        <center className="alignCenter">
                            <div>
                                <ValidatorForm ref="form" onSubmit={this.handleSubmit} onKeyDown={e => { this.handleKeyDown(e) }}>

                                    <div style={{ backgroundColor: 'white', padding: '30px', borderRadius: '16px' }}>
                                        <div>
                                            <h2>Faça <strong style={{ fontSize: '26px' }}>login</strong> para continuar</h2>
                                        </div>
                                        <div>
                                            <NeoInputRequired
                                                changeCallback={this.updateValue.bind(this, "login")}
                                                blurCallback={this.formatLogin}
                                                name="login"
                                                type="text"
                                                value={this.state.login}
                                                validators={['required']}
                                                errorMessages={['']}
                                                attr={{
                                                    mask: 'login',
                                                    ref: 'login',
                                                    className: 'form-control loginInput',
                                                    id: 'login',
                                                    placeholder: 'E-mail ou CPF',
                                                }} />
                                        </div>
                                        <div>
                                            <NeoInputRequired
                                                changeCallback={this.updateValue.bind(this, "password")}
                                                showPassword={this.showPassword.bind(this, "passwordType")}
                                                name="password"
                                                type={this.state.passwordType}
                                                minLength="8"
                                                value={this.state.password}
                                                validators={['required']}
                                                errorMessages={['']}
                                                attr={{
                                                    mask: 'text',
                                                    ref: 'password',
                                                    className: 'form-control loginInput',
                                                    id: 'password',
                                                    placeholder: 'Senha',
                                                }}
                                            />
                                                        <NeoSubmit text="Login" color="green" classe="loginBtn" />
                                        </div>
                                    </div>
                        
                                </ValidatorForm>
                            </div>
                        </center>
                    </div>

                </div>
            </div>

        );
    }
}
export default LoginForm;