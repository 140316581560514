import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';

import { Button } from 'react-bootstrap';
import { GLOBAL } from '../../config/global';
import { CONFIG } from '../../config/index';
import styles from './styles/styles';
import './styles/styles.css';
import '../../assets/css/style.css';
import StepTitle from './StepTitle';
import { NeoInputFile } from '../NeoInputs/NeoInputs'
import { NeoAlert, NeoLoading, NeoAlertDecision } from '../NeoAlert/NeoModal';
import { NeoButtonNav, NeoSubmit } from '../NeoButtons/NeoButtons';
import NeoTooltip from '../NeoTooltip/NeoTooltip'
import { NeoPercent } from '../NeoPercent/NeoPercent';

const stepAtual = 'G'

class Step_G extends Component {
    constructor() {
        super()
        this.handleSubmit = this.handleSubmit.bind(this);
        this.inputFileControl = this.inputFileControl.bind(this);
        this.alertControl = this.alertControl.bind(this);
        this.loadingControl = this.loadingControl.bind(this);
        this.alertDecisionControl = this.alertDecisionControl.bind(this);

        this.state = {
            choiceVisible: true,
            documents: {
                carteiraTrabalhoFoto: { size: null },
                carteiraTrabalhoQualificacao: { size: null },
                rgcnh: { size: null },
                comprovanteEndereco: { size: null },
            },
            userId: GLOBAL.getCookie('_uf_neoId'),
            cpf: null,
            funcionarioCadastrado: false,
            quantFuncCadastrados: null,
            telaCarregou: false,
            modalEsconderBotao: false,

        }
    }

    static displayName = 'ui-RegisterForm'

    componentDidMount() {

        document.getElementById('s3-cadastro').classList.add('specialSize')


        this.loadingControl(true, 'Aguarde');

        //verifica se o id tá salvo no cookie
        let userId = this.state.userId;
        // console.log(userId);
        if (userId && userId != "undefined") {

            let url = CONFIG.API_PREFIX + '/users/finduser';
            fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    userLogin: {
                        _id: userId
                    }
                })

            }).then(response => response.json())
                .then(responseJson => {
                    if (responseJson.success) {

                        let dados = responseJson.data;
                        // console.log(dados)
                        this.setState({
                            cpf: dados.cpf,
                            quantFuncCadastrados: dados.quantFuncCadastrados,
                            declarouIR: dados.declarouIR
                        })

                        //Consulta dados do funcionario      
                        url = CONFIG.API_PREFIX + '/funcionarios/findfuncionario';

                        fetch(url, {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json"
                            },
                            body: JSON.stringify({
                                userLogin: {
                                    idEmpregador: this.state.userId,
                                    numeroFuncionario: Number(this.state.quantFuncCadastrados) + 1
                                }
                            })
                        }).then(response => response.json())
                            .then(responseJson => {
                                if (responseJson.success) {

                                    var documentos = {}

                                    if (!responseJson.data.documentos[0].caminho) {
                                        this.setState({
                                            funcionarioCadastrado: true,
                                            funcionario: responseJson.data,
                                            telaCarregou: true
                                        })
                                        this.loadingControl(false, '');
                                    } else {
                                        responseJson.data.documentos.forEach(function (item) {
                                            documentos[item.caminho.split('_')[1].split('.')[0]] = item
                                        })

                                        this.setState({
                                            funcionarioCadastrado: true,
                                            funcionario: responseJson.data,
                                            documents: documentos,
                                            telaCarregou: true
                                        })

                                        this.loadingControl(false, '');

                                    }

                                } else {
                                    this.setState({
                                        telaCarregou: true
                                    })
                                    this.loadingControl(false, '');

                                }
                            })

                        //controla o histórico de steps
                        let historicoStep = responseJson.data.historicoStep;
                        if (historicoStep[historicoStep.length - 1] === stepAtual) historicoStep.pop();
                        this.setState({
                            historicoStep: historicoStep,
                        })

                    } else {
                        this.setState({
                            telaCarregou: true
                        })
                        this.loadingControl(false, '');
                    }
                }).catch(err => {
                    console.error(err)
                    this.setState({
                        telaCarregou: true,
                        modalTitle: "Ops!",
                        modalText: "Ocorreu um erro ao buscar os dados. Entre em contato com o administrador do sistema",
                        modalEsconderBotao: true
                    })
                    this.loadingControl(false, '');
                    this.alertControl();
                });

        } else {
            this.loadingControl(false, '');
            this.setState({
                declarouIR: 1
            })
        }
        this._isMounted = true;
    }

    changeVisibility = () => {
        this.setState(state => ({
            choiceVisible: !state.choiceVisible
        }))
    }

    //Tratativa do input file
    inputFileControl(e) {

        // console.log(e);

        let acceptedTypes = ['pdf', 'jpeg', 'png', 'jpg'];

        if (e.erro == true) {
            this.setState({
                modalTitle: 'Ops!',
                modalText: 'Ocorreu um erro ao anexar o arquivo',
            })
        }
        else if (e.size > 10000000 && e.size) {
            this.setState({
                modalTitle: 'Ops!',
                modalText: 'O arquivo selecionado é muito grande. Anexe um documento com até 10mb.',
            })
            this.alertControl();

        } else if (!acceptedTypes.includes(e.extensao) && e.extensao) {
            this.setState({
                modalTitle: 'Ops!',
                modalText: 'O formato do arquivo selecionado é inválido.',
            })
            this.alertControl();
        } else {
            // console.log(e.id)
            let documentos = Object.assign({}, this.state.documents);
            // console.log(documentos);
            documentos[e.id] = e;
            this.setState((state) => {
                return {
                    documents: documentos
                }
            })
        }
        if (!e.extensao && !e.size) {
            this.setState(() => ({
                anexoSalvoBanco: null,
            }))
        }
    }

    //Controla o loading
    loadingControl(visible, title) {
        this.setState({
            loading: visible,
            modalTitle: title
        })
    }

    /*
    Grava dados no banco
    */

    objectSize = function (obj) {
        var size = 0, key;
        for (key in obj) {
            if (obj.hasOwnProperty(key)) size++;
        }
        return size;
    };

    gravaDocumento(callback) {
        let self = this;
        let documentos = this.state.documents;
        let docSize = this.objectSize(documentos);
        let documentosGravados = 0;
        let key;
        this.loadingControl(true, 'Salvando Documentos');
        let deuErro = false;


        if (documentosGravados == docSize) {
            if (!deuErro) {
                // console.log("GRAVOU")
                callback(false, 'documentos salvos com sucesso');
            } else {
                //assim n aparece um erro pra cada gravação que falhou, mas sim um geral
                // console.log("DEU ERRO")
                callback(true, 'Erro ao salvar documentos');
            }

        } else {
            for (key in documentos) {
                let fileData = {
                    "fileName": `func${this.state.quantFuncCadastrados + 1}_${documentos[key].id}.${documentos[key].extensao}`,
                    "folderName": this.state.cpf,
                    "base64": documentos[key].base64
                }
                if (documentos[key]["base64"]) {
                    let url = CONFIG.API_PREFIX + '/files/saveFile';
                    fetch(url, {
                        method: "POST",
                        headers: {
                            "Origin": "*",
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify(
                            { "fileData": fileData }
                        )

                    }).then(response => response.json())
                        .then(responseJson => {
                            if (responseJson.success) {

                                documentosGravados++;
                                // console.log(documentosGravados)

                                if (documentosGravados == docSize) {
                                    // console.log("documentos salvos com sucesso 1")
                                    callback(false, 'documentos salvos com sucesso');
                                }

                            } else {
                                // console.log("Deu ruim");
                                // console.log(responseJson.success);
                                deuErro = true;
                                documentosGravados++;
                                if (documentosGravados == docSize && deuErro) {
                                    callback(true, 'Erro ao salvar documentos');
                                }
                            }
                        }).catch(err => {
                            documentosGravados++;
                            // console.log("Deu ruim: " + documentosGravados);
                            console.error(err);
                            deuErro = true;
                            if (documentosGravados == docSize && deuErro) {
                                callback(true, 'Erro ao salvar documentos');
                            }
                        });
                } else {
                    documentosGravados++;
                    if (deuErro) {
                        if (documentosGravados == docSize) {
                            deuErro = true;
                        }
                    } else {
                        if (documentosGravados == docSize) {
                            // console.log("documentos salvos com sucesso 2")
                            callback(false, 'documentos salvos com sucesso');
                        }
                    }

                }
            }
        }
    }

    atualizaDadosEmpregador(step, numFunc, callback) {
        let self = this;
        let historicoStep = this.state.historicoStep
        // console.log(historicoStep)

        if (this.state.historicoStep[this.state.historicoStep.length - 1] != step) {
            historicoStep = self.state.historicoStep;
            historicoStep.push(stepAtual);
        }



        let dataToSend = {
            _id: this.state.userId,
            params: {
                step: step,
                historicoStep: historicoStep,
                quantFuncCadastrados: Number(this.state.quantFuncCadastrados) + numFunc
            }
        }
        if (numFunc == null) delete (dataToSend.params.quantFuncCadastrados)

        let url = CONFIG.API_PREFIX + '/users/updateUser';
        fetch(url, {
            method: "PUT",
            headers: {
                "Origin": "*",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                data: dataToSend
            })

        }).then(response => response.json())
            .then(responseJson => {
                if (responseJson.success) {
                    callback(false, responseJson.success)
                } else {
                    // console.log("Deu ruim");
                    // console.log(responseJson.success);
                    self.loadingControl(false, '');

                    self.setState({
                        modalTitle: 'Ops!',
                        modalText: 'Ocorreu um erro ao salvar os dados',
                    })
                    self.alertControl();
                    callback(true, 'err')
                }

            }).catch(err => {
                console.error(err);
                callback(true, 'err')
            });
    }

    criaFuncionario(callback) {

        let self = this;
        let documentos = this.state.documents;
        let docSize = this.objectSize(documentos);
        let documentosGravados = 0;
        let key;
        let documentsToSend = []
        this.loadingControl(true, 'Salvando Dados');

        if (documentosGravados == docSize) {
            callback({ err: false });
        } else {
            for (key in documentos) {

                let anexoToSend = {
                    caminho: documentos[key].id ? `${self.state.cpf}/func${this.state.quantFuncCadastrados + 1}_${documentos[key].id}.${documentos[key].extensao}` : null,
                    nomeOriginal: documentos[key].name,
                    tamanho: documentos[key].size
                }

                documentsToSend.push(anexoToSend);
            }
        }


        let url = CONFIG.API_PREFIX + '/funcionarios/createFuncionario';
        fetch(url, {
            method: "POST",
            headers: {
                "Origin": "*",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                numeroFuncionario: Number(self.state.quantFuncCadastrados) + 1,
                idEmpregador: self.state.userId,
                documentos: documentsToSend
            })

        }).then(response => response.json())
            .then(responseJson => {
                if (responseJson.success) {
                    callback(false, responseJson.success)
                } else {
                    // console.log("Deu ruim");
                    // console.log(responseJson.success);
                    callback(true, 'err')
                }

            }).catch(err => {
                console.error(err);
                callback(true, 'err')
            });
    }

    atulizaDadosFuncionario(callback) {
        var self = this;

        let documentos = this.state.documents;
        let docSize = this.objectSize(documentos);
        let documentosGravados = 0;
        let key;
        let documentsToSend = []

        if (documentosGravados == docSize) {
            callback(false);
        } else {
            for (key in documentos) {
                let anexoToSend = {}
                if (documentos[key].name) {
                    anexoToSend = {
                        caminho: documentos[key].id ? `${self.state.cpf}/func${this.state.quantFuncCadastrados + 1}_${documentos[key].id}.${documentos[key].extensao}` : null,
                        nomeOriginal: documentos[key].name,
                        size: documentos[key].size
                    }
                } else {
                    anexoToSend = {
                        caminho: documentos[key].caminho,
                        nomeOriginal: documentos[key].nomeOriginal,
                        size: documentos[key].size
                    }
                }


                documentsToSend.push(anexoToSend);
            }
        }

        self.loadingControl(true, 'Salvando dados, aguarde');

        self.setState({
            loadingButton: true,
        })

        let dataToSend = {
            idEmpregador: self.state.userId,
            numeroFuncionario: Number(self.state.quantFuncCadastrados) + 1,
            params: {
                documentos: documentsToSend
            }
        }

        let url = CONFIG.API_PREFIX + '/funcionarios/updateFuncionario';

        fetch(url, {
            method: "PUT",
            headers: {
                "Origin": "*",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                data: dataToSend
            })
        }).then(response => response.json())
            .then(responseJson => {

                if (responseJson.success) {

                    // console.log(responseJson);

                    callback(false, responseJson);

                    self.loadingControl(false, '');
                }

                else {
                    // console.log(responseJson.success);

                    self.loadingControl(false, '');

                    self.setState({
                        modalTitle: 'Ops!',
                        modalText: 'Ocorreu um erro ao salvar os dados',
                    })
                    self.alertControl();
                    callback(true, 'err');

                }

                self.setState({
                    loadingButton: false,
                })

            }).catch(err => {

                console.error(err);

                self.loadingControl(false, '');
                callback(true, 'err');

                self.setState({
                    modalTitle: 'Ops!',
                    modalText: 'Ocorreu um erro ao salvar os dados',
                    loadingButton: false,
                })

                self.alertControl();
            });
    }

    gravaDados() {
        let self = this;
        this.loadingControl(true, 'Gravando dados')
        let deuErro = false;
        self.gravaDocumento(function (err, response) {

            if (!err) {
                if (self.state.funcionarioCadastrado) {
                    self.atulizaDadosFuncionario(function (err, responseAtualiza) {
                        if (!err) {
                            self.atualizaDadosEmpregador('H1', 1, function (err, responseEmpregador) {
                                self.props.nextScreen();
                            })
                        } else {
                            deuErro = true;
                        }
                    })
                } else {
                    self.criaFuncionario(function (err, responseCria) {
                        if (!err) {
                            self.atualizaDadosEmpregador('H1', 1, function (err, responseEmpregador) {
                                self.props.nextScreen();
                            })
                        } else {
                            deuErro = true;
                        }
                    })
                }
            } else {
                deuErro = true;
            }

            if (deuErro) {
                //assim n aparece um erro pra cada gravação que falhou, mas sim um geral
                self.loadingControl(false, '');

                self.setState({
                    modalTitle: 'Ops!',
                    modalText: 'Ocorreu um erro ao salvar os dados',
                })
                self.alertControl();
            }
        })
    }

    //Controla a visibilidade do alerta decisivo
    alertDecisionControl(acao, titulo, mensagem, evt) {
        let self = this;
        let lastScreenCode = this.state.historicoStep[this.state.historicoStep.length - 1];
        // console.log(this.state.historicoStep);

        this.setState(state => ({
            showAlertDecision: !state.showAlertDecision,
            modalTitle: titulo,
            modalText: mensagem,
        }))

        if (acao == 'continuar') {
            self.loadingControl(true, 'Aguarde');

            self.atualizaDadosEmpregador(lastScreenCode, 0, function (err, response) {
                if (!err) {
                    self.props.lastScreen(lastScreenCode);
                } else {
                    self.loadingControl(false, '');
                    self.setState({
                        modalTitle: 'Ops!',
                        modalText: 'Ocorreu um erro ao buscar as informações',
                    })
                    self.alertControl();
                }
            });


        }
    }

    handleSubmit(e) {
        let documentos = this.state.documents;
        // console.log(documentos)


        if (
            (documentos.carteiraTrabalhoFoto.size || documentos.carteiraTrabalhoFoto.caminho)
            && (documentos.carteiraTrabalhoQualificacao.size || documentos.carteiraTrabalhoQualificacao.caminho)
            && (documentos.rgcnh.size || documentos.rgcnh.caminho)
            && (documentos.comprovanteEndereco.size || documentos.comprovanteEndereco.caminho)
        ) {
            this.gravaDados();

        } else {
            this.setState({
                modalTitle: 'Ops!',
                modalText: 'É necessário anexar todos os documentos',
            })
            this.alertControl();
        }

    }

    //Controla a visibilidade do alerta
    alertControl() {
        this.setState(state => ({
            showAlert: !state.showAlert
        }))
    }

    handleKeyDown = (event) => {

        if (event.key === 'Enter') {

            event.preventDefault();
        }
    };

    render() {

        let qtdFunc = this.state.quantFuncCadastrados != null ? `${this.state.quantFuncCadastrados + 1}º` : ''

        const textoTitulo = {
            titulo: `Agora precisamos das informações do ${qtdFunc} funcionário`,
            produto: '',
            subtitulo: '',
            alinhamento: 'center'
        }

        return (
            <div className="s3-step" data-step="G">

                <NeoAlert
                    show={this.state.showAlert}
                    title={this.state.modalTitle}
                    text={this.state.modalText}
                    close={this.alertControl}
                    modalEsconderBotao={this.state.modalEsconderBotao}
                />

                <NeoAlertDecision
                    show={this.state.showAlertDecision}
                    title={this.state.modalTitle}
                    text={this.state.modalText}
                    sim={this.alertDecisionControl.bind(this, "continuar")}
                    nao={this.alertDecisionControl.bind(this, "cancelar")}
                />

                <NeoLoading title={this.state.modalTitle} show={this.state.loading} />


                <div className="s3-wrapper">

                    <Row >
                        <StepTitle texto={textoTitulo} />
                        <div className="s3-content col-md-12" style={{ padding: "0px" }}>
                            <div className="s3-signup" style={{ margin: "0px" }}>
                                <Row>
                                    <div className="s3-form-item clearfix col-md-12" style={{ padding: "0" }}>
                                        {/* Begin Register Form */}
                                        < div className="register-content" style={{ marginTop: "10px" }}>

                                            <div className="register-body" style={{ padding: "0" }}>
                                                <form onSubmit={this.handleSignUpSubmit} onKeyDown={e => { this.handleKeyDown(e) }}>
                                                    {
                                                        this.state.telaCarregou ?
                                                            <div>
                                                                <Row style={styles.rowSpace}>
                                                                    <Col xs={12} md={2}></Col>
                                                                    <Col xs={12} md={8}>
                                                                        <label className="form-control-label align-center">Envie-nos uma cópia (digitalizada ou foto) dos seguintes documentos do funcionário:</label>
                                                                    </Col>
                                                                    <Col xs={12} md={2}></Col>
                                                                </Row>


                                                                <Row style={styles.rowSpace} className="verticalAlign">
                                                                    <Col xs={12} md={2}></Col>
                                                                    <Col xs={10} md={8}>
                                                                        <NeoInputFile value={this.inputFileControl}
                                                                            callback={this.inputFileControl}
                                                                            data={this.state.documents.carteiraTrabalhoFoto}
                                                                            id="carteiraTrabalhoFoto"
                                                                            attr={{
                                                                                multiple: false,
                                                                                texto: 'Carteira de Trabalho – Página com foto',
                                                                            }} />
                                                                    </Col>
                                                                    <Col xs={2} md={1}>
                                                                        <NeoTooltip position="top" text="Página com foto e número da CTPS" />
                                                                    </Col>
                                                                </Row>
                                                                <Row style={styles.rowSpace} className="verticalAlign">
                                                                    <Col xs={12} md={2}></Col>
                                                                    <Col xs={10} md={8}>
                                                                        <NeoInputFile value={this.inputFileControl}
                                                                            callback={this.inputFileControl}
                                                                            data={this.state.documents.carteiraTrabalhoQualificacao}
                                                                            id="carteiraTrabalhoQualificacao"
                                                                            attr={{
                                                                                multiple: false,
                                                                                texto: 'Carteira de Trabalho – Qualificação',
                                                                            }} />
                                                                    </Col>
                                                                    <Col xs={2} md={1}>
                                                                        <NeoTooltip position="top" text="Página que contém nome, filiação, local de nascimento, dentre outros" />
                                                                    </Col>
                                                                </Row>

                                                                <Row style={styles.rowSpace} className="verticalAlign">
                                                                    <Col xs={12} md={2}></Col>
                                                                    <Col xs={10} md={8}>
                                                                        <NeoInputFile value={this.inputFileControl}
                                                                            callback={this.inputFileControl}
                                                                            data={this.state.documents.rgcnh}
                                                                            id="rgcnh"
                                                                            attr={{
                                                                                multiple: false,
                                                                                texto: 'RG (verso) ou CNH (aberta)',
                                                                            }} />
                                                                    </Col>
                                                                    <Col xs={2} md={1}>
                                                                        <NeoTooltip position="top" text="Precisamos do número da identidade" />
                                                                    </Col>
                                                                </Row>

                                                                <Row style={styles.rowSpace} className="verticalAlign">
                                                                    <Col xs={12} md={2}></Col>
                                                                    <Col xs={10} md={8}>

                                                                        <NeoInputFile value={this.inputFileControl}
                                                                            callback={this.inputFileControl}
                                                                            data={this.state.documents.comprovanteEndereco}
                                                                            id="comprovanteEndereco"
                                                                            attr={{
                                                                                multiple: false,
                                                                                texto: 'Comprovante de endereço',
                                                                            }} />
                                                                    </Col>
                                                                    <Col xs={2} md={1}>
                                                                        <NeoTooltip position="top" text="Conta de água, energia elétrica, telefonia ou cartão de crédito" />
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                            : <div className="bigSpace"> </div>
                                                    }
                                                </form>
                                            </div>
                                        </div >
                                        {/* Begin Register Form */}

                                    </div>
                                </Row>
                            </div>
                        </div>
                    </Row>
                </div>
                <NeoButtonNav text="Voltar" callback={this.alertDecisionControl.bind(this, 'abrir', 'Atenção', 'Ao voltar os dados preenchidos serão perdidos, deseja continuar?')} />
                <NeoButtonNav text="Continuar" color="green" callback={this.handleSubmit} loading={this.state.loading} classe="removePadding" />
                <NeoPercent text="70%" />
            </div>



        );
    }
}

export default Step_G;