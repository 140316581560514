import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';

import { GLOBAL } from '../../config/global';
import { CONFIG } from '../../config/index';
import styles from './styles/styles';
import { ValidatorForm } from 'react-form-validator-core';
import './styles/styles.css';
import '../../assets/css/style.css';
import { NeoInputRequired, NeoInputFile } from '../NeoInputs/NeoInputs';
import { NeoAlert, NeoLoading, NeoAlertDecision } from '../NeoAlert/NeoModal';
import { NeoButtonNav } from '../NeoButtons/NeoButtons';
import { NeoPercent } from '../NeoPercent/NeoPercent';


const stepAtual = 'F3'

class Step_F3 extends Component {
    constructor() {
        super()
        this.handleSubmit = this.handleSubmit.bind(this);
        this.inputFileControl = this.inputFileControl.bind(this);
        this.alertControl = this.alertControl.bind(this);
        this.loadingControl = this.loadingControl.bind(this);

        this.state = {
            choiceVisible: true,
            numDeclaracao: '',
            anexo: {
                base64: null,
                nomeOriginal: null,
                size: null
            },
            anexoSalvoBanco: null,
            telaCarregou: false,
            modalClass: null,
            userId: null,
            modalEsconderBotao: false,
        }
    }

    static displayName = 'ui-RegisterForm'

    //roda assim q a tela abre
    componentDidMount() {

        document.getElementById('s3-cadastro').classList.add('contratos')

        this.setState({
            userId: GLOBAL.getCookie('_uf_neoId')
        })

        //verifica se o id tá salvo no cookie
        let userId = GLOBAL.getCookie('_uf_neoId');
        if (userId && userId != "undefined") {

            this.loadingControl(true, 'Aguarde');
            //Consulta pra preencher os dados da tela assim que entra       
            let url = CONFIG.API_PREFIX + '/users/finduser';
            fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    userLogin: {
                        _id: userId
                    }
                })
            }).then(response => response.json())
                .then(responseJson => {
                    if (responseJson.success) {
                        let dados = responseJson.data;
                        if (dados.PENULTIMOIRPF) {
                            this.setState(() => ({
                                numDeclaracao: dados.PENULTIMOIRPF.numDeclaracao,
                                anexoSalvoBanco: dados.PENULTIMOIRPF.anexos,
                                telaCarregou: true
                            }))
                        }
                        this.setState({
                            cpf: dados.cpf,
                            telaCarregou: true
                        })

                        //controla o histórico de steps
                        let historicoStep = responseJson.data.historicoStep;
                        if (historicoStep[historicoStep.length - 1] === stepAtual) historicoStep.pop();
                        this.setState({
                            historicoStep: historicoStep,
                        })

                    } else {
                        // console.log("Deu ruim");
                        // console.log(responseJson.success);
                        this.setState({
                            telaCarregou: true
                        })
                    }
                    this.loadingControl(false, '');
                }).catch(err => {
                    console.error(err);
                    this.loadingControl(false, '');
                    this.setState({
                        telaCarregou: true,
                        modalTitle: "Ops!",
                        modalText: "Ocorreu um erro ao buscar os dados. Entre em contato com o administrador do sistema",
                        modalEsconderBotao: true
                    })
                    this.alertControl();
                });
        }
    }

    changeVisibility = () => {
        this.setState(state => ({
            choiceVisible: !state.choiceVisible
        }))
    }

    /*
Grava dados no banco
*/
    gravaDocumento(callback, msgLoading = "Salvando Dados") {
        let self = this;
        let fileData = {
            "fileName": "EMPREGADOR_PENULTIMO_IRPF." + this.state.anexo.extensao,
            "folderName": this.state.cpf,
            "base64": this.state.anexo.base64
        }

        this.loadingControl(true, msgLoading);
        let url = CONFIG.API_PREFIX + '/files/saveFile';
        fetch(url, {
            method: "POST",
            headers: {
                "Origin": "*",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(
                { "fileData": fileData }
            )

        }).then(response => response.json())
            .then(responseJson => {
                if (responseJson.success) {
                    callback(self);
                } else {
                    // console.log("Deu ruim");
                    // console.log(responseJson.success);
                    this.setState({
                        modalTitle: 'Ops!',
                        modalText: 'Ocorreu um erro ao salvar os dados',
                    })
                    this.alertControl('positionFixed');
                }
                this.loadingControl(false, '');

            }).catch(err => {
                console.error(err);
                this.loadingControl(false, '');
                this.setState({
                    modalTitle: 'Ops!',
                    modalText: 'Ocorreu um erro ao salvar os dados',
                })
                this.alertControl('positionFixed');
            });
    }

    gravarDados = function (self, nextScreen, callback, msgLoading = "Salvando Dados") {
        let anexoToSend;
        let historicoStep = this.state.historicoStep

        if (this.state.historicoStep[this.state.historicoStep.length - 1] != nextScreen) {
            historicoStep = self.state.historicoStep;
            historicoStep.push(stepAtual);
        }

        // console.log(self.state)
        if (self.state.anexo.base64) {
            anexoToSend = {
                caminho: self.state.anexo ? `${self.state.cpf}/EMPREGADOR_PENULTIMO_IRPF.${self.state.anexo.extensao}` : null,
                nomeOriginal: self.state.anexo.name,
                tamanho: self.state.anexo.size
            }
        } else {
            anexoToSend = this.state.anexoSalvoBanco
        }
        let dataToSend = {
            _id: self.state.userId,
            params: {
                step: nextScreen,
                historicoStep: historicoStep,
                PENULTIMOIRPF: {
                    numDeclaracao: self.state.numDeclaracao,
                    anexos: anexoToSend,
                }
            }
        }
        self.loadingControl(true, msgLoading);
        let url = CONFIG.API_PREFIX + '/users/updateUser';
        fetch(url, {
            method: "PUT",
            headers: {
                "Origin": "*",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                data: dataToSend
            })

        }).then(response => response.json())
            .then(responseJson => {
                if (responseJson.success) {
                    // console.log(responseJson)
                    callback(false, responseJson);
                } else {
                    // console.log("Deu ruim");
                    // console.log(responseJson.success);
                    self.setState({
                        modalTitle: 'Ops!',
                        modalText: 'Ocorreu um erro ao salvar os dados',
                    })
                    self.alertControl('positionFixed');
                    callback(true, 'Erro ao salvar dados');
                }
                self.loadingControl(false, '');

            }).catch(err => {
                console.error(err);
                self.loadingControl(false, '');
                self.setState({
                    modalTitle: 'Ops!',
                    modalText: 'Ocorreu um erro ao salvar os dados',
                })
                self.alertControl('positionFixed');
                callback(true, 'Erro ao salvar dados');
            });
    }

    handleSubmit(e) {

        let numDeclaracao = this.state.numDeclaracao.replace(/[_,\.,–, ]/g, '');
        let anexo = this.state.anexo;
        let self = this;
        let camposValidados = Boolean((numDeclaracao.length && numDeclaracao.length == 12) || (anexo.base64 || this.state.anexoSalvoBanco));

        if (!camposValidados) {
            this.setState({
                modalTitle: 'Ops!',
                modalText: 'É necessário preencher todos os campos ou anexar um arquivo referente ao Imposto de Renda.',
            })
            this.alertControl("positionFixed");
        } else {
            if (this.state.anexo.base64) {
                // console.log(this.state)
                this.gravaDocumento(this.gravarDados(self, 'G', function (err, response) {
                    if (!err) {
                        self.props.nextScreen("G");
                    }
                }));
            } else {
                this.gravarDados(self, 'G', function (err, response) {
                    if (!err) {
                        self.props.nextScreen("G");
                    }
                });
            }
        }
    }

    //Tratativa do input file
    inputFileControl(e) {

        let acceptedTypes = ['pdf', 'jpeg', 'png', 'jpg'];

        if (e.erro == true) {
            this.setState({
                modalTitle: 'Ops!',
                modalText: 'Ocorreu um erro ao anexar o arquivo',
            })
        }
        else if (e.size > 10000000 && e.size) {
            this.setState({
                modalTitle: 'Ops!',
                modalText: 'O arquivo selecionado é muito grande. Anexe um documento com até 10mb.',
            })
            this.alertControl("positionFixed");

        } else if (!acceptedTypes.includes(e.extensao) && e.extensao) {
            this.setState({
                modalTitle: 'Ops!',
                modalText: 'O formato do arquivo selecionado é inválido.',
            })
            this.alertControl("positionFixed");
        } else {
            this.setState(() => ({
                anexo: e,
            }))
        }
        if (!e.extensao && !e.size) {
            this.setState(() => ({
                anexoSalvoBanco: null,
            }))
        }
    }

    //Controla a visibilidade do alerta
    alertControl(classe = '') {
        this.setState(state => ({
            modalClass: classe,
            showAlert: !state.showAlert,
        }))
    }

    //Controla o loading
    loadingControl(visible, title) {
        this.setState({
            loading: visible,
            modalTitle: title
        })
    }

    //Controla a visibilidade do alerta decisivo
    alertDecisionControl(acao, titulo, mensagem, evt) {
        let self = this;
        let lastStep = this.state.historicoStep[this.state.historicoStep.length - 1];

        this.setState(state => ({
            showAlertDecision: !state.showAlertDecision,
            modalTitle: titulo,
            modalText: mensagem,
        }))

        if (acao == 'continuar') {
            self.loadingControl(true, 'Aguarde');

            self.gravarDados(self, lastStep, function (err, response) {
                if (!err) {
                    self.props.lastScreen(lastStep);
                } else {
                    self.setState({
                        modalTitle: 'Ops!',
                        modalText: 'Ocorreu um erro. Tente novamente mais tarde.',
                    })
                }
            }, "Aguarde");

        }
    }

    //Atualiza os states
    updateValue = function (name, evt, value = null) {
        let valor = value;
        if (evt) {
            valor = evt.target.value
        }
        this.setState(() => ({ [name]: valor }))
        // console.log(this.state)
    }

    handleKeyDown = (event) => {

        if (event.key === 'Enter') {

            event.preventDefault();
        }
    };

    render() {

        return (
            <div>
                <NeoAlert
                    show={this.state.showAlert}
                    title={this.state.modalTitle}
                    text={this.state.modalText}
                    close={this.alertControl}
                    class={this.state.modalClass}
                    modalEsconderBotao={this.state.modalEsconderBotao}
                />

                <NeoLoading title={this.state.modalTitle} show={this.state.loading} />

                <NeoAlertDecision
                    show={this.state.showAlertDecision}
                    title={this.state.modalTitle}
                    text={this.state.modalText}
                    sim={this.alertDecisionControl.bind(this, "continuar")}
                    nao={this.alertDecisionControl.bind(this, "cancelar")}
                />

                <div className="s3-step" data-step="F2">

                    <ValidatorForm ref="form" onSubmit={() => console.log('submit')} onKeyDown={e => { this.handleKeyDown(e) }}>
                        <div className="s3-wrapper">

                            <Row >
                                <div className="s3-content col-md-12">
                                    <div className="s3-signup">
                                        <Row>
                                            <div className="s3-form-item clearfix col-md-12">
                                                {/* Begin Register Form */}
                                                < div className="register-content" style={{ marginTop: "90px" }}>

                                                    <div className="register-body" style={{ marginBottom: "20px" }}>
                                                        <Row style={styles.rowSpace} className="verticalAlign">
                                                            <Col xs={12} md={6}>
                                                                <label htmlFor="numDeclaracao" className="form-control-label">Informe o número de seu penúltimo recibo da Declaração de Imposto de Renda Pessoa Física (IRPF):</label>
                                                            </Col>
                                                            <Col xs={12} md={6}>

                                                                <NeoInputRequired
                                                                    changeCallback={this.updateValue.bind(this, "numDeclaracao")}
                                                                    name="numDeclaracao"
                                                                    type="text"
                                                                    value={this.state.numDeclaracao}
                                                                    validators={['isIRPF']}
                                                                    errorMessages={['Número inválido']}

                                                                    attr={{
                                                                        mask: 'irpf',
                                                                        ref: 'numDeclaracao',
                                                                        className: 'form-control',
                                                                        id: 'numDeclaracao',
                                                                        placeholder: 'Digitar o número da penúltima declaração',
                                                                    }} />
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xs={12} md={6}></Col>
                                                            <Col xs={12} md={6}>
                                                                <label htmlFor="" className="form-control-label">Ou</label>
                                                            </Col>
                                                        </Row>

                                                        {
                                                            this.state.telaCarregou ?
                                                                <Row style={styles.rowSpace} className="verticalAlign">
                                                                    <Col xs={12} md={6}></Col>
                                                                    <Col xs={12} md={6}>
                                                                        <NeoInputFile value={this.inputFileControl}
                                                                            data={this.state.anexoSalvoBanco}
                                                                            id="declaracaoIRPF"
                                                                            attr={{
                                                                                multiple: false,
                                                                                texto: 'Anexar declaração do IR',
                                                                            }} />
                                                                    </Col>
                                                                </Row>
                                                                : null
                                                        }
                                                        <Row style={styles.bigSpace}></Row>
                                                    </div>
                                                </div >
                                                {/* Begin Register Form */}
                                            </div>
                                        </Row>
                                    </div>
                                </div>
                            </Row>
                        </div>
                    </ValidatorForm>
                </div>
                <NeoButtonNav text="Voltar" callback={this.alertDecisionControl.bind(this, 'abrir', 'Atenção', 'Ao voltar os dados preenchidos serão perdidos, deseja continuar?')} />
                <NeoButtonNav text="Continuar" color="green" callback={this.handleSubmit} />
                <NeoPercent text="65%" />
            </div>


        );
    }
}

export default Step_F3;