import React, { Component } from 'react';
class LandingPage extends Component {

    constructor() {
        super();
    }

    static displayName = 'ui-LandingPage'

    render() {
        return (

            <div>
                <div className="container">
                    <ul className="nav">
                        <li className="nav-item">
                            <a className="nav-link active" href="/">Landing Page</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/login">Login</a>
                        </li>
                    </ul>
                </div>
                <section id="jumbotron" class="s3-section-jumbotron">
                    <div class="container">
                        <div class="row">
                            <div class="col-xs-12">
                                <h1><span>Sua contabilidade agora é fácil, acessível e digital.</span><strong>Conheça o NeoContador.</strong></h1>
                                <div class="s3-slider">
                                    <div class="s3-slider-arrows">
                                        <button class="s3-slick-prev"><i class="s3-icon-arrow-left-02"></i></button>
                                        <button class="s3-slick-next"><i class="s3-icon-arrow-right-02"></i></button>
                                    </div>
                                    <div class="s3-slider-navigation hidden-xs hidden-sm">
                                        <ul>
                                            <li class="active" data-goto="0"><a href="JavaScript:void(0);">Declaração de Imposto de Renda</a></li>
                                            <li class="" data-goto="1"><a href="JavaScript:void(0);">Micro Empreendedor Individual</a></li>
                                            <li class="" data-goto="2"><a href="JavaScript:void(0);">Folha de Pagamento de Domésticas</a></li>
                                        </ul>
                                    </div>
                                    <div class="s3-slick">

                                        <div class="s3-slick-item">
                                            <div class="s3-flex-wrapper">
                                                <div class="s3-image" style="background-image: url('data/images/slide-03.png');"></div>
                                                <div class="s3-content">
                                                    <div class="s3-header">Folha de Pagamento de Domésticas</div>
                                                    <div class="s3-body">

                                                        <div class="s3-wrapper">
                                                            <div class="s3-price">
                                                                <span class="s3-number">R$39,90*</span>
                                                            </div>
                                                            <div class="s3-ast">* por doméstica</div>
                                                            <div class="s3-cta">
                                                                <a href="JavaScript:void(0);" class="s3-btn grey material">Em Breve</a>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="secOptimize" class="s3-section-optimize">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-8 col-md-offset-2">
                                <div class="s3-image"><img src="data/images/img-optimize.png" alt="" /></div>
                                <div class="s3-content">
                                    <div class="s3-header">
                                        <h2>Existimos para otimizar seu tempo</h2>
                                    </div>
                                    <div class="s3-body">
                                        <p>Chega de anotar tudo no caderninho! Agora que você conhece o NeoContador, pode ter certeza que cuidaremos de todo seu processo contábil com o maior carinho do mundo, enquanto você dedica todo o seu tempo no que mais importa: o seu negócio.</p>
                                        <div class="s3-cta"><a href="./register" class="s3-btn green material">Cadastre Aqui</a></div>
                                        <p><a href="JavaScript:void(0);">Tem alguma dúvida? A gente te ajuda!</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="secNumbers" class="s3-section-numbers">
                    <div class="s3-badge"><img src="data/images/badge.png" alt="NeoContador" /></div>
                    <div class="container">
                        <div class="row">
                            <div class="col-md-8 col-md-offset-2">
                                <h2>Os números são impressionantes!</h2>
                                <h3>Mas convenhamos, não podia ser diferente.</h3>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-10 col-md-offset-1">
                                <div class="s3-numbers">
                                    <div class="row">

                                        <div class="col-sm-4">
                                            <div class="s3-item">
                                                <div class="s3-number">752</div>
                                                <div class="s3-name">Funcionários</div>
                                                <div class="s3-description">de nossos clientes ativos<br />em nossos sistemas em 01/2024</div>
                                            </div>
                                        </div>

                                        <div class="col-sm-4">
                                            <div class="s3-item">
                                                <div class="s3-number">26</div>
                                                <div class="s3-name">Pessoas</div>
                                                <div class="s3-description">dedicadas à excelência<br />no serviço para você</div>
                                            </div>
                                        </div>

                                        <div class="col-sm-4">
                                            <div class="s3-item">
                                                <div class="s3-number">+6300</div>
                                                <div class="s3-name">Guias</div>
                                                <div class="s3-description">de tributos <br />geradas em 2023</div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="secTestimonials" class="s3-section-testimonials hidden">
                    <div class="container">
                        <div class="row">
                            <div class="col-xs-12">
                                <h2>Nossos clientes têm toda razão.</h2>
                            </div>
                        </div>
                        <div class="row">
                            <div class="s3-testimonials-slick">

                                <div class="col-md-4">
                                    <div class="s3-item">
                                        <div class="s3-box">
                                            <div class="s3-content">“Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.” Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.“</div>
                                            <div class="s3-info">
                                                <p>Raphael Felicio</p>
                                                <p>Co-Founder da Estúdio Two</p>
                                            </div>
                                        </div>
                                        <div class="s3-avatar">
                                            <img src="data/images/img-avatar.png" alt="Avatar" />
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <div class="s3-item">
                                        <div class="s3-box">
                                            <div class="s3-content">“Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.” Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.“</div>
                                            <div class="s3-info">
                                                <p>Raphael Felicio</p>
                                                <p>Co-Founder da Estúdio Two</p>
                                            </div>
                                        </div>
                                        <div class="s3-avatar">
                                            <img src="data/images/img-avatar.png" alt="Avatar" />
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <div class="s3-item">
                                        <div class="s3-box">
                                            <div class="s3-content">“Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.” Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.“</div>
                                            <div class="s3-info">
                                                <p>Raphael Felicio</p>
                                                <p>Co-Founder da Estúdio Two</p>
                                            </div>
                                        </div>
                                        <div class="s3-avatar">
                                            <img src="data/images/img-avatar.png" alt="Avatar" />
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>

                <section id="secCta" class="s3-section-cta">
                    <div class="s3-header">
                        <div class="container">
                            <div class="row">

                                <div class="col-xs-12">
                                    <h2>
                                        <span>Otimize seus gastos com contabilidade!</span>
                                        <a href="./register" class="s3-btn white material">Cadastre Aqui</a>
                                    </h2>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="s3-body">
                        <div class="container">
                            <div class="row">

                                <div class="col-xs-12">
                                    <div class="s3-image">
                                        <img src="data/images/macbook.png" alt="Macbook Preview" />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>

                <section id="secContact" class="s3-section-contact">
                    <div class="container">
                        <div class="row">

                            <div class="col-md-5">
                                <div class="s3-faq">
                                    <h3>Dúvidas Frequentes</h3>
                                    <div class="panel-group" id="pFaq" role="tablist" aria-multiselectable="true">

                                        <div class="panel">
                                            <div class="panel-heading" role="tab" id="ph01">
                                                <h4 class="panel-title">
                                                    <a role="button" data-toggle="collapse" data-parent="#pFaq" href="#c01" aria-expanded="true" aria-controls="c01">
                                                        Já sou MEI. Posso passar a utilizar agora?
										</a>
                                                </h4>
                                            </div>
                                            <div id="c01" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="ph01">
                                                <div class="panel-body">
                                                    <p>Sim, basta fazer o cadastro e nos informar seu CNPJ, que faremos a validação e verificaremos se está tudo em ordem com o seu MEI.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="panel">
                                            <div class="panel-heading" role="tab" id="ph02">
                                                <h4 class="panel-title">
                                                    <a class="collapsed" role="button" data-toggle="collapse" data-parent="#pFaq" href="#c02" aria-expanded="false" aria-controls="c02">
                                                        É possível enviar meus documentos online?
										</a>
                                                </h4>
                                            </div>
                                            <div id="c02" class="panel-collapse collapse" role="tabpanel" aria-labelledby="ph02">
                                                <div class="panel-body">
                                                    <p>Sim, nossa comunicação é toda digital, então o envio dos documentos e informações se dará de forma totalmente segura através de nossos sistemas.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="panel">
                                            <div class="panel-heading" role="tab" id="ph03">
                                                <h4 class="panel-title">
                                                    <a class="collapsed" role="button" data-toggle="collapse" data-parent="#pFaq" href="#c03" aria-expanded="false" aria-controls="c03">
                                                        Sou obrigado a declarar meu imposto de renda?
										</a>
                                                </h4>
                                            </div>
                                            <div id="c03" class="panel-collapse collapse" role="tabpanel" aria-labelledby="ph03">
                                                <div class="panel-body">
                                                    <p>Se você recebeu em 2016 rendimentos tributáveis cuja soma tenha sido maior que R$ 28.559,70 ou rendimentos isentos, não tributáveis ou tributados exclusivamente na fonte em valor superior a R$ 40.000,00; ou ainda tinha em 31 de dezembro de 2016, a posse ou a propriedade de bens ou direitos de valor total superior a R$ 300.000,00; se obteve, em qualquer mês de 2016, lucro na venda de bens ou direitos sujeitos à incidência de imposto de renda e ou tiver operado na Bolsa de Valores em 2016, você estará obrigado.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6 col-md-offset-1">
                                <div class="s3-contact">
                                    <h3>Entre em contato</h3>
                                    <p>Entre em contato conosco caso tenha alguma dúvida não sanada no <a href="./faq">Perguntas e Respostas</a>, sugestões ou comentários. Teremos prazer em responder!</p>
                                    <form class="s3-basic" id="contact" method="post" action="app/mailer/send">

                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="s3-form-item">
                                                    <input type="text" placeholder="Nome" name="nome" required />
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="s3-form-item">
                                                    <input type="email" placeholder="E-mail" name="email" required />
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-xs-12">
                                                <div class="s3-form-item">
                                                    <textarea name="mensagem" id="" cols="30" rows="4" placeholder="Mensagem" required></textarea>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-xs-12 text-right">
                                                <div class="s3-form-item">
                                                    <button type="submit" class="s3-btn green material arrow-right"><span>Enviar</span><i class="s3-icon-arrow-right-01"></i></button>
                                                </div>
                                            </div>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}
export default LandingPage;