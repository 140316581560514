import {Button, ButtonToolbar, OverlayTrigger, Tooltip} from 'react-bootstrap'
import React from 'react'
import './styles.css'

const NeoTooltip = props => (
    <ButtonToolbar className="align-center">
        <OverlayTrigger
        key={props.position}
        placement={props.position}
        overlay={
            <Tooltip id={`tooltip-${props.position}`}>
                {props.text}
            </Tooltip>
        }
        >
        <a variant="secondary" className="NeoTooltip">i</a>
        </OverlayTrigger>
  
    </ButtonToolbar>
)

export default NeoTooltip