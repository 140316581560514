import React, { Component } from "react";
import badge from '../../assets/data/images/badge.png'
import logoColor from '../../assets/data/images/the-logo-color.png'
import logoPequenaColor from '../../assets/data/images/the-logo-icon-color.png'
import { CONFIG } from '../../config/index';
import './style.css'
import { Link } from 'react-router-dom'

const changeVisibility = () => {
    document.querySelector('#mainHeader #mobileNavigation').classList.add('showMobileNavigation');
    document.querySelector('#mainHeader #mobileNavigation ul').classList.add('showMobileNavigation')
    document.querySelector('#mainHeader #mobileNavigation').onclick = function () {
        document.querySelector('#mainHeader #mobileNavigation').classList.remove('showMobileNavigation');
        document.querySelector('#mainHeader #mobileNavigation ul').classList.remove('showMobileNavigation')
    }
}

const NavBar = () => (
    <header id="mainHeader" class="s3-main-header">
        <div class="container">
            <div class="row">
                <div class="col-xs-12">
                    <div class="s3-flex-wrapper">

                        <div class="s3-logo hidden-xs hidden-sm">
                            <a href="./"><img src={logoColor} alt="NeoContador.com" className="logoColor" /></a>
                            <a href="./"><img src={logoPequenaColor} alt="NeoContador.com" className="smallLogoColor" /></a>
                        </div>

                        <div class="s3-logo hidden-md hidden-lg">
                            <a href="./"><img src={logoColor} alt="NeoContador.com" className="logoColor" /></a>
                            <a href="./"><img src={logoPequenaColor} alt="NeoContador.com" className="smallLogoColor" /></a>
                        </div>

                        <nav id="mainNavigation" class="s3-main-navigation hidden-xs hidden-sm">
                            <ul>
                                <li><Link to='quem-somos'>Quem Somos</Link></li>
                                <li><Link to='nossos-servicos'>Nossos Serviços</Link></li>
                                <li><Link to='contato'>Contato</Link></li>
                                <li><Link to='login'>Login</Link></li>
                            </ul>
                            <div class="s3-cta"><Link to='register'>Cadastre AQUI</Link></div>
                        </nav>
                        <div class="s3-mobile-navigation-toggler hidden-md hidden-lg">
                            <a onClick={changeVisibility}><i class="s3-icon-menu"></i></a>
                        </div>

                        <div id="mobileNavigation" class="s3-mobile-navigation">
                            <ul >
                            <li><Link to='quem-somos'>Quem Somos</Link></li>
                                <li><Link to='nossos-servicos'>Nossos Serviços</Link></li>
                                <li><Link to='contato'>Contato</Link></li>
                                <li><Link to='login'>Login</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
)
 

const NumerosImpressionantes = () => (
    <section id="secNumbers" class="s3-section-numbers">
        <div class="s3-badge"><img src={badge} alt="NeoContador.com" /></div>
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <h2 className="textoBranco">Os números são impressionantes!</h2>
                    <h3 className="textoBranco">Mas convenhamos, não podia ser diferente.</h3>
                </div>
            </div>
            <div class="row" style={{ "display": "flex", "justifyContent": "center" }}>
                <div class="col-md-10 col-md-offset-1">
                    <div class="s3-numbers">
                        <div class="row">

                            <div class="col-12 col-md-4">
                                <div class="s3-item">
                                    <div class="s3-number textoBranco">2.000+</div>
                                    <div class="s3-name textoBranco">DECLARAÇÕES</div>
                                    <div class="s3-description textoBranco">entregues em 2023</div>
                                </div>
                            </div>

                            <div class="col-12 col-md-4">
                                <div class="s3-item">
                                    <div class="s3-number textoBranco">40+</div>
                                    <div class="s3-name textoBranco">Pessoas</div>
                                    <div class="s3-description textoBranco">dedicadas à excelência<br />nos serviços prestados aos nossos clientes</div>
                                </div>
                            </div>

                            <div class="col-12 col-md-4">
                                <div class="s3-item">
                                    <div class="s3-number textoBranco">7.000+</div>
                                    <div class="s3-name textoBranco">Guias</div>
                                    <div class="s3-description textoBranco">de tributos <br />geradas em 2023</div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
)

const OtimizeGastos = () => (
    <div class="s3-header">
        <div class="container">
            <div class="row">

                <div class="col-xs-12">
                    <h2>
                        <span className="textoBranco" ><h1 style={{ 'display': 'inline-block', 'margiRight': '25px;', 'line-height': '125%' }}>Otimize seus gastos com contabilidade!</h1></span>
                        <Link className="s3-btn white material" to='register'>Cadastre AQUI</Link>
                    </h2>
                </div>

            </div>
        </div>
    </div>
);

const DuvidasFrequentes = () => (
    <div class="col-md-5">
        <div class="s3-faq">
            <h3>Dúvidas Frequentes</h3>
            <div class="panel-group" id="pFaq" role="tablist" aria-multiselectable="true">

                <div class="panel">
                    <div class="panel-heading" role="tab" id="ph01">
                        <h4 class="panel-title">
                            <a type="button" data-bs-toggle="collapse" data-bs-target="#collapseWidthExample1" aria-expanded="false" aria-controls="collapseWidthExample1">
                            Por que devo contratar o NeoContador.com se o governo disponibiliza os portais para cálculo
das obrigações?
										</a>
                        </h4>
                    </div>
                    <div class="collapse collapse-vertical" id="collapseWidthExample1">
                        <div class="card card-body" >
                            <p>O Governo Federal disponibiliza um site na internet onde o gerenciamento desta relação de trabalho pudesse ser feito pelo próprio empregador, porém tal plataforma está longe de ser ideal, pois não realiza os cálculos da folha de pagamentos do empregado, muitas vezes é confusa e congestionada, não gera contratos de trabalho, rescisões, recibos de salários e férias, inclusive podendo induzir o usuário ao erro quando eventos avulsos
acontecem, como férias e 13º salário, acarretando no recolhimento indevido dos encargos.</p>
                        </div>
                    </div>
                </div>

                <div class="panel">
                    <div class="panel-heading" role="tab" id="ph02">
                        <h4 class="panel-title">
                            <a type="button" data-bs-toggle="collapse" data-bs-target="#collapseWidthExample2" aria-expanded="false" aria-controls="collapseWidthExample2">
                                É possível enviar meus documentos online?
										</a>
                        </h4>
                    </div>
                    <div class="collapse collapse-vertical" id="collapseWidthExample2">
                        <div class="panel-body">
                            <p>Sim, nossa comunicação é toda digital, então o envio dos documentos e informações se dará de forma totalmente segura através de nossos sistemas.</p>
                        </div>
                    </div>
                </div>

                <div class="panel">
                    <div class="panel-heading" role="tab" id="ph03">
                        <h4 class="panel-title">
                            <a type="button" data-bs-toggle="collapse" data-bs-target="#collapseWidthExample3" aria-expanded="false" aria-controls="collapseWidthExample3">
                            Sou obrigado a declarar meu imposto de renda em 2024?
										</a>
                        </h4>
                    </div>
                    <div class="collapse collapse-vertical" id="collapseWidthExample3">
                        <div class="panel-body">
                            <p>Se você recebeu em 2023 rendimentos tributáveis cuja soma tenha sido maior que R$28.559,70 ou rendimentos isentos, não tributáveis ou tributados exclusivamente na fonte em valor superior a R$ 40.000,00; ou ainda tinha em 31 de dezembro de 2023, a posse ou a propriedade de bens ou direitos de valor total superior a R$ 300.000,00; se obteve, em qualquer mês de 2023, lucro na venda de bens ou direitos sujeitos à incidência de imposto de renda  (inclusive ações) e ou tiver operado mais de R$40.000,00 na Bolsa de Valores em 2023, você estará obrigado.</p>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
)

class EnviaEmails extends Component {


    constructor(props) {
        super(props);
        this.state = {
            nome: '',
            email: '',
            assunto: 'Dúvidas sobre a plataforma',
            mensagem: '',
            txtBtn: 'Enviar'
        }

        this.updateState = this.updateState.bind(this);
        this.enviarEmail = this.enviarEmail.bind(this);
    }

    updateState(element) {
        let self = this;
        this.setState({
            [element.target.name]: element.target.value
        }, () => console.log(self.state))
    }

    enviarEmail(event) {
        this.setState({
            txtBtn: 'Enviando...'
        })
        event.preventDefault()
        let url = CONFIG.API_PREFIX + '/mails/ufMail';
        fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "destinatario": "domesticas@neocontador.com", //TODO DEV: Seu email HOMOLOG/PROD: domesticas@neocontador.com
                "assunto": "Perguntas sobre a Plataforma",
                "conteudo": `Nome: ${this.state.nome}<br/> Email: ${this.state.email} <br />${this.state.mensagem}`
            })
        }).then(response => response.json())
            .then(responseJson => {
                if (responseJson.accepted.length) {
                    this.setState({
                        txtBtn: 'Enviado!',
                        nome: '',
                        email: '',
                        mensagem: '',
                    })
                } else {
                    console.error("Erro")
                }
            }).catch(err => {
                console.error(err)
            });
    }

    render() {


        var classes = 'col-md-6 '
        if (!this.props.ocultarEntreContato) {
            classes += "col-md-offset-1 "
        }

        return (
            <div class={classes}>
                <div class="s3-contact">
                    {!this.props.ocultarEntreContato ?
                        <h3>Entre em contato</h3>
                        : null
                    }

                    <p style={{ "text-align": this.props.alinhamento ? this.props.alinhamento : 'left' }}>Entre em contato conosco caso tenha alguma dúvida não sanada ou deseje fazer sugestões ou comentários. Teremos prazer em responder!</p>
                    <form class="s3-basic" id="contact" onSubmit={this.enviarEmail}>

                        <div class="row">
                            <div class="col-md-6">
                                <div class="s3-form-item">
                                    <input type="text" placeholder="Nome" name="nome" value={this.state.nome} onChange={this.updateState} required />
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="s3-form-item">
                                    <input type="email" placeholder="E-mail" name="email" value={this.state.email} onChange={this.updateState} required />
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-xs-12">
                                <div class="s3-form-item">
                                    <textarea name="mensagem" id="" cols="30" rows="4" name="mensagem" value={this.state.mensagem} placeholder="Mensagem" onChange={this.updateState} required></textarea>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-xs-12 text-right">
                                <div class="s3-form-item">
                                    <button type="submit" class="s3-btn green material arrow-right"><span className="textoBranco">{this.state.txtBtn}</span><i class="s3-icon-arrow-right-01 textoBranco"></i></button>
                                </div>
                            </div>
                        </div>

                    </form>
                </div>
            </div>
        )
    }
}


const Footer = () => (
    <footer id="mainFooter" class="s3-main-footer">
        <div class="container">
            <div class="row">
                <div class="col-lg-10 col-lg-offset-1">
                    <div class="s3-footer-content">
                        <div class="row">

                            <div class="col-sm-12 col-md-8">
                                <div class="s3-top">
                                    <h3>Sobre o NeoContador.com</h3>
                                    <p>Um escritório de contabilidade digital completo com a missão de simplificar a vida das pessoas através das suas
                  soluções práticas para processamento de folhas de pagamento, cálculos de tributos e declarações fiscais. Com estas soluções práticas conseguimos atender com qualidade e eficiência Empregadores Domésticos, Microempreendedores Individuais, Micro e Pequenas Empresas e declarantes do Imposto de Renda Pessoa Física.</p>
                                </div>
                            </div>

                            <div class="col-sm-12 col-md-3 col-sm-offset-1 col-lg-3 col-lg-offset-1">
                                <h3>NeoContador.com</h3>
                                <ul class="menu">
                                    <li><Link to='quem-somos'>Quem Somos</Link></li>
                                    <li><Link to='nossos-servicos'>Serviços</Link></li>
                                    <li><Link to='contato'>Contato</Link></li>
                                    <li><Link to='login'>Login</Link></li>
                                </ul>
                                <ul class="social">
                                    <li><a href="https://www.facebook.com/neocontador/" target="_blank"><i class="s3-social-facebook"></i></a></li>
                                    <li><a href="https://twitter.com/NeoContador_com" target="_blank"><i class="s3-social-twitter"></i></a></li>
                                    <li><a href="https://www.instagram.com/neocontador.com.br/" target="_blank"><i class="s3-social-instagram"></i></a></li>
                                    <li><a href="https://www.linkedin.com/company/neocontadorcom/" target="_blank"><i class="s3-social-linkedin"></i></a></li>
                                </ul>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12 text-center">
                    <div class="s3-copyright">
                        <p>© NeoContador.com Contabilidade Digital - Todos os direitos reservados.</p>
                        <p>Feito em Belo Horizonte</p>
                    </div>
                </div>
            </div>
        </div>
    </footer>
)


export {
    NavBar,
    OtimizeGastos,
    DuvidasFrequentes,
    EnviaEmails,
    NumerosImpressionantes,
    Footer
}
