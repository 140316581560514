import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { Button } from 'react-bootstrap';

import { GLOBAL } from '../../config/global';
import { CONFIG } from '../../config/index';
import styles from './styles/styles';
import './styles/styles.css';
import '../../assets/css/style.css';
import StepTitle from './StepTitle';
import { NeoInputRequired } from '../NeoInputs/NeoInputs'
import { ValidatorForm } from 'react-form-validator-core';
import { NeoRadio } from '../NeoButtons/NeoButtons'
import { NeoButtonNav, NeoSubmit } from '../NeoButtons/NeoButtons'
import { NeoAlert, NeoAlertDecision, NeoLoading } from '../NeoAlert/NeoModal'
import NeoTooltip from '../NeoTooltip/NeoTooltip'
import { NeoInputFile } from '../NeoInputs/NeoInputs'
import $ from 'jquery'

const stepAtual = 'Inves_A'

class Step_Inves_A extends Component {

    constructor() {
        super()
        this.handleSubmit = this.handleSubmit.bind(this);
        this.updateValue = this.updateValue.bind(this);
        this.alertControl = this.alertControl.bind(this);
        this.alertDecisionControl = this.alertDecisionControl.bind(this);
        this.checkboxControlVendas = this.checkboxControlVendas.bind(this);
        this.checkboxControlDec_Ir = this.checkboxControlDec_Ir.bind(this);
        this.gravarDados = this.gravarDados.bind(this);
        this.loadingControl = this.loadingControl.bind(this);
        this.inputFileControl = this.inputFileControl.bind(this);

      
        this.state = {
            decImpostRenda: 1,
            anexo: {
                base64: null,
                nomeOriginal: null,
                size: null
            },
            anexoSalvoBanco: null,
            vendasMensais: 1,
            corretoras:'',
            loadingButton: false,
            userId: GLOBAL.getCookie('_uf_neoId'),
        }

    }

    static displayName = 'ui-RegisterForm'

    componentDidMount() {
        //document.getElementById('s3-cadastro').classList.add('specialSize')

        /*this.setState({
            userId: GLOBAL.getCookie('_uf_neoId')
        })*/
        let userId = this.state.userId;

        //console.log(userId)

        //verifica se o id tá salvo no cookie
        //let userId = GLOBAL.getCookie('_uf_neoId');

        if (userId && userId != "undefined") {

            this.loadingControl(true, 'Buscando dados, aguarde');

            //Consulta pra preencher os dados da tela assim que entra       
            let url = CONFIG.API_PREFIX + '/users/finduser';

            fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    userLogin: {
                        _id: userId
                    }
                })
            }).then(response => response.json())
                .then(responseJson => {

                    if (responseJson.success) {

                        let dados = responseJson.data;

                        //controla o histórico de steps
                        let cpf = responseJson.data.cpf;
                        let historicoStep = responseJson.data.historicoStep;
                        if (historicoStep[historicoStep.length - 1] === stepAtual) historicoStep.pop();
                        this.setState({
                            cpf: cpf,
                            historicoStep: historicoStep,
                        })

                        //console.log(this.state)
                        
                    }

                    else {

                        this.loadingControl(false, '');

                        this.setState(() => ({
                            modalTitle: 'Ops!',
                            modalText: 'Ocorreu um erro ao buscar os dados, atualize a página ou entre em contato com o administrador do sistema.',
                            modalEsconderBotao: true,
                            telaCarregou: true
                        }))

                        this.alertControl('diminuir-modal');

                        // console.log(responseJson.success);
                    }
                    this.loadingControl(false, '');
                }).catch(err => {

                    console.error(err);

                    this.loadingControl(false, '');

                    this.setState(() => ({
                        modalTitle: 'Ops!',
                        modalText: 'Ocorreu um erro ao buscar os dados, atualize a página ou entre em contato com o administrador do sistema.',
                        modalEsconderBotao: true,
                        telaCarregou: true
                    }))

                    this.alertControl('diminuir-modal');
                    this.loadingControl(false, '');
                });
        }
    }

    gravaDocumento(callback) {
        let self = this;
        let fileData = {
            "fileName": "EMPREGADOR_ULTIMO_IRPF." + this.state.anexo.extensao,
            "folderName": this.state.cpf,
            "base64": this.state.anexo.base64
        }

        this.loadingControl(true, 'Salvando Dados');
        let url = CONFIG.API_PREFIX + '/files/saveFile';
        fetch(url, {
            method: "POST",
            headers: {
                "Origin": "*",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(
                { "fileData": fileData }
            )

        }).then(response => response.json())
            .then(responseJson => {
                if (responseJson.success) {
                    callback(self);
                } else {
                    // console.log("Deu ruim");
                    // console.log(responseJson.success);
                    this.setState({
                        modalTitle: 'Ops!',
                        modalText: 'Ocorreu um erro ao salvar os dados',
                    })
                    this.alertControl('positionFixed');
                }
                this.loadingControl(false, '');

            }).catch(err => {
                console.error(err);
                this.loadingControl(false, '');
                this.setState({
                    modalTitle: 'Ops!',
                    modalText: 'Ocorreu um erro ao salvar os dados',
                })
                this.alertControl('positionFixed');
            });
    }

    gravarDados = function () {

        //console.log('acessou gravarDados')
        this.setState({
            loading: true,
            loadingTitle: 'Salvando, aguarde',
            loadingButton: true,
        })

        let anexoToSend;
        let historicoStep = this.state.historicoStep;
        //console.log(historicoStep)
        historicoStep.push(stepAtual);

        if (this.state.anexo.base64) {
            anexoToSend = {
                caminho: this.state.anexo ? `${this.state.cpf}/EMPREGADOR_ULTIMO_IRPF.${this.state.anexo.extensao}` : null,
                nomeOriginal: this.state.anexo.name,
                tamanho: this.state.anexo.size
            }
        } else {
            anexoToSend = this.state.anexoSalvoBanco
        }
        this.loadingControl(true, 'Salvando dados, aguarde');

        let step = 'Final_inves'

        let dataToSend = {
            _id: this.state.userId,
            params: {
                decImpostRenda: this.state.decImpostRenda,
                ULTIMOIRPF: {
                    anexos: anexoToSend,
                },
                vendasMensais: this.state.vendasMensais,
                corretoras: this.state.corretoras,
                step: step,
                historicoStep: this.state.historicoStep
            }
        }

        //console.log(dataToSend)

        let url = CONFIG.API_PREFIX + '/users/updateUser';

        fetch(url, {
            method: "PUT",
            headers: {
                "Origin": "*",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                data: dataToSend,
            })

        }).then(response => response.json())
            .then(responseJson => {

                if (responseJson.success) {

                    // console.log(responseJson);

                    this.props.nextScreen();

                    this.loadingControl(false, '');
                }

                else {

                    // console.log(responseJson.success);

                    this.loadingControl(false, '');

                    this.setState({
                        modalTitle: 'Ops!',
                        modalText: 'Ocorreu um erro ao salvar os dados',
                    })
                    this.alertControl();
                    }

                            this.setState({
                                loadingButton: false,
                            })

                        }).catch(err => {

                            console.error(err);

                            this.loadingControl(false, '');

                            this.setState({
                                modalTitle: 'Ops!',
                                modalText: 'Ocorreu um erro ao salvar os dados',
                                loadingButton: false,
                            })

                            this.alertControl();
                        });
    }

    handleSubmit(e) {

        let anexo = this.state.anexo;
        let camposValidados = (anexo.base64 || this.state.anexoSalvoBanco);
        // console.log(`${!camposValidados} || ${this.state.declarouIRPF1vez == 0}`);

        if (!camposValidados) {
            this.setState({
                modalTitle: 'Ops!',
                modalText: 'É necessário preencher todos os campos ou anexar um arquivo referente ao Imposto de Renda.',
            })
            this.alertControl("positionFixed");
        } else {
            if (this.state.anexo.base64) {
                // console.log(this.state)
                this.gravaDocumento(this.gravarDados);
            } else {
                this.gravarDados();
            }
        }
    }
    

    //Atualiza os states
    updateValue = function (name, evt) {
        let valor = evt;
        console.log(valor);
        if (isNaN(Number(evt))) {
            valor = evt.target.value;
        }
        this.setState(state => ({ [name]: valor }))
    }

    checkboxControlVendas(option) {
        console.log('valor vendas mensais:'+option);
        this.updateValue('vendasMensais', option);
    }

    checkboxControlDec_Ir(option) {
        console.log(option);
        this.updateValue('decImpostRenda', option);
    }

    inputFileControl(e) {
        console.log('acessou a função')
        let acceptedTypes = ['pdf', 'jpeg', 'png', 'jpg'];

        if (e.erro == true) {
            this.setState({
                modalTitle: 'Ops!',
                modalText: 'Ocorreu um erro ao anexar o arquivo',
            })
        }
        else if (e.size > 10000000 && e.size) {
            this.setState({
                modalTitle: 'Ops!',
                modalText: 'O arquivo selecionado é muito grande. Anexe um documento com até 10mb.',
            })
            this.alertControl("positionFixed");

        } else if (!acceptedTypes.includes(e.extensao) && e.extensao) {
            this.setState({
                modalTitle: 'Ops!',
                modalText: 'O formato do arquivo selecionado é inválido.',
            })
            this.alertControl("positionFixed");
        } else {
            this.setState(() => ({
                anexo: e,
            }))
        }
        if (!e.extensao && !e.size) {
            this.setState(() => ({
                anexoSalvoBanco: null,
            }))
        }
    }

    //Controla a visibilidade do alerta
    alertControl() {
        this.setState(state => ({
            showAlert: !state.showAlert
        }))
    }

    //Controla a visibilidade do alerta decisivo
    alertDecisionControl(acao, titulo, mensagem, evt) {

        this.setState(state => ({
            showAlertDecision: !state.showAlertDecision,
            modalTitle: titulo,
            modalText: mensagem,
        }))

        if (acao == 'continuar') this.props.lastScreen();
    }

    loadingControl(visible, title) {
        this.setState({
            loading: visible,
            modalTitle: title
        })
    }

    handleKeyDown = (event) => {

        if (event.key === 'Enter') {

            event.preventDefault();
        }
    };

   

    render() {

            
        const textoTitulo = {
            titulo: 'Ótimo, você escolheu o      ',
            produto: '        Cálculos de renda variável para investidores',
            subtitulo: 'Vamos começar com as primeiras informações.'
        }

        ValidatorForm.propTypes = {

            instantValidate: () => false, //PERGUNTAR PRO MARIO
        }

        ValidatorForm.addValidationRule('confirmPass', (value) => {

            if (this.state.password == this.state.confirmPassword) {

                return true
            }

            return false;
        });

        return (

            <div>

                <NeoAlert
                    show={this.state.showAlert}
                    title={this.state.modalTitle}
                    text={this.state.modalText}
                    close={this.alertControl}
                />

                <NeoAlertDecision
                    show={this.state.showAlertDecision}
                    title={this.state.modalTitle}
                    text={this.state.modalText}
                    sim={this.alertDecisionControl.bind(this, "continuar")}
                    nao={this.alertDecisionControl.bind(this, "cancelar")}
                />

                <NeoLoading
                    title={this.state.loadingTitle}
                    show={this.state.loading}
                />

                <ValidatorForm ref="form" onSubmit={this.handleSubmit} onKeyDown={e => { this.handleKeyDown(e) }}>

                    <div className="s3-step" data-step="A">
                        <div className="s3-wrapper">
                            {/* //className = "s3-signup s3-body"> */}
                            <Row onClick={this.sendData}>
                                <Row style={styles.alignContentCenter}>
                                    <StepTitle texto={textoTitulo} />
                                </Row>
                                <div className="s3-content col-md-12" style={{ padding: "0" }}>
                                    <div className="s3-signup">
                                        <Row>
                                            <div className="s3-form-item clearfix col-md-12" style={{ padding: "0" }}>

                                                {/* Begin Register Form */}
                                                < div className="register-content" style={{ marginTop: "10px" }}>

                                                    <div className="register-body" style={{ padding: "0" }}>

                                                        <Row style={styles.rowSpace} className="verticalAlign">

                                                            <Col xs={12} md={6}>
                                                                <label htmlFor="vendasMensais" className="form-control-label">Realiza vendas mensalmente? </label>
                                                            </Col>
                                                            <Col xs={12} md={6}>
                                                                <NeoRadio
                                                                    opt1="Sim"
                                                                    opt2="Não"
                                                                    value={this.checkboxControlVendas}
                                                                    defaultValue={this.state.vendasMensais}
                                                                />
                                                            </Col>
                                                        </Row>

                                                        <Row style={styles.rowSpace} className="verticalAlign">
                                                            <Col xs={10} md={5}>
                                                                <label htmlFor="username" className="form-control-label">Opera em quais corretoras? </label>
                                                            </Col>
                                                            <Col xs={2} md={1}>
                                                                <NeoTooltip position="top" text="Digite o nome das corretoras nas quais atua, separados por (,)" />
                                                            </Col>
                                                            <Col xs={12} md={6}>
                                                                <NeoInputRequired
                                                                        changeCallback={this.updateValue.bind(this, "corretoras")}
                                                                        name="corretoras"
                                                                        type="text"
                                                                        value={this.state.corretoras}
                                                                        attr={{
                                                                            mask: 'text',
                                                                            ref: 'corretoras',
                                                                            className: 'form-control',
                                                                            id: 'corretoras',
                                                                            placeholder: 'Nome corretoras',
                                                                            
                                                                        }} />
                                                            </Col>
                                                        </Row>

                                                        <Row style={styles.rowSpace} className="verticalAlign">

                                                            <Col xs={12} md={6}>
                                                                <label htmlFor="decImpostRenda" className="form-control-label">Fez a declaração de imposto de renda deste ano? </label>
                                                            </Col>
                                                            <Col xs={12} md={6}>
                                                                <NeoRadio
                                                                    opt1="Declarei"
                                                                    opt2="Não Declarei"

                                                                    value={this.checkboxControlDec_Ir}
                                                                    defaultValue={this.state.decImpostRenda}
                                                                />
                                                            </Col>
                                                        </Row>

                                                        
                                                        {
                                                            this.state.decImpostRenda == 1?
                                                                <Row style={styles.rowSpace} className="verticalAlign">
                                                                    <Col xs={12} md={6}></Col>
                                                                    <Col xs={12} md={6}>
                                                                        <NeoInputFile value={this.inputFileControl}
                                                                            data={this.state.anexoSalvoBanco}
                                                                            id="declaracaoIRPF"
                                                                            attr={{
                                                                                multiple: false,
                                                                                texto: 'Anexar declaração do IR',
                                                                            }} />
                                                                    </Col>
                                                                </Row>
                                                                : null
                                                        }
                                                    </div>
                                                </div >
                                                {/* Begin Register Form */}


                                            </div>
                                        </Row>
                                    </div>
                                </div>
                            </Row>

                        </div>

                    </div>
                    <NeoButtonNav text="Voltar" callback={this.alertDecisionControl.bind(this, 'abrir', 'Atenção', 'Ao voltar os dados preenchidos serão perdidos, deseja continuar?')} />
                    <NeoSubmit text="Continuar" color="green" loading={this.state.loadingButton} />

                </ValidatorForm>
            </div>
        );
    }
}
export default Step_Inves_A;