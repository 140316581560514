import React from "react";
import imgFaq from '../assets/data/images/img-faq_bottom.png'

import { NavBar, NumerosImpressionantes, OtimizeGastos, DuvidasFrequentes, EnviaEmails, Footer } from '../components/sectionsHome/sectionsHome'

document.addEventListener('DOMContentLoaded', function(){
    // INICIO: Controla o accordion
    document.querySelectorAll('.page-faq .el-accordion .item header a').forEach(function (item, index) {
        var elemento = item.parentElement.parentElement.childNodes[1]
        
        item.addEventListener('click', function () {
            
            document.querySelectorAll('.el-accordion .item .content').forEach(function (element) {
                
                    if(element != elemento){
                        element.style.height = '0px'
                        element.parentElement.classList.remove('active')
                        element.classList.remove('active')
                    }
                    
            })

            if (!elemento.classList.value.includes('active')){
                elemento.classList.add('active')
                item.parentElement.parentElement.classList.add('active')
                elemento.style.height = '400px'
                elemento.style.height = `${elemento.childNodes[0].childNodes[0].offsetHeight + 20}px`;
            }else{
                elemento.classList.remove('active')
                item.parentElement.parentElement.classList.remove('active')
                elemento.style.height = '0px'
            }
        })
    })
    // FIM: Controla o accordion
    

    //INCIO: Controla a visibilidade dos faqs
    document.querySelectorAll('.page-faq a[data-tab^="faq"]').forEach(function(item){
        // console.log(item)
        item.addEventListener('click', function(tab){
                // console.log(item)
                document.querySelectorAll('a[data-tab^="faq"]').forEach(function(element){
                    element.parentElement.classList.remove('active')
                })
                document.querySelectorAll('div[data-tab^="faq"]').forEach(function(element){
                    element.classList.remove('active')
                })
             item.parentElement.classList.add('active');
             document.querySelector('div[data-tab="'+item.getAttribute('data-tab')+'"]').classList.add('active')
        })
    })
    //FIM: Controla a visibilidade dos faqs
})


const Landing = () => (

    <div className="page-faq principal">
        <NavBar />

        <section class="s3-page-header">
            <div class="container">
                <div class="row">
                    <div class="col-xs-12">
                        <h1 style={{ marginTop: '50px' }}>Tem alguma dúvida?</h1>
                        <h1 style={{ marginToh1: '0px' }}><strong>A gente te ajuda!</strong></h1>
                    </div>
                </div>
            </div>
        </section>

        <section class="s3-section-faq">
            <div class="container">

                <div class="row">
                    <div class="col-xs-12">
                        <h2>Dúvidas Frequentes</h2>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-8 col-md-offset-2">
                        <div class="s3-faq">
                            <div class="el-tabs">

                                <ul>
                                    <li class="hidden">
                                        <a href="JavaScript:void(0);" data-tab="faq_1"><span>Contabilidade<br />para Domésticas</span></a>
                                    </li>
                                    <li class="active">
                                        <a href="JavaScript:void(0);" data-tab="faq_2"><span className="">Declaração de<br />Imposto de Renda</span></a>
                                    </li>
                                    <li>
                                        <a href="JavaScript:void(0);" data-tab="faq_3"><span className="">Micro Empreendedor<br />Individual - MEI</span></a>
                                    </li>
                                </ul>

                                <div class="tab hidden" data-tab="faq_1">
                                    <h3>Folha de Pagamento de Domésticas</h3>
                                    <div class="row">
                                        <div class="col-md-10 col-md-offset-1">
                                            <div class="el-accordion">
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="tab active" data-tab="faq_2">
                                    <h3>Declaração de Imposto de Renda</h3>
                                    <div class="row">
                                        <div class="col-md-10 col-md-offset-1">
                                            <div class="el-accordion">
                                                <div class="item active">
                                                    <header><a href="JavaScript:void(0);">Quem está obrigado a declarar o imposto de renda este ano?</a></header>
                                                    <div class="content" data-height="258px" style={{ "height": "258px" }}>
                                                        <div class="wrapper">
                                                            <p>• Quem recebeu, em 2016, rendimentos tributáveis cuja soma tenha sido maior que R$ 28.559,70.<br></br>
                                                               • Quem recebeu, em 2016, rendimentos isentos, não tributáveis ou tributados exclusivamente na fonte em valor superior a R$ 40.000,00. <br></br>
                                                               • Quem tinha, em 31 de dezembro de 2016, a posse ou a propriedade de bens ou direitos de valor total superior a R$ 300.000,00. <br></br>
                                                               • Quem obteve, em qualquer mês de 2016, lucro na venda de bens ou direitos sujeitos à incidência de imposto de renda. <br></br>
                                                               • Quem tiver operado na Bolsa de Valores em 2016.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="item">
                                                    <header><a href="JavaScript:void(0);">Sou sócio de empresas. Devo declarar o Imposto de Renda?</a></header>
                                                    <div class="content" data-height="100px">
                                                        <div class="wrapper">
                                                            <p>A condição de sócio por si só não obriga à entrega da declaração de imposto de renda. Devem ser observadas as condições de obrigatoriedade.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="item">
                                                    <header><a href="JavaScript:void(0);">O que são rendimentos tributáveis?</a></header>
                                                    <div class="content" data-height="150px">
                                                        <div class="wrapper">
                                                            <p>São recebimentos que compõem a base de cálculo do imposto de renda, tais como salários, soldos, pró-labores, bolsa estágio, aluguéis, aposentadorias e pensões, royalties, recebimentos oriundos de trabalhos autônomos e sem vínculo empregatício, rendimentos oriundos de previdência privada (VGBL), dentre outros.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="item">
                                                    <header><a href="JavaScript:void(0);">O que são rendimentos isentos e não tributáveis?</a></header>
                                                    <div class="content" data-height="150px">
                                                        <div class="wrapper">
                                                            <p>São os rendimentos sobre os quais não incide o imposto de renda, tais como dividendos e lucros distribuídos aos sócios de empresas, seguro-desemprego, saque de FGTS, doações, heranças, indenizações trabalhistas e rendimentos de cadernetas de poupança, dentre outros.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="item">
                                                    <header><a href="JavaScript:void(0);">O que são rendimentos tributáveis exclusivamente na fonte?</a></header>
                                                    <div class="content" data-height="150px">
                                                        <div class="wrapper">
                                                            <p>Os rendimentos tributados exclusivamente na fonte são recolhidos obrigatoriamente pela empresa ou instituição que faz o pagamento da quantia, e quem recebe o valor não precisa pagar o imposto novamente. São exemplos os prêmios de loteria, juros sobre capital próprio, participação nos lucros e resultados (PLR), rendimentos de aplicações financeiras, dentre outros.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="item">
                                                    <header><a href="JavaScript:void(0);">Qual o prazo para entrega da Declaração do IR 2017?</a></header>
                                                    <div class="content" data-height="60px">
                                                        <div class="wrapper">
                                                            <p>O prazo vai de 02 de março a 28 de abril de 2017.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="item">
                                                    <header><a href="JavaScript:void(0);">Quando vou receber minha restituição do IR 2017?</a></header>
                                                    <div class="content" data-height="150px">
                                                        <div class="wrapper">
                                                            <p>As restituições serão feitas em sete lotes, entre junho e dezembro deste ano, por ordem de entrega da declaração, ou seja, quem entregar a declaração mais cedo receberá a restituição antes. As datas são as seguintes: 16/06, 17/07, 15/08, 15/09, 16/10, 16/11 e 15/12.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="item">
                                                    <header><a href="JavaScript:void(0);">Quais são os possíveis abatimentos da base de cálculo, que poderão diminuir o Imposto de Renda a recolher ou aumentar a restituição?</a></header>
                                                    <div class="content" data-height="198px">
                                                        <div class="wrapper">
                                                            <p>Podem ser abatidas da base de cálculo do Imposto de Renda as despesas com educação e saúde, pagamentos de pensões, previdências social e privada e INSS de empregados domésticos, bem como abatimento fixo por dependentes. Caso o contribuinte não consiga comprovar esses abatimentos em valor expressivo, pode optar pelo abatimento de 20% da base de cálculo, sem comprovação, que é permitido pela Receita Federal através da declaração no modelo simplificado.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="item">
                                                    <header><a href="JavaScript:void(0);">Quais são as despesas dedutíveis?</a></header>
                                                    <div class="content" data-height="198px">
                                                        <div class="wrapper">
                                                            <p>As despesas dedutíveis são as despesas médicas, que não têm limite de valor, e as despesas com educação, limitadas a R$ 3.561,50 no ano. Gastos com materiais escolares e atividades extracurriculares, como escolas de línguas ou cursinhos preparatórios, não podem ser deduzidos do IR. Entre as despesas médicas dedutíveis na declaração de IR estão os gastos com internação, exames, consultas, aparelhos e próteses; e planos de saúde, realizados em benefício de quem declara ou de seus dependentes.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="item">
                                                    <header><a href="JavaScript:void(0);">Estou desempregado, tenho que entregar o IRPF?</a></header>
                                                    <div class="content" data-height="150px">
                                                        <div class="wrapper">
                                                            <p>Independentemente de estar empregado ou não, deve-se observar os critérios de obrigatoriedade. Por exemplo: se, no período em que o contribuinte esteve empregado em 2016, tiver auferido mais de R$ 28.559,70 em salários, deverá fazer a declaração. O contribuinte deverá entrar em contato com o antigo empregador para solicitar o informe de rendimentos.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="item">
                                                    <header><a href="JavaScript:void(0);">Tive 2 ou mais empregos em 2016, mas em nenhum deles meus rendimentos ultrapassaram R$ 28.559,70. Devo fazer a declaração?</a></header>
                                                    <div class="content" data-height="150px">
                                                        <div class="wrapper">
                                                            <p>Deve-se observar a soma de todos os rendimentos no ano, de todas as fontes de pagamentos. Se essa soma superar R$ 28.559,70, deverá ser feita a declaração. O contribuinte deverá entrar em contato com antigos empregadores para solicitar os informes de rendimentos.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="item">
                                                    <header><a href="JavaScript:void(0);">Posso abater da base de cálculo o valor gasto com dependentes?</a></header>
                                                    <div class="content" data-height="100px">
                                                        <div class="wrapper">
                                                            <p>Quem optar pela declaração completa poderá abater um valor de R$ 2.275,08 por cada dependente informado na declaração, além das despesas de educação e saúde, conforme os limites estabelecidos.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="item">
                                                    <header><a href="JavaScript:void(0);">Quem pode ser considerado dependente?</a></header>
                                                    <div class="content" data-height="80px">
                                                        <div class="wrapper">
                                                            <p>Podem ser considerados dependentes pra fins de IRPF o cônjuge, filhos, enteados, irmãos, netos, bisnetos, pais, avós e bisavós.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="item">
                                                    <header><a href="JavaScript:void(0);">O que é o modelo completo de declaração de Imposto de Renda?</a></header>
                                                    <div class="content" data-height="220px">
                                                        <div class="wrapper">
                                                            <p>É a declaração na qual são permitidos os lançamentos das deduções de despesas, como gastos com educação e saúde, além do abatimento de dependentes, contribuições previdenciárias, doações, dentre outros. Esse modelo é mais detalhado e complexo, pois permite um abatimento maior da base de cálculo do imposto, desde que as despesas sejam devidamente comprovadas, podendo diminuir o imposto a recolher ou até mesmo aumentar a restituição. Torna-se vantajosa se o contribuinte conseguir comprovar o equivalente a mais que 20% dos rendimentos tributados em despesas dedutíveis.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="item">
                                                    <header><a href="JavaScript:void(0);">O que o é modelo simplificado de declaração de Imposto de Renda?</a></header>
                                                    <div class="content" data-height="150px">
                                                        <div class="wrapper">
                                                            <p>A opção por preencher a declaração simplificada garante um abatimento único de 20% sobre a renda tributável sem que o contribuinte precise comprovar nenhuma despesa. Neste ano, o desconto único de 20% é limitado ao teto de R$ 16.754,34.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="item">
                                                    <header><a href="JavaScript:void(0);">Como saber qual modelo de declaração é o mais vantajoso pra mim?</a></header>
                                                    <div class="content" data-height="198px">
                                                        <div class="wrapper">
                                                            <p>Para saber qual tipo de declaração é mais vantajoso, basta preencher toda a declaração com os dados exigidos. Antes do envio do documento, o programa gerador da declaração indica automaticamente qual modelo irá gerar menos imposto a pagar. É importante ter a orientação de um contador para verificar se os dados foram preenchidos corretamente e os comprovantes de dedução de fato são válidos.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="item">
                                                    <header><a href="JavaScript:void(0);">Qual o valor do abatimento para quem opta pela declaração simplificada?</a></header>
                                                    <div class="content" data-height="150px">
                                                        <div class="wrapper">
                                                            <p>O abatimento da base de cálculo do imposto de renda na declaração simplificada é de 20% dos rendimentos tributáveis, limitado a R$ 16.754,34.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="item">
                                                    <header><a href="JavaScript:void(0);">Posso abater as despesas com educação?</a></header>
                                                    <div class="content" data-height="230px">
                                                        <div class="wrapper">
                                                            <p>Sim. Na declaração completa é possível abater despesas com educação até o limite de R$ 3.561,50. Podem ser abatidas apenas despesas com ensino técnico, fundamental, médio, superior, pós-graduação, mestrado e doutorado. Esse tipo de despesa dedutível não inclui gastos com materiais escolares e atividades extracurriculares, como escolas de línguas ou cursinhos preparatórios.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="item">
                                                    <header><a href="JavaScript:void(0);">Posso abater os gastos com empregados domésticos?</a></header>
                                                    <div class="content" data-height="150px">
                                                        <div class="wrapper">
                                                            <p>Sim. Na declaração completa, será possível deduzir até R$ 1.093,77 em despesas com um empregado doméstico este ano.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="item">
                                                    <header><a href="JavaScript:void(0);">Posso abater os valores gastos com despesas médicas?</a></header>
                                                    <div class="content" data-height="220px">
                                                        <div class="wrapper">
                                                            <p>Sim. Não há limites de valor para a dedução dos gastos com saúde, mas nem todos os tipos de despesas médicas podem ser deduzidos da base do imposto. Entre as despesas médicas dedutíveis na declaração de IR estão os gastos com internação, exames, consultas, aparelhos e próteses; e planos de saúde, realizados em benefício de quem declara ou de seus dependentes. Para declarar essas despesas, é preciso ter os comprovantes dos gastos, como recibos e notas fiscais ou o informe enviado pelo plano de saúde, que contenham o nome, o endereço e o CPF ou CNPJ de quem recebeu os pagamentos.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="item">
                                                    <header><a href="JavaScript:void(0);">Posso abater as minhas contribuições à Previdência Social?</a></header>
                                                    <div class="content" data-height="150px">
                                                        <div class="wrapper">
                                                            <p>O contribuinte pode deduzir da base de cálculo do Imposto de Renda todas as contribuições feitas ao INSS, seja como trabalhador formal ou autônomo. Também é possível deduzir contribuições ao INSS pagas por um dependente que tenha rendimentos tributáveis próprios, mas que são incluídos na declaração do contribuinte.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="item">
                                                    <header><a href="JavaScript:void(0);">Posso abater os pagamentos a planos de previdência privada?</a></header>
                                                    <div class="content" data-height="250px">
                                                        <div class="wrapper">
                                                            <p>Apenas pagamentos a Plano Gerador de Benefício Livre (PGBL). Quem contribui a um plano de previdência privada na modalidade Plano Gerador de Benefício Livre (PGBL) ou para o fundo de pensão oferecido pela empresa pode deduzir as contribuições feitas ao longo do ano passado da base de cálculo do Imposto de Renda, até o limite de 12% da renda tributável. Vale lembrar que o benefício não se trata de uma isenção de IR, mas um adiamento do pagamento. Quando o contribuinte for resgatar os recursos aplicados no plano no futuro, a tributação incidirá não apenas sobre a rentabilidade da aplicação, mas sobre todo o valor investido.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="item">
                                                    <header><a href="JavaScript:void(0);">Contribuí para Previdência Privada na modalidade PGBL. Posso abater na minha declaração de Imposto de Renda?</a></header>
                                                    <div class="content" data-height="180px">
                                                        <div class="wrapper">
                                                            <p>Sim, podem ser deduzidas as contribuições feitas da base de cálculo do Imposto de Renda, até o limite de 12% da renda tributável. Vale lembrar que o benefício não se trata de uma isenção de IR, mas um adiamento do pagamento. Quando o contribuinte for resgatar os recursos aplicados no plano no futuro, a tributação incidirá não apenas sobre a rentabilidade da aplicação, mas sobre todo o valor investido.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="item">
                                                    <header><a href="JavaScript:void(0);">Contribuí para Previdência Privada na modalidade VGBL. Posso abater na minha declaração de Imposto de Renda?</a></header>
                                                    <div class="content" data-height="150px">
                                                        <div class="wrapper">
                                                            <p>As contribuições para previdência privada na modalidade VGBL não são dedutíveis no imposto de renda, e apenas os seus rendimentos são tributados, quando do resgate do plano. Os pagamentos feitos deverão ser lançados na ficha de Bens e Direitos, pelo valor histórico.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="item">
                                                    <header><a href="JavaScript:void(0);">Posso abater os pagamentos de pensão judicial?</a></header>
                                                    <div class="content" data-height="150px">
                                                        <div class="wrapper">
                                                            <p>Todo valor estabelecido pela Justiça pode ser deduzido, mas contribuições informais são consideradas mesadas e não entram nos critérios de dedução.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="item">
                                                    <header><a href="JavaScript:void(0);">Posso deduzir no meu imposto de renda as doações que fiz no último ano?</a></header>
                                                    <div class="content" data-height="310px">
                                                        <div class="wrapper">
                                                            <p>Somente as contribuições a instituições que se enquadram nas regras de doações com incentivos fiscais podem ser deduzidas da base de cálculo do imposto de renda. Contudo, as chamadas doações incentivadas só podem ser feitas aos: fundos municipais, estaduais, distrital e nacional da criança e do adolescente, que se enquadram no Estatuto da Criança e do Adolescente (ECA) ou Estatuto do Idoso; projetos aprovados pelo Ministério da Cultura e enquadrados na Lei de Incentivo à Cultura (Lei Rouanet); projetos aprovados pelo Ministério da Cultura ou pela Agência Nacional de Cinema (Ancine) e enquadrados na Lei de Incentivo à Atividade Audiovisual; projetos aprovados pelo Ministério do Esporte e enquadrados na Lei de Incentivo ao Esporte. Essas doações não podem, somadas, ultrapassar o limite de 6% do valor do imposto de renda devido. </p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="item">
                                                    <header><a href="JavaScript:void(0);">E se eu perder o prazo para entregar a minha declaração do IR?</a></header>
                                                    <div class="content" data-height="195px">
                                                        <div class="wrapper">
                                                            <p>Caso a declaração do IR seja entregue em atraso, existindo imposto devido, será aplicada multa de 1% ao mês-calendário ou fração de atraso, incidente sobre o imposto devido, ainda que integralmente pago, observados os valores mínimo de R$ 165,74 e máximo de 20% do imposto devido; inexistindo imposto devido, a multa é de R$ 165,74. Caso o contribuinte esteja desobrigado à entrega e mesmo assim desejar fazer a declaração de IR, não incidirá multa sobre eventual entrega em atraso.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="item">
                                                    <header><a href="JavaScript:void(0);">Qual documento devo solicitar para a empresa onde trabalho ou trabalhei?</a></header>
                                                    <div class="content" data-height="175px">
                                                        <div class="wrapper">
                                                            <p>O Informe de rendimentos do empregador, que possui as informações sobre os salários, contribuições ao INSS, Imposto de Renda Retido na Fonte (IRRF), eventuais contribuições à previdência privada e coparticipação em plano de saúde corporativo. O contribuinte deverá entrar em contato com o antigo empregador para solicitar o informe de rendimentos. O prazo para a entrega dos informes de rendimentos do empregador terminou dia 27/02/2017.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="item">
                                                    <header><a href="JavaScript:void(0);">Qual documento devo solicitar para as instituições financeiras (bancos) onde tenho ou tive conta?</a></header>
                                                    <div class="content" data-height="245px">
                                                        <div class="wrapper">
                                                            <p>O Informe de Rendimentos, que as instituições financeiras geralmente enviam por correio ou disponibilizam na internet. Além disso, o informe pode ser obtido em caixas eletrônicos ou nas agências. Esse informe resume os rendimentos recebidos pelo contribuinte ao longo do ano, como os ganhos tributáveis recebidos de pessoa jurídica e de tributação exclusiva, além de informações sobre bens e direitos, como aplicações financeiras e saldo em conta. Se o vínculo com a instituição financeira foi encerrado em 2016, você deverá comparecer a uma das agências do banco para obter o informe de rendimentos relativo ao período em que ainda era correntista.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="item">
                                                    <header><a href="JavaScript:void(0);">Como devo declarar os aluguéis que recebi no último ano?</a></header>
                                                    <div class="content" data-height="240px">
                                                        <div class="wrapper">
                                                            <p>Quem paga ou recebe aluguéis deve reunir a documentação que comprove os valores. Caso o inquilino seja pessoa física e os pagamentos foram feitos diretamente ao proprietário, sem o intermédio de imobiliárias, a comprovação junto à Receita é feita com os recibos dos depósitos bancários. Se houver uma imobiliária administrando um imóvel ocupado por pessoa física, ela pode fornecer um histórico dos aluguéis pagos no ano aos clientes. Caso o inquilino seja pessoa jurídica, o próprio inquilino é responsável por entregar o informe de rendimentos para o proprietário, uma vez que é ele quem deve reter e recolher o Imposto de Renda.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="item">
                                                    <header><a href="JavaScript:void(0);">Quais comprovantes devo ter de despesas médicas e odontológicas para abater no meu imposto de renda?</a></header>
                                                    <div class="content" data-height="170px">
                                                        <div class="wrapper">
                                                            <p>Os documentos devem trazer a razão social da empresa ou o nome do profissional, com CNPJ ou CPF, o endereço do estabelecimento, o serviço realizado, o nome completo do paciente e o valor. Caso o contribuinte tenha recebido algum reembolso do plano de saúde, também será necessário reunir os recibos que comprovam o valor total do serviço pago e o valor reembolsado pelo plano.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="item">
                                                    <header><a href="JavaScript:void(0);">Quais comprovantes devo ter de gastos com educação para no abater meu imposto de renda?</a></header>
                                                    <div class="content" data-height="1">
                                                        <div class="wrapper">
                                                            <p>Os documentos que detalham esses pagamentos devem conter o nome e o CNPJ da instituição de ensino. As instituições de ensino geralmente emitem um comprovante com o resumo dos valores pagos durante o ano e o repassam ao cliente. Caso o recibo não tenha sido entregue, você deve solicitar o documento. Gastos com materiais escolares e atividades extracurriculares, como escolas de línguas ou cursinhos preparatórios, não podem ser deduzidos do IR.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="item">
                                                    <header><a href="JavaScript:void(0);">Como faço para utilizar os gastos com empregados domésticos para abatimento do imposto de renda?</a></header>
                                                    <div class="content" data-height="150px">
                                                        <div class="wrapper">
                                                            <p>O empregado deve ser cadastrado no e-Social e as guias devem estar recolhidas. O contribuinte deverá guardar as guias do DAE (Documento de Arrecadação do Esocial) quitadas para fins de comprovação.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="item">
                                                    <header><a href="JavaScript:void(0);">Sou Microempreendedor Individual. Tenho que fazer a Declaração de Imposto de Renda como Pessoa Física?</a></header>
                                                    <div class="content" data-height="225px">
                                                        <div class="wrapper">
                                                            <p>É preciso esclarecer que a receita bruta auferida pelo MEI (em razão de sua atividade) não é o rendimento que a pessoa física do empreendedor deve considerar para efeito de Imposto de Renda da Pessoa Física. O rendimento da pessoa física é parte da receita bruta obtida na atividade, menos as despesas do negócio, tais como aluguel, telefone, compras de mercadorias que serão revendidas, empregado (salário de empregado, se tiver), etc. Portanto, é a sobra transferida para a pessoa física do MEI, para seus gastos pessoais. Este rendimento costuma ficar no limite de isenção.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="item">
                                                    <header><a href="JavaScript:void(0);">Como evitar a tributação adicional da pessoa física, formalizada como MEI, além do que ele já paga mensalmente?</a></header>
                                                    <div class="content" data-height="150px">
                                                        <div class="wrapper">
                                                            <p>Para isso o Microempreendedor Individual (MEI) deverá contar com os serviços de um contador habilitado e registrado no Conselho de Contabilidade. Será necessário que o mesmo faça a escrituração contábil das operações do MEI afim de apurar o lucro obtido e informá-lo na declaração de Imposto de Renda do empreendedor, com todo o embasamento documental e técnico.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="tab" data-tab="faq_3">
                                    <h3>Micro Empreendedor Individual</h3>
                                    <div class="el-accordion">
                                        <div class="col-md-10 col-md-offset-1">
                                            <div class="el-accordion">

                                                <div class="item">
                                                    <header><a href="JavaScript:void(0);">O que é Microempreendedor Individual (MEI)?</a></header>
                                                    <div class="content" data-height="150px">
                                                        <div class="wrapper">
                                                            <p>O Microempreendedor Individual (MEI) é a pessoa que trabalha por conta própria, sem vínculo empregatício, e que se legaliza como pequeno empresário.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="item">
                                                    <header><a href="JavaScript:void(0);">O MEI tem CNPJ (Cadastro Nacional das Pessoas Jurídicas)?</a></header>
                                                    <div class="content" data-height="150px">
                                                        <div class="wrapper">
                                                            <p>Sim. O MEI é uma Pessoa Jurídica formalizada.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="item">
                                                    <header><a href="JavaScript:void(0);">Quais são as condições para se tornar um Microempreendedor Individual (MEI)?</a></header>
                                                    <div class="content" data-height="150px">
                                                        <div class="wrapper">
                                                            <p>O faturamento total anual da atividade deve estar limitado a R$ 60.000,00 (Sessenta mil reais) no ano-calendário, de janeiro a dezembro, numa média de R$ 5.000,00 mensais, no máximo. A obtenção do CNPJ e a inscrição da Junta Comercial não substituem as normas de ocupação dos municípios, que devem ser observadas e obedecidas. Outro ponto importante: o MEI não pode ser sócio, titular ou administrador de outra empresa. Também não pode ter filial. Pode contar com até um empregado, mas apenas um, e que receba um salário mínimo ou o piso salarial da categoria profissional (ver sindicato), se este for mais alto.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="item">
                                                    <header><a href="JavaScript:void(0);">Quais são as restrições para se tornar um MEI?</a></header>
                                                    <div class="content" data-height="150px">
                                                        <div class="wrapper">
                                                            <p>O MEI não pode ser sócio, titular ou administrador de outra empresa.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="item">
                                                    <header><a href="JavaScript:void(0);">Empreendedor de qualquer atividade pode se formalizar como um MEI?</a></header>
                                                    <div class="content" data-height="150px">
                                                        <div class="wrapper">
                                                            <p>Não. As atividades permitidas estão listadas aqui.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="item">
                                                    <header><a href="JavaScript:void(0);">Qual o limite de faturamento de um MEI?</a></header>
                                                    <div class="content" data-height="150px">
                                                        <div class="wrapper">
                                                            <p>O faturamento total anual do MEI deve estar limitado a R$ 60.000,00 (Sessenta mil reais) no ano-calendário, de janeiro a dezembro, numa média de R$ 5.000,00 mensais, no máximo.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="item">
                                                    <header><a href="JavaScript:void(0);">O Microempreendedor Individual (MEI) pode ter filial?</a></header>
                                                    <div class="content" data-height="150px">
                                                        <div class="wrapper">
                                                            <p>Não.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="item">
                                                    <header><a href="JavaScript:void(0);">O Microempreendedor Individual (MEI) pode ter funcionários?</a></header>
                                                    <div class="content" data-height="150px">
                                                        <div class="wrapper">
                                                            <p>O MEI pode contar com até um empregado, mas apenas um, e que receba um salário mínimo mensal ou o piso salarial da categoria profissional (ver sindicato), se for mais alto.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="item">
                                                    <header><a href="JavaScript:void(0);">Além do cadastro no portal do empreendedor, o MEI deve ser registrado em outro órgão?</a></header>
                                                    <div class="content" data-height="150px">
                                                        <div class="wrapper">
                                                            <p>A obtenção do CNPJ e a inscrição da Junta Comercial não substituem as normas de ocupação dos municípios, que devem ser observadas e obedecidas. Desta maneira o futuro MEI deve fazer a consulta de viabilidade em seu município para obtenção do Alvará de Localização e Funcionamento.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="item">
                                                    <header><a href="JavaScript:void(0);">Qual valor dos tributos que o Microempreendedor Individual (MEI) deve arcar?</a></header>
                                                    <div class="content" data-height="150px">
                                                        <div class="wrapper">
                                                            <p>O MEI goza de isenção de praticamente todos os tributos. Deve pagar apenas uma taxa fixa mensal de 5% do salário mínimo vigente (R$ 46,85 em 2017) a título de contribuição previdenciária ao INSS, mais R$ 1,00 de ICMS se a atividade for comércio ou indústria, ou mais R$ 5,00 de ISS se a atividade for prestação de serviço.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="item">
                                                    <header><a href="JavaScript:void(0);">Quanto o MEI deve pagar de tributos mensalmente?</a></header>
                                                    <div class="content" data-height="150px">
                                                        <div class="wrapper">
                                                            <p>R$ 47,85 - Comércio e Indústria (INSS + ICMS)</p>
                                                            <p>R$ 51,85 - Prestação de Serviços (INSS + ISS)</p>
                                                            <p>R$ 52,85 - Atividades mistas (INSS + ICMS + ISS)</p>
                                                            <p>R$ 46,85 - Atividades isentas de ICMS e ISS</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="item">
                                                    <header><a href="JavaScript:void(0);">Como é feito o pagamento dos tributos pelo Microempreendedor Individual (MEI)?</a></header>
                                                    <div class="content" data-height="150px">
                                                        <div class="wrapper">
                                                            <p>O pagamento mensal dos tributos é feito por meio de um documento chamado DAS, que é gerado pela Internet, no portal do empreendedor. O pagamento é feito na rede bancária ou em casas lotéricas, até o dia 20 de cada mês.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="item">
                                                    <header><a href="JavaScript:void(0);">Quais são os benefícios de ser Microempreendedor Individual (MEI)?</a></header>
                                                    <div class="content" data-height="150px">
                                                        <div class="wrapper">
                                                            <p>O MEI tem cobertura da Previdência Social para o Empreendedor e sua família; segurança para desenvolver seu pequeno negócio de forma legal; com o CNPJ, pode emitir notas e vender para outras empresas e para o governo; pode comprovar renda legal e financiar compras com acesso facilitado aos serviços bancários.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="item">
                                                    <header><a href="JavaScript:void(0);">O Microempreendedor Individual (MEI) pode emitir NF (Nota Fiscal)?</a></header>
                                                    <div class="content" data-height="150px">
                                                        <div class="wrapper">
                                                            <p>Sim, o MEI pode emitir Notas Fiscais, e desta maneira vender para outras empresas e para o governo.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="item">
                                                    <header><a href="JavaScript:void(0);">O Microempreendedor Individual (MEI) é obrigado a emitir NF (Nota Fiscal)?</a></header>
                                                    <div class="content" data-height="150px">
                                                        <div class="wrapper">
                                                            <p>No caso de venda ou serviço a empresas, as chamadas pessoas jurídicas, o MEI está obrigado a emitir NF. Já no caso da venda ou prestação de serviços for feita para consumidor final pessoa física o MEI está dispensado da emissão de NF.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="item">
                                                    <header><a href="JavaScript:void(0);">Quais são os benefícios previdenciários (INSS) aos quais o MEI tem direito?</a></header>
                                                    <div class="content" data-height="150px">
                                                        <div class="wrapper">
                                                            <p>Aposentadoria por idade, aposentadoria por invalidez, auxílio-doença, salário maternidade, auxílio reclusão e pensão por morte. Os valores dos benefícios sempre tomarão por base o valor de um salário mínimo.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="item">
                                                    <header><a href="JavaScript:void(0);">Quais são as condições para o MEI estar apto a aposentar por idade?</a></header>
                                                    <div class="content" data-height="150px">
                                                        <div class="wrapper">
                                                            <p>Para aposentar por idade a mulher deve ter 60 anos e o homem, 65 anos. Os trabalhadores rurais podem pedir aposentadoria por idade com cinco anos a menos. É necessário contribuir pelo menos 180 meses e a renda será de um salário mínimo.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="item">
                                                    <header><a href="JavaScript:void(0);">Quais são as condições para o MEI estar apto a aposentar por tempo de contribuição?</a></header>
                                                    <div class="content" data-height="150px">
                                                        <div class="wrapper">
                                                            <p>Para ter direito à aposentadoria por tempo de contribuição, o MEI deverá complementar o pagamento ao INSS com uma alíquota complementar de 15%, calculada sobre o salário-mínimo por meio da GPS (código 1910), na rede bancária, até o dia 15 do mês seguinte.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="item">
                                                    <header><a href="JavaScript:void(0);">Quais são as condições para o MEI estar apto a aposentar por invalidez?</a></header>
                                                    <div class="content" data-height="150px">
                                                        <div class="wrapper">
                                                            <p>O trabalhador tem que contribuir para a Previdência Social por no mínimo 12 meses.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="item">
                                                    <header><a href="JavaScript:void(0);">Quais são as condições para o MEI estar apto a receber o auxílio-doença?</a></header>
                                                    <div class="content" data-height="150px">
                                                        <div class="wrapper">
                                                            <p>Para ter direito ao benefício, o trabalhador tem de contribuir para a Previdência Social por, no mínimo, 12 meses (carência);</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="item">
                                                    <header><a href="JavaScript:void(0);">Quais são as condições para o MEI estar apto a receber o salário-maternidade?</a></header>
                                                    <div class="content" data-height="150px">
                                                        <div class="wrapper">
                                                            <p>São necessárias 10 contribuições mensais para receber o benefício;</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="item">
                                                    <header><a href="JavaScript:void(0);">Quais são as condições para a família do MEI estar apta a receber o auxílio-reclusão?</a></header>
                                                    <div class="content" data-height="150px">
                                                        <div class="wrapper">
                                                            <p>Direito adquirido a partir da primeira contribuição que o MEI fizer.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="item">
                                                    <header><a href="JavaScript:void(0);">Quais são as condições para a família do MEI estar apta a receber pensão por morte?</a></header>
                                                    <div class="content" data-height="150px">
                                                        <div class="wrapper">
                                                            <p>Direito adquirido a partir da primeira contribuição que o MEI fizer.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="item">
                                                    <header><a href="JavaScript:void(0);">O Microempreendedor Individual (MEI) deve ter contabilidade?</a></header>
                                                    <div class="content" data-height="150px">
                                                        <div class="wrapper">
                                                            <p>A contabilidade formal está dispensada. A rigor, depois da inscrição como MEI e dos recolhimentos mensais, a declaração anual simplificada é a única obrigação. Essa declaração anual deverá ser feita também pela internet até o último dia do mês de MAIO do ano subsequente.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="item">
                                                    <header><a href="JavaScript:void(0);">Quais são as obrigações do Microempreendedor Individual (MEI)?</a></header>
                                                    <div class="content" data-height="370px">
                                                        <div class="wrapper">
                                                            <p>Efetuar os recolhimentos mensais do DAS, fazer a declaração anual simplificada e emitir Nota Fiscal para clientes Pessoa Jurídica. Para a declaração anual simplificada o MEI deve estar munido com os formulários simplificados mensais das receitas brutas, onde devem ser anotados separadamente os valores provenientes da revenda de mercadorias, da venda de produtos industrializados e da prestação de serviços, dependendo da atividade desenvolvida pelo empreendedor. Essas receitas devem estar separadas ainda entre as que foram efetivadas com a dispensa de emissão de nota fiscal (no caso de consumidor final pessoa física) ou com emissão de nota fiscal (no caso de venda ou serviço a empresas, as chamadas pessoas jurídicas). O MEI deverá manter em seu poder, da mesma forma, as notas fiscais de compras de produtos e de serviços, de modo a garantir a procedência legal de tudo que adquire, e as eventualmente emitidas para serem anexadas ao formulário simplificado.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="item">
                                                    <header><a href="JavaScript:void(0);">Quais são os cuidados adicionais que o MEI pode e deve adotar?</a></header>
                                                    <div class="content" data-height="330px">
                                                        <div class="wrapper">
                                                            <p>O Empreendedor deve zelar pela sua atividade e manter o controle em relação ao que compra e ao que vende, e quanto está ganhando e gastando. Essa organização mínima permite gerenciar melhor o negócio e sua própria vida financeira, além de ser importante para crescer e se desenvolver. No mínimo deve preencher os formulários simplificados mensais das receitas brutas, onde devem ser anotados separadamente os valores provenientes da revenda de mercadorias, da venda de produtos industrializados e da prestação de serviços, dependendo da atividade desenvolvida pelo empreendedor. Essas receitas devem estar separadas ainda entre as que foram efetivadas com a dispensa de emissão de nota fiscal (no caso de consumidor final pessoa física) ou com emissão de nota fiscal (no caso de venda ou serviço a empresas, as chamadas pessoas jurídicas).</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="item">
                                                    <header><a href="JavaScript:void(0);">O que é o Formulário Simplificado que o MEI deve preencher?</a></header>
                                                    <div class="content" data-height="220px">
                                                        <div class="wrapper">
                                                            <p>São formulários mensais das receitas brutas, onde devem ser anotados separadamente os valores provenientes da revenda de mercadorias, da venda de produtos industrializados e da prestação de serviços, dependendo da atividade desenvolvida pelo empreendedor. Essas receitas devem estar separadas ainda entre as que foram efetivadas com a dispensa de emissão de nota fiscal (no caso de consumidor final pessoa física) ou com emissão de nota fiscal (no caso de venda ou serviço a empresas, as chamadas pessoas jurídicas).</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="item">
                                                    <header><a href="JavaScript:void(0);">Quais as obrigações do MEI quando contratar um empregado?</a></header>
                                                    <div class="content" data-height="150px">
                                                        <div class="wrapper">
                                                            <p>O MEI que contratar empregado deverá registrá-lo e ficar atento às seguintes obrigações: salário, 13º, férias, 1/3 férias, FGTS, INSS (8% - empregado e 3% - empregador), Contribuição Sindical do empregado, vale transporte, salário família, CAGED, RAIS, PIS, devendo observar ainda a convenção coletiva do sindicato da categoria.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="item">
                                                    <header><a href="JavaScript:void(0);">Quais os custos que o MEI terá quando contratar um empregado?</a></header>
                                                    <div class="content" data-height="175px">
                                                        <div class="wrapper">
                                                            <p>Tomando-se por base o salário mínimo de janeiro de 2017, a despesa total do MEI com o ajudante será de R$ 1.040,07, sendo R$ 28,11 para o INSS (empregador) e R$ 74,96 para o FGTS, correspondentes a 3% e 8% da remuneração paga. Vale lembrar também que o MEI está obrigado a reter e recolher o INSS (empregado) no valor de R$ 74,96 correspondentes a 8% para salários até R$ 1.247,11, porém este custo é do empregado.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="item">
                                                    <header><a href="JavaScript:void(0);">Como se formalizar como Microempreendedor Individual (MEI)?</a></header>
                                                    <div class="content" data-height="150px">
                                                        <div class="wrapper">
                                                            <p>Antes de fazer o registro da formalização, deve-se consultar a prefeitura para saber se é possível desenvolver a atividade no local (bairro, casa ou rua) desejado e se ela está de acordo com a legislação, especialmente a sanitária, que cuida da saúde pública, e a de uso e ocupação do solo, que protege e ordena o crescimento das cidades. Depois disso, a formalização é feita pela Internet no site www.portaldoempreendedor.gov.br.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="item">
                                                    <header><a href="JavaScript:void(0);">Quais dados o empreendedor deve informar para se formalizar como MEI?</a></header>
                                                    <div class="content" data-height="150px">
                                                        <div class="wrapper">
                                                            <p>O número do CPF, data de nascimento, dados da identidade, o CEP, nome, endereço, telefone, e-mail, os números dos recibos de entrega das últimas declarações de imposto de renda (caso não tenha declarado, será necessário o título de eleitor), a atividade a ser exercida e selecionar os campos de desimpedimentos legais.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="item">
                                                    <header><a href="JavaScript:void(0);">Após a formalização, quais documentos comprovam a situação de MEI?</a></header>
                                                    <div class="content" data-height="150px">
                                                        <div class="wrapper">
                                                            <p>Concluída a inscrição, o empreendedor obterá imediatamente o CNPJ, a inscrição no INSS, na Junta Comercial e o alvará provisório. Tratando-se de atividade comercial, a Inscrição Estadual também sairá com o cadastro sincronizado. O sistema também gerará o Certificado da Condição de Microempreendedor Individual – CCMEI.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="item">
                                                    <header><a href="JavaScript:void(0);">Como o Microempreendedor Individual (MEI) se regulariza junto ao seu município?</a></header>
                                                    <div class="content" data-height="150px">
                                                        <div class="wrapper">
                                                            <p>Antes de proceder ao registro, deve-se consultar o município e verificar se a atividade é considerada de alto grau de risco para fins de obtenção da licença de funcionamento e se necessita de alvará da vigilância sanitária. Veja também se no endereço residencial que se pretende exercer a atividade pode ser instalado um negócio, lembrando que o bem estar coletivo está acima do interesse individual. Atividades barulhentas ou com grande circulação de pessoas, dificilmente poderão ser exercidas em residências.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="item">
                                                    <header><a href="JavaScript:void(0);">O Microempreendedor Individual (MEI) pode utilizar o endereço residencial?</a></header>
                                                    <div class="content" data-height="150px">
                                                        <div class="wrapper">
                                                            <p>Sim. Conforme dispõe a Lei Geral das Micro e Pequenas Empresas, o Município poderá conceder Alvará de Funcionamento Provisório para o MEI instalado em áreas desprovidas de regulação fundiária legal ou com regulamentação precária e na residência do próprio MEI, na hipótese em que a atividade não gere grande circulação de pessoas.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="item">
                                                    <header><a href="JavaScript:void(0);">Como o MEI poderá obter o Alvará de Funcionamento?</a></header>
                                                    <div class="content" data-height="150px">
                                                        <div class="wrapper">
                                                            <p>Durante a inscrição no portal, o MEI que exercer atividade de baixo risco deverá manifestar eletronicamente sua concordância ao Termo de Ciência e Responsabilidade, isto é, declara que conhece e atende os requisitos legais exigidos pelo Estado e Município para emissão do Alvará de Licença de Funcionamento Provisório, com prazo de 180 dias de validade. O não atendimento dos requisitos legais acarretará no cancelamento do Alvará Provisório e de sua inscrição como MEI. Além disso deve entrar com o pedido de Alvará oficial junto a prefeitura de sua cidade.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="item">
                                                    <header><a href="JavaScript:void(0);">E se o MEI atrasar nos pagamentos mensais?</a></header>
                                                    <div class="content" data-height="150px">
                                                        <div class="wrapper">
                                                            <p>Em caso de atraso, haverá cobrança de juros e multa. A multa será de 0,33% por dia de atraso limitado a 20% e os juros serão calculados com base na taxa SELIC, sendo que, para o primeiro mês de atraso, os juros serão de 1%. Após o vencimento, deverá ser gerado novo DAS no portal que já conterá os valores da multa e dos juros, sem precisar fazer cálculos por fora.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="item">
                                                    <header><a href="JavaScript:void(0);">Uma pessoa pode ser empregada em uma empresa e também atuar como MEI?</a></header>
                                                    <div class="content" data-height="150px">
                                                        <div class="wrapper">
                                                            <p>Sim, inclusive podendo prestar serviço para outras empresas. Não há problema algum, desde que este trabalho não caracterize relação de emprego, isto é, vínculo empregatício. Isso significa que o benefício fiscal criado pela lei é destinado ao empreendedor, e não à empresa que o contrata.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="item">
                                                    <header><a href="JavaScript:void(0);">E se o MEI empregado em uma empresa for demitido da mesma?</a></header>
                                                    <div class="content" data-height="150px">
                                                        <div class="wrapper">
                                                            <p>O empreendedor não terá como receber seguro-desemprego, uma vez que ele continua trabalhando como MEI.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="item">
                                                    <header><a href="JavaScript:void(0);">O MEI pode realizar cessão de mão-de-obra?</a></header>
                                                    <div class="content" data-height="150px">
                                                        <div class="wrapper">
                                                            <p>Salvo os serviços de hidráulica, eletricidade, pintura, alvenaria, carpintaria e de manutenção ou reparo de veículos, cuja tributação previdenciária tem características próprias, o MEI não poderá realizar cessão ou locação de mão de obra. Entende-se por cessão ou locação de mão de obra a colocação à disposição da empresa contratante, em suas dependências ou nas de terceiros, de trabalhadores, inclusive o MEI, que realizem serviços contínuos relacionados ou não com sua atividade fim, quaisquer que sejam a natureza e a forma de contratação.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="item">
                                                    <header><a href="JavaScript:void(0);">E nos casos permitidos de cessão de mão-de-obra pelo MEI?</a></header>
                                                    <div class="content" data-height="150px">
                                                        <div class="wrapper">
                                                            <p>A empresa que contratar um MEI para prestação de serviços de hidráulica, eletricidade, pintura, alvenaria, carpintaria e de manutenção ou reparo de veículos está obrigada a recolher a Contribuição Previdenciária Patronal (CPP), no valor de 20% sobre a contratação.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="item">
                                                    <header><a href="JavaScript:void(0);">E se o faturamento aumentar, o MEI vai pagar mais?</a></header>
                                                    <div class="content" data-height="150px">
                                                        <div class="wrapper">
                                                            <p>A formalização do MEI é a porta de entrada para o Simples Nacional. Havendo excesso de receita terá que se desenquadrar como MEI e ingressar no Simples Nacional convencional, cuja tributação será maior, de acordo com o aumento de sua receita bruta anual. Ao crescer e faturar mais, você pula de faixa. São 20 faixas, conforme o seu faturamento, até o limite de R$ 3,6 milhões. As condições oferecidas pelo sistema simplificado de tributação são vantajosas, quando comparadas aos outros regimes tributários, que são o lucro real e o lucro presumido.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="item">
                                                    <header><a href="JavaScript:void(0);">O MEI tem que fazer a Declaração de Imposto de Renda como Pessoa Física?</a></header>
                                                    <div class="content" data-height="150px">
                                                        <div class="wrapper">
                                                            <p>É preciso esclarecer que a receita bruta auferida pelo MEI (em razão de sua atividade) não é o rendimento que a pessoa física do empreendedor deve considerar para efeito de Imposto de Renda da Pessoa Física. O rendimento da pessoa física é parte da receita bruta obtida na atividade, menos as despesas do negócio, tais como aluguel, telefone, compras de mercadorias que serão revendidas, empregado (salário de empregado, se tiver), etc. Portanto, é a sobra transferida para a pessoa física do MEI, para seus gastos pessoais. Este rendimento costuma ficar no limite de isenção.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="item">
                                                    <header><a href="JavaScript:void(0);">Como a pessoa física, formalizada como MEI, comprova sua renda?</a></header>
                                                    <div class="content" data-height="150px">
                                                        <div class="wrapper">
                                                            <p>O microempreendedor individual precisa regularizar a transferência do lucro para a sua pessoa física. A maior parte dos MEI são prestadores de serviço e têm a totalidade de seu rendimento (depois de pagar o imposto) direcionada para a pessoa física (não precisa adquirir estoque, por exemplo). O que muitos dos MEI não sabem é que eles precisam do contador para orientar sobre como apontar seus rendimentos no IRPF, podendo inclusive isentá-los de tributação adicional, através da escrituração contábil regular.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="item">
                                                    <header><a href="JavaScript:void(0);">Como a pessoa física, formalizada como MEI, obtém uma declaração de sua renda?</a></header>
                                                    <div class="content" data-height="150px">
                                                        <div class="wrapper">
                                                            <p>O MEI deverá contar com os serviços de contabilidade. Atualmente os órgãos públicos, instituições financeiras e demais interessados aceitam apenas a Declaração de Renda Eletrônica, conhecida como DECORE Eletrônica, que são geradas nos Conselhos Regionais de Contabilidade. Para tanto o MEI precisará que um contador faça esta declaração, assinando-a digitalmente, e anexando toda a documentação probatória da renda declarada.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="item">
                                                    <header><a href="JavaScript:void(0);">A pessoa física, formalizada como MEI pode sofrer tributação adicional?</a></header>
                                                    <div class="content" data-height="150px">
                                                        <div class="wrapper">
                                                            <p>Sim. Sem uma contabilidade, o MEI prestador de serviço será tributado em todo valor que ultrapassar 32% de seu lucro, e o MEI Comerciante será tributado em todo valor que ultrapassar 8% de seu lucro, ambos podendo chegar a uma alíquota de até 27,5% aplicada sobre o valor excedente.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="item">
                                                    <header><a href="JavaScript:void(0);">Como a tributação adicional afeta o MEI prestador de serviços na sua pessoa física?</a></header>
                                                    <div class="content" data-height="150px">
                                                        <div class="wrapper">
                                                            <p>Supondo que o MEI teve receita de R$ 5.000,00 no mês. Sem um contador, R$ 1.600,00 estarão isentos de tributos (32% sobre R$ 5.000,00). Sobre os outros R$ 3.400,00, podem incidir impostos de até 27,5%. Ou seja, o risco de cometer um erro na declaração e ser penalizado por isso (pagando mais impostos) é um grande motivo para contratação de um bom contador pelo MEI.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="item">
                                                    <header><a href="JavaScript:void(0);">Como a tributação adicional afeta o MEI comerciante na sua pessoa física?</a></header>
                                                    <div class="content" data-height="150px">
                                                        <div class="wrapper">
                                                            <p>Supondo que o MEI teve receita de R$ 5.000,00. Sem um contador, R$ 400,00 estarão isentos de tributos (8% sobre R$ 5.000,00). Sobre os outros R$ 4.600,00, podem incidir impostos de até 27,5%. Ou seja, o risco de cometer um erro na declaração e ser penalizado por isso (pagando mais impostos) é um grande motivo para contratação de um bom contador pelo MEI.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="item">
                                                    <header><a href="JavaScript:void(0);">Como evitar a tributação adicional da pessoa física, formalizada como MEI, além do que ele já paga mensalmente?</a></header>
                                                    <div class="content" data-height="150px">
                                                        <div class="wrapper">
                                                            <p>Para isso o Microempreendedor Individual (MEI) deverá contar com os serviços de um contador habilitado e registrado no Conselho de Contabilidade. Será necessário que o mesmo faça a escrituração contábil das operações do MEI afim de apurar o lucros obtido e informa-lo na declaração de Imposto de Renda do empreendedor, com todo o embasamento documental e técnico.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="item">
                                                    <header><a href="JavaScript:void(0);">O MEI pode cancelar a inscrição e encerrar a empresa?</a></header>
                                                    <div class="content" data-height="150px">
                                                        <div class="wrapper">
                                                            <p>Sim. Atualmente o portal do empreendedor admite realizar a inscrição do MEI pela internet, e também está preparado para permitir alterações e baixa (encerramento).</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="item">
                                                    <header><a href="JavaScript:void(0);">Por que o Microempreendedor Individual deveria contratar um contador?</a></header>
                                                    <div class="content" data-height="150px">
                                                        <div class="wrapper">
                                                            <p>A propaganda oficial é que o MEI está dispensado de ter um serviço de contabilidade, mas algumas obrigações devem ser cumpridas, e o contador é peça importante para orientação e comodidade do empreendedor, para que este possa focar no seu negócio. De acordo com dados da Receita Federal, mais da metade dos MEI estão inadimplentes e irregulares. Além disso, caso o MEI deseje contratar um funcionário, o que é permitido, precisará do contador para processar a folha de pagamento, gerando os recibos de salários, férias e 13º, e as guias de INSS e FGTS, respeitando toda a legislação, minimizando riscos para o empreendedor.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="item">
                                                    <header><a href="JavaScript:void(0);">Como eu faço para me tornar um MEI?</a></header>
                                                    <div class="content" data-height="150px">
                                                        <div class="wrapper">
                                                            <p>A formalização deve ser feita pelo Portal do Empreendedor no endereço www.portaldoempreendedor.gov.br, de forma gratuita. Para se formalizar, é preciso informar o número do título de eleitor ou o número do último recibo de entrega da Declaração de Imposto de Renda Pessoa Física.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="item">
                                                    <header><a href="JavaScript:void(0);">Qual a legislação a ser observada pelo MEI?</a></header>
                                                    <div class="content" data-height="150px">
                                                        <div class="wrapper">
                                                            <p>Lei Complementar nº 123/2006 e Resoluções do Comitê Gestor do Simples Nacional, principalmente a Resolução nº 94/2011.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="item">
                                                    <header><a href="JavaScript:void(0);">Como o MEI pode fazer alteração contratual ou atualização de dados cadastrais?</a></header>
                                                    <div class="content" data-height="150px">
                                                        <div class="wrapper">
                                                            <p>O MEI, a qualquer momento, pode alterar os dados do seu cadastro, como por exemplo, endereço, atividades, nome fantasia. Todas as modificações devem ser feitas pelo Portal do Empreendedor.  A alteração de dados é gratuita e registrada automaticamente.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="item">
                                                    <header><a href="JavaScript:void(0);">Como dar baixa no cadastro de MEI?</a></header>
                                                    <div class="content" data-height="150px">
                                                        <div class="wrapper">
                                                            <p>Para cancelar a inscrição como MEI, basta acessar o Portal do Empreendedor e solicitar a baixa do registro.  Após realizar a baixa, o MEI deverá preencher a Declaração Anual do MEI - DASN/SIMEI de Extinção – Encerramento, no Portal do Simples Nacional.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="item">
                                                    <header><a href="JavaScript:void(0);">Um estrangeiro pode registrar seu negócio como microempreendedor individual? Como?</a></header>
                                                    <div class="content" data-height="150px">
                                                        <div class="wrapper">
                                                            <p>O estrangeiro com visto permanente, pode se formalizar como MEI, através do Portal do Empreendedor. Por não possuir título de eleitor ou não estar obrigado a entrega da Declaração de Imposto de Renda de Pessoa Física – DIRPF, deverá elaborar e  transmitir a DIRPF para Receita Federal do Brasil e após, utilizar o numero do recibo para fazer sua formalização como MEI.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="item">
                                                    <header><a href="JavaScript:void(0);">O cidadão com restrição cadastral (Serasa e SPC) pode registrar um MEI?</a></header>
                                                    <div class="content" data-height="150px">
                                                        <div class="wrapper">
                                                            <p>Não existem impedimentos para o cidadão que possui restrições cadastrais em se registrar como MEI.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="item">
                                                    <header><a href="JavaScript:void(0);">Qual a idade necessária para iniciar uma atividade como MEI?</a></header>
                                                    <div class="content" data-height="150px">
                                                        <div class="wrapper">
                                                            <p>A idade mínima é de 18 anos, porém, poderão registrar-se como MEI as pessoas maiores de 16 anos e menores de 18 anos legalmente emancipadas. Nesse último caso, é obrigatório, ao se inscrever no Portal do Empreendedor, o preenchimento eletrônico da Declaração de Capacidade, com o seguinte texto: "Declaro, sob as penas da Lei, ser legalmente emancipado".</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="item">
                                                    <header><a href="JavaScript:void(0);">O MEI pode utilizar o comércio eletrônico nos seus negócios?</a></header>
                                                    <div class="content" data-height="150px">
                                                        <div class="wrapper">
                                                            <p>Não existem impedimentos para o MEI realizar negócios via comércio eletrônico.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="item">
                                                    <header><a href="JavaScript:void(0);">Quais as atividades permitidas para o microempreendedor individual?</a></header>
                                                    <div class="content" data-height="150px">
                                                        <div class="wrapper">
                                                            <p>Existem mais de 480 atividades permitidas. A lista pode ser encontrada no Portal do Empreendedor.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="item">
                                                    <header><a href="JavaScript:void(0);">O MEI pode abrir conta corrente e ter acesso a crédito?</a></header>
                                                    <div class="content" data-height="150px">
                                                        <div class="wrapper">
                                                            <p>Sim, não existem restrições para o MEI em abrir conta corrente e ter acesso a crédito. Inclusive existem linhas de crédito especificas para esse público.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="item">
                                                    <header><a href="JavaScript:void(0);">O MEI pode contratar pessoas? Como registrar o empregado do MEI?</a></header>
                                                    <div class="content" data-height="150px">
                                                        <div class="wrapper">
                                                            <p>O MEI pode contratar até um empregado com remuneração de um salário mínimo ou piso salarial da categoria.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="item">
                                                    <header><a href="JavaScript:void(0);">Quem pode evitar que usem o meu CPF para abrir MEI sem minha autorização?</a></header>
                                                    <div class="content" data-height="150px">
                                                        <div class="wrapper">
                                                            <p>O próprio cidadão, tendo o cuidado com suas informações pessoais.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="item">
                                                    <header><a href="JavaScript:void(0);">Como expandir os negócios e transformar o MEI em microempresa?</a></header>
                                                    <div class="content" data-height="150px">
                                                        <div class="wrapper">
                                                            <p>O MEI poderá pedir o desenquadramento como MEI e se transformar em Microempresa, a qualquer tempo.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>


        <section id="secTestimonials" class="s3-section-testimonials hidden">
            <div class="container">
                <div class="row">
                    <div class="col-xs-12">
                        <h2>Nossos clientes têm toda razão.</h2>
                    </div>
                </div>
                <div class="row">
                    <div class="s3-testimonials-slick">

                        <div class="col-md-4">
                            <div class="s3-item">
                                <div class="s3-box">
                                    <div class="s3-content">“Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.” Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.“</div>
                                    <div class="s3-info">
                                        <p>Raphael Felicio</p>
                                        <p>Co-Founder da Estúdio Two</p>
                                    </div>
                                </div>
                                <div class="s3-avatar">
                                    <img src="data/images/img-avatar.png" alt="Avatar" />
                                </div>
                            </div>
                        </div>

                        <div class="col-md-4">
                            <div class="s3-item">
                                <div class="s3-box">
                                    <div class="s3-content">“Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.” Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.“</div>
                                    <div class="s3-info">
                                        <p>Raphael Felicio</p>
                                        <p>Co-Founder da Estúdio Two</p>
                                    </div>
                                </div>
                                <div class="s3-avatar">
                                    <img src="data/images/img-avatar.png" alt="Avatar" />
                                </div>
                            </div>
                        </div>

                        <div class="col-md-4">
                            <div class="s3-item">
                                <div class="s3-box">
                                    <div class="s3-content">“Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.” Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.“</div>
                                    <div class="s3-info">
                                        <p>Raphael Felicio</p>
                                        <p>Co-Founder da Estúdio Two</p>
                                    </div>
                                </div>
                                <div class="s3-avatar">
                                    <img src="data/images/img-avatar.png" alt="Avatar" />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>



        <section id="secContact" class="s3-section-contact">
            <div class="container">
                <div class="row">

                    <div class="col-md-3 col-md-offset-1 image">
                        <img src={imgFaq} />
                    </div>

                    <EnviaEmails />

                </div>
            </div>
        </section>

        <Footer />


    </div>

);

export default Landing;
