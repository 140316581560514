import React from "react";
import macbook from '../assets/data/images/macbook.png';
import imgDomesticas from '../assets/data/images/slide-03.png';
import otimizarTempo from '../assets/data/images/img-optimize.png';

import { NavBar, NumerosImpressionantes, OtimizeGastos, DuvidasFrequentes, EnviaEmails, Footer } from '../components/sectionsHome/sectionsHome'


const Teste = function(props){
    return <h1>{props.texto}</h1>
}

const Landing = () => (

    <div>
        <NavBar />

        <section id="jumbotron" class="s3-section-jumbotron">
            <div class="container">
                <div class="row">
                    <div class="col-xs-12">
                        <h1><span><h1>Sua contabilidade agora é fácil, acessível e digital.</h1></span><strong><h1>Conheça o NeoContador.</h1></strong></h1>
                        <div class="s3-slider">
                            <div class="s3-slider-arrows">
                                <button class="s3-slick-prev"><i class="s3-icon-arrow-left-02"></i></button>
                                <button class="s3-slick-next"><i class="s3-icon-arrow-right-02"></i></button>
                            </div>
                            <div class="s3-slider-navigation hidden-xs hidden-sm">
                                <ul>
                                    <li class="" data-goto="2"><a href="/register">Contabilidade para Domésticas</a></li>
                                </ul>
                            </div>
                            <div class="s3-slick">


                                <div class="s3-slick-item">
                                    <div class="s3-flex-wrapper">
                                        <div class="s3-image" style={{ "background-image": "url("+imgDomesticas+");" }}>
                                            <img src={imgDomesticas} alt="Contabilidade de Domésticas" style={{"height": "100%"}}/>
                                        </div>
                                        <div class="s3-content">
                                            <div class="s3-header">Contabilidade para Domésticas</div>
                                            <div class="s3-body">

                                                <div class="s3-wrapper">
                                                    <div class="s3-price">
                                                        <span class="s3-number">R$49,90*</span>
                                                    </div>
                                                    <div class="s3-ast">* por doméstica</div>
                                                    <div class="s3-cta">
                                                        <a href="./register" class="s3-btn green material">Cadastre Aqui</a>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section id="secOptimize" class="s3-section-optimize">
            <div class="container">
                <div class="row">
                    <div class="col-md-8 col-md-offset-2">
                        <div class="s3-image"><img src={otimizarTempo} alt="" /></div>
                        <div class="s3-content">
                            <div class="s3-header">
                                <h2>Existimos para otimizar seu tempo</h2>
                            </div>
                            <div class="s3-body">
                                <p>Chega de anotar tudo no caderninho! Agora que você conhece a NeoContador, pode ter certeza que cuidaremos de todo seu processo contábil com o maior carinho do mundo, enquanto você dedica todo o seu tempo no que mais importa: o seu negócio.</p>
                                <div class="s3-cta"><a href="./register" class="s3-btn green material">Cadastre Aqui</a></div>
                                <p><a href="JavaScript:void(0);">Tem alguma dúvida? A gente te ajuda!</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <NumerosImpressionantes />

        <section id="secTestimonials" class="s3-section-testimonials hidden">
            <div class="container">
                <div class="row">
                    <div class="col-xs-12">
                        <h2>Nossos clientes têm toda razão.</h2>
                    </div>
                </div>
                <div class="row">
                    <div class="s3-testimonials-slick">

                        <div class="col-md-4">
                            <div class="s3-item">
                                <div class="s3-box">
                                    <div class="s3-content">“Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.” Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.“</div>
                                    <div class="s3-info">
                                        <p>Raphael Felicio</p>
                                        <p>Co-Founder da Estúdio Two</p>
                                    </div>
                                </div>
                                <div class="s3-avatar">
                                    <img src="data/images/img-avatar.png" alt="Avatar" />
                                </div>
                            </div>
                        </div>

                        <div class="col-md-4">
                            <div class="s3-item">
                                <div class="s3-box">
                                    <div class="s3-content">“Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.” Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.“</div>
                                    <div class="s3-info">
                                        <p>Raphael Felicio</p>
                                        <p>Co-Founder da Estúdio Two</p>
                                    </div>
                                </div>
                                <div class="s3-avatar">
                                    <img src="data/images/img-avatar.png" alt="Avatar" />
                                </div>
                            </div>
                        </div>

                        <div class="col-md-4">
                            <div class="s3-item">
                                <div class="s3-box">
                                    <div class="s3-content">“Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.” Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.“</div>
                                    <div class="s3-info">
                                        <p>Raphael Felicio</p>
                                        <p>Co-Founder da Estúdio Two</p>
                                    </div>
                                </div>
                                <div class="s3-avatar">
                                    <img src="data/images/img-avatar.png" alt="Avatar" />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>

        <section id="secCta" class="s3-section-cta">

            <OtimizeGastos />

            <div class="s3-body">
                <div class="container">
                    <div class="row">

                        <div class="col-xs-12">
                            <div class="s3-image">
                                <img src={macbook} alt="Macbook Preview" />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>

        <section id="secContact" class="s3-section-contact">
            <div class="container">
                <div class="row">

                    <DuvidasFrequentes />

                    <EnviaEmails/>

                </div>
            </div>
        </section>
    
        <Footer />
    
    
    </div>

);

export default Landing;
