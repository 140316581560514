import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';

import {Button} from 'react-bootstrap';
import { CONFIG } from '../../config/index';
import './styles/styles.css';
import '../../assets/css/style.css';
import StepTitle from './StepTitle';

//const Teste = (props) => <h3>AAAA</h3> 


class Step_Construcao extends Component {
    constructor() {
        super()
        this.handleSignUpSubmit = this.handleSignUpSubmit.bind(this);

        this.state = {
            username: undefined,
            cpf: undefined,
            email: undefined,
            password: undefined,
            telephone: undefined,
            numberEmployees: undefined,
            valor: '50,00'
        }
    }

    static displayName = 'ui-RegisterForm'


    /*
    Register Form area
    */

    handleSignUpSubmit(e) {
        e.preventDefault();
        let dataToSend = {
            userData: {
                username: this.refs.username.value,
                cpf: this.refs.cpf.value,
                email: this.refs.email.value,
                password: this.refs.password.value,
                numberEmployees: this.refs.numberEmployees.value
            }
        };
        let url = CONFIG.API_PREFIX + '/users/register';

        fetch(url, {
            method: "POST",
            body: JSON.stringify(dataToSend),
            headers: {
                "Content-Type": "application/json"
            }
        }).then(response => response.json())
            .then(responseJson => {
                // console.log(responseJson)
                if (responseJson.success) {
                    this.setState({
                        signUp: {
                            success: true,
                            message: responseJson.message
                        }
                    });
                } else {
                    this.setState({
                        signUp: {
                            success: false,
                            message: responseJson.message
                        }
                    });
                }
            }).catch(err => console.log('Error ', err));

        this.refs.username.value = '';
        this.refs.cpf.value = ''
        this.refs.email.value = '';
        this.refs.password.value = '';
        this.refs.numberEmployees.value = ''
    }

    render() {

        const textoTitulo = {
                titulo: 'Em construção.', 
                produto: '', 
                subtitulo: '',
                alinhamento: 'center'
        }

        return (
            <div className="s3-step" data-step="Construção">
                <div className="s3-wrapper">

                {/* //className = "s3-signup s3-body"> */}

                     <Row >
                        <StepTitle texto={textoTitulo}/>
                        
                    </Row>
                </div>
            </div>
            
           
            
        );
    }
}

export default Step_Construcao;