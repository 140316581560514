import React from "react";
import macbook from '../assets/data/images/macbook.png';
import imgDomesticas from '../assets/data/images/slide-03.png';
import imgOptimize from '../assets/data/images/img-optimize.png';
import block1 from '../assets/data/images/icon-block_1.png';
import block2 from '../assets/data/images/icon-block_2.png';
import block3 from '../assets/data/images/icon-block_3.png';

import { NavBar, NumerosImpressionantes, OtimizeGastos, DuvidasFrequentes, EnviaEmails, Footer } from '../components/sectionsHome/sectionsHome'


const Teste = function (props) {
    return <h1>{props.texto}</h1>
}

const Landing = () => (

    <div className="page-contato principal">
        <NavBar />

        <section class="s3-page-header">
            <div class="container">
                <div class="row">
                    <div class="col-xs-12">
                        <h1 className="textoBranco" style={{ marginTop: '50px' }}>Contato</h1>
                        <p className="textoBranco" style={{ marginTop: '0px' }}>Tire suas dúvidas ou fale conosco. <br />Será um imenso prazer falar com você.</p>
                    </div>
                </div>
            </div>
        </section>

        <section class="s3-section-contact">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">

                        <div class="content">
                            <div className="s3-image">
                                <p class="text-center image"><img src={imgOptimize} /></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="s3-section-contact" style={{'paddingTop': '0'}}>
            <h2>Existimos para otimizar seu tempo</h2>
            <h3 className="textoAzulClaro">Entre em contato conosco</h3>
            <div class="container">
                <div class="row" style={{ 'display': 'flex', 'justifyContent': 'center' }}>

                    <EnviaEmails ocultarEntreContato="true" alinhamento="center"/>

                </div>
            </div>
        </section>

        <section class="s3-section-cta">
            <OtimizeGastos />
        </section>
        <Footer />


    </div>

);

export default Landing;
