import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Button } from 'react-bootstrap';
import { CONFIG } from '../../config/index';
import styles from './styles/styles'
import { NeoButtonNav, NeoButtonChoice } from '../NeoButtons/NeoButtons'
import NeoNavbar2 from '../NeoNavbar/NeoNavbar2'
import NeoNavbar from '../NeoNavbar/NeoNavbar'
import StepTitle from './StepTitle'
import Step_Construcao from './Step_Construcao'
import Step_Inicial from './Step_Inicial'
import Contratos from './Contratos'
import Step_A from './Step_A'
import Step_B1 from './Step_B1'
import Step_B2 from './Step_B2'
import Step_C from './Step_C'
import Step_D from './Step_D'
import Step_E from './Step_E'
import Step_F1 from './Step_F1'
import Step_F2 from './Step_F2'
import Step_F3 from './Step_F3'
import Step_G from './Step_G'
import Step_H1 from './Step_H1'
import Step_H2 from './Step_H2'
import Step_I from './Step_I'
import Step_Z from './Step_Z'
import Step_P from './Step_P'
import Step_P1 from './Step_P1'
import Step_P2 from './Step_P2'
import Step_Inves from './Step_Inves';
import Step_Inves_A from './Step_Inves_A'
import Step_Final_Inves from './Step_Final_Inves'

class StepsController extends Component {

    constructor(props) {
        super(props);
        this.state = {
            step: this.props.step
            
        }

        this.nextStep = this.nextStep.bind(this);
    }

    componentDidMount() {
        document.getElementById( 's3-cadastro').setAttribute('data-step', this.state.step);
    }

    nextStep = function (params) {

        //Tira as classes específicas pra responsividade em determinados steps
        document.getElementById('s3-cadastro').classList.remove('contratos');
        document.getElementById('s3-cadastro').classList.remove('specialSize');

        this.setState(state => ({
            step: params
        }));
    }

    callbackFunction = function (e) {
        console.log(e);
    }
    render() {
        return (
            <div>
                {
                    this.state.step == "Contratos" ?
                        <div>
                            <NeoNavbar2 />
                            <Contratos 
                                nextScreen1={this.nextStep.bind(this, 'INICIAL')} 
                                nextScreen2={this.nextStep.bind(this, 'P')}
                                nextScreen3={this.nextStep.bind(this, 'Inves')}
                            />
                        </div>
                        : this.state.step == "P"?
                            <div>
                                <NeoNavbar />
                                <Step_P 
                                    lastScreen={this.nextStep.bind(this, 'Contratos')}
                                    nextScreen={this.nextStep}
                                />
                            </div>
                        : this.state.step == "P1"?
                            <div>
                                <NeoNavbar />
                                    <Step_P1 
                                        lastScreen={this.nextStep.bind(this, 'P')}
                                    />
                            </div>
                        : this.state.step == "P2"?
                            <div>
                                <NeoNavbar />
                                    <Step_P2 
                                        lastScreen={this.nextStep.bind(this, 'P')}
                                    />
                            </div>
                        :this.state.step == "Inves"?
                            <div>
                                <NeoNavbar/>
                                <Step_Inves 
                                    lastScreen={this.nextStep.bind(this, 'Contratos')}
                                    nextScreen={this.nextStep.bind(this, 'Inves_A')}
                                />
                            </div>
                        :this.state.step == "Inves_A"?
                            <div>
                                <NeoNavbar />
                                <Step_Inves_A
                                    lastScreen={this.nextStep.bind(this, 'Inves')}
                                    nextScreen={this.nextStep.bind(this, 'Final_Inves')}
                                />
                            </div>
                        :this.state.step == "Final_Inves"?
                            <div>
                                <NeoNavbar2 />
                                <Step_Final_Inves/>
                            </div>
                        : this.state.step == "INICIAL" ?
                            <div>
                                <NeoNavbar2 />
                                <Step_Inicial
                                    lastScreen={this.nextStep.bind(this, "Contratos")}
                                    nextScreen={this.nextStep.bind(this, "A")}
                                />
                            </div>
                            : this.state.step == "A" ?
                                <div>
                                    <NeoNavbar />
                                    <Step_A parentCallback={this.callbackFunction}
                                        lastScreen={this.nextStep.bind(this, 'INICIAL')}
                                        nextScreen={this.nextStep.bind(this, 'B1')}
                                    />
                                </div>

                                :
                                <div>
                                    {
                                        this.state.step == "B1" ?
                                            <div>
                                                <NeoNavbar />
                                                <Step_B1
                                                    nextScreen={this.nextStep.bind(this, 'B2')}
                                                />
                                            </div>
                                        : this.state.step == "B2" ?
                                            <div>
                                                <NeoNavbar />
                                                <Step_B2
                                                    lastScreen={this.nextStep}
                                                    nextScreen={this.nextStep} />
                                            </div>

                                        : this.state.step == "C" ?
                                            <div>
                                                <NeoNavbar />
                                                <Step_C callbackSim={this.nextStep.bind(this, 'D')} callbackNao={this.nextStep} />

                                            </div>
                                        : this.state.step == "D" ?
                                            <div>
                                                <NeoNavbar />
                                                <Step_D parentCallback={this.callbackFunction}
                                                    lastScreen={this.nextStep}
                                                    nextScreen={this.nextStep}
                                                />
                                            </div>
                                        : this.state.step == "E" ?
                                            <div>
                                                <NeoNavbar />
                                                <Step_E
                                                    lastScreen={this.nextStep}
                                                    nextScreen={this.nextStep}
                                                />

                                            </div>
                                        : this.state.step == "F1" ?
                                            <div>
                                                <NeoNavbar />
                                                <Step_F1
                                                    lastScreen={this.nextStep}
                                                    nextScreen={this.nextStep.bind(this, 'G')}
                                                />
                                            </div>
                                        : this.state.step == "F2" ?
                                            <div>
                                                <NeoNavbar />
                                                <Step_F2
                                                    lastScreen={this.nextStep}
                                                    nextScreen={this.nextStep}
                                                />

                                            </div>
                                        : this.state.step == "F3" ?
                                            <div>
                                                <NeoNavbar />
                                                <Step_F3
                                                    lastScreen={this.nextStep}
                                                    nextScreen={this.nextStep}
                                                />

                                            </div>
                                        : this.state.step == 'G' ?
                                            <div>
                                                <NeoNavbar />
                                                <Step_G
                                                    lastScreen={this.nextStep}
                                                    nextScreen={this.nextStep.bind(this, 'H1')}
                                                />
                                            </div>
                                        : this.state.step == 'H1' ?
                                            <div>
                                                <NeoNavbar />
                                                <Step_H1
                                                    parentCallback={this.callbackFunction}
                                                    lastScreen={this.nextStep}
                                                    nextScreen={this.nextStep.bind(this, 'H2')}
                                                />
                                            </div>
                                        : this.state.step == 'H2' ?
                                            <div>
                                                <NeoNavbar />
                                                <Step_H2
                                                    parentCallback={this.callbackFunction}
                                                    lastScreen={this.nextStep}
                                                    nextScreen={this.nextStep.bind(this)}
                                                />
                                            </div>
                                        : this.state.step == 'I' ?
                                            <div>
                                                <NeoNavbar />
                                                <Step_I
                                                    lastScreen={this.nextStep}
                                                    nextScreen={this.nextStep}
                                                />
                                            </div>
                                        : this.state.step == 'Z' ?
                                            <div>
                                                <NeoNavbar />
                                                <Step_Z />
                                                {/* <NeoButtonNav text="Continuar" color="green" callback={() => window.location.href = "/"} /> */}
                                            </div>
                                        :    <div>
                                                <NeoNavbar />
                                                <Step_Construcao />
                                                <NeoButtonNav text="Voltar" callback={() => window.location.href = "/"} />
                                            </div>
                                    }
                                </div>
                }

            </div>
            


        )
    }
}




export default StepsController