const styles = {
    displayNone: {
        display: 'none'
    },
    alignCenter: {
        textAlign: 'center'
    },

    inputFileBtn: {
        border: '0px',//'1px solid rgb(139, 139, 139)',
        padding: '10px 0px',
        width: '100%'

    },

    inputFileText: {
        color: '#fff',
        //backgroundColor: 'red'
    },
    inputFile:{
        display: "flex"
    },
    
    removePadding: {
        padding: 0
    },
    removeMargin: {
        margin: 0
    },
    pointer: {
        cursor: 'pointer'
    },
    error: {
        borderColor: '#ff0000'
    },

    positionRelative: {
        position: 'relative'
    },
    passInput: {
        // textIndent: '30px'
    },
    passIcon: {
        cursor: 'pointer',
        position: 'absolute',
        top: '16px',
        right: '7px',
        fontSize: '15px',
        userSelect: 'none'
    },

    selectStyle: {
        noOptionsMessage: 'NADA'
    }

}


export default styles;