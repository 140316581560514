import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';

import { Button } from 'react-bootstrap';
import { GLOBAL } from '../../config/global';
import { CONFIG } from '../../config/index';
import styles from './styles/styles';
import './styles/styles.css';
import '../../assets/css/style.css';
import StepTitle from './StepTitle';
import { NeoButtonChoice } from '../NeoButtons/NeoButtons'
import { NeoPercent } from '../NeoPercent/NeoPercent';

import StepsController from './StepsController'
import { NeoAlert, NeoLoading } from '../NeoAlert/NeoModal';

//const Teste = (props) => <h3>AAAA</h3> 

const stepAtual = 'C'

class Step_C extends Component {
    constructor() {
        super()
        this.alertControl = this.alertControl.bind(this);
        this.botaoNao = this.botaoNao.bind(this);
        this.botaoSim = this.botaoSim.bind(this);
        this.state = {
            choiceVisible: true,
            userId: GLOBAL.getCookie('_uf_neoId'),
            modalEsconderBotao: false
        }
    }

    static displayName = 'ui-RegisterForm'

    //roda assim q a tela abre
    componentDidMount() {
        document.getElementById('s3-cadastro').classList.add('contratos')
        //verifica se o id tá salvo no cookie
        let userId = this.state.userId
        if (userId && userId != "undefined") {

            this.loadingControl(true, 'Aguarde');
            //Consulta pra preencher os dados da tela assim que entra       
            let url = CONFIG.API_PREFIX + '/users/finduser';
            fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    userLogin: {
                        _id: userId
                    }
                })
            }).then(response => response.json())
                .then(responseJson => {
                    if (responseJson.success) {
                        let dados = responseJson.data;
                        this.setState({
                            cpf: dados.cpf,
                            declarouIR: dados.declarouIR,
                            telaCarregou: true
                        })

                        //controla o histórico de steps
                        let historicoStep = responseJson.data.historicoStep;
                        if (historicoStep[historicoStep.length - 1] === stepAtual) historicoStep.pop();
                        this.setState({
                            historicoStep: historicoStep,
                        })

                    } else {
                        // console.log("Deu ruim");
                        // console.log(responseJson.success);
                        this.setState({
                            telaCarregou: true
                        })
                    }
                    this.loadingControl(false, '');
                }).catch(err => {
                    console.error(err);

                    this.loadingControl(false, '');
                    this.setState({
                        telaCarregou: true,
                        modalTitle: "Ops!",
                        modalText: "Ocorreu um erro ao buscar os dados. Entre em contato com o administrador do sistema",
                        modalEsconderBotao: true
                    })
                    this.alertControl("Ops!", "Ocorreu um erro ao buscar os dados. Entre em contato com o administrador do sistema");
                });
        }
    }

    changeVisibility = () => {
        this.setState(state => ({
            choiceVisible: !state.choiceVisible
        }))
    }

    //Controla a visibilidade do alerta
    alertControl(classe = '') {
        this.setState(state => ({
            modalClass: classe,
            showAlert: !state.showAlert,
        }))
    }

    //Controla o loading
    loadingControl(visible, title) {
        this.setState({
            loading: visible,
            modalTitle: title
        })
    }


    gravaDados(step, callback) {
        let self = this;
        let historicoStep = self.state.historicoStep;
        historicoStep.push(stepAtual);

        let dataToSend = {
            _id: this.state.userId,
            params: {
                step: step,
                historicoStep: historicoStep
            }
        }

        let url = CONFIG.API_PREFIX + '/users/updateUser';
        self.loadingControl(true, 'Aguarde, salvando dados');
        fetch(url, {
            method: "PUT",
            headers: {
                "Origin": "*",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                data: dataToSend
            })

        }).then(response => response.json())
            .then(responseJson => {
                if (responseJson.success) {
                    callback(false, 'Dados Gravados')
                } else {
                    callback(true, 'Erro ao gravar dados')
                }
                self.loadingControl(false, '');

            }).catch(err => {
                console.error(err);
                self.loadingControl(false, '');
            });
    }

    botaoNao() {
        let self = this
        let nextScreen = this.state.declarouIR == '1' ? 'F2' : 'F1';
        this.gravaDados(nextScreen, function (err, response) {
            if (!err) {
                self.props.callbackNao(nextScreen)
            }
        })
    }
    botaoSim() {
        let self = this
        this.gravaDados('D', function (err, response) {
            if (!err) {
                self.props.callbackSim()
            }
        })
    }

    handleKeyDown = (event) => {

        if (event.key === 'Enter') {

            event.preventDefault();
        }
    };

    render() {

        const textoTitulo = {
            titulo: 'Você já tem cadastro no eSocial para fazer a folha e recolher os encargos de seu(s) funcionário(s)?',
            produto: '',
            subtitulo: '',
            observacao: '(O cadastro consiste em chave de acesso e senha no portal do governo)',
            alinhamento: 'center'
        }

        return (
            <div className="s3-step" data-step="C">
                <NeoAlert
                    show={this.state.showAlert}
                    title={this.state.modalTitle}
                    text={this.state.modalText}
                    close={this.alertControl}
                    class={this.state.modalClass}
                    modalEsconderBotao={this.state.modalEsconderBotao}
                />

                <NeoLoading title={this.state.modalTitle} show={this.state.loading} />

                <div className="s3-wrapper">

                    {/* //className = "s3-signup s3-body"> */}

                    <Row >
                        <StepTitle texto={textoTitulo} />
                        <div className="s3-content col-md-12">
                            <div className="s3-signup">
                                <Row>
                                    <div className="s3-form-item clearfix col-md-12">
                                        {/* Begin Register Form */}
                                        < div className="register-content" style={{ marginTop: "10px" }}>

                                            <div className="register-body">
                                                <form onSubmit={this.handleSignUpSubmit} onKeyDown={e => { this.handleKeyDown(e) }}>


                                                    <Row style={styles.alignCenter}>
                                                        <Col xs={12} md={6} className="btnChoice">
                                                            <NeoButtonChoice lg={3} text="Sim" callback={this.botaoSim} />
                                                        </Col>
                                                        <Col xs={12} md={6} className="btnChoice">
                                                            <NeoButtonChoice lg={3} text="Não" callback={this.botaoNao} />
                                                        </Col>
                                                    </Row>

                                                </form>
                                            </div>
                                        </div >
                                        {/* Begin Register Form */}

                                    </div>
                                </Row>
                            </div>
                        </div>
                    </Row>
                </div>
                <NeoPercent text="45%" />
            </div>



        );
    }
}

export default Step_C;