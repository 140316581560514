import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import StepTitle from './StepTitle';
import { NeoAlert, NeoLoading } from '../NeoAlert/NeoModal';
import { GLOBAL } from '../../config/global';
import { CONFIG } from '../../config/index';
import { NeoPercent } from '../NeoPercent/NeoPercent';

class Step_Z extends Component {
  constructor() {
    super()
    this.alertControl = this.alertControl.bind(this);
    this.state = {
      username: null,
      cpf: null,
      telefone: null,
      email: null,
      dataNascimento: null,
      userId: GLOBAL.getCookie('_uf_neoId'),
      enviouEmail: null,
      endereco: {},
      funcionario: [],
      esocial: {},
      tituloEleitor: {},
      ULTIMOIRPF: {},
      PENULTIMOIRPF: {},
      telaCarregou: false,
      dadosEmpregador: false
    }
  }

  static displayName = 'ui-RegisterForm'

  //roda assim q a tela abre
  componentDidMount() {
    document.getElementById('s3-cadastro').classList.add('contratos')
    let self = this;
    this.setState({
      userId: GLOBAL.getCookie('_uf_neoId')
    })

    //verifica se o id tá salvo no cookie
    let userId = GLOBAL.getCookie('_uf_neoId');
    if (userId && userId != "undefined") {

      this.loadingControl(true, 'Aguarde, não atualize esta página');
      //Consulta pra preencher os dados da tela assim que entra       
      let url = CONFIG.API_PREFIX + '/users/finduser';
      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          userLogin: {
            _id: userId
          }
        })
      }).then(response => response.json())
        .then(responseJson => {
          if (responseJson.success) {

            let dados = responseJson.data;

            // console.log(`dado-finduser`);
            // console.log(dados)

            this.setState({
              cpf: dados.cpf,
              email: dados.email,
              username: dados.name,
              enviouEmail: dados.emailEnviadoSucesso,
            })

            if (!dados.emailEnviadoSucesso) {
              self.loadingControl(false, '');
              self.enviaEmail(function (err, resultEmpregador) {
                // console.log(resultEmpregador)
                self.loadingControl(false, '');
              });
            } else {
              // console.log("não enviou email")
              self.loadingControl(false, '');
            }

            self.loadingControl(true, 'Aguarde, não atualize esta página');

            self.recuperaDadosEmpregador((err, returnDados) => {
              if (!err) {

                this.setState({
                  cpf: returnDados.cpf,
                  email: returnDados.email,
                  telefone: returnDados.telefone,
                  userName: returnDados.name,
                  dataNascimento: returnDados.dataNascimento,
                  enviouEmail: returnDados.emailEnviadoSucesso,
                  endereco: returnDados.endereco,
                  funcionario: returnDados.funcionarios,
                  esocial: returnDados.esocial,
                  tituloEleitor: returnDados.tituloEleitor,
                  ULTIMOIRPF: returnDados.ULTIMOIRPF,
                  PENULTIMOIRPF: returnDados.PENULTIMOIRPF,
                  telaCarregou: true,
                  dadosEmpregador: true
                })
                document.getElementById('s3-cadastro').classList.add('totalHeight');
                document.getElementById('s3-cadastro').style.height = '100%';
                self.loadingControl(false, '');
              }
              else {
                this.setState({
                  dadosEmpregador: false
                });
                self.loadingControl(false, '');
              }
            })

          } else {
            // console.log("Deu ruim");
            // console.log(responseJson.success);
            this.setState({
              telaCarregou: true
            })
            self.loadingControl(false, '');
          }
        }).catch(err => {
          console.error(err);
          self.loadingControl(false, '');
          self.setState({
            telaCarregou: true
          })
        });


    }
  }

  recuperaDadosEmpregador(callback) {
    let url = CONFIG.API_PREFIX + '/users/buscaDadosEmpregador/' + this.state.cpf;
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      },

    }).then(response => response.json())
      .then(responseJson => {
        if (responseJson.success) {
          callback(false, responseJson.data)
        } else {
          callback(true, "Erro ao buscar dados")
        }
        this.loadingControl(false, '');
      }).catch(err => {
        console.error(err);
        this.loadingControl(false, '');
        callback(false, err);
      });
  }


  enviaEmail(callback) {
    let url = CONFIG.API_PREFIX + '/users/enviaEmails/' + this.state.cpf;
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      },

    }).then(response => {
        let responseJson = response.json()
        if (responseJson.success) { 
          callback(false, responseJson.data)
        } else {
          callback(true, "Erro ao buscar dados")
        }
        this.loadingControl(false, '');
      }).catch(err => {
        console.error('ERRO NO ENVIO DE EMAIL');
        console.error(err);
        this.loadingControl(false, '');
        this.setState({
          telaCarregou: true
        })
        callback(false, err);
      });
  }


  //Controla a visibilidade do alerta
  alertControl() {
    this.setState(state => ({
      showAlert: !state.showAlert
    }))
  }

  //Controla o loading
  loadingControl(visible, title) {
    this.setState({
      loading: visible,
      modalTitle: title
    })
  }


  render() {

    const textoTitulo = {
      titulo: 'Parabéns! Agora você é um Empregador assessorado por ',
      produto: ' profissionais qualificados!',
      subtitulo: 'Portanto, agora é só aguardar que nossa equipe vai auditar seus dados em até 48 horas e você receberá os primeiros relatórios de registro.',
      alinhamento: 'center'
    }

    // console.log(this.state)
    let data = this.state;
    let endereco = this.state.endereco;
    let funcionario = this.state.funcionario;
    let esocial = this.state.esocial;
    let tituloEleitor = this.state.tituloEleitor;
    let ULTIMOIRPF = this.state.ULTIMOIRPF;
    let PENULTIMOIRPF = this.state.PENULTIMOIRPF;

    return (

      <div className="s3-step stepFinal" data-step="Z">
        <NeoAlert
          show={data.showAlert}
          title={data.modalTitle}
          text={data.modalText}
          close={this.alertControl}
        />

        <NeoLoading title={data.modalTitle} show={data.loading} />

        <div className="s3-wrapper">

          {/* //className = "s3-signup s3-body"> */}

          <Row >
            <StepTitle texto={textoTitulo} />
            {
              this.state.telaCarregou && this.state.dadosEmpregador ?

                <div className="containerGeral">

                  <Row>
                    <div className="containerDocumentos">
                      <h4>Suas informações, como empregador:</h4>

                      <Col xs={12} md={12}>
                        <div className="listaDocumentos">
                          <ul>
                            <li><span><strong>Nome:  </strong></span>{data.userName}</li>
                            <li><span><strong>Cpf:  </strong></span>{data.cpf}</li>
                            <li><span><strong>Email:  </strong></span>{data.email}</li>
                            <li><span><strong>Telefone:  </strong></span>{data.telefone}</li>
                            <li><span><strong>Data de Nascimento:  </strong></span>{data.dataNascimento}</li>
                            <li><span><strong>Endereço:  </strong></span></li>
                            <Col xs={12} md={12}>
                              <ul>
                                <li><span><strong>Cep:  </strong></span>{endereco.cep}</li>
                                <li><span><strong>Bairro:  </strong></span>{endereco.bairro}</li>
                                <li><span><strong>Cidade:  </strong></span>{endereco.localidade}</li>
                                <li><span><strong>Estado:  </strong></span>{endereco.uf}</li>
                                <li><span><strong>Rua:  </strong></span>{endereco.logradouro}</li>
                                <li><span><strong>Número:  </strong></span>{endereco.numeroResidencia}</li>
                                {endereco.complementoResidencia ? <li><span><strong>Complemento:  </strong></span>{endereco.complementoResidencia}</li> : null}
                              </ul>
                            </Col>
                            {
                              esocial && esocial.codAcesso ?
                                <ul>
                                  <li><span><strong>E-Social:</strong></span></li>
                                  <Col xs={12} md={12}>
                                    <ul>
                                      <li><span><strong>Código de Acesso:  </strong></span>{esocial.codAcesso}</li>
                                      <li><span><strong>Nº Funcionários Cadastrados:  </strong></span>{esocial.funcionariosTemCadastro}</li>
                                    </ul>
                                  </Col>
                                </ul>
                                : null
                            }
                            {
                              tituloEleitor && tituloEleitor.numTituloEleitor ?
                                <ul>
                                  <li><span><strong>Título de Eleitor:</strong></span></li>
                                  <Col xs={12} md={12}>
                                    <ul>
                                      <li><span><strong>Número:  </strong></span>{tituloEleitor.numTituloEleitor}</li>
                                      <li><span><strong>Zona:  </strong></span>{tituloEleitor.zona}</li>
                                      <li><span><strong>Seção:  </strong></span>{tituloEleitor.secao}</li>
                                    </ul>
                                  </Col>
                                </ul>
                                : null
                            }
                            {
                              ULTIMOIRPF && ULTIMOIRPF.numDeclaracao ?
                                <li><span><strong>Última declaração IRPF:  </strong></span>{ULTIMOIRPF.numDeclaracao}</li>
                                : null
                            }
                            {
                              PENULTIMOIRPF && PENULTIMOIRPF.numDeclaracao ?
                                <li><span><strong>Penúltima declaração IRPF:  </strong></span>{PENULTIMOIRPF.numDeclaracao}</li>
                                : null
                            }
                          </ul>
                        </div>
                      </Col>
                    </div>
                  </Row>
                  {
                    funcionario ?

                      <Row>
                        <div className="containerDocumentos">
                          {funcionario.length ? <h4>Do(s) seu(s) empregado(s):</h4> : null}
                          <Col xs={12} md={12}>
                            <div className="listaDocumentos">
                              {
                                funcionario.map((funcionario, index) => {

                                  return (
                                    <ul key={index}>
                                      <li><span><strong>Funcionário {index + 1}</strong></span></li>
                                      <Col xs={12} md={12}>
                                        <ul>
                                          <li><span><strong>PIS:  </strong></span>{funcionario.pis}</li>
                                          <li><span><strong>Data de Admissão:  </strong></span>{funcionario.dataAdmissao}</li>
                                          <li><span><strong>Escolaridade:  </strong></span>{funcionario.escolaridadeLabel}</li>
                                          <li><span><strong>Função:  </strong></span>{funcionario.funcaoLabel}</li>
                                          <li><span><strong>Possui Dependente(s)?  </strong></span>
                                            {funcionario.possuiDependentes == 1 ? 'Sim' : 'Não'}
                                          </li>
                                          <li><span><strong>Possui Vale Transporte?  </strong></span>
                                            {funcionario.possuiValeTransporte == 1 ? 'Sim' : 'Não'}
                                          </li>
                                          <li><span><strong>Jornada:  </strong></span>{funcionario.jornadaLabel}</li>
                                          <li><span><strong>Salário:  </strong></span>{funcionario.salario}</li>
                                        </ul>
                                      </Col>
                                    </ul>
                                  )
                                })
                              }
                            </div>
                          </Col>
                        </div>
                      </Row>

                      : null
                  }

                </div>
                : null
            }
          </Row>
        </div>
        <NeoPercent text="100%" />
      </div>
    );
  }
}

export default Step_Z;