import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';

import { Button } from 'react-bootstrap';
import { GLOBAL } from '../../config/global';
import { CONFIG } from '../../config/index';
import styles from './styles/styles';
import './styles/styles.css';
import '../../assets/css/style.css';
import StepTitle from './StepTitle';
import { NeoInput, NeoSelect, NeoInputRequiredSpecial } from '../NeoInputs/NeoInputs'
import { ValidatorForm } from 'react-form-validator-core';
import { NeoButtonNav, NeoSubmit } from '../NeoButtons/NeoButtons'
import { NeoAlert, NeoAlertDecision, NeoLoading } from '../NeoAlert/NeoModal';
import { NeoPercent } from '../NeoPercent/NeoPercent';

const stepAtual = 'H2'

class Step_H2 extends Component {

    constructor() {
        super()
        this.updateValue = this.updateValue.bind(this);
        this.onlyNumber = this.onlyNumber.bind(this);
        this.gravarDados = this.gravarDados.bind(this);
        this.alertDecisionControl = this.alertDecisionControl.bind(this);
        this.alertControl = this.alertControl.bind(this);
        this.fixDuasCasasDecimais = this.fixDuasCasasDecimais.bind(this);

        this.state = {
            choiceVisible: true,
            userId: '',
            loading: false,
            loadingButton: false,
            jornada: '',
            jornadaLabel: '',
            salario: '',
            telaCarregou: false,
            showAlertDecision: false,
            modalTitle: '',
            modalText: '',
            modalEsconderBotao: false,
            quantFuncCadastrados: 0,
            quantFuncFaltaCadastrar: 0,
            modalClass: null,
        }
    }

    static displayName = 'ui-RegisterForm'

    //roda assim q a tela abre
    componentDidMount() {

        document.getElementById('s3-cadastro').classList.add('contratos')

        this.setState({
            userId: GLOBAL.getCookie('_uf_neoId')
        })

        //verifica se o id tá salvo no cookie
        let userId = GLOBAL.getCookie('_uf_neoId');

        if (userId && userId != "undefined") {

            this.loadingControl(true, 'Buscando dados, aguarde');

            //Consulta pra preencher os dados da tela assim que entra       
            let url = CONFIG.API_PREFIX + '/users/finduser';

            fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    userLogin: {
                        _id: userId
                    }
                })
            }).then(response => response.json())
                .then(responseJson => {

                    if (responseJson.success) {

                        let dados = responseJson.data;

                        if (!isNaN(dados.quantFuncCadastrados)) {

                            let quantFuncCadastrados = dados.quantFuncCadastrados;

                            this.setState(() => ({
                                quantFuncCadastrados: dados.quantFuncCadastrados,
                                quantFuncFaltaCadastrar: dados.quantFuncFaltaCadastrar,
                            }))

                            //Consulta pra preencher os dados da tela assim que entra       
                            let url = CONFIG.API_PREFIX + '/funcionarios/findfuncionario';

                            fetch(url, {
                                method: "POST",
                                headers: {
                                    "Content-Type": "application/json"
                                },
                                body: JSON.stringify({
                                    userLogin: {
                                        idEmpregador: userId,
                                        numeroFuncionario: quantFuncCadastrados
                                    }
                                })
                            }).then(response => response.json())
                                .then(responseJson => {

                                    if (responseJson.success) {

                                        let dados = responseJson.data;

                                        this.setState(() => ({
                                            jornada: dados.jornada ? dados.jornada : '',
                                            jornadaLabel: dados.jornadaLabel ? dados.jornadaLabel : '',
                                            salario: dados.salario ? dados.salario : '',
                                            possuiDependentes: dados.possuiDependentes,
                                            telaCarregou: true
                                        }))
                                    }

                                    else {

                                        this.setState(() => ({
                                            telaCarregou: true
                                        }))

                                        // console.log(responseJson.success);
                                    }

                                    this.loadingControl(false, '');
                                }).catch(err => {

                                    this.setState(() => ({
                                        telaCarregou: true
                                    }))

                                    console.error(err);
                                    this.loadingControl(false, '');
                                });
                        }

                        else {

                            this.loadingControl(false, '');

                            this.setState(() => ({
                                modalTitle: 'Ops!',
                                modalText: 'Ocorreu um erro ao buscar os dados, atualize a página ou entre em contato com o administrador do sistema.',
                                modalEsconderBotao: true,
                                telaCarregou: true
                            }))

                            this.alertControl('diminuir-modal');
                        }

                        //controla o histórico de steps
                        let historicoStep = responseJson.data.historicoStep;
                        if (historicoStep[historicoStep.length - 1] === stepAtual) historicoStep.pop();
                        this.setState({
                            historicoStep: historicoStep,
                        })

                    }

                    else {

                        this.loadingControl(false, '');

                        this.setState(() => ({
                            modalTitle: 'Ops!',
                            modalText: 'Ocorreu um erro ao buscar os dados, atualize a página ou entre em contato com o administrador do sistema.',
                            modalEsconderBotao: true,
                            telaCarregou: true
                        }))

                        this.alertControl('diminuir-modal');

                        // console.log(responseJson.success);
                    }
                }).catch(err => {

                    console.error(err);

                    this.loadingControl(false, '');

                    this.setState(() => ({
                        modalTitle: 'Ops!',
                        modalText: 'Ocorreu um erro ao buscar os dados, atualize a página ou entre em contato com o administrador do sistema.',
                        modalEsconderBotao: true,
                        telaCarregou: true
                    }))

                    this.alertControl('diminuir-modal');
                });
        }

        else {

            this.loadingControl(false, '');

            this.setState(() => ({
                modalTitle: 'Ops!',
                modalText: 'Ocorreu um erro ao buscar os dados, atualize a página ou entre em contato com o administrador do sistema.',
                modalEsconderBotao: true,
                telaCarregou: true
            }))

            this.alertControl('diminuir-modal');
        }
    }

    gravarDados = function () {

        var self = this;
        let historicoStep = self.state.historicoStep;
        historicoStep.push(stepAtual);

        self.loadingControl(true, 'Salvando dados, aguarde');

        self.setState({
            loadingButton: true,
        })

        let step = null
        if (this.state.possuiDependentes == 1) {
            step = 'I'
        } else {
            if (this.state.quantFuncCadastrados == this.state.quantFuncFaltaCadastrar) {
                step = 'Z'
            } else {
                step = 'G'
            }
        }


        let dataToSend = {
            idEmpregador: self.state.userId,
            numeroFuncionario: self.state.quantFuncCadastrados,
            params: {
                jornada: self.state.jornada,
                jornadaLabel: self.state.jornadaLabel,
                salario: self.state.salario,
            }
        }

        let url = CONFIG.API_PREFIX + '/funcionarios/updateFuncionario';

        fetch(url, {
            method: "PUT",
            headers: {
                "Origin": "*",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                data: dataToSend
            })
        }).then(response => response.json())
            .then(responseJson => {

                if (responseJson.success) {

                    // console.log(responseJson);

                    let dataToSend = {
                        _id: self.state.userId,
                        params: {
                            historicoStep: historicoStep,
                            step: step,
                        }
                    }

                    let url = CONFIG.API_PREFIX + '/users/updateUser';

                    fetch(url, {
                        method: "PUT",
                        headers: {
                            "Origin": "*",
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify({
                            data: dataToSend
                        })
                    }).then(response => response.json())
                        .then(responseJson => {

                            if (responseJson.success) {

                                // console.log(responseJson);

                                self.props.nextScreen(step);

                                self.loadingControl(false, '');
                            }

                            else {

                                // console.log(responseJson.success);

                                self.loadingControl(false, '');

                                self.setState({
                                    modalTitle: 'Ops!',
                                    modalText: 'Ocorreu um erro ao salvar os dados',
                                })
                                self.alertControl();
                            }

                            self.setState({
                                loadingButton: false,
                            })

                        }).catch(err => {

                            console.error(err);

                            self.loadingControl(false, '');

                            self.setState({
                                modalTitle: 'Ops!',
                                modalText: 'Ocorreu um erro ao salvar os dados',
                                loadingButton: false,
                            })

                            self.alertControl();
                        });
                }

                else {

                    // console.log(responseJson.success);

                    self.loadingControl(false, '');

                    self.setState({
                        modalTitle: 'Ops!',
                        modalText: 'Ocorreu um erro ao salvar os dados',
                    })
                    self.alertControl();
                }

                self.setState({
                    loadingButton: false,
                })

            }).catch(err => {

                console.error(err);

                self.loadingControl(false, '');

                self.setState({
                    modalTitle: 'Ops!',
                    modalText: 'Ocorreu um erro ao salvar os dados',
                    loadingButton: false,
                })

                self.alertControl();
            });
    }

    changeVisibility = () => {
        this.setState(state => ({
            choiceVisible: !state.choiceVisible
        }))
    }

    //Atualiza os states
    updateValue = function (name, evt, value = null, labelSelect = null) {

        let valor = value;

        if (evt) {

            valor = evt.target.value
        }

        if (!labelSelect) this.setState(state => ({ [name]: valor }))

        else {

            this.setState(state => ({
                [name]: valor,
                [name + 'Label']: labelSelect
            }))
        }
    }

    //Controla o loading
    loadingControl(visible, title) {
        this.setState({
            loading: visible,
            modalTitle: title
        })
    }

    //Controla a visibilidade do alerta decisivo
    alertDecisionControl(acao, titulo, mensagem, evt) {

        this.setState(state => ({
            showAlertDecision: !state.showAlertDecision,
            modalTitle: titulo,
            modalText: mensagem,
        }))

        if (acao == 'continuar') this.gravarDadosVoltar();
    }

    gravarDadosVoltar = function () {

        var self = this;
        let lastStep = this.state.historicoStep[this.state.historicoStep.length - 1];

        self.loadingControl(true, 'Aguarde');

        let dataToSend = {
            _id: self.state.userId,
            params: {
                step: lastStep,
                historicoStep: this.state.historicoStep,
            }
        }

        let url = CONFIG.API_PREFIX + '/users/updateUser';

        fetch(url, {
            method: "PUT",
            headers: {
                "Origin": "*",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                data: dataToSend
            })
        }).then(response => response.json())
            .then(responseJson => {

                if (responseJson.success) {

                    // console.log(responseJson);

                    self.props.lastScreen(lastStep);

                    self.loadingControl(false, '');
                }

                else {

                    // console.log(responseJson.success);

                    self.loadingControl(false, '');

                    self.setState({
                        modalTitle: 'Ops!',
                        modalText: 'Ocorreu um erro. Tente novamente mais tarde',
                    })
                    self.alertControl();
                }

                self.setState({
                    loadingButton: false,
                })

            }).catch(err => {

                console.error(err);

                self.loadingControl(false, '');

                self.setState({
                    modalTitle: 'Ops!',
                    modalText: 'Ocorreu um erro. Tente novamente mais tarde',
                    loadingButton: false,
                })

                self.alertControl();
            });
    }

    //Controla a visibilidade do alerta
    alertControl(classe = '') {
        this.setState(state => ({
            modalClass: classe,
            showAlert: !state.showAlert
        }))
    }

    //Bloqueia o campo de texto para que seja possível aceitar só números
    onlyNumber = function (name, evt) {

        let valor = evt.target.value

        // Campo de salario não pode ser 0, não pode ter mais de 15 numeros e pode ter ,
        if (name == 'salario') {

            if (valor.split(',')[0].replace(/\./g, '').length <= 13 &&
                valor.replace(/\./g, '').replace(',', '').length <= 15
            ) {

                let valorNumber = Number(valor.replace(',', '.'));

                let valorFinal = valor.replace(/[^0-9,]/g, "");
                let valorFinalArray = valorFinal.split(',');

                if (valorFinalArray.length > 2) {

                    valorFinal = valorFinalArray[0] + ',' + valorFinalArray[1];
                }

                this.setState(state => ({ [name]: valorFinal }));
            }
        }

        else {

            this.setState(state => ({ [name]: valor.replace(/\W+/g, "").replace(/[_A-Z-a-z]/g, '') }));
        }
    }

    // Fixa valor numerico do campo em duas casas decimais
    fixDuasCasasDecimais = function (name, evt) {

        let valor = evt.target.value;
        let valorFinal = null

        if (valor.split(',')[0].length <= 14) {

            valorFinal = String(Number(valor.replace(/\./g, '').replace(',', '.')).toFixed(2)).replace('.', ',');

            //coloca os pontos nas casas de milhar
            valorFinal = `${valorFinal.split(',')[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")},${valorFinal.split(',')[1]}`

            this.setState(state => ({ [name]: valorFinal }));
            // console.log(valor.length)
        }

    }

    handleKeyDown = (event) => {

        if (event.key === 'Enter') {

            event.preventDefault();
        }
    };

    render() {

        const textoTitulo = {
            titulo: 'Agora, precisamos de mais algumas informações do funcionário',
            produto: '',
            subtitulo: '',
            alinhamento: 'center'
        }

        return (

            <div>

                <NeoLoading title={this.state.modalTitle} show={this.state.loading} />

                <NeoAlertDecision
                    show={this.state.showAlertDecision}
                    title={this.state.modalTitle}
                    text={this.state.modalText}
                    sim={this.alertDecisionControl.bind(this, "continuar")}
                    nao={this.alertDecisionControl.bind(this, "cancelar")}
                />

                <NeoAlert
                    show={this.state.showAlert}
                    title={this.state.modalTitle}
                    text={this.state.modalText}
                    close={this.alertControl}
                    modalEsconderBotao={this.state.modalEsconderBotao}
                    class={this.state.modalClass}
                />

                <ValidatorForm ref="form" onSubmit={this.gravarDados} onKeyDown={e => { this.handleKeyDown(e) }}>
                    <div className="s3-step" data-step="H2">
                        <div className="s3-wrapper">

                            <Row >
                                <StepTitle texto={textoTitulo} />
                                <div className="s3-content col-md-12">
                                    <div className="s3-signup" style={{ margin: "0" }}>

                                        <Row>
                                            <div className="s3-form-item clearfix col-md-12" style={{ padding: "0" }}>
                                                {/* Begin Register Form */}
                                                < div className="register-content" style={{ marginTop: "10px" }}>
                                                    <div className="register-body" style={{ padding: "0" }}>

                                                        <Row style={styles.rowSpace} className="verticalAlign">
                                                            <Col xs={12} md={6}>
                                                                <label htmlFor="jornada" className="form-control-label">Informe a jornada de trabalho semanal:</label>
                                                            </Col>
                                                            <Col xs={12} md={6}>{this.state.telaCarregou ?
                                                                <NeoSelect
                                                                    value={this.updateValue.bind(this, 'jornada', null)}
                                                                    placeholder="Selecione uma jornada"
                                                                    defaultValue={this.state.jornada}
                                                                    defaultLabel={this.state.jornadaLabel}
                                                                    name="jornada"
                                                                    inputValue={this.state.jornadaLabel}
                                                                    maxHeight={90}
                                                                    options={[
                                                                        'Jornada diurna de 7 h e 20 min com 100 min de intervalo.',
                                                                        'Jornada diurna de 8 h e 48 min com 72 min de intervalo.',
                                                                        'Jornada diurna de 8 h com 120 min de intervalo.',
                                                                        'Jornada diurna de 9 h com 60 min de intervalo.',
                                                                        'Jornada diurna de 8 h com 60 min de intervalo.',
                                                                        'Jornada diurna de 4 h sem intervalo.',
                                                                        'Jornada diurna de 6 h com 15 min de intervalo.',
                                                                    ]}
                                                                />
                                                                : null
                                                            }
                                                            </Col>
                                                        </Row>

                                                        <Row style={styles.rowSpace} className="verticalAlign">
                                                            <Col xs={12} md={6}>
                                                                <label htmlFor="salario" className="form-control-label">Qual será o salário mensal?</label>
                                                            </Col>
                                                            <Col xs={12} md={6}>
                                                                <NeoInputRequiredSpecial
                                                                    changeCallback={this.onlyNumber.bind(this, "salario")}
                                                                    blurCallback={this.fixDuasCasasDecimais.bind(this, "salario")}
                                                                    name="salario"
                                                                    type="text"
                                                                    maxLength="20"
                                                                    value={this.state.salario}
                                                                    firstText="R$"
                                                                    secondText="/mês"
                                                                    validators={['required', 'isMaiorQueZero']}
                                                                    errorMessages={['Obrigatório', 'Valor Inválido']}
                                                                    attr={{
                                                                        mask: 'salario',
                                                                        ref: 'salario',
                                                                        className: 'form-control',
                                                                        id: 'salario',
                                                                    }}
                                                                />
                                                            </Col>
                                                        </Row>

                                                    </div>
                                                </div >
                                                {/* Begin Register Form */}
                                            </div>
                                        </Row>

                                    </div>
                                </div>
                            </Row>

                        </div>
                    </div>
                    <NeoButtonNav text="Voltar" callback={this.alertDecisionControl.bind(this, 'abrir', 'Atenção', 'Ao voltar os dados preenchidos serão perdidos, deseja continuar?')} />
                    <NeoSubmit text="Continuar" color="green" loading={this.state.loadingButton} />
                    <NeoPercent text="90%" />
                </ValidatorForm>
            </div>
        );
    }
}

export default Step_H2;