import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';

import { GLOBAL } from '../../config/global';
import { CONFIG } from '../../config/index';
import styles from './styles/styles';
import './styles/styles.css';
import '../../assets/css/style.css';
import StepTitle from './StepTitle';
import { NeoInput, NeoInputRequired } from '../NeoInputs/NeoInputs'
import { ValidatorForm } from 'react-form-validator-core';
import { NeoButtonNav, NeoSubmit } from '../NeoButtons/NeoButtons';
import NeoTooltip from '../NeoTooltip/NeoTooltip'
import { NeoAlert, NeoLoading, NeoAlertDecision } from '../NeoAlert/NeoModal';
import { NeoPercent } from '../NeoPercent/NeoPercent';

import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';


//const Teste = (props) => <h3>AAAA</h3> 
const stepAtual = 'B2'

class Step_B2 extends Component {
    constructor() {
        super()
        this.updateValue = this.updateValue.bind(this);
        this.onlyNumber = this.onlyNumber.bind(this);
        this.alertControl = this.alertControl.bind(this);
        this.nextScreen = this.nextScreen.bind(this);
        this.formatSpecialTypes = this.formatSpecialTypes.bind(this);
        this.alertDecisionControl = this.alertDecisionControl.bind(this);

        this.state = {
            username: undefined,
            cpf: undefined,
            email: undefined,
            valor: '',
            valorDesconto: '',
            desconto: 0,
            cupom: '',
            quantidadeFuncionarios: 1,
            userId: GLOBAL.getCookie('_uf_neoId'),
            numeroCartao: '',
            showAlert: false,
            cupons: [],
            cvc: '',
            validade: '',
            name: "**** ** * ****",
            finalStep: undefined,
            modalEsconderBotao: false,
        }
    }

    static displayName = 'ui-RegisterForm'

    componentDidMount() {
        let self = this;
        this.loadingControl(true, 'Aguarde');

        //verifica se o id tá salvo no cookie
        let userId = this.state.userId;

        let url = CONFIG.API_PREFIX + '/pgto/cupomDesconto';
        fetch(url, {
            method: "GET",
        }).then(response => response.json())
            .then(responseCupom => {
                self.setState({
                    cupons: responseCupom.result
                })
                // console.log(responseCupom);
                url = CONFIG.API_PREFIX + '/pgto/criarSessao'
                fetch(url, {
                    method: "POST",
                }).then(response => response.json())
                    .then(responseSession => {
                        // console.log(responseSession)
                        let session = responseSession.result.session.id[0];
                        // console.log(session);
                        window.PagSeguroDirectPayment.setSessionId(session);

                        if (userId && userId != "undefined") {

                            url = CONFIG.API_PREFIX + '/users/finduser';
                            fetch(url, {
                                method: "POST",
                                headers: {
                                    "Content-Type": "application/json"
                                },
                                body: JSON.stringify({
                                    userLogin: {
                                        _id: userId
                                    }
                                })

                            }).then(response => response.json())
                                .then(responseJson => {
                                    if (responseJson.success) {
                                        if (responseJson.data.endereco) {
                                            let dados = responseJson.data;
                                            // console.log(dados)
                                            this.setState({
                                                quantidadeFuncionarios: Number(dados.quantidadeFuncionarios),
                                                valor: 49.9 * Number(dados.quantidadeFuncionarios),
                                                valorDesconto: 49.9 * Number(dados.quantidadeFuncionarios),
                                                nascimento: dados.dataNascimento,
                                                cep: dados.endereco.cep,
                                                bairro: dados.endereco.bairro,
                                                logradouro: dados.endereco.logradouro,
                                                localidade: dados.endereco.localidade,
                                                uf: dados.endereco.uf,
                                                numero: dados.endereco.numeroResidencia,
                                                complemento: dados.endereco.complementoResidencia,
                                                name: dados.name,
                                                email: dados.email,
                                                cpf: dados.cpf,
                                                telefone: dados.telefone,
                                                finalStep: dados.finalStep,
                                                codAdesaoPagSeguro: dados.codAdesaoPagSeguro
                                            }, () => console.log(this.state))

                                        } else {
                                            this.setState({
                                                declarouIR: 1,
                                            })
                                        }
                                        //controla o histórico de steps
                                        let historicoStep = responseJson.data.historicoStep;
                                        if (historicoStep[historicoStep.length - 1] === stepAtual) historicoStep.pop();
                                        this.setState({
                                            historicoStep: historicoStep,
                                        })
                                    } else {
                                        this.setState({
                                            declarouIR: 1
                                        })
                                    }
                                    this.loadingControl(false, '');

                                }).catch(err => {
                                    console.error(err)
                                    this.setState({
                                        declarouIR: 1
                                    })
                                    this.loadingControl(false, '');
                                });

                        } else {
                            this.loadingControl(false, '');
                        }
                    }).catch((e) => {
                        console.error(1, e)
                        this.loadingControl(false, '');
                        this.setState({
                            modalEsconderBotao: true
                        })
                        this.alertControl("Ops!", "Ocorreu um erro ao buscar os dados. Entre em contato com o administrador do sistema");
                    })

            }).catch((e) => {
                this.loadingControl(false, '');
            })

        this._isMounted = true;
    }

    //Atualiza os states
    updateValue = function (name, evt) {
        let valor = evt;
        if (isNaN(Number(evt))) {
            valor = evt.target.value
        }

        this.setState(state => ({ [name]: valor }))

        if (name === "name" && valor === "") {
            this.setState(state => ({ "name": "**** ** * ****" }))
        }
        if (name === "cupom") {
            var cupom = this.state.cupons.find(function (item) {
                return item.nome === valor
            })

            if (cupom) {
                this.setState(() => ({ 'desconto': Number(cupom.valor) >= Number(this.state.valor) ? 0 : cupom.valor }))
            } else {
                this.setState(() => ({ 'desconto': 0 }))
            }
        }
        // console.log(this.state)
    }

    //Controla o loading
    loadingControl(visible, title) {
        this.setState({
            loading: visible,
            modalTitle: title
        })
    }

    //Controla a visibilidade do alerta
    alertControl(title, text) {
        this.setState(state => ({
            showAlert: !state.showAlert,
            modalTitle: title,
            modalText: text

        }))
    }

    //Bloqueia o campo de texto para que seja possível aceitar só números
    onlyNumber = function (name, evt) {
        let valor = evt.target.value.replace(/ /g, '');
        if (name == "numeroCartao") {
            if (valor.length <= 16)
                this.setState(state => ({ [name]: valor.replace(/\W+/g, "").replace(/[_A-Z-a-z]/g, '') }));
        } else {
            this.setState(state => ({ [name]: valor.replace(/\W+/g, "").replace(/[_A-Z-a-z]/g, '') }));
        }

    }

    creditCardBrand = (callback) => {
        window.PagSeguroDirectPayment.getBrand({
            cardBin: this.state.numeroCartao.replace(/ /g, '').substr(0, 6),
            success: function (response) {
                callback(false, response.brand.name)
            }, error: function (e) {
                callback(true, e)
            }
        })
    }

    creditCardToken = (callback) => {
        let self = this;
        this.creditCardBrand(function (err, bandeira) {
            if (!err) {
                window.PagSeguroDirectPayment.createCardToken({
                    cardNumber: self.state.numeroCartao.replace(/ /g, ''), // Número do cartão de crédito
                    brand: bandeira, // Bandeira do cartão
                    cvv: self.state.cvc, // CVV do cartão
                    expirationMonth: self.state.validade.split('/')[0], // Mês da expiração do cartão
                    expirationYear: self.state.validade.split('/')[1], // Ano da expiração do cartão, é necessário os 4 dígitos.
                    success: function (response) {
                        callback(false, response.card.token)
                    },
                    error: function (response) {
                        callback(true, response.errors)
                    },

                });
            } else {
                callback(err, bandeira)
            }

        })
    }

    paymentRequest = (callback) => {
        let self = this;
        this.creditCardToken(function (err, token) {
            let url = CONFIG.API_PREFIX + '/pgto/consultaAssinaturas'

            if (!err) {
                fetch(url, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(
                        {
                            cpf: self.state.cpf,
                            cupom: self.state.cupom,
                            valor: (Number(self.state.valor) - Number(self.state.desconto)).toFixed(2)
                        }
                    )
                }).then(response => response.json())
                    .then(responseJson => {
                        self.loadingControl(true, 'Processando Pagamento');
                        if (!responseJson.error) {
                            let hash = window.PagSeguroDirectPayment.getSenderHash();
                            let dataToSend = {
                                codigoAssinatura: responseJson.result,
                                sender: {
                                    userName: self.state.name,
                                    userEmail: self.state.email,
                                    userHash: hash,
                                    phone: {
                                        areaCode: self.state.telefone.split(' ')[0].replace(/[()]/gi, ''),
                                        phoneNumber: self.state.telefone.split(' ')[1].replace(/[-]/gi, '')
                                    }
                                },
                                address: {
                                    street: self.state.logradouro,
                                    number: self.state.numero,
                                    complement: self.state.complemento,
                                    district: self.state.bairro,
                                    city: self.state.localidade,
                                    state: self.state.uf,
                                    postalCode: self.state.cep.replace(/[-]/gi, '')
                                },
                                documents: {
                                    cpf: self.state.cpf
                                },
                                payment: {
                                    token: token,
                                    birthDate: self.state.nascimento,
                                    name: self.state.name
                                }
                            }

                            url = CONFIG.API_PREFIX + '/pgto/aderirPlano'

                            fetch(url, {
                                method: "POST",
                                headers: {
                                    "Content-Type": "application/json"
                                },
                                body: JSON.stringify(dataToSend)

                            }).then(response => response.json())
                                .then(responseJson => {
                                    if (!responseJson.error) {
                                        // console.log(responseJson);
                                        callback(false, responseJson)
                                    } else {
                                        callback(true, responseJson)
                                        // console.log("erro")
                                    }

                                }).catch(err => {
                                    console.error(err)
                                    callback(true, err)
                                    self.loadingControl(false, '');
                                })
                        } else {
                            console.error(responseJson)
                            callback(true, err)
                            self.loadingControl(false, '');
                        }

                    }).catch(err => {
                        console.error(err)
                        callback(true, err)
                        self.loadingControl(false, '');
                    })

            } else {
                self.loadingControl(false, '');
                callback(true, err)
                console.error(err)
            }
        })
    }

    mudancaPagamento = (callback) => {
        let self = this;
        // console.log("mudancaPagamento")
        this.creditCardToken(function (err, token) {
            if (!err) {
                // console.log("processando pagamento")
                self.loadingControl(true, 'Processando Pagamento');

                let hash = window.PagSeguroDirectPayment.getSenderHash();
                let dataToSend = {
                    codigoAssinatura: self.state.codAdesaoPagSeguro,
                    sender: {
                        userName: self.state.name,
                        userEmail: self.state.email,
                        userHash: hash,
                        phone: {
                            areaCode: self.state.telefone.split(' ')[0].replace(/[()]/gi, ''),
                            phoneNumber: self.state.telefone.split(' ')[1].replace(/[-]/gi, '')
                        }
                    },
                    address: {
                        street: self.state.logradouro,
                        number: self.state.numero,
                        complement: self.state.complemento,
                        district: self.state.bairro,
                        city: self.state.localidade,
                        state: self.state.uf,
                        postalCode: self.state.cep.replace(/[-]/gi, '')
                    },
                    documents: {
                        cpf: self.state.cpf
                    },
                    payment: {
                        token: token,
                        birthDate: self.state.nascimento,
                        name: self.state.name
                    }
                }

                let url = CONFIG.API_PREFIX + '/pgto/mudancaPagamento'
                // console.log("chegou")
                fetch(url, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(dataToSend)

                }).then(response => response.json())
                    .then(responseJson => {
                        if (!responseJson.error) {
                            // console.log(responseJson);
                            callback(false, responseJson)
                        } else {
                            callback(true, responseJson)
                            // console.log("erro")
                        }

                    }).catch(err => {
                        console.error(err)
                        callback(true, err)
                        self.loadingControl(false, '');
                    })




            } else {
                self.loadingControl(false, '');
                callback(true, err)
                // console.log(err)
            }
        })
    }

    cancelaAssinatura = (callback) => {
        let self = this;
        let url = CONFIG.API_PREFIX + '/pgto/cancelarAssinatura'

        fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "codAssinatura": self.state.codAdesaoPagSeguro
            })

        }).then(response => response.json())
            .then(responseJson => {
                if (!responseJson.error) {
                    // console.log(responseJson);
                    callback(false, responseJson)
                } else {
                    callback(true, responseJson)
                    // console.log("erro")
                }

            }).catch(err => {
                console.error(err)
                callback(true, err)
                self.loadingControl(false, '');
            })
    }

    //Formata campos que não foi possível aplicar máscara
    formatSpecialTypes = function (name, evt) {
        let valorFormatado = evt.target.value.replace(/ /g, ''); //tira os espaços
        let length = valorFormatado.replace(/[(,), ,-]/g, '').length //olha o tamanho sem os caracteres especiais

        /*Na época que foi desenvolvido, o plugin de máscara não conseguia fazer uma
        máscara dinâmica de um jeito eficiente, por isso foi feito assim */
        if (name == "numeroCartao") {

            if (length >= 16) {
                valorFormatado = valorFormatado.replace(/[(,), ,-]/g, '').replace(/^(\d{4})(\d{4})(\d{4})(\d{4}).*/, '$1 $2 $3 $4');
            } else if (length == 15) {
                valorFormatado = valorFormatado.replace(/[(,), ,-]/g, '').replace(/^(\d{4})(\d{6})(\d{5}).*/, '$1 $2 $3');
            } else if (length == 14) {
                valorFormatado = valorFormatado.replace(/[(,), ,-]/g, '').replace(/^(\d{4})(\d{6})(\d{4}).*/, '$1 $2 $3');
            }
        }
        this.setState(state => ({ [name]: valorFormatado }))
    }

    efetuaPagamento = function (nextStep, historicoStep) {
        let self = this;
        // return self.props.nextScreen(nextStep);
        this.paymentRequest(function (err, retorno) {
            if (!err) {
                self.loadingControl(true, 'Atualizando dados, aguarde');

                let dataToSend = {
                    _id: self.state.userId,
                    params: {
                        declarouIR: self.state.declarouIR,
                        dataNascimento: self.state.nascimento,
                        step: nextStep,
                        historicoStep: historicoStep,
                        validadeCartao: self.state.validade,
                        codAdesaoPagSeguro: retorno.result.code,
                        valorPago: (Number(self.state.valor) - Number(self.state.desconto)).toFixed(2)
                    }
                }

                //salva os dados em cache pra não ter que fazer requisição sempre q entra na tela
                //localStorage.setItem('stepB2', JSON.stringify(dataToSend));


                let url = CONFIG.API_PREFIX + '/users/updateUser';
                fetch(url, {
                    method: "PUT",
                    headers: {
                        "Origin": "*",
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        data: dataToSend
                    })

                }).then(response => response.json())
                    .then(responseJson => {
                        if (responseJson.success) {
                            // console.log(responseJson)
                            self.props.nextScreen(nextStep);
                        } else {
                            // console.log(responseJson.success);
                            historicoStep.pop();
                            this.setState({
                                historicoStep: historicoStep
                            })
                            self.alertControl("Ops!", "Ocorreu um erro ao salvar os dados");
                        }
                        self.loadingControl(false, '');

                    }).catch(err => {
                        console.error(err);
                        self.alertControl("Ops!", "Ocorreu um erro ao salvar os dados");
                        historicoStep.pop();
                        this.setState({
                            historicoStep: historicoStep
                        })
                        self.loadingControl(false, '');
                    });
            } else {
                console.error(err, retorno)
                self.loadingControl(false, '');
                self.alertControl("Ops!", "Ocorreu um erro ao processar o pagamento");
                historicoStep.pop();
                self.setState({
                    historicoStep: historicoStep
                })
            }
        })
    }

    nextScreen(e) {
        // console.log('NEXT SCREEN')
        let self = this;
        this.loadingControl(true, 'Processando Pagamento');
        let historicoStep = self.state.historicoStep;
        historicoStep.push(stepAtual);
        /*
            Se o "finalStep" indicar que o último step for este, já manda pro final do fluxo,
            caso contrário, continua normal.
        */
        let nextStep = this.state.finalStep == stepAtual ? 'Z' : 'C'
        let deuErro = false;
        // console.log(nextStep);

        if (nextStep == 'Z' || this.state.codAdesaoPagSeguro) {
            // console.log("entrou aqui")
            // this.cancelaAssinatura(function (err, response) {
            //     if (err) {
            //         self.loadingControl(false, '');
            //         self.alertControl("Ops!", "Ocorreu um erro ao processar o pagamento");
            //         historicoStep.pop();
            //         self.setState({
            //             historicoStep: historicoStep
            //         })
            //         return;
            //     }
            // })
            this.mudancaPagamento(function (err, response) {
                // console.log("entrou aqui tb")
                if (err) {
                    // console.log('deu erro')
                    console.error(err);
                    self.loadingControl(false, '');
                    self.alertControl("Ops!", "Ocorreu um erro ao processar o pagamento");
                    historicoStep.pop();
                    deuErro = true;
                    self.setState({
                        historicoStep: historicoStep
                    })
                    return;
                } else {

                    let dataToSend = {
                        _id: self.state.userId,
                        params: {
                            declarouIR: self.state.declarouIR,
                            dataNascimento: self.state.nascimento,
                            step: nextStep,
                            historicoStep: historicoStep,
                            validadeCartao: self.state.validade,
                            valorPago: (Number(self.state.valor) - Number(self.state.desconto)).toFixed(2)
                        }
                    }

                    let url = CONFIG.API_PREFIX + '/users/updateUser';
                    fetch(url, {
                        method: "PUT",
                        headers: {
                            "Origin": "*",
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify({
                            data: dataToSend
                        })

                    }).then(response => response.json())
                        .then(responseJson => {
                            if (responseJson.success) {
                                // console.log(responseJson)
                                self.props.nextScreen(nextStep);
                            } else {
                                // console.log(responseJson.success);
                                historicoStep.pop();
                                this.setState({
                                    historicoStep: historicoStep
                                })
                                self.alertControl("Ops!", "Ocorreu um erro ao salvar os dados");
                            }
                            self.loadingControl(false, '');

                        }).catch(err => {
                            console.error(err);
                            self.alertControl("Ops!", "Ocorreu um erro ao salvar os dados");
                            historicoStep.pop();
                            this.setState({
                                historicoStep: historicoStep
                            })
                            self.loadingControl(false, '');
                        });
                }
            })
        } else {
            // console.log("oua")
            this.efetuaPagamento(nextStep, historicoStep);
        }
    }



    handleKeyDown = (event) => {

        if (event.key === 'Enter') {

            event.preventDefault();
        }
    };

    //Controla a visibilidade do alerta decisivo
    alertDecisionControl(acao, titulo, mensagem, evt) {

        this.setState(state => ({
            showAlertDecision: !state.showAlertDecision,
            modalTitle: titulo,
            modalText: mensagem,
        }))

        if (acao == 'continuar') this.gravarDadosVoltar();
    }

    gravarDadosVoltar = function () {

        var self = this;

        self.loadingControl(true, 'Aguarde');

        let dataToSend = {
            _id: self.state.userId,
            params: {
                step: this.state.historicoStep[this.state.historicoStep.length - 1],
            }
        }

        let url = CONFIG.API_PREFIX + '/users/updateUser';

        fetch(url, {
            method: "PUT",
            headers: {
                "Origin": "*",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                data: dataToSend
            })
        }).then(response => response.json())
            .then(responseJson => {

                if (responseJson.success) {

                    // console.log(responseJson);

                    self.props.lastScreen(this.state.historicoStep[this.state.historicoStep.length - 1]);

                    self.loadingControl(false, '');
                }

                else {
                    
                    console.error(2, responseJson)
                    // console.log(responseJson.success);

                    self.loadingControl(false, '');

                    self.setState({
                        modalTitle: 'Ops!',
                        modalText: 'Ocorreu um erro ao buscar os dados. Entre em contato com o administrador do sistema.',
                        modalEsconderBotao: true
                    })
                    self.alertControl();
                }

                self.setState({
                    loadingButton: false,
                })

            }).catch(err => {

                console.error(err);

                self.loadingControl(false, '');

                self.setState({
                    modalTitle: 'Ops!',
                    modalText: 'Ocorreu um erro ao buscar os dados',
                    loadingButton: false,
                })

                self.alertControl();
            });
    }

    render() {

        const textoTitulo = {
            titulo: 'Agora preencha os campos abaixo com suas informações de pagamento.',
            produto: '',
            subtitulo: '',
            alinhamento: 'center'
        }


        return (

            <div>
                <NeoLoading title={this.state.modalTitle} show={this.state.loading} />
                <NeoAlert
                    show={this.state.showAlert}
                    title={this.state.modalTitle}
                    text={this.state.modalText}
                    modalEsconderBotao={this.state.modalEsconderBotao}
                    close={this.alertControl}
                />
                <NeoAlertDecision
                    show={this.state.showAlertDecision}
                    title={this.state.modalTitle}
                    text={this.state.modalText}
                    sim={this.alertDecisionControl.bind(this, "continuar")}
                    nao={this.alertDecisionControl.bind(this, "cancelar")}
                />

                <ValidatorForm ref="form" onSubmit={this.nextScreen} onKeyDown={e => { this.handleKeyDown(e) }}>

                    <div className="s3-step" data-step={stepAtual}>
                        <div className="s3-wrapper">

                            {/* //className = "s3-signup s3-body"> */}

                            <Row >
                                <StepTitle texto={textoTitulo} />
                                <div className="s3-content col-md-12">
                                    <div className="s3-signup">
                                        <Row>
                                            <div className="s3-form-item clearfix col-md-12">
                                                {/* Begin Register Form */}
                                                < div className="register-content" style={{ marginTop: "10px" }}>

                                                    <div className="register-body">
                                                        <Row style={styles.rowSpace} className="verticalAlign">
                                                            <Col xs={12} md={4}>
                                                                <Cards
                                                                    cvc={this.state.cvc}
                                                                    expiry={this.state.validade}
                                                                    focused={this.state.focus}
                                                                    name={this.state.name}
                                                                    number={this.state.numeroCartao}
                                                                />
                                                            </Col>
                                                            <Col xs={12} md={2}></Col>
                                                            <Col xs={12} md={6} id="cardData">


                                                                <NeoInputRequired

                                                                    changeCallback={this.onlyNumber.bind(this, "numeroCartao")}
                                                                    focusCallback={() => this.setState({ focus: "number" })}
                                                                    blurCallback={this.formatSpecialTypes.bind(this, 'numeroCartao')}
                                                                    name="number"
                                                                    type="text"
                                                                    maxLength='20'
                                                                    value={this.state.numeroCartao}
                                                                    validators={['required', 'minStringLength:14']}
                                                                    errorMessages={['Obrigatório', 'Cartão Inválido']}

                                                                    attr={{
                                                                        mask: '',
                                                                        ref: 'numeroCartao',
                                                                        className: 'form-control',
                                                                        id: 'numeroCartao',
                                                                        placeholder: 'Número do Cartão',
                                                                        obrigatorio: true
                                                                    }} />

                                                                <NeoInputRequired

                                                                    changeCallback={this.updateValue.bind(this, "name")}
                                                                    focusCallback={() => this.setState({ focus: "name" })}
                                                                    name="name"
                                                                    type="text"
                                                                    value={this.state.name === "**** ** * ****" ? "" : this.state.name}
                                                                    validators={['required']}
                                                                    errorMessages={['Obrigatório']}

                                                                    attr={{
                                                                        mask: 'text',
                                                                        ref: 'name',
                                                                        className: 'form-control',
                                                                        id: 'name',
                                                                        placeholder: 'Nome impresso no cartão',
                                                                        obrigatorio: true
                                                                    }} />



                                                                <Col xs={12} sm={6} md={6} id="colValidade">
                                                                    <NeoInputRequired
                                                                        changeCallback={this.updateValue.bind(this, "validade")}
                                                                        focusCallback={() => this.setState({ focus: "expiry" })}
                                                                        name="expiry"
                                                                        type="text"
                                                                        value={this.state.validade}
                                                                        validators={['required', 'validadeCartao']}
                                                                        errorMessages={['Obrigatório', 'Data Inválida']}
                                                                        attr={{
                                                                            mask: 'validadeCartao',
                                                                            ref: 'validade',
                                                                            className: 'form-control',
                                                                            id: 'validade',
                                                                            placeholder: '__/____',
                                                                            obrigatorio: true
                                                                        }} />
                                                                </Col>
                                                                <Col xs={12} sm={6} md={6} id="colCVV">
                                                                    <NeoInputRequired

                                                                        changeCallback={this.onlyNumber.bind(this, "cvc")}
                                                                        focusCallback={() => this.setState({ focus: "cvc" })}
                                                                        name="cvc"
                                                                        type="text"
                                                                        maxLength='4'
                                                                        value={this.state.cvc}
                                                                        validators={['required']}
                                                                        errorMessages={['Obrigatório']}
                                                                        attr={{
                                                                            mask: 'text',
                                                                            ref: 'CVV',
                                                                            className: 'form-control',
                                                                            id: 'CVV',
                                                                            placeholder: 'CVV',
                                                                            obrigatorio: true
                                                                        }} />
                                                                </Col>
                                                            </Col>
                                                        </Row>
                                                        <Row style={styles.rowSpace} className="verticalAlign">
                                                            <Col xs={10} md={5}>
                                                                <div>
                                                                    <label htmlFor="cupom" className="form-control-label">Possui cupom de desconto?</label>
                                                                </div>

                                                                <div>
                                                                    <label htmlFor="cupom" className="form-control-label"><small>(Se não, pule este campo)</small></label>
                                                                </div>
                                                            </Col>
                                                            <Col xs={2} md={1}>
                                                                <NeoTooltip position="left" text="Cupom de desconto" />
                                                            </Col>
                                                            <Col xs={12} md={6}>
                                                                <NeoInputRequired

                                                                    changeCallback={this.updateValue.bind(this, "cupom")}
                                                                    name="cupom"
                                                                    type="text"
                                                                    value={this.state.cupom}
                                                                    validators={['isCupom']}
                                                                    errorMessages={['Cupom Inválido']}

                                                                    attr={{
                                                                        type: 'text',
                                                                        ref: 'cupom',
                                                                        className: 'form-control',
                                                                        id: 'cupom',
                                                                        placeholder: 'Cupom de desconto',
                                                                        obrigatorio: false
                                                                    }} />
                                                            </Col>
                                                        </Row>

                                                        <Row style={styles.rowSpace} className="verticalAlign">
                                                            <Col xs={12} md={6}>
                                                                <label htmlFor="valTotal" className="form-control-label"></label>                                        </Col>
                                                            <Col xs={12} sm={12} md={3}>

                                                                {
                                                                    this.state.desconto ?
                                                                        <div>
                                                                            <span className="valorDesconto">
                                                                                {`R$ ${String(Number(this.state.valor).toFixed(2)).replace('.', ',')}/mês`}
                                                                            </span>
                                                                            <div className="valorTotal">
                                                                                <span >
                                                                                    {`R$ ${String(Number(this.state.valor - this.state.desconto).toFixed(2)).replace('.', ',')}/mês`}
                                                                                </span>
                                                                            </div>
                                                                        </div>

                                                                        : <div className="valorTotal">
                                                                            <span >
                                                                                {`R$ ${String(Number(this.state.valor).toFixed(2)).replace('.', ',')}/mês`}
                                                                            </span>
                                                                        </div>
                                                                }

                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </div >
                                            </div>
                                        </Row>
                                    </div>
                                </div>
                            </Row>
                        </div>
                    </div>
                    <NeoButtonNav text="Voltar" callback={this.alertDecisionControl.bind(this, 'abrir', 'Atenção', 'Ao voltar os dados preenchidos serão perdidos, deseja continuar?')} />
                    <NeoSubmit text="Confirmar" color="green" loading={this.state.loading} />
                    <NeoPercent text="40%" />

                </ValidatorForm>
            </div>


        );
    }
}

export default Step_B2;