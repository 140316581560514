const styles = {
    registerHeader :{
        display: "flex",
        justifyContent: "center",
        color: "rgb(189, 189, 189)",
    },
    rowSpace : {
        margin: "1% 0"
    },
    bigSpace: {
        margin: "2% 0"
    },
    inlineField: {
        display: 'inline-block',
        width: '49%',
        //margin: '0 1%'
    },
    alignCenter: {
        textAlign: 'center'
    },
    alignContentCenter: {
        display: 'flex',
        justifyContent: 'center'
    }
}

export default styles;