import React, { Component } from 'react';
import { Container, Row } from 'reactstrap';

import { CONFIG } from '../config/index';
import { GLOBAL } from '../config/global';
import  NeoNavbar  from '../components/NeoNavbar/NeoNavbar'
import { NeoAlert, NeoLoading } from '../components/NeoAlert/NeoModal'
import StepsController from '../components/Steps/StepsController'
import '../assets/css/style.css'

//const Teste = (props) => <h3>AAAA</h3> 


class RegisterForm extends Component {
    constructor() {
        super()

        this.state = {
            userId: GLOBAL.getCookie('_uf_neoId'),
            step: 'Contratos',
            telaCarregou: false
        }
    }

    static displayName = 'ui-RegisterForm'

    componentDidMount() {

        //verifica se o id tá salvo no cookie
        let userId = this.state.userId;

        if (userId && userId != "undefined") {
            // console.log(userId)
                this.loadingControl(true, 'Aguarde');
                let url = CONFIG.API_PREFIX + '/users/finduser';
                fetch(url, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        userLogin: {
                            _id: userId
                        }
                    })

                }).then(response => response.json())
                    .then(responseJson => {
                        if (responseJson.success) {
                            if(responseJson.data.step){
                                // console.log(responseJson.data)
                                if(this.state.step === "Contratos" && this.state.userId && this.state.userId != "undefined"){
                                    this.setState(() => ({
                                        step: responseJson.data.step,
                                        telaCarregou: true
                                    }))
                                    
                                }else{
                                    this.setState(() => ({
                                        step: 'Contratos',
                                        telaCarregou: true
                                    }))
                                }
                                this.loadingControl(false, '');
                            }
                        } else {
                            // console.log("Deu ruim");
                            // console.log(responseJson.success);
                            this.setState(() => ({
                                step: 'Contratos',
                                telaCarregou: true
                            }))
                            this.loadingControl(false, '');
                        }

                    }).catch(err => {
                        console.error(err);
                        this.setState(() => ({
                            step: 'Contratos',
                            telaCarregou: true
                        }))
                        this.loadingControl(false, '');
                    });

        }else{
            this.setState(() => ({
                step: 'Contratos',
                telaCarregou: true
            }))
            this.loadingControl(false, '');
        }
    }

    //Controla o loading
    loadingControl(visible, title){
        this.setState({
            loading: visible,
            modalTitle: title
        })
    }

    render() {


        return (
            
        <div className="page-cadastro">
            <NeoLoading title={this.state.modalTitle} show={this.state.loading} class="responsive" />
             <Container className="s3-cadastro" id="s3-cadastro">
                <Container>
                    {
                        this.state.step?
                        <Row>
                            <div className="col-sm-12 col-md-10 col-md-offset-1 ">
                                <div id="mainWrapper" className="s3-main-wrapper visible">
                                {  }
                                    <div className="s3-body">
                                        <div className="s3-steps">
                                            {
                                                this.state.telaCarregou?
                                                    <StepsController step={this.state.step}/>
                                                :null
                                            }
                                            
                                        </div> 
                                    </div>
                                </div>
                            </div>
                        </Row>
                        :null
                    }
                </Container>
            </Container>
        </div>
        
            
        );
    }
}

export default RegisterForm;