import React from "react";
import macbook from '../assets/data/images/macbook.png';
import imgDomesticas from '../assets/data/images/slide-03.png';
import imgOptimize from '../assets/data/images/img-optimize.png';
import block1 from '../assets/data/images/icon-block_1.png';
import block2 from '../assets/data/images/icon-block_2.png';
import block3 from '../assets/data/images/icon-block_3.png';

import { NavBar, NumerosImpressionantes, OtimizeGastos, DuvidasFrequentes, EnviaEmails, Footer } from '../components/sectionsHome/sectionsHome'


const Teste = function (props) {
	return <h1>{props.texto}</h1>
}

const Landing = () => (

	<div className="page-quem_somos principal">
		<NavBar />

		<section class="s3-page-header">
			<div class="container">
				<div class="row">
					<div class="col-xs-12">
						<h1 style={{ marginTop: '40px' }}>NeoContador.com</h1>
						<p style={{ marginTop: '0px' }}>Uma nova maneira simples e barata de<br />lidar com a sua contabilidade.</p>
					</div>
				</div>
			</div>
		</section>

		<section class="s3-section-optimize">
			<div class="container">
				<div class="row">
					<div class="col-md-10 col-md-offset-1">
						<h2>Existimos para otimizar seu tempo</h2>
						<div class="content">
							<p>O NeoContador.com é um escritório de contabilidade digital completo com a missão de simplificar a vida das pessoas através das suas soluções práticas para processamento de folhas de pagamento, cálculos de tributos e declarações fiscais. Com estas soluções práticas conseguimos atender com qualidade e eficiência Empregadores Domésticos, Microempreendedores Individuais, Micro e Pequenas Empresas e declarantes do Imposto de Renda Pessoa Física e investidores em renda variável.</p>
							<strong>MISSÃO</strong>
							<p>Nossa missão é democratizar a contabilidade de qualidade, descomplicar a relação com o Fisco e simplificar a vida das pessoas.</p>
							<p><strong>VALORES</strong></p>
							<p>Somos apaixonados por empreendedorismo e realmente vestimos a camisa de nossos clientes!</p>
							<p>Somos fanáticos por tecnologia e sempre buscamos simplificar nossas soluções!</p>
							<p>Somos extremamente éticos: respeitamos as informações de nossos clientes numa relação de confiança absoluta e seguimos estritamente as normas fiscais!</p>
							<p>Somos focados em pessoas, seja atendendo com excelência nossos clientes ou valorizando nossa equipe. Realmente queremos melhorar a vida das pessoas!</p>
							<p class="text-center image"><img src={imgOptimize} /></p>
						</div>
					</div>
				</div>
			</div>
		</section>

		<section className="s3-section-steps">
			<div class="container">
				<div class="row">
					<div class="col-xs-12" style={{ marginTop: '20px' }}>
						<h2>A parte chata a gente faz.<br /><strong>Entenda como funciona o serviço:</strong></h2>
					</div>
				</div>
				<div class="row">
					<div class="col-xs-12">
						<div class="s3-steps">
							<div class="item"><span>1</span></div>
							<span></span>
							<div class="item"><span>2</span></div>
							<span></span>
							<div class="item"><span>3</span></div>
						</div>
					</div>
				</div>
				<div class="row">
					<div className="col-md-4">
						<div class="block">
							<div class="icon"><img src={block1} /></div>
							<h3>Cadastre-se em<br />um dos nossos<br />serviços</h3>
							<div class="content">
								<p>Escolha o plano que mais se adequa à sua situação, responda as perguntinhas de avaliação e efetue o pagamento.</p>
							</div>
						</div>
					</div>
					<div class="col-md-4">
						<div className="block">
							<div class="icon"><img src={block2} /></div>
							<h3>Envie sua<br />documentação<br />online</h3>
							<div class="content">
								<p>Envie os documentos solicitados. Os mesmos podem ser via fotos ou digitalizados, mas devem estar legíveis.</p>
							</div>
						</div>
					</div>
					<div className="col-md-4">
						<div class="block">
							<div class="icon"><img src={block3} /></div>
							<h3>Acompanhe<br />pelo site</h3>
							<div class="content">
								<p>Depois do cadastro, nossos contadores farão a análise das informações e documentos e começarão a enviar as guias, declarações e relatórios por email/aplicativo. Você acompanha tudo online e quando quiser!</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>

		<NumerosImpressionantes />

		<section id="secTestimonials" class="s3-section-testimonials hidden">
			<div class="container">
				<div class="row">
					<div class="col-xs-12">
						<h2>Nossos clientes têm toda razão.</h2>
					</div>
				</div>
				<div class="row">
					<div class="s3-testimonials-slick">

						<div class="col-md-4">
							<div class="s3-item">
								<div class="s3-box">
									<div class="s3-content">“Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.” Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.“</div>
									<div class="s3-info">
										<p>Raphael Felicio</p>
										<p>Co-Founder da Estúdio Two</p>
									</div>
								</div>
								<div class="s3-avatar">
									<img src="data/images/img-avatar.png" alt="Avatar" />
								</div>
							</div>
						</div>

						<div class="col-md-4">
							<div class="s3-item">
								<div class="s3-box">
									<div class="s3-content">“Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.” Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.“</div>
									<div class="s3-info">
										<p>Raphael Felicio</p>
										<p>Co-Founder da Estúdio Two</p>
									</div>
								</div>
								<div class="s3-avatar">
									<img src="data/images/img-avatar.png" alt="Avatar" />
								</div>
							</div>
						</div>

						<div class="col-md-4">
							<div class="s3-item">
								<div class="s3-box">
									<div class="s3-content">“Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.” Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.“</div>
									<div class="s3-info">
										<p>Raphael Felicio</p>
										<p>Co-Founder da Estúdio Two</p>
									</div>
								</div>
								<div class="s3-avatar">
									<img src="data/images/img-avatar.png" alt="Avatar" />
								</div>
							</div>
						</div>

					</div>
				</div>
			</div>
		</section>

		<section className="s3-section-cta">
			<OtimizeGastos />
		</section>

		<Footer />


	</div>

);

export default Landing;
