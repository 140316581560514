import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { auth } from "./auth";
import withTracker from "./withTracker";
import "bootstrap/dist/css/bootstrap.min.css";
import "./shards-dashboard/styles/shards-dashboards.1.1.0.min.css";
// Layout Types
import { DefaultLayout, Landing } from "./layouts";

// Route Views
 import BlogOverview from "./views/BlogOverview";
 import UserProfileLite from "./views/UserProfileLite";
 import AddNewPost from "./views/AddNewPost";
 import Errors from "./views/Errors";
 import ComponentsOverview from "./views/ComponentsOverview";
 import Tables from "./views/Tables";
 import BlogPosts from "./views/BlogPosts";
import LandingPage from "./views/LandingPage";
import QuemSomos from './views/QuemSomos';
import NossosServicos from './views/NossosServicos';
import TermosUso from './views/TermosUso';
import Faq from './views/Faq';
import Contato from  './views/Contato'
import LoginForm from "./views/LoginForm";
import RegisterForm from "./views/RegisterForm";
import Investidor from "./views/InvestidorPage";


const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      auth.isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
      )
    }
  />
);

const ALLROUTES = [
  {
    path: "/index.html",
    exact: true,
    layout: Landing,
    private: false,
    component: LandingPage,
  },
  {
    path: "/",
    exact: true,
    layout: Landing,
    private: false,
    component: LandingPage,
  },
  {
    path: "/login",
    exact: true,
    layout: LoginForm,
    component: LoginForm,
    private: false
    
  },
  {
    path: "/register",
    exact: true,
    layout: RegisterForm,
    component: RegisterForm,
    private: false
  },
  {
    path:"/investidores",
    exact: true,
    layout: Investidor,
    component: Investidor,
    private: false
  },
  {
    path: "/quem-somos",
    exact: true,
    layout: QuemSomos,
    component: QuemSomos,
    private: false
  },
  {
    path: "/nossos-servicos",
    exact: true,
    layout: NossosServicos,
    component: NossosServicos,
    private: false
  },
  {
    path: "/termos-de-uso",
    exact: true,
    layout: TermosUso,
    component: TermosUso,
    private: false
  },
  {
    path: "/contato",
    exact: true,
    layout: Contato,
    component: Contato,
    private: false
  },
  {
    path: "/faq",
    exact: true,
    layout: Faq,
    component: Faq,
    private: false
  },
  
   {
     path: "/blog-overview",
     layout: DefaultLayout,
     component: BlogOverview,
     private: true
    
   },
   {
     path: "/user-profile-lite",
     layout: DefaultLayout,
     component: UserProfileLite,
     private: true
   },
   {
     path: "/add-new-post",
     layout: DefaultLayout,
     component: AddNewPost,
     private: true
   },
   {
     path: "/errors",
     layout: DefaultLayout,
     component: Errors,
     private: true
   },
   {
     path: "/components-overview",
     layout: DefaultLayout,
     component: ComponentsOverview,
     private: true
   },
   {
     path: "/tables",
     layout: DefaultLayout,
     component: Tables,
     private: true
   },
   {
     path: "/blog-posts",
     layout: DefaultLayout,
     component: BlogPosts,
     private: true
   }
];

const Routes = (props) => (
  <BrowserRouter basename="/">
    <Switch>
      {ALLROUTES.map((route, index) => {
        return (
          route.private ? 
          <PrivateRoute
            key={index}
            path={route.path}
            exact={route.exact}
            component={withTracker(props => {
              return (
                <route.layout {...props}>
                  <route.component {...props} />
                </route.layout>
              );
            })}
          /> : 
          <Route
            key={index}
            path={route.path}
            exact={route.exact}
            component={withTracker(props => {
              return (
                <route.layout {...props}>
                  <route.component {...props} />
                </route.layout>
              );
            })}
          />
        );
      })}
    </Switch>
  </BrowserRouter>
);

export default Routes;