import React from "react";
import macbook from '../assets/data/images/macbook.png';
import imgDomesticas from '../assets/data/images/slide-03.png';
import otimizarTempo from '../assets/data/images/img-optimize.png';
import slide2 from '../assets/data/images/slide2.jpg';
import slide3 from '../assets/data/images/slide3.jpg';
import acoes from '../assets/data/images/frame1.png';
import servicos from '../assets/data/images/frame2.png';
import domestica from '../assets/data/images/frame3.png';

import { NavBar, NumerosImpressionantes, OtimizeGastos, DuvidasFrequentes, EnviaEmails, Footer } from '../components/sectionsHome/sectionsHome'


const Teste = function (props) {
    return <h1>{props.texto}</h1>
}


const Landing = () => (

    <div class="principal">
        <NavBar />
        <section id="jumbotron" class="s3-section-jumbotron">
            <div class="container">
                <div class="row">
                    <div>
                        <h1 style={{ marginTop: "180px" }}><span><h1>Sua contabilidade agora é fácil, acessível e digital</h1></span><span><h1 id="landingSubtitle">Conheça o NeoContador.com</h1></span></h1>
                        <div class="slider">
                            <div id="carouselExampleAutoplaying" class="carousel slide" data-bs-ride="carousel">
                                <div class="carouselExample-navigation hidden-xs hidden-sm" >
                                    <ul>
                                        <li class="" data-goto="0"><a href="/register">Folha de Pagamento de Domésticas</a></li>
                                        <li class="" data-goto="1"><a href="/investidores">Cálculos fiscais para investidores</a></li>
                                    </ul>
                                </div>
                                <div class="carousel-inner">
                                    <div class="carousel-item active">
                                        <div class="s3-flex-wrapper">
                                            <div class="s3-image">
                                                <img src={domestica} class="d-block w-100" alt="Folha de Pagamento de Domésticas" style={{ "width": "100%", "height": "100%" }} />
                                            </div>
                                            <div class="s3-content">
                                                <div class="s3-header">Folha de Pagamento de Domésticas</div>
                                                <div class="s3-body">
                                                    <div class="s3-wrapper">
                                                        <div class="s3-price">
                                                            <span class="s3-number">R$49,90</span>
                                                        </div>
                                                        <div class="s3-ast">* por doméstica mensalmente</div>
                                                        <div class="s3-cta">
                                                            <a href="./register" class="s3-btn green material">Cadastre AQUI</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="carousel-item">
                                        <div class="s3-flex-wrapper">
                                            <div class="s3-image">
                                                <img src={acoes} class="d-block w-100" alt="Folha de Pagamento de Domésticas" style={{ "width": "100%", "height": "100%" }} />
                                            </div>
                                            <div class="s3-content">
                                                <div class="s3-header">Cálculos de renda variável para investidores</div>
                                                <div class="s3-body">
                                                    <div class="s3-wrapper">
                                                        <div class="s3-price">
                                                            <span class="s3-number">R$99,90</span>
                                                        </div>
                                                        <div class="s3-ast">* por mês</div>
                                                        <div class="s3-ast"></div>
                                                        <div class="s3-cta">
                                                            <a href="./investidores" class="s3-btn green material">SAIBA MAIS</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="carousel-item">
                                        <div class="s3-flex-wrapper">
                                            <div class="s3-image">
                                                <img src={servicos} class="d-block w-100" alt="Folha de Pagamento de Domésticas" style={{ "width": "100%", "height": "100%" }} />
                                            </div>
                                            <div class="s3-content">
                                                <div class="s3-header">Contabilidade para prestadores de serviços</div>
                                                <div class="s3-body">
                                                    <div class="s3-wrapper">
                                                        <div class="s3-price">
                                                            <span class="s3-number">A partir de R$396,00</span>
                                                        </div>
                                                        <div class="s3-ast">* por mês</div>
                                                        <div class="s3-cta">
                                                            <a href="./register" class="s3-btn green material">Cadastre AQUI</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="prev">
                                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span class="visually-hidden">Previous</span>
                                </button>
                                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="next">
                                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span class="visually-hidden">Next</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <section id="secOptimize" class="s3-section-optimize">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <div class="s3-image"><img src={otimizarTempo} alt="" /></div>
                        <div class="s3-content">
                            <div class="s3-header">
                                <h2>Existimos para otimizar seu tempo</h2>
                            </div>
                            <div class="s3-body">
                                <p>Chega de anotar tudo no caderninho! Agora que você conhece o NeoContador, pode ter certeza que cuidaremos de todo seu processo contábil com o maior carinho do mundo, enquanto você dedica todo o seu tempo no que mais importa: o seu negócio.</p>
                                <div class="s3-cta"style={{ marginTop: "10px" }}><a href="./register" class="s3-btn green material">CADASTRE AQUI</a></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <NumerosImpressionantes />

        <section id="secTestimonials" class="s3-section-testimonials hidden">
            <div class="container">
                <div class="row">
                    <div class="col-xs-12">
                        <h2>Nossos clientes têm toda razão.</h2>
                    </div>
                </div>
                <div class="row">
                    <div class="s3-testimonials-slick">

                        <div class="col-md-4">
                            <div class="s3-item">
                                <div class="s3-box">
                                    <div class="s3-content">“Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.” Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.“</div>
                                    <div class="s3-info">
                                        <p>Raphael Felicio</p>
                                        <p>Co-Founder da Estúdio Two</p>
                                    </div>
                                </div>
                                <div class="s3-avatar">
                                    <img src="data/images/img-avatar.png" alt="Avatar" />
                                </div>
                            </div>
                        </div>

                        <div class="col-md-4">
                            <div class="s3-item">
                                <div class="s3-box">
                                    <div class="s3-content">“Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.” Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.“</div>
                                    <div class="s3-info">
                                        <p>Raphael Felicio</p>
                                        <p>Co-Founder da Estúdio Two</p>
                                    </div>
                                </div>
                                <div class="s3-avatar">
                                    <img src="data/images/img-avatar.png" alt="Avatar" />
                                </div>
                            </div>
                        </div>

                        <div class="col-md-4">
                            <div class="s3-item">
                                <div class="s3-box">
                                    <div class="s3-content">“Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.” Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.“</div>
                                    <div class="s3-info">
                                        <p>Raphael Felicio</p>
                                        <p>Co-Founder da Estúdio Two</p>
                                    </div>
                                </div>
                                <div class="s3-avatar">
                                    <img src="data/images/img-avatar.png" alt="Avatar" />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>

        <section id="secCta" class="s3-section-cta">

            <OtimizeGastos />

            <div class="s3-body">
                <div class="container">
                    <div class="row">

                        <div class="col-xs-12">
                            <div class="s3-image">
                                <img src={macbook} alt="Macbook Preview" />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>

        <section id="secContact" class="s3-section-contact">
            <div class="container">
                <div class="row">

                    <DuvidasFrequentes />

                    <EnviaEmails />

                </div>
            </div>
        </section>

        <Footer />


    </div>

);

export default Landing;
