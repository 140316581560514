import React, { Component } from 'react';
import { Row } from 'reactstrap';
import styles from './styles/styles'
// import '../../assets/css/style.css'
import StepTitle from './StepTitle';

//const Teste = (props) => <h3>AAAA</h3> 


class Contratos extends Component {
    constructor() {
        super()
        this.state = {
            username: undefined,
            cpf: undefined,
            email: undefined,
            password: undefined,
            telephone: undefined,
            numberEmployees: undefined,
            valor: '49,90'
        }
        this.state2 = {
            valor: '99,90'
        }
    }

    static displayName = 'ui-RegisterForm'


    componentDidMount(){
        document.getElementById('s3-cadastro').classList.add('contratos')
    }

    render() {

        const textoTitulo = {
                titulo: 'No que o NeoContador pode te ajudar?', 
                produto: '', 
                subtitulo: '',
                alinhamento: 'center'
        }

        return (
            <div className="s3-step" data-step="Contratos">
                <div className="s3-wrapper">

                    <Row style={styles.alignContentCenter}>
                        <StepTitle texto={textoTitulo}/>
                    </Row>
                    <Row style={Object.assign({},styles.alignCenter, styles.alignContentCenter)} >
                        <div className="col-md-4 m-1 m-lg-0">
                            <div className="s3-item" data-next="A">
                                <div className="s3-wrapper" onClick={this.props.nextScreen1}>
                                    <div className="s3-top">
                                        <div className="s3-title textoAzulClaro">Folha de Pagamento de Domésticas</div>
                                    </div>
                                    <div className="s3-bottom">
                                        <div className="s3-content">
                                            <div className="s3-price s3-white textoAzulEscuro">R${this.state.valor}</div>
                                            <div className="s3-info s3-white textoAzulEscuro">* por doméstica mensalmente</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="s3-choose">
                                    <a className="next" data-next="A" data-form="none" onClick={this.props.nextScreen1}>Escolher</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 m-1 m-lg-0">
                            <div className="s3-item" data-next="Inves">
                                <div className="s3-wrapper" onClick={this.props.nextScreen3}>
                                    <div className="s3-top">
                                        <div className="s3-title textoAzulClaro">Cálculos de renda variável para investidores</div>
                                    </div>
                                    <div className="s3-bottom">
                                        <div className="s3-content">
                                            <div className="s3-price s3-white textoAzulEscuro">R${this.state2.valor}</div>
                                            <div className="s3-info s3-white textoAzulEscuro">* por mês</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="s3-choose">
                                    <a className="next" data-next="Inves" data-form="none" onClick={this.props.nextScreen3}>Escolher</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 m-1 m-lg-0">
                            <div className="s3-item" data-next="P">
                                <div className="s3-wrapper" onClick={this.props.nextScreen2}>
                                    <div className="s3-top">
                                        <div className="s3-title textoAzulClaro">Contabilidade para prestadores de serviços</div>
                                    </div>
                                    <div className="s3-bottom">
                                        <div className="s3-content">
                                            <div className="s3-price s3-white textoAzulEscuro">A partir de R$396,00</div>
                                            <div className="s3-info s3-white textoAzulEscuro">* por mês</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="s3-choose">
                                    <a className="next" data-next="P" data-form="none" onClick={this.props.nextScreen2}>Escolher</a>
                                </div>
                            </div>
                        </div>
                    </Row>
                </div>
            </div>
            
           
            
        );
    }
}

export default Contratos;