import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

import { NeoButtonNav, NeoSubmit } from "../NeoButtons/NeoButtons";
import { Button } from "react-bootstrap";
import { CONFIG } from "../../config/index";
import "./styles/styles.css";
import "../../assets/css/style.css";
import StepTitle from "./StepTitle";
import styles from './styles/styles'

//const Teste = (props) => <h3>AAAA</h3>

class Step_Inicial extends Component {
  constructor() {
    super();
  }

  static displayName = "ui-RegisterForm";

  render() {
    const textoTitulo = {
      titulo: "Vamos Começar!",
      produto: "",
      subtitulo:
        "Para agilizar o seu cadastro, e não demorar mais que 3 minutos, tenha em mãos os seguintes documentos:",
      alinhamento: "center"
    };

    return (
      <div className="s3-step stepInicial" data-step="Inicial">
        <div className="s3-wrapper">
          {/* //className = "s3-signup s3-body"> */}

          <Row>
            <Row style={styles.alignContentCenter}>
              <StepTitle texto={textoTitulo} />
            </Row>
            <Row>
              <div className="containerDocumentos">
                <h5>Seus documentos, como empregador:</h5>

                <Col xs={12} md={12}>
                  <div className="listaDocumentos">
                    <ul>
                      <li>
                        2 últimas declarações de imposto de renda (IRPF), caso
                        tenha declarado
                      </li>
                      <li>Título de eleitor</li>
                    </ul>
                  </div>
                </Col>
              </div>
            </Row>
            <Row>
              <div className="containerDocumentos">
                <h5>Do(s) seu(s) empregado(s):</h5>
                <Col xs={12} md={12}>
                  <div className="listaDocumentos">
                    <ul>
                      <li>Carteira de Trabalho (CTPS)</li>
                      <li>PIS (caso não tenha na CTPS) </li>
                      <li>Identidade ou CNH</li>
                      <li>Comprovante de endereço</li>
                    </ul>
                  </div>
                </Col>
              </div>
            </Row>
          </Row>
        </div>
        <NeoButtonNav text="Voltar" callback={this.props.lastScreen} />
        <NeoButtonNav
          text="Cadastre Aqui"
          color="green"
          callback={this.props.nextScreen}
        />
      </div>
    );
  }
}

export default Step_Inicial;
