import React, { Component } from 'react';
import {
    Navbar,
    Nav,
    NavItem
} from 'react-bootstrap';

import logoCompleta from '../../images/logos/logo-white.png';
import logoReduzida from '../../images/logos/logo.png';
import '../../assets/css/style.css';
import './styles.css';
import { GLOBAL } from '../../config/global';
import { CONFIG } from '../../config/index';



class NeoNavbar2 extends Component {


    constructor() {
        super()
        this.logout = this.logout.bind(this);
        this.state = {
            userId: GLOBAL.getCookie('_uf_neoId'),
            name: null
        }
    }
    //roda assim q a tela abre
    componentDidMount() {
        //verifica se o id tá salvo no cookie
        let self = this;
        // console.log(this.state.userId)
        let userId = this.state.userId
        if (userId && userId != "undefined") {


            //Consulta pra preencher os dados da tela assim que entra       
            let url = CONFIG.API_PREFIX + '/users/finduser';
            fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    userLogin: {
                        _id: userId
                    }
                })
            }).then(response => response.json())
                .then(responseJson => {
                    if (responseJson.success) {
                        let dados = responseJson.data;
                        this.setState({
                            name: self.formatName(dados.name)
                        })
                        // console.log(this.state);

                    } else {
                        // console.log("Deu ruim");
                        // console.log(responseJson.success);

                    }

                }).catch(err => {
                    console.error(err);
                });
        }
    }

    formatName(name) {
        let userName = name
        if (name.split(' ').length >= 2) {
            userName = `${name.split(' ')[0]}`
        }
        return userName
    }


    logout() {
        this.setState({
            name: null
        })
        GLOBAL.setCookie('_uf_neoId', "undefined", 1);
        window.location.href = "/"
    }

    render() {

        return (
            <header className="removePadding" style={{ marginTop: '0px' }}>
                <div className="s3-flex-wrapper">


                    <Navbar expand="md" className="s3-navigation" variant="dark">
                        <span className="brand">
                            <a href="/">
                                <img src={logoCompleta} className="brandImage" alt="NeoContador" style={{ marginLeft: '0px' }} />
                                <img src={logoReduzida} className="smallBrandImage" alt="NeoContador" style={{ marginLeft: '0px' }} />
                            </a>
                        </span>
                        

                            <span className="navItens justify-content-end">
                                {
                                    this.state.name ?
                                        <div>

                                                <Navbar.Text id="userName"><span className="userName">{this.state.name}</span></Navbar.Text>

                                                <Navbar.Text><a className="login" onClick={this.logout}>Logout</a></Navbar.Text>
                                            
                                        </div>
                                        : <Navbar.Text>  </Navbar.Text>
                                }
                            </span>
                    </Navbar>




                    {/*         
                <div className="s3-logo">
                    <a href="/"><img src={logo} alt="NeoContador" /></a>
                </div>

                <nav className="s3-navigation">
                    <ul>
                        <li>
                            {
                                this.state.name ?
                                    <div>
                                        <span className="userName">{this.state.name}</span>
                                        <a className="login" onClick={this.logout}>Logout</a>
                                    </div>
                                    : <a href="/login">Login</a>
                            }


                        </li>
                    </ul>
                </nav> */}
                </div>

            </header>
        )
    }

}

export default NeoNavbar2;