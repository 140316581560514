import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';

import { GLOBAL } from '../../config/global';
import { CONFIG } from '../../config/index';
import styles from './styles/styles';
import './styles/styles.css';
import '../../assets/css/style.css';
import StepTitle from './StepTitle';
import { NeoInputRequired, NeoCamera } from '../NeoInputs/NeoInputs'
import { NeoRadio } from '../NeoButtons/NeoButtons'
import { ValidatorForm } from 'react-form-validator-core';
import { NeoAlert, NeoLoading } from '../NeoAlert/NeoModal';
import { NeoButtonNav, NeoSubmit } from '../NeoButtons/NeoButtons';
import { NeoPercent } from '../NeoPercent/NeoPercent';

const stepAtual = 'E'

class Step_E extends Component {
    constructor() {
        super()
        this.nextScreen = this.nextScreen.bind(this);
        this.updateValue = this.updateValue.bind(this);
        this.updateCepFields = this.updateCepFields.bind(this);
        this.alertControl = this.alertControl.bind(this);
        this.onlyNumber = this.onlyNumber.bind(this);
        this.checkboxControl = this.checkboxControl.bind(this);


        this.state = {
            choiceVisible: true,
            loading: false,
            showAlert: false,
            cep: '',
            nascimento: '',
            logradouro: '',
            bairro: '',
            numero: '',
            localidade: '',
            uf: '',
            complemento: '',
            declarouIR: '',
            modalTitle: '',
            modalText: '',
            disabled: false,
            userId: GLOBAL.getCookie('_uf_neoId')
        }

        var _isMounted = false;

    }

    static displayName = 'ui-RegisterForm'


    componentDidMount() {
        this.loadingControl(true, 'Aguarde');

        //verifica se o id tá salvo no cookie
        let userId = this.state.userId;
        // console.log(userId);
        if (userId && userId != "undefined") {

            //if(!localStorage.getItem('stepE')){

            let url = CONFIG.API_PREFIX + '/users/finduser';
            fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    userLogin: {
                        _id: userId
                    }
                })

            }).then(response => response.json())
                .then(responseJson => {
                    if (responseJson.success) {
                        if (responseJson.data.endereco) {
                            let dados = responseJson.data;
                            // console.log(dados)
                            this.setState(() => ({
                                nascimento: dados.dataNascimento,
                                cep: dados.endereco.cep,
                                bairro: dados.endereco.bairro,
                                logradouro: dados.endereco.logradouro,
                                localidade: dados.endereco.localidade,
                                uf: dados.endereco.uf,
                                numero: dados.endereco.numeroResidencia,
                                complemento: dados.endereco.complementoResidencia,
                                declarouIR: dados.declarouIR,
                                disabled: true
                            }))
                            // console.log(this.state.declarouIR)
                        } else {
                            this.setState({
                                declarouIR: 1
                            })
                        }

                        //controla o histórico de steps
                        let historicoStep = responseJson.data.historicoStep;
                        if (historicoStep[historicoStep.length - 1] === stepAtual) historicoStep.pop();
                        this.setState({
                            historicoStep: historicoStep,
                        })

                    } else {
                        this.setState({
                            declarouIR: 1
                        })
                    }
                    this.loadingControl(false, '');

                }).catch(err => {
                    console.error(err)
                    this.loadingControl(false, '');
                    this.setState({
                        declarouIR: 1
                    })
                });
            // }
            // else{

            //     let stepData = localStorage.getItem('stepE');
            //     stepData = JSON.parse(stepData).params;

            //     this.setState(() => ({
            //         nascimento: stepData.dataNascimento,
            //         cep: stepData.endereco.cep,
            //         bairro: stepData.endereco.bairro,
            //         logradouro: stepData.endereco.logradouro,
            //         localidade: stepData.endereco.localidade,
            //         uf: stepData.endereco.uf,
            //         numero: stepData.endereco.numeroResidencia,
            //         complemento: stepData.endereco.complementoResidencia
            //     }))

            //     this.loadingControl(false, '');
            // }

        } else {
            this.loadingControl(false, '');
            this.setState({
                declarouIR: 1
            })
        }
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    changeVisibility = () => {
        this.setState(state => ({
            choiceVisible: !state.choiceVisible
        }))
    }
    /*
    Register Form area
    */

    //Controla o loading
    loadingControl(visible, title) {
        this.setState({
            loading: visible,
            modalTitle: title
        })
    }

    //Controla a visibilidade do alerta
    alertControl() {
        this.setState(state => ({
            showAlert: !state.showAlert
        }))
    }

    nextScreen(e) {
        let self = this;
        let nextScreenCode = this.state.declarouIR == '1' ? 'F2' : 'F1';
        let historicoStep = self.state.historicoStep;
        historicoStep.push(stepAtual);

        // console.log(nextScreenCode)
        this.loadingControl(true, 'Atualizando dados, aguarde');

        let dataToSend = {
            _id: this.state.userId,
            params: {
                dataNascimento: this.state.nascimento,
                declarouIR: this.state.declarouIR,
                step: nextScreenCode,
                historicoStep: historicoStep,
                endereco: {
                    cep: this.state.cep,
                    logradouro: this.state.logradouro,
                    bairro: this.state.bairro,
                    localidade: this.state.localidade,
                    uf: this.state.uf,
                    numeroResidencia: this.state.numero,
                    complementoResidencia: this.state.complemento,
                }
            }
        }

        //salva os dados em cache pra não ter que fazer requisição sempre q entra na tela
        localStorage.setItem('stepE', JSON.stringify(dataToSend));


        let url = CONFIG.API_PREFIX + '/users/updateUser';
        fetch(url, {
            method: "PUT",
            headers: {
                "Origin": "*",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                data: dataToSend
            })

        }).then(response => response.json())
            .then(responseJson => {
                if (responseJson.success) {
                    // console.log(responseJson)
                    this.props.nextScreen(nextScreenCode);
                } else {
                    // console.log("Deu ruim");
                    // console.log(responseJson.success);
                }
                this.loadingControl(false, '');

            }).catch(err => {
                console.error(err);
                this.loadingControl(false, '');
            });
    }

    //Atualiza os states
    updateValue = function (name, evt, value = null) {
        let valor = value;
        if (evt) {
            valor = evt.target.value
        }
        this.setState(state => ({ [name]: valor }))
    }

    //atualiza os campos de endereço a preencher o cep
    updateCepFields = function () {
        if (this.state.cep.replace(/[_,-]/g, '').length != 8) return

        this.loadingControl(true, 'Consultando CEP, aguarde');

        let cep = this.state.cep.replace(/[.,-]/g, '');
        // console.log(cep)
        let url = `https://viacep.com.br/ws/${cep}/json/`
        fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        }).then(response => response.json())
            .then(responseJson => {
                // console.log(responseJson)
                if (responseJson.cep == this.state.cep) {
                    let key;
                    responseJson.loading = false;
                    responseJson.disabled = true;
                    for (key in responseJson) {
                        this.updateValue(key, null, responseJson[key]);
                    }
                    this.loadingControl(false, '');
                } else {
                    let obj = {
                        logradouro: '',
                        bairro: '',
                        numero: '',
                        localidade: '',
                        uf: '',
                        complemento: '',
                        loading: false,
                        disabled: false
                    }
                    let key;
                    for (key in obj) {
                        this.updateValue(key, null, obj[key]);
                    }
                    this.setState({
                        loading: false,
                        showAlert: true,
                        modalTitle: 'Ops!',
                        modalText: 'Ocorreu um erro ao consultar o CEP'
                    })
                }
            }).catch(err => {
                console.error('Error ', err)
                this.setState({
                    loading: false,
                    showAlert: true,
                    modalTitle: 'Ops!',
                    modalText: 'Ocorreu um erro ao consultar o CEP'
                })
            });
    }

    //Bloqueia o campo de texto para que seja possível aceitar só números
    onlyNumber = function (name, evt) {
        let valor = evt.target.value
        this.setState(state => ({ [name]: valor.replace(/\W+/g, "").replace(/[_A-Z-a-z]/g, '') }))
    }

    //Controla o valor do checkbox
    checkboxControl(option) {
        //option == 1 => sim
        //option == 2 => não
        console.log(option);
        this.updateValue('declarouIR', null, option);
    }

    handleKeyDown = (event) => {

        if (event.key === 'Enter') {

            event.preventDefault();
        }
    };

    render() {

        const textoTitulo = {
            titulo: 'Ok, precisamos de mais informações para o',
            produto: 'eSocial',
            subtitulo: '',
            alinhamento: 'center'
        }

        return (

            <div>
                <NeoAlert
                    show={this.state.showAlert}
                    title={this.state.modalTitle}
                    text={this.state.modalText}
                    close={this.alertControl}
                />

                <NeoLoading title={this.state.modalTitle} show={this.state.loading} />

                <ValidatorForm ref="form" onSubmit={this.nextScreen} onKeyDown={e => { this.handleKeyDown(e) }}>


                    <div className="s3-step" data-step="E">
                        <div className="s3-wrapper">

                            {/* //className = "s3-signup s3-body"> */}

                            <Row >
                                <StepTitle texto={textoTitulo} />
                                <div className="s3-content col-md-12">
                                    <div className="s3-signup">
                                        <Row>
                                            <div className="s3-form-item clearfix col-md-12">
                                                {/* Begin Register Form */}
                                                < div className="register-content" style={{ marginTop: "10px" }}>

                                                    <div className="register-body">

                                                        <Row style={styles.rowSpace} className="verticalAlign">
                                                            <Col xs={12} md={6}>
                                                                <label htmlFor="nascimento" className="form-control-label">Qual a sua data de nascimento?</label>

                                                            </Col>
                                                            <Col xs={12} md={6}>
                                                                <NeoInputRequired
                                                                    changeCallback={this.updateValue.bind(this, "nascimento")}
                                                                    name="nascimento"
                                                                    type="text"
                                                                    value={this.state.nascimento}
                                                                    validators={['required', 'isDate']}
                                                                    errorMessages={['Obrigatório', 'Data Inválida']}
                                                                    attr={{
                                                                        mask: 'date',
                                                                        ref: 'nascimento',
                                                                        className: 'form-control',
                                                                        id: 'nascimento',
                                                                        placeholder: '__/__/____',
                                                                        obrigatorio: true
                                                                    }} />
                                                            </Col>
                                                        </Row>
                                                        <Row style={styles.rowSpace} className="verticalAlign">
                                                            <Col xs={12} md={6}>
                                                                <label htmlFor="cep" className="form-control-label">Informe seu endereço atual</label>
                                                            </Col>
                                                            <Col xs={12} md={6}>
                                                                <NeoInputRequired
                                                                    changeCallback={this.updateValue.bind(this, "cep")}
                                                                    blurCallback={this.updateCepFields}
                                                                    name="cep"
                                                                    type="text"
                                                                    value={this.state.cep}
                                                                    validators={['required', 'isCep']}
                                                                    errorMessages={['Obrigatório', 'CEP inválido']}
                                                                    attr={{
                                                                        mask: 'cep',
                                                                        ref: 'cep',
                                                                        className: 'form-control',
                                                                        id: 'cep',
                                                                        placeholder: 'CEP',
                                                                        autocomplete: 'new-password',
                                                                        obrigatorio: true
                                                                    }} />
                                                            </Col>
                                                        </Row>
                                                        <Row style={styles.rowSpace} className="verticalAlign">
                                                            <Col xs={12} md={6}></Col>
                                                            <Col xs={12} md={6}>
                                                                <NeoInputRequired
                                                                    changeCallback={this.updateValue.bind(this, "logradouro")}
                                                                    name="logradouro"
                                                                    type="text"
                                                                    value={this.state.logradouro}
                                                                    validators={['required']}
                                                                    errorMessages={['Obrigatório']}

                                                                    attr={{
                                                                        mask: 'text',
                                                                        ref: 'logradouro',
                                                                        className: 'form-control',
                                                                        id: 'logradouro',
                                                                        placeholder: 'Endereço',
                                                                        disabled: this.state.disabled,
                                                                        obrigatorio: true
                                                                    }} />
                                                            </Col>
                                                        </Row>
                                                        <Row style={styles.rowSpace} className="verticalAlign">
                                                            <Col xs={12} md={6}></Col>
                                                            <Col xs={12} md={6}>
                                                                <NeoInputRequired
                                                                    changeCallback={this.updateValue.bind(this, "bairro")}
                                                                    name="bairro"
                                                                    type="text"
                                                                    value={this.state.bairro}
                                                                    validators={['required']}
                                                                    errorMessages={['Obrigatório']}

                                                                    attr={{
                                                                        mask: 'text',
                                                                        ref: 'bairro',
                                                                        className: 'form-control',
                                                                        id: 'bairro',
                                                                        placeholder: 'Bairro',
                                                                        disabled: this.state.disabled,
                                                                        obrigatorio: true
                                                                    }} />
                                                            </Col>
                                                        </Row>
                                                        <Row style={styles.rowSpace} className="verticalAlign">
                                                            <Col xs={12} md={6}> </Col>
                                                            <Col xs={12} md={4}>
                                                                <NeoInputRequired
                                                                    changeCallback={this.updateValue.bind(this, "localidade")}
                                                                    name="localidade"
                                                                    type="text"
                                                                    value={this.state.localidade}
                                                                    validators={['required']}
                                                                    errorMessages={['Obrigatório']}

                                                                    attr={{
                                                                        mask: 'text',
                                                                        ref: 'localidade',
                                                                        className: 'form-control',
                                                                        id: 'localidade',
                                                                        placeholder: 'Cidade',
                                                                        disabled: this.state.disabled,
                                                                        obrigatorio: true
                                                                    }} />
                                                            </Col>
                                                            <Col xs={12} md={2}>
                                                                <NeoInputRequired

                                                                    changeCallback={this.updateValue.bind(this, "uf")}
                                                                    name="uf"
                                                                    type="text"
                                                                    value={this.state.uf}
                                                                    validators={['required']}
                                                                    errorMessages={['Obrigatório']}

                                                                    attr={{
                                                                        mask: 'text',
                                                                        ref: 'uf',
                                                                        className: 'form-control',
                                                                        id: 'uf',
                                                                        placeholder: 'UF',
                                                                        disabled: this.state.disabled,
                                                                        obrigatorio: true
                                                                    }} />
                                                            </Col>
                                                        </Row>
                                                        <Row style={styles.rowSpace} className="verticalAlign">
                                                            <Col xs={12} md={6}></Col>
                                                            <Col xs={12} md={2}>
                                                                <NeoInputRequired

                                                                    changeCallback={this.onlyNumber.bind(this, "numero")}
                                                                    name="numero"
                                                                    type="text"
                                                                    value={this.state.numero}
                                                                    validators={['required']}
                                                                    errorMessages={['Obrigatório']}

                                                                    attr={{
                                                                        mask: 'number',
                                                                        ref: 'numero',
                                                                        className: 'form-control',
                                                                        id: 'numero',
                                                                        placeholder: 'Número',
                                                                        obrigatorio: true
                                                                    }} />
                                                            </Col>
                                                            <Col xs={12} md={4}>
                                                                <NeoInputRequired

                                                                    changeCallback={this.updateValue.bind(this, "complemento")}
                                                                    name="complemento"
                                                                    type="text"
                                                                    value={this.state.complemento}

                                                                    attr={{
                                                                        mask: 'text',
                                                                        ref: 'complemento',
                                                                        className: 'form-control',
                                                                        id: 'complemento',
                                                                        placeholder: 'Complemento',
                                                                        obrigatorio: true
                                                                    }} />
                                                            </Col>
                                                        </Row>

                                                        <Row style={styles.rowSpace}>
                                                            <Col xs={12} md={6}>
                                                                <label htmlFor="irpf" className="form-control-label">Você já fez alguma Declaração de Imposto de Renda Pessoa Física (IRPF)?</label>
                                                            </Col>
                                                            <Col xs={12} md={6}>
                                                                {
                                                                    this.state.declarouIR == '' ?
                                                                        null
                                                                        :
                                                                        <NeoRadio
                                                                            opt1="Sim"
                                                                            opt2="Nunca declarei Imposto de Renda"
                                                                            value={this.checkboxControl}
                                                                            defaultValue={this.state.declarouIR}
                                                                        />
                                                                }

                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </div >
                                                {/* Begin Register Form */}

                                            </div>
                                        </Row>
                                    </div>
                                </div>
                            </Row>
                        </div>
                    </div>

                    <NeoButtonNav text="Voltar" callback={this.props.lastScreen} />
                    <NeoSubmit text="Continuar" color="green" loading={this.state.loading} />
                    <NeoPercent text="20%" />
                </ValidatorForm>
            </div>

        );
    }
}

export default Step_E;