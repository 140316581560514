import React from "react";
import imgPlan1 from '../assets/data/images/img-plan_1.png';
import imgPlan2 from '../assets/data/images/investsquare.png';

import { NavBar, NumerosImpressionantes, OtimizeGastos, DuvidasFrequentes, EnviaEmails, Footer } from '../components/sectionsHome/sectionsHome'


const Teste = function (props) {
    return <h1>{props.texto}</h1>
}

const Landing = () => (

    <div className="page-nossos_servicos principal">
        <NavBar />

        <section class="s3-page-header">
            <div class="container">
                <div class="row">
                    <div class="col-xs-12">
                        <h1 style={{ marginTop: '60px' }}>NeoContador</h1>
                        <p style={{ marginTop: '0px' }}>Sua contabilidade agora é fácil, acessível e digital.<br />Escolha a melhor solução para você.</p>
                    </div>
                </div>
            </div>
        </section>

        <section class="s3-section-plans">
		<div class="container">
			<div class="row">
				<div class="col-xs-12">
					<h2>Existimos para otimizar seu tempo</h2>
				</div>
			</div>
			<div class="row">
				<div class="col-xs-12">
					
                <div class="s3-plan">
                                <div class="row">
                                    <div class="col-lg-4 hidden-xs hidden-sm hidden-md">
                                        <div class="s3-thumbnail"><img src={imgPlan2} /></div>
                                    </div>
                                    <div class="col-lg-3 col-md-5">
                                        <div class="s3-info">
                                            <h3>Cálculo de renda variável <br />para investidores</h3>
                                            <div class="price">R$99,90<span>/mês</span></div>
                                            <div class="cta"><a href="./investidores" class="s3-btn green material">Saiba Mais</a></div>
                                        </div>
                                    </div>
                                    <div class="col-lg-5 col-md-7">
                                        <ul class="s3-list">
                                            <li>Apuração mensal e anual de resultados;</li>
                                            <li>Cálculo do Imposto de Renda a pagar;</li>
                                            <li>Relatórios detalhados mensais e anuais;</li>
                                            <li>Conexão direta com a corretora pelo open finance;</li>
                                            <li>Suporte prioritário e personalizado;</li>
                                            <li>Consultoria fiscal e planejamento tributário estratégico;</li>
                                            <li>Reuniões periódicas para revisão e planejamento;</li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="s3-more-info">
                                    <div class="s3-close"><a style={{ "cursor": 'pointer' }} onClick={() => document.querySelector('.s3-more-info').classList.remove('active')}><i style={{ 'color': '#fff' }} class="s3-icon-close"></i></a></div>
                                    <div class="wrapper">
                                        <p>Com a normatização da PEC das Domésticas a relação de trabalho entre empregadores e empregados domésticos passou por profundas mudanças, e várias obrigações passaram a ser exigidas para que tal relação esteja sempre regularizada, como controle de jornada de trabalho e recolhimento de diversos encargos sobre o salário do empregado (INSS, FGTS, IRRF). </p>
                                        <p>O Governo Federal disponibilizou um site na internet onde o gerenciamento desta relação de trabalho pudesse ser feito pelo próprio empregador, porém tal plataforma está longe de ser ideal, pois não realiza os cálculos da folha de pagamentos do empregado, muitas vezes é confusa e congestionada, não gera contratos de trabalho, rescisões, recibos de salários e férias, inclusive podendo induzir o usuário ao erro quando eventos avulsos acontecem, como férias e 13º salário, acarretando no recolhimento indevido dos encargos.</p>
                                        <p>Nossa Solução: através dos nossos sistemas, visando dar comodidade aos nossos clientes, fazemos os cadastros necessários no sistema do Governo Federal, geramos as folhas de pagamentos dos empregados domésticos, cálculos das férias e recisões, folhas de controle de ponto, contrato de trabalho, e a guia de recolhimento com os valores corretos e devidamente apurados por um contador especialista. Todos estes documentos são enviados e disponibilizados aos nossos clientes, gerando total comodidade ao mesmo, que só tem o trabalho de nos avisar sobre horas extras, períodos de férias ou rescisões e efetuar os pagamentos.</p>
                                    </div>
                                </div>
                            </div>


					<div class="s3-plan">
						<div class="row">
							<div class="col-lg-4 hidden-xs hidden-sm hidden-md">
								<div class="s3-thumbnail"><img src={imgPlan1} /></div>
							</div>
							<div class="col-lg-3 col-md-5">
								<div class="s3-info">
									<h3>Folha de Pagamento de <br />Domésticas</h3>
									<div class="price">R$49,90<span>/mês</span></div>
									<div class="cta"><a href="./register" class="s3-btn green material">Contrate Agora</a></div>
								</div>
							</div>
							<div class="col-lg-5 col-md-7">
								<ul class="s3-list">
									<li>Cadastro do empregador e dos empregados no e-Social;</li>
									<li>Cálculo da folha de pagamento mensalmente;</li>
									<li>Apuração dos encargos (INSS e FGTS) a recolher;</li>
									<li>Cálculo e acompanhamento de férias e encargos;</li>
									<li>Cálculo do 13º salário e encargos;</li>
									<li>Liberação da folha de ponto dos funcionários;</li>
									<li>Cálculo de rescisões;</li>
									<li>Cálculos de horas extras.</li>
								</ul>
							</div>
						</div>
						<div class="s3-more-info">
							<div class="s3-close"><a style={{"cursor":'pointer'}} onClick={()=> document.querySelector('.s3-more-info').classList.remove('active')}><i style={{'color':'#fff'}} class="s3-icon-close"></i></a></div>
							<div class="wrapper">
								<p>Com a normatização da PEC das Domésticas a relação de trabalho entre empregadores e empregados domésticos passou por profundas mudanças, e várias obrigações passaram a ser exigidas para que tal relação esteja sempre regularizada, como controle de jornada de trabalho e recolhimento de diversos encargos sobre o salário do empregado (INSS, FGTS, IRRF). </p>
								<p>O Governo Federal disponibilizou um site na internet onde o gerenciamento desta relação de trabalho pudesse ser feito pelo próprio empregador, porém tal plataforma está longe de ser ideal, pois não realiza os cálculos da folha de pagamentos do empregado, muitas vezes é confusa e congestionada, não gera contratos de trabalho, rescisões, recibos de salários e férias, inclusive podendo induzir o usuário ao erro quando eventos avulsos acontecem, como férias e 13º salário, acarretando no recolhimento indevido dos encargos.</p>
								<p>Nossa Solução: através dos nossos sistemas, visando dar comodidade aos nossos clientes, fazemos os cadastros necessários no sistema do Governo Federal, geramos as folhas de pagamentos dos empregados domésticos, cálculos das férias e recisões, folhas de controle de ponto, contrato de trabalho, e a guia de recolhimento com os valores corretos e devidamente apurados por um contador especialista. Todos estes documentos são enviados e disponibilizados aos nossos clientes, gerando total comodidade ao mesmo, que só tem o trabalho de nos avisar sobre horas extras, períodos de férias ou rescisões e efetuar os pagamentos.</p>
							</div>
						</div>
					</div>


				</div>
			</div>
            
		</div>
	    </section>



        <NumerosImpressionantes />

        <section id="secTestimonials" class="s3-section-testimonials hidden">
            <div class="container">
                <div class="row">
                    <div class="col-xs-12">
                        <h2>Nossos clientes têm toda razão.</h2>
                    </div>
                </div>
                <div class="row">
                    <div class="s3-testimonials-slick">

                        <div class="col-md-4">
                            <div class="s3-item">
                                <div class="s3-box">
                                    <div class="s3-content">“Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.” Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.“</div>
                                    <div class="s3-info">
                                        <p>Raphael Felicio</p>
                                        <p>Co-Founder da Estúdio Two</p>
                                    </div>
                                </div>
                                <div class="s3-avatar">
                                    <img src="data/images/img-avatar.png" alt="Avatar" />
                                </div>
                            </div>
                        </div>

                        <div class="col-md-4">
                            <div class="s3-item">
                                <div class="s3-box">
                                    <div class="s3-content">“Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.” Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.“</div>
                                    <div class="s3-info">
                                        <p>Raphael Felicio</p>
                                        <p>Co-Founder da Estúdio Two</p>
                                    </div>
                                </div>
                                <div class="s3-avatar">
                                    <img src="data/images/img-avatar.png" alt="Avatar" />
                                </div>
                            </div>
                        </div>

                        <div class="col-md-4">
                            <div class="s3-item">
                                <div class="s3-box">
                                    <div class="s3-content">“Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.” Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.“</div>
                                    <div class="s3-info">
                                        <p>Raphael Felicio</p>
                                        <p>Co-Founder da Estúdio Two</p>
                                    </div>
                                </div>
                                <div class="s3-avatar">
                                    <img src="data/images/img-avatar.png" alt="Avatar" />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>

        <section className="s3-section-cta">
            <OtimizeGastos />
        </section>

        <section id="secContact" class="s3-section-contact">
            <div class="container">
                <div class="row">

                    <DuvidasFrequentes />

                    <EnviaEmails/>

                </div>
            </div>
        </section>

        <Footer />


    </div>

);

export default Landing;
