import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';

import { Button } from 'react-bootstrap';
import { CONFIG } from '../../config/index';
import { GLOBAL } from '../../config/global';
import styles from './styles/styles';
import './styles/styles.css';
import '../../assets/css/style.css';
import StepTitle from './StepTitle';
import { NeoInputFile } from '../NeoInputs/NeoInputs'
import { NeoAlert, NeoAlertDecision, NeoLoading } from '../NeoAlert/NeoModal';
import { NeoButtonNav, NeoSubmit } from '../NeoButtons/NeoButtons'
import NeoTooltip from '../NeoTooltip/NeoTooltip'
import { NeoPercent } from '../NeoPercent/NeoPercent';

const stepAtual = 'I'

class Step_I extends Component {
    constructor() {
        super()
        this.handleSubmit = this.handleSubmit.bind(this);
        this.loadingControl = this.loadingControl.bind(this);
        this.alertDecisionControl = this.alertDecisionControl.bind(this);
        this.inputFileControl = this.inputFileControl.bind(this);
        this.linhaDocumentos = this.linhaDocumentos.bind(this);
        this.objectSize = this.objectSize.bind(this);
        this.alertControl = this.alertControl.bind(this);

        this.state = {
            choiceVisible: true,
            documents: [{}],
            lastEvent: '', //guarda se a ultima ação foi remover ou adicionar linha
            telaCarregou: false
        }
    }

    static displayName = 'ui-RegisterForm-StepI'

    //roda assim q a tela abre

    componentDidMount() {
        document.getElementById('s3-cadastro').classList.add('specialSize')

        this.setState({
            userId: GLOBAL.getCookie('_uf_neoId')
        })

        //verifica se o id tá salvo no cookie
        let userId = GLOBAL.getCookie('_uf_neoId');

        if (userId && userId != "undefined") {

            this.loadingControl(true, 'Buscando dados, aguarde');

            //Consulta pra preencher os dados da tela assim que entra       
            let url = CONFIG.API_PREFIX + '/users/finduser';

            fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    userLogin: {
                        _id: userId
                    }
                })
            }).then(response => response.json())
                .then(responseJson => {

                    if (responseJson.success) {

                        let dados = responseJson.data;

                        if (!isNaN(dados.quantFuncCadastrados)) {

                            this.setState(() => ({
                                cpf: dados.cpf,
                                quantFuncCadastrados: dados.quantFuncCadastrados,
                                quantFuncFaltaCadastrar: dados.quantFuncFaltaCadastrar
                            }))
                            //Consulta pra preencher os dados da tela assim que entra       
                            let url = CONFIG.API_PREFIX + '/funcionarios/findfuncionario';

                            fetch(url, {
                                method: "POST",
                                headers: {
                                    "Content-Type": "application/json"
                                },
                                body: JSON.stringify({
                                    userLogin: {
                                        idEmpregador: userId,
                                        numeroFuncionario: dados.quantFuncCadastrados
                                    }
                                })
                            }).then(response => response.json())
                                .then(responseJson => {

                                    if (responseJson.success) {

                                        let dados = responseJson.data;

                                        this.setState({
                                            idFuncionario: dados["_id"],
                                            numeroFuncionario: dados.numeroFuncionario
                                        }, () => {
                                            //Consulta pra preencher os dados da tela assim que entra       
                                            let url = CONFIG.API_PREFIX + `/dependentes/findDependente/${this.state.idFuncionario}`;

                                            fetch(url, {
                                                method: "GET",
                                                headers: {
                                                    "Content-Type": "application/json"
                                                },
                                            }).then(response => response.json())
                                                .then(responseJson => {

                                                    if (responseJson.success) {

                                                        let dados = responseJson.data;

                                                        this.setState({
                                                            documents: dados.documents,
                                                            telaCarregou: true
                                                        }, () => console.log(this.state))
                                                    }

                                                    else {

                                                        this.setState(() => ({
                                                            telaCarregou: true
                                                        }))

                                                        // console.log(responseJson.success);
                                                    }

                                                    this.loadingControl(false, '');
                                                }).catch(err => {

                                                    this.setState(() => ({
                                                        telaCarregou: true
                                                    }))

                                                    console.error(err);
                                                    this.loadingControl(false, '');
                                                });
                                        })
                                    }

                                    else {

                                        this.setState(() => ({
                                            telaCarregou: true
                                        }))

                                        // console.log(responseJson.success);
                                    }

                                    this.loadingControl(false, '');
                                }).catch(err => {

                                    this.setState(() => ({
                                        telaCarregou: true
                                    }))

                                    console.error(err);
                                    this.loadingControl(false, '');
                                });

                        }

                        else {

                            this.loadingControl(false, '');

                            this.setState(() => ({
                                modalTitle: 'Ops!',
                                modalText: 'Ocorreu um erro ao buscar os dados, atualize a página ou entre em contato com o administrador do sistema.',
                                modalEsconderBotao: true,
                                telaCarregou: true
                            }))

                            this.alertControl('diminuir-modal');
                        }
                        //controla o histórico de steps
                        let historicoStep = responseJson.data.historicoStep;
                        if (historicoStep[historicoStep.length - 1] === stepAtual) historicoStep.pop();
                        this.setState({
                            historicoStep: historicoStep,
                        })
                    }

                    else {

                        this.loadingControl(false, '');

                        this.setState(() => ({
                            modalTitle: 'Ops!',
                            modalText: 'Ocorreu um erro ao buscar os dados, atualize a página ou entre em contato com o administrador do sistema.',
                            modalEsconderBotao: true,
                            telaCarregou: true
                        }))

                        this.alertControl('diminuir-modal');

                        // console.log(responseJson.success);
                    }
                }).catch(err => {

                    console.error(err);

                    this.loadingControl(false, '');

                    this.setState(() => ({
                        modalTitle: 'Ops!',
                        modalText: 'Ocorreu um erro ao buscar os dados, atualize a página ou entre em contato com o administrador do sistema.',
                        modalEsconderBotao: true,
                        telaCarregou: true
                    }))

                    this.alertControl('diminuir-modal');
                });
        }
    }


    //roda toda vez que atualiza o state
    componentDidUpdate() {
        if (this.state.lastEvent == 'add') {
            this.scrollBottom("rowsContainer");
        }
    }

    changeVisibility = () => {
        this.setState(state => ({
            choiceVisible: !state.choiceVisible
        }))
    }

    //Pega o numero de keys de um obj
    objectSize = function (obj) {
        var size = 0, key;
        for (key in obj) {
            if (obj.hasOwnProperty(key)) size++;
        }
        return size;
    };

    gravarDados = function () {

        var self = this;
        let historicoStep = self.state.historicoStep;
        historicoStep.push(stepAtual);
        let documentos = this.state.documents;
        let dataToSend = [];
        // console.log(documentos);

        self.loadingControl(true, 'Salvando dados, aguarde');

        self.setState({
            loadingButton: true,
        })

        let step = null

        if (this.state.quantFuncCadastrados == this.state.quantFuncFaltaCadastrar) {
            step = 'Z'
        } else {
            step = 'G'
        }


        this.gravaDocumento(function (err, response) {
            if (!err) {
                // console.log("gravou")
                documentos.forEach(function (documento, index) {
                    let key;
                    let obj = {};
                    for (key in documento) {
                        var anexoToSend = {
                            caminho: documento[key].id ? `${self.state.cpf}/func${self.state.numeroFuncionario}_dep${index + 1}_${documento[key].id.replace(/[0-9]/g, '')}.${documento[key].extensao}` : documento[key].caminho,
                            nomeOriginal: documento[key].nomeOriginal,
                            size: documento[key].size
                        }
                        obj[key] = anexoToSend;
                    }
                    dataToSend.push(obj);
                })

                let url = CONFIG.API_PREFIX + '/dependentes/upsertDependente';

                // console.log(dataToSend)

                fetch(url, {
                    method: "PUT",
                    headers: {
                        "Origin": "*",
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        idFuncionario: self.state.idFuncionario,
                        params: dataToSend
                    })
                }).then(response => response.json())
                    .then(responseJson => {

                        if (responseJson.success) {

                            // console.log(responseJson);

                            let dataToSend = {
                                _id: self.state.userId,
                                params: {
                                    historicoStep: historicoStep,
                                    step: step,
                                }
                            }

                            let url = CONFIG.API_PREFIX + '/users/updateUser';

                            fetch(url, {
                                method: "PUT",
                                headers: {
                                    "Origin": "*",
                                    "Content-Type": "application/json"
                                },
                                body: JSON.stringify({
                                    data: dataToSend
                                })
                            }).then(response => response.json())
                                .then(responseJson => {

                                    if (responseJson.success) {

                                        // console.log(responseJson);

                                        self.props.nextScreen(step);

                                        self.loadingControl(false, '');
                                    }

                                    else {

                                        // console.log(responseJson.success);

                                        self.loadingControl(false, '');

                                        self.setState({
                                            modalTitle: 'Ops!',
                                            modalText: 'Ocorreu um erro ao salvar os dados',
                                        })
                                        self.alertControl();
                                    }

                                    self.setState({
                                        loadingButton: false,
                                    })

                                }).catch(err => {

                                    console.error(err);

                                    self.loadingControl(false, '');

                                    self.setState({
                                        modalTitle: 'Ops!',
                                        modalText: 'Ocorreu um erro ao salvar os dados',
                                        loadingButton: false,
                                    })

                                    self.alertControl();
                                });
                        }

                        else {

                            // console.log(responseJson.success);

                            self.loadingControl(false, '');

                            self.setState({
                                modalTitle: 'Ops!',
                                modalText: 'Ocorreu um erro ao salvar os dados',
                            })
                            self.alertControl();
                        }

                        self.setState({
                            loadingButton: false,
                        })

                    }).catch(err => {

                        console.error(err);

                        self.loadingControl(false, '');

                        self.setState({
                            modalTitle: 'Ops!',
                            modalText: 'Ocorreu um erro ao salvar os dados',
                            loadingButton: false,
                        })

                        self.alertControl();
                    });
            } else {
                self.loadingControl(false, '');

                self.setState({
                    modalTitle: 'Ops!',
                    modalText: 'Ocorreu um erro ao salvar os dados',
                    loadingButton: false,
                })

                self.alertControl();
            }
        })
    }

    gravaDocumento(callback) {
        let self = this;
        let documentos = this.state.documents;
        let docSize = this.state.documents.length
        let documentosGravados = 0;
        let key;
        this.loadingControl(true, 'Salvando Documentos');
        let deuErro = false;


        documentos.forEach(function (item, index) {
            documentosGravados = 0
            for (key in documentos[index]) {
                let docSize = 2;
                let fileData = {
                    "fileName": `func${self.state.numeroFuncionario}_dep${index + 1}_${key}.${documentos[index][key].extensao}`,
                    "folderName": self.state.cpf,
                    "base64": documentos[index][key].base64
                }
                if (documentos[index][key]["base64"]) {
                    let url = CONFIG.API_PREFIX + '/files/saveFile';
                    fetch(url, {
                        method: "POST",
                        headers: {
                            "Origin": "*",
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify(
                            { "fileData": fileData }
                        )

                    }).then(response => response.json())
                        .then(responseJson => {
                            if (responseJson.success) {

                                documentosGravados++;
                                // console.log(documentosGravados)

                            } else {
                                // console.log("Deu ruim");
                                // console.log(responseJson.success);
                                deuErro = true;
                                documentosGravados++;
                                if (documentosGravados == docSize && deuErro) {
                                    callback(true, 'Erro ao salvar documentos');
                                }
                            }
                        }).catch(err => {
                            documentosGravados++;
                            // console.log("Deu ruim: " + documentosGravados);
                            console.error(err);
                            deuErro = true;
                            if (documentosGravados == docSize && deuErro) {
                                callback(true, 'Erro ao salvar documentos');
                            }
                        });
                } else {
                    documentosGravados++;
                    // console.log("FAKE GRAVACAO")
                }
            }

        })

        if (deuErro) {
            if (documentosGravados == docSize) {
                deuErro = true;
            }

            callback(true, 'erro ao salvar documentos');
        } else {
            callback(false, 'documentos salvos com sucesso');
        }

    }

    verificaObrigatoriedade() {
        let self = this;
        let documentos = this.state.documents
        let numTotalRequerido = documentos.length * 2 //2 anexos por linha
        let numTotalAnexado = documentos.reduce(function (acumulado, atual) {
            if (self.objectSize(atual) == 2 && atual.cpf.size && atual.rg.size)
                acumulado += self.objectSize(atual);
            return acumulado;
        }, 0);
        if (numTotalAnexado === numTotalRequerido) {
            // console.log("GRAVANDO")
            this.gravarDados();
            // this.gravaDocumento(function (err, response) {
            //     console.log(response);
            //     self.loadingControl(false, '');
            // });
        } else {
            this.setState({
                modalTitle: 'Ops!',
                modalText: 'É necessário anexar todos os documentos para prosseguir',
            })
            this.alertControl();
        }
    }

    handleSubmit(e) {
        e.preventDefault();
        this.verificaObrigatoriedade();
    }

    handleKeyDown = (event) => {

        if (event.key === 'Enter') {

            event.preventDefault();
        }
    };

    //Controla o loading
    loadingControl(visible, title) {
        this.setState({
            loading: visible,
            modalTitle: title
        })
    }

    //Controla a visibilidade do alerta
    alertControl() {
        this.setState(state => ({
            showAlert: !state.showAlert
        }))
    }

    //Controla a visibilidade do alerta decisivo
    alertDecisionControl(acao, titulo, mensagem, evt) {

        this.setState(state => ({
            showAlertDecision: !state.showAlertDecision,
            modalTitle: titulo,
            modalText: mensagem,
        }))

        if (acao == 'continuar') this.gravarDadosVoltar();
    }

    gravarDadosVoltar = function () {

        var self = this;

        self.loadingControl(true, 'Aguarde');

        let dataToSend = {
            _id: self.state.userId,
            params: {
                step: this.state.historicoStep[this.state.historicoStep.length - 1],
                historicoStep: this.state.historicoStep
            }
        }

        let url = CONFIG.API_PREFIX + '/users/updateUser';

        fetch(url, {
            method: "PUT",
            headers: {
                "Origin": "*",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                data: dataToSend
            })
        }).then(response => response.json())
            .then(responseJson => {

                if (responseJson.success) {

                    // console.log(responseJson);

                    self.props.lastScreen(this.state.historicoStep[this.state.historicoStep.length - 1]);

                    self.loadingControl(false, '');
                }

                else {

                    // console.log(responseJson.success);

                    self.loadingControl(false, '');

                    self.setState({
                        modalTitle: 'Ops!',
                        modalText: 'Ocorreu um erro ao buscar os dados',
                    })
                    self.alertControl();
                }

                self.setState({
                    loadingButton: false,
                })

            }).catch(err => {

                console.error(err);

                self.loadingControl(false, '');

                self.setState({
                    modalTitle: 'Ops!',
                    modalText: 'Ocorreu um erro ao buscar os dados',
                    loadingButton: false,
                })

                self.alertControl();
            });
    }

    //Tratativa do input file
    inputFileControl(e) {

        // console.log(e);

        let acceptedTypes = ['pdf', 'jpeg', 'png', 'jpg'];

        if (e.erro == true) {
            this.setState({
                modalTitle: 'Ops!',
                modalText: 'Ocorreu um erro ao anexar o arquivo',
            })
        }
        else if (e.size > 10000000 && e.size) {
            this.setState({
                modalTitle: 'Ops!',
                modalText: 'O arquivo selecionado é muito grande. Anexe um documento com até 10mb.',
            })
            this.alertControl();

        } else if (!acceptedTypes.includes(e.extensao) && e.extensao) {
            this.setState({
                modalTitle: 'Ops!',
                modalText: 'O formato do arquivo selecionado é inválido.',
            })
            this.alertControl();
        } else {
            let id = e.id.replace(/[0-9]/g, '')
            let index = e.id.replace(/[cpf rg]/g, '');
            let documentos = this.state.documents;
            // console.log(`index ${index}`)
            //evita problemas na hora que remove uma linha
            if (documentos[index]) documentos[index][id] = e;


            this.setState((state) => {
                return {
                    documents: documentos,
                    lastEvent: 'attachment'
                }
            })
            // console.log(documentos)
        }
        if (!e.extensao && !e.size) {
            this.setState(() => ({
                anexoSalvoBanco: null,
            }))
        }
    }

    scrollBottom(id) {
        var objDiv = document.getElementById(id);
        objDiv.scrollTop = objDiv.scrollHeight;
    }

    linhaDocumentos(index) {
        let self = this;
        let numRows = this.state.documents.length - 1//this.objectSize(this.state.documents) - 1;

        function addDoc() {
            let docs = self.state.documents
            docs.push({});
            self.setState({
                documents: [],
                lastEvent: 'add'
            }, () => {
                self.setState(() => ({
                    documents: docs,
                    lastEvent: 'add'
                }))
            })
        }

        function removeDoc() {
            let docs = self.state.documents

            //remove o registro do documento
            docs.splice(index, 1);

            //Corrige os indexes do Ids pra seguir a ordem certinha (cpf1, cpf2,...)
            //e não ter o risco de ficar (cpf1, cpf3, ...)
            docs.forEach(function (item, index) {
                if (item.cpf)
                    if (item.cpf.id.replace('cpf', '') != index)
                        item.cpf.id = `cpf${index}`
                if (item.rg)
                    if (item.rg.id.replace('rg', '') != index)
                        item.rg.id = `rg${index}`
            })

            /*
                Único jeito que consegui fazer funcionar (sem gastar mt tempo)
                foi limpando o state e, depois, atualizando com os dados dos documentos
            */
            self.setState({
                documents: [],
                lastEvent: 'remove'
            }, () => {
                self.setState(() => ({
                    documents: docs,
                    lastEvent: 'remove'
                }))
            })
        }

        return (
            <div key={`row${index}`}>
                <Row style={{ margin: "15px 0 0 0px" }}>
                    <Col xs={12} md={2}></Col>
                    <Col xs={12} md={4}>

                        <label style={{ fontSize: "11px" }}>Dependente {index + 1}</label>

                    </Col>
                    <Col xs={12} md={7}></Col>

                </Row>
                <Row style={styles.rowSpace} className="verticalAlign">
                    <Col xs={12} md={2}></Col>
                    <Col xs={12} md={4}>
                        <NeoInputFile value={this.inputFileControl}
                            callback={this.inputFileControl}
                            data={this.state.documents[index][`cpf`]}
                            id={`cpf${index}`}
                            maxLength={14}
                            attr={{
                                multiple: false,
                                texto: 'CPF',
                            }}
                            ref={`cpf${index}`}
                        />
                    </Col>
                    <Col xs={12} md={4}>
                        <NeoInputFile value={this.inputFileControl}
                            callback={this.inputFileControl}
                            data={this.state.documents[index][`rg`]}
                            id={`rg${index}`}
                            maxLength={14}
                            attr={{
                                multiple: false,
                                texto: 'RG ou Cert. Nascimento',
                            }}
                            ref={`rg${index}`}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={2}></Col>
                    <Col xs={12} md={3}>
                        {
                            numRows > 0 ?
                                <Button className="btnDependentes" onClick={() => removeDoc()}>x</Button>
                                : null
                        }
                        {
                            index == numRows ?
                                <Button className="btnDependentes" onClick={() => addDoc()}>+</Button>
                                : null
                        }

                    </Col>
                    <Col xs={12} md={7}></Col>
                </Row>
            </div>
        )
    }

    render() {

        const textoTitulo = {
            titulo: 'Já que seu funcionário tem dependentes, precisamos das informações deles',
            produto: '',
            subtitulo: '',
            alinhamento: 'center'
        }

        let self = this;

        let documents = this.state.documents
        let rows = [];
        documents.forEach(function (item, index) {
            rows.push(self.linhaDocumentos(index));
        })

        return (
            <div>

                <NeoLoading title={this.state.modalTitle} show={this.state.loading} />


                <NeoAlert
                    show={this.state.showAlert}
                    title={this.state.modalTitle}
                    text={this.state.modalText}
                    close={this.alertControl}
                    modalEsconderBotao={this.state.modalEsconderBotao}
                    class={this.state.modalClass}
                />

                <NeoAlertDecision
                    show={this.state.showAlertDecision}
                    title={this.state.modalTitle}
                    text={this.state.modalText}
                    sim={this.alertDecisionControl.bind(this, "continuar")}
                    nao={this.alertDecisionControl.bind(this, "cancelar")}
                />

                <div className="s3-step" data-step="I">
                    <div className="s3-wrapper">

                        <Row >
                            <StepTitle texto={textoTitulo} />
                            <div className="s3-content col-md-12" style={{ padding: "0" }}>
                                <div className="s3-signup">
                                    <Row>
                                        <div className="s3-form-item clearfix col-md-12" style={{ padding: "0" }}>
                                            {/* Begin Register Form */}
                                            < div className="register-content" style={{ marginTop: "10px" }}>

                                                <div className="register-body">

                                                    <Row style={styles.rowSpace}>
                                                        <Col xs={2} md={2}>
                                                            <   NeoTooltip position="right" text="Clique no ( + ) para adicionar mais dependentes. Os arquivos não podem ter mais de 6MB. Arquivos suportados: JPG, PNG e PDF" />
                                                        </Col>
                                                        <Col xs={10} md={8}>
                                                            <label className="form-control-label">Envie-nos uma cópia (digitalizada ou foto) do CPF de cada dependente, assim como a Certidão de Nascimento ou RG:</label>
                                                        </Col>
                                                        <Col xs={12} md={2}></Col>
                                                    </Row>
                                                    <div id="rowsContainer" className="rowsContainer">

                                                        {this.state.telaCarregou ? rows : null}

                                                    </div>
                                                </div>
                                            </div >
                                            {/* Begin Register Form */}

                                        </div>
                                    </Row>
                                </div>
                            </div>
                        </Row>
                    </div>
                </div>
                <NeoButtonNav text="Voltar" callback={this.alertDecisionControl.bind(this, 'abrir', 'Atenção', 'Ao voltar os dados preenchidos serão perdidos, deseja continuar?')} />
                <NeoSubmit text="Continuar" color="green" callback={this.handleSubmit} loading={this.state.loading} />
                <NeoPercent text="95%" />
            </div>


        );
    }
}

export default Step_I;