import React, { Component } from 'react'
import { Container } from 'react-bootstrap'
import { Row } from 'reactstrap';
import './styles.css'
import { Spinner } from 'react-bootstrap'
// import ImagePreview from './ImagePreview';

const NeoAlert = (props) => {
  let modalClass = 'modal ';
  if (props.class) {
    modalClass += String(props.class);
  }
  return props.show ?
    <div className={modalClass}>
      <Container className="modal-content">
        <Row className="align-center">
          <h1 className="modal-title">{props.title}</h1>
        </Row>
        <Row className="align-center">
          <h3 className="motal-text">{props.text}</h3>
        </Row>{
          !props.modalEsconderBotao ?
            <Row className="align-center">
              <a className="modal-btn" onClick={props.close}>Fechar</a>
            </Row>
            : null}
      </Container>
    </div>
    : null

}

const NeoAlertDecision = (props) => (
  props.show ?
    <div className="modal">
      <Container className="modal-content">
        <Row className="align-center">
          <h1 className="modal-title">{props.title}</h1>
        </Row>
        <Row className="align-center">
          <h3 className="motal-text">{props.text}</h3>
        </Row>
        <Row className="align-center">
          <div className="div-btn">
            <a className="modal-btn" onClick={props.sim}>Sim</a>
          </div>
          <div className="div-btn">
            <a className="modal-btn" onClick={props.nao}>Não</a>
          </div>
        </Row>
      </Container>
    </div>
    : null
)

const NeoLoading = (props) => {

  let modalClass = 'loading ';
  if (props.class) {
    modalClass += String(props.class);
  }

  return (
    props.show ?
      <div className={modalClass}>
        <Container className="loading-content">
          <Row className="align-center">
            <h2 className="loading-title">{props.title}</h2>
          </Row>
          <Row className="align-center">
            <Spinner animation="border" role="status" variant="primary">
              <span className="sr-only">Carregando...</span>
            </Spinner>
          </Row>

        </Container>
      </div>
      : null

  )
}

// class NeoCamera extends Component {

//   constructor() {
//     super()
//     this.onError = this.onError.bind(this);
//     this.handleTakePhoto = this.handleTakePhoto.bind(this);
//     this.cancelarFoto = this.cancelarFoto.bind(this);
//     this.tirarOutraFoto = this.tirarOutraFoto.bind(this);

//     this.state = {
//       showErrorMessage: false,
//       foto: null
//     }
//   }

//   componentDidMount(){
//     //bloqueia o scroll
//     document.querySelector('.s3-cadastro.container').classList.add('blockScroll');
//   }

//   handleTakePhoto(dataUri) {
//     this.setState(() => ({
//       foto: dataUri
//     }))

//   }

//   onError(erro) {
//     console.error(`Erro ao inicializar a câmera: ${erro}`)

//     this.setState(() => ({
//       showErrorMessage: true
//     }))
//   }

//   salvarFoto() {

//   }
//   cancelarFoto() {
//     this.setState(() => ({
//       foto: null
//     }))
//   }
//   tirarOutraFoto() {
//     this.setState(() => ({
//       foto: null
//     }))
//   }

//   render() {
//     return (
//       <div className="modal">
//         <Container className="camera-content">
//           {
//             this.state.showErrorMessage ?
//               <div>
//                 <h2>Ocorreu um erro ao iniciar a câmera</h2>
//                 <div className="erroButton">
//                   <button className="cameraButton" id="cancelarFoto" onClick={this.cancelarFoto}>Cancelar</button>
//                 </div>

//               </div>
//               :
//               <div>
//                 <h3>Tire uma foto do seu documento</h3>

//                 {
//                   !this.state.foto ?
//                     <div id="cameraDiv">
//                       <Camera
//                         onTakePhotoAnimationDone={dataUri => this.handleTakePhoto(dataUri)}
//                         onCameraError={err => this.onError(err)}
//                         isDisplayStartCameraError={false}
//                         isFullScreen={true}
//                         idealResolution={{ width: 720, height: 1280 }}
//                       />
//                     </div>

//                     :
//                     <div>
//                       <img className="cameraImage"
//                         src={this.state.foto}
//                       />
//                       <div>
//                         <button className="cameraButton" id="salvarImagem">Salvar Imagem</button>
//                         <button className="cameraButton" id="outraFoto" onClick={this.tirarOutraFoto}>Tirar outra Foto</button>
//                       </div>
//                       <div>
//                         <button className="cameraButton" id="cancelarFoto" onClick={this.cancelarFoto}>Cancelar</button>
//                       </div>
//                     </div>

//                 }
//               </div>
//           }

//         </Container>
//       </div>
//     )
//   }

// }

export {
  NeoAlert,
  NeoAlertDecision,
  NeoLoading
} 