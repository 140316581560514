import React from "react";
import corretoras from '../assets/data/images/corretoras.png';
import graficos from '../assets/data/images/graficos.png';
import ganhos from '../assets/data/images/ganhos.png';
import relatorios from '../assets/data/images/relatorios.png';
import sono from '../assets/data/images/sono.png';
import tempo from '../assets/data/images/tempo.png';
import ajuda from '../assets/data/images/ajuda.png';
import analise from '../assets/data/images/analise.png';
import automatizar from '../assets/data/images/automatizar.png';
import reducao from '../assets/data/images/reducao.png';
import imgPlan1 from '../assets/data/images/investsquare.png';
import leao from '../assets/data/images/leao.png';




import { NavBar, Footer, DuvidasFrequentes, EnviaEmails, OtimizeGastos } from '../components/sectionsHome/sectionsHome'

const Teste = function (props) {
    return <h1>{props.texto}</h1>
}



const Investidor = () => (

    <div class="principal">
        <NavBar />

        <section id="jumbotron" class="s3-section-jumbotron">
            <div class="container">
                <div class="row">
                    <div>
                        <h1 style={{ marginTop: '170px' }}><span><h1 id="landingInvSubtitle">Maximize seus Investimentos com o NeoContador.com                    <br></br>Seu Parceiro Fiscal na Bolsa</h1></span><span><h1>Deixe a complexidade conosco e foque no crescimento do seu portfólio</h1></span></h1>
                    </div>
                </div>
            </div>
        </section>


        <div className="page-quem_somos principal">
            <section className="s3-section-steps">
                <div class="container">
                    <div class="row">
                        <div class="col-xs-12">
                            <h2>Mais Agilidade<br /><strong>Mais confiança</strong></h2>
                        </div>
                    </div>

                    <div class="row">
                        <div className="col-md-6">
                            <div class="block">
                                <p className="block-title">Conexão com a Corretora via OpenFinance</p>
                                <div class="card-img-top-2" >
                                    <img src={corretoras} style={{ width: '70%', height: '70%' }} />
                                </div>
                                <p style={{ fontFamily: 'Poppins', fontSize: '16px', color: '#666b74' }}>Conecte-se ao Open Finance e não se preocupe em coletar suas notas de corretagem mensalmente.</p>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div class="block">
                                <p className="block-title">Análises da sua Carteira</p>
                                <div class="card-img-top-2" >
                                    <img src={graficos} style={{ width: '90%', height: '90%' }} />
                                </div>
                                <p style={{ fontFamily: 'Poppins', fontSize: '16px', color: '#666b74' }}>Tenha relatórios atualizados sobre distribuição de ativos, rentabilidade e proventos.</p>
                            </div>
                        </div>
                    </div>
                    <br></br>
                    <div class="row">
                        <div className="col-md-6">
                            <div class="block">
                                <p className="block-title">Relatórios Detalhados</p>
                                <div class="card-img-top-2" >
                                    <img src={relatorios} style={{ width: '90%', height: '90%' }} />
                                </div>
                                <p style={{ fontFamily: 'Poppins', fontSize: '16px', color: '#666b74' }}>
                                    Saiba todos os detalhes dos seus investimentos. Receba relatórios mensais detalhados sobre sua custódia, resultados e impostos calculados.</p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div class="block">
                                <p className="block-title">Economia Fiscal</p>
                                <div class="card-img-top-2" >
                                    <img src={ganhos} style={{ width: '70%', height: '70%' }} />
                                </div>
                                <p style={{ fontFamily: 'Poppins', fontSize: '16px', color: '#666b74' }}>Receba dicas de oportunidades para redução de imposto.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

        <div className="page-nossos_servicos principal">

            <section class="s3-section-plans">
                <div class="container">
                    <div class="row">
                        <div class="col-xs-12">
                            <h2>Existimos para otimizar seu tempo</h2>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12">


                            <div class="s3-plan">
                                <div class="row">
                                    <div class="col-lg-4 hidden-xs hidden-sm hidden-md">
                                        <div class="s3-thumbnail"><img src={imgPlan1} /></div>
                                    </div>
                                    <div class="col-lg-3 col-md-5">
                                        <div class="s3-info">
                                            <h3>Cálculo de renda variável <br />para investidores</h3>
                                            <div class="price">R$99,90<span>/mês</span></div>
                                            <div class="cta"><a href="./register" class="s3-btn green material">Contrate agora</a></div>
                                        </div>
                                    </div>
                                    <div class="col-lg-5 col-md-7">
                                        <ul class="s3-list">
                                            <li>Apuração mensal e anual de resultados;</li>
                                            <li>Cálculo do Imposto de Renda a pagar;</li>
                                            <li>Relatórios detalhados mensais e anuais;</li>
                                            <li>Conexão direta com a corretora pelo open finance;</li>
                                            <li>Suporte prioritário e personalizado;</li>
                                            <li>Consultoria fiscal e planejamento tributário estratégico;</li>
                                            <li>Reuniões periódicas para revisão e planejamento;</li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="s3-more-info">
                                    <div class="s3-close"><a style={{ "cursor": 'pointer' }} onClick={() => document.querySelector('.s3-more-info').classList.remove('active')}><i style={{ 'color': '#fff' }} class="s3-icon-close"></i></a></div>
                                    <div class="wrapper">
                                        <p>Com a normatização da PEC das Domésticas a relação de trabalho entre empregadores e empregados domésticos passou por profundas mudanças, e várias obrigações passaram a ser exigidas para que tal relação esteja sempre regularizada, como controle de jornada de trabalho e recolhimento de diversos encargos sobre o salário do empregado (INSS, FGTS, IRRF). </p>
                                        <p>O Governo Federal disponibilizou um site na internet onde o gerenciamento desta relação de trabalho pudesse ser feito pelo próprio empregador, porém tal plataforma está longe de ser ideal, pois não realiza os cálculos da folha de pagamentos do empregado, muitas vezes é confusa e congestionada, não gera contratos de trabalho, rescisões, recibos de salários e férias, inclusive podendo induzir o usuário ao erro quando eventos avulsos acontecem, como férias e 13º salário, acarretando no recolhimento indevido dos encargos.</p>
                                        <p>Nossa Solução: através dos nossos sistemas, visando dar comodidade aos nossos clientes, fazemos os cadastros necessários no sistema do Governo Federal, geramos as folhas de pagamentos dos empregados domésticos, cálculos das férias e recisões, folhas de controle de ponto, contrato de trabalho, e a guia de recolhimento com os valores corretos e devidamente apurados por um contador especialista. Todos estes documentos são enviados e disponibilizados aos nossos clientes, gerando total comodidade ao mesmo, que só tem o trabalho de nos avisar sobre horas extras, períodos de férias ou rescisões e efetuar os pagamentos.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </div>

        <div className="cntrl-invest">
            <section>
                <h2>O que você precisa para ter controle sobre seus investimentos.</h2>
                <div className="cards_invest">
                    <div className="card">
                        <div className="cards_image">
                            <img src={leao} style={{ width: '50px', height: '50px', objectFit: 'contain' }} />
                        </div>
                        <div className="card-body">
                            <h5 className="card-title text-center">Cálculo do Imposto de Renda</h5>
                            <p className="card-text text-center">Fazemos todos os cálculos do Imposto de Renda dos seus investimentos na Bolsa.</p>
                        </div>
                    </div>

                    <div className="card">
                        <img src={analise} style={{ width: '40px', height: '40px', objectFit: 'contain' }} />
                        <div className="card-body">
                            <h5 className="card-title text-center">Análise detalhada da carteira</h5>
                            <p className="card-text text-center">Decida seus próximos passos com assertividade a partir de dados consolidados.</p>
                        </div>
                    </div>
                </div>
            </section>
        </div>


        <div className="auto-calculo-ir">
            <section>
                <h2>Por que automatizar o cálculo do IR na Bolsa?</h2>
                <div class="container">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="card">
                                <div className="cards_image">
                                    <img src={tempo} style={{ width: '40px', height: '40px', objectFit: 'contain' }} />
                                </div>
                                <div class="card-body">
                                    <h5 class="card-title text-center">Ganhe tempo</h5>
                                    <p class="card-text text-center">Não perca tempo com cálculos e regras tributárias complicadas. Importe suas notas de corretagem e, com poucos cliques, gere a Darf do seu Imposto de Renda.</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="card">
                                <div className="cards_image">
                                    <img src={reducao} style={{ width: '40px', height: '40px', objectFit: 'contain' }} />
                                </div>
                                <div class="card-body">
                                    <h5 class="card-title text-center">Pague menos impostos</h5>
                                    <p class="card-text text-center">Não pague imposto à toa. Prejuízos anteriores e taxas de corretagem podem ser abatidos no imposto devido. Importando suas notas de corretagem, seus cálculos ficam mais precisos.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6">
                            <div class="card">
                                <div className="cards_image">
                                    <img src={ajuda} style={{ width: '40px', height: '40px', objectFit: 'contain' }} />
                                </div>
                                <div class="card-body">
                                    <h5 class="card-title text-center">Simplifique seu trabalho</h5>
                                    <p class="card-text text-center">Quem investiu R$1 na Bolsa é obrigado a entregar a Declaração Anual. Com nossos Relatórios Auxiliares você copia e cola no programa da Receita enquanto toma um café.</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="card">
                                <div className="cards_image">
                                    <img src={sono} style={{ width: '40px', height: '40px', objectFit: 'contain' }} />
                                </div>
                                <div class="card-body">
                                    <h5 class="card-title text-center">Durma tranquilo com suas obrigações</h5>
                                    <p class="card-text text-center">A Declaração é anual, mas a apuração é mensal. Acompanhe seus resultados e imprima sua DARF todo mês. Não cobramos a mais por cálculos retroativos.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

        <div className="">

        </div>
        <section id="secContact" class="s3-section-contact">
            <div class="container">
                <div class="row">

                    <DuvidasFrequentes />

                    <EnviaEmails />

                </div>
            </div>
        </section>

        <Footer />


    </div>

);

export default Investidor;
