import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { Button } from 'react-bootstrap';

import { GLOBAL } from '../../config/global';
import { CONFIG } from '../../config/index';
import styles from './styles/styles';
import './styles/styles.css';
import '../../assets/css/style.css';
import StepTitle from './StepTitle';
import { NeoInputRequired } from '../NeoInputs/NeoInputs'
import { ValidatorForm } from 'react-form-validator-core';
import { NeoRadio } from '../NeoButtons/NeoButtons'
import { NeoButtonNav, NeoSubmit } from '../NeoButtons/NeoButtons'
import { NeoAlert, NeoAlertDecision, NeoLoading } from '../NeoAlert/NeoModal'
import NeoTooltip from '../NeoTooltip/NeoTooltip'
import { NeoInputFile } from '../NeoInputs/NeoInputs'
import $ from 'jquery'

class Step_Inves extends Component {
    constructor() {
        super()
        this.handleSignUpSubmit = this.handleSignUpSubmit.bind(this);
        this.updateValue = this.updateValue.bind(this);
        this.formatSpecialTypes = this.formatSpecialTypes.bind(this);
        this.onlyNumber = this.onlyNumber.bind(this);;
        this.alertControl = this.alertControl.bind(this);
        this.alertDecisionControl = this.alertDecisionControl.bind(this);
        this.verificaValidacao = this.verificaValidacao.bind(this);
        this.selectControl = this.selectControl.bind(this);
        this.checkboxControlVendas = this.checkboxControlVendas.bind(this);
        this.checkboxControlDec_Ir = this.checkboxControlDec_Ir.bind(this);

      
        this.state = {
            username: '',
            cpf: '',
            email: '',
            corretoras: '',
            decImpostRenda: 1,
            telephone: "",
            password: '',
            confirmPassword: '',
            passwordType: 'password',
            confirmPasswordType: 'password',
            vendasMensais: 1,
            validado: false,
            showAlert: false,
            showAlertDecision: false,
            loading: false,
            loadingTitle: 'Aguarde',
            loadingButton: false,
            modalTitle: '',
            modalText: '',
            lastEvent: '',
            telaCarregou: true
        }

        this.globalVariable = {
            emailValidado: false,
        }
    }

    static displayName = 'ui-RegisterForm'

    //Cadastra usuário no sistema
    handleSignUpSubmit(e) {
        console.log('acessou o cadastro')
        this.setState({
            loading: true,
            loadingTitle: 'Salvando, aguarde',
            loadingButton: true,
        })

        e.preventDefault();

        let dataToSend = {
            userData: {
                username: this.state.username,
                cpf: this.state.cpf.replace(/[.,-/]/g, ''),
                email: this.state.email,
                password: this.state.password,
                telefone: this.state.telephone,
                quantidadeFuncionarios: null,
                possuicnpj: null,
                vendasMensais: this.state.vendasMensais,
                decImpostRenda: this.state.decImpostRenda,
                corretoras: this.state.corretoras,
                step: 'Final_Inves',
                historicoStep: ['Inves'],
                servicoContratado: 'Investidor'
            }
        };

        console.log(dataToSend)

        let url = CONFIG.API_PREFIX + '/users/register';

        fetch(url, {
            method: "POST",
            body: JSON.stringify(dataToSend),
            headers: {
                "Content-Type": "application/json"
            }
        }).then(response => response.json())
            .then(responseJson => {
                // console.log(responseJson)

                if (responseJson.success) {

                    //salvar o userId no cookie do navegador com validade de 30 dias
                    GLOBAL.setCookie('_uf_neoId', responseJson.data.ops[0]._id, 30);

                    this.setState({
                        loading: false,
                        loadingButton: false,
                    })

                    this.props.nextScreen();
                }

                else {

                    this.setState({
                        loading: false,
                        loadingButton: false,
                        modalTitle: 'Ops!',
                        modalText: 'Ocorreu um erro ao salvar os dados. Tente novamente mais tarde.'
                    })
                    this.alertControl();
                }
            }).catch(err => {
                // console.log('Error ', err)
                this.setState({
                    loading: false,
                    loadingButton: false,
                    modalTitle: 'Ops!',
                    modalText: 'Ocorreu um erro ao salvar os dados. Tente novamente mais tarde.',
                })
                this.alertControl();
            });
    }

    verifyIfExists(name, e) {
        // console.log(name);
        if ((name == 'cpf' && e) || (name == 'email' && this.globalVariable.emailValidado)) {
            name = (name === "cpf" ? "CPF" : name);
            this.setState({
                loading: true,
                loadingTitle: 'Aguarde, verificando ' + name,
            })

            name = (name.toLowerCase() == 'cpf' ? 'CPF' : 'E-mail');

            let dados = {

                userLogin: {}
            }

            // console.log(name);

            if (name == 'CPF') dados.userLogin.cpf = this.state.cpf.replace(/[.,-]/g, '');
            else dados.userLogin.email = this.state.email;

            let url = CONFIG.API_PREFIX + '/users/finduser';
            fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(dados)
            }).then(response => response.json())
                .then(responseJson => {
                    if (responseJson.success) {
                        window.location = '/login'
                    }
                    else {
                        this.setState({
                            loading: false,
                        })
                    }
                }).catch(err => {
                    // console.log(err)
                    this.setState({
                        loading: false,
                        modalTitle: 'Ops!',
                        modalText: 'Ocorreu um erro ao verificar ' + name + '. Tente novamente mais tarde.',
                        cpf: (name == 'CPF' ? '' : this.state.cpf),
                        email: (name == 'E-mail' ? '' : this.state.email),
                    })
                    this.alertControl();
                });
        }
    }

    confirmPass = function () {

        return ($('[name="confirmPassword"]').val() == $('[name="password"]').val() ? true : false)
    }

    //Permite ver/ocultar a senha
    showPassword = function (name, evt) {
        if (this.state[name] == 'password') {
            this.setState(state => ({
                [name]: 'text'
            }))
        } else {
            this.setState(state => ({
                [name]: 'password'
            }))
        }
    }

    //Formata campos que não foi possível aplicar máscara
    formatSpecialTypes = function (name, evt) {
        let valorFormatado = evt.target.value;
        let length = valorFormatado.replace(/[(,), ,-]/g, '').length

        /*Na época que foi desenvolvido, o plugin de máscara não conseguia fazer uma
        máscara dinâmica de um jeito eficiente, por isso foi feito assim */
        if (name == "telephone") {

            if (length >= 11) {
                valorFormatado = valorFormatado.replace(/[(,), ,-]/g, '').replace(/^(\d{2})(\d{5})(\d{4}).*/, '($1) $2-$3');
            } else if (length == 10) {
                valorFormatado = valorFormatado.replace(/[(,), ,-]/g, '').replace(/^(\d{2})(\d{4})(\d{4}).*/, '($1) $2-$3');
            }
        }
        this.setState(state => ({ [name]: valorFormatado }))
    }

    //Bloqueia o campo de texto para que seja possível aceitar só números
    onlyNumber = function (name, evt) {

        let valor = evt.target.value

        // Campo de quantidade de funcionários não pode ser 0
        if (name == 'numberEmployees' && Number(valor) == 0) {

            this.setState(state => ({ [name]: '' }));
        }

        else {

            this.setState(state => ({ [name]: valor.replace(/\W+/g, "").replace(/[_A-Z-a-z]/g, '') }));
        }
    }

    //Atualiza os states
    updateValue = function (name, evt) {
        let valor = evt;
        console.log(valor)
        if (isNaN(Number(evt))) {
            valor = evt.target.value
        }
        this.setState(state => ({ [name]: valor }))
    }

    //Controla o valor do select
    selectControl(e) {

        this.updateValue('numberEmployees', e);
    }

    //Verifica se as senhas são iguais


    //Permite ver/ocultar a senha


    checkboxControlVendas(option) {
        console.log('valor vendas mensais:'+option);
        this.updateValue('vendasMensais', option);
    }

    checkboxControlDec_Ir(option) {
        console.log(option);
        this.updateValue('decImpostRenda', option);
    }

    //Controla a visibilidade do alerta
    alertControl() {
        this.setState(state => ({
            showAlert: !state.showAlert
        }))
    }

    //Controla a visibilidade do alerta decisivo
    alertDecisionControl(acao, titulo, mensagem, evt) {

        this.setState(state => ({
            showAlertDecision: !state.showAlertDecision,
            modalTitle: titulo,
            modalText: mensagem,
        }))

        if (acao == 'continuar') this.props.lastScreen();
    }

    //Verifica se campo está validado e atualiza variável
    verificaValidacao(name, evt) {

        this.globalVariable[name + 'Validado'] = evt;
    }

    handleKeyDown = (event) => {

        if (event.key === 'Enter') {

            event.preventDefault();
        }
    };

   

    render() {

            
        const textoTitulo = {
            titulo: 'Ótimo, você escolheu o      ',
            produto: '        Cálculos de renda variável para investidores',
            subtitulo: 'Vamos começar com as primeiras informações.'
        }

        ValidatorForm.propTypes = {

            instantValidate: () => false, //PERGUNTAR PRO MARIO
        }

        ValidatorForm.addValidationRule('confirmPass', (value) => {

            if (this.state.password == this.state.confirmPassword) {

                return true
            }

            return false;
        });

        return (

            <div>

                <NeoAlert
                    show={this.state.showAlert}
                    title={this.state.modalTitle}
                    text={this.state.modalText}
                    close={this.alertControl}
                />

                <NeoAlertDecision
                    show={this.state.showAlertDecision}
                    title={this.state.modalTitle}
                    text={this.state.modalText}
                    sim={this.alertDecisionControl.bind(this, "continuar")}
                    nao={this.alertDecisionControl.bind(this, "cancelar")}
                />

                <NeoLoading
                    title={this.state.loadingTitle}
                    show={this.state.loading}
                />

                <ValidatorForm ref="form" onSubmit={this.handleSignUpSubmit} onKeyDown={e => { this.handleKeyDown(e) }}>

                    <div className="s3-step" data-step="A">
                        <div className="s3-wrapper">
                            {/* //className = "s3-signup s3-body"> */}
                            <Row onClick={this.sendData}>
                                <Row style={styles.alignContentCenter}>
                                    <StepTitle texto={textoTitulo} />
                                </Row>
                                <div className="s3-content col-md-12" style={{ padding: "0" }}>
                                    <div className="s3-signup">
                                        <Row>
                                            <div className="s3-form-item clearfix col-md-12" style={{ padding: "0" }}>

                                                {/* Begin Register Form */}
                                                < div className="register-content" style={{ marginTop: "10px" }}>

                                                    <div className="register-body" style={{ padding: "0" }}>

                                                        <Row style={styles.rowSpace} className="verticalAlign">
                                                            <Col xs={12} md={6}>
                                                                <label htmlFor="username" className="form-control-label">Informe seu nome completo: </label>
                                                            </Col>
                                                            <Col xs={12} md={6}>
                                                                <NeoInputRequired
                                                                    changeCallback={this.updateValue.bind(this, "username")}
                                                                    name="username"
                                                                    type="text"
                                                                    value={this.state.username}
                                                                    validators={['required']}
                                                                    errorMessages={['Obrigatório']}
                                                                    attr={{
                                                                        mask: 'text',
                                                                        ref: 'username',
                                                                        className: 'form-control',
                                                                        id: 'username',
                                                                        placeholder: 'Nome',
                                                                        erro: this.state.username || this.state.username == null ? false : true
                                                                    }} />
                                                            </Col>
                                                        </Row>

                                                        <Row style={styles.rowSpace} className="verticalAlign">
                                                            <Col xs={12} md={6}>
                                                                <label htmlFor="cpf" className="form-control-label">Informe seu CPF: </label>                                        </Col>
                                                            <Col xs={12} md={6}>
                                                                <NeoInputRequired
                                                                    changeCallback={this.updateValue.bind(this, "cpf")}
                                                                    validatorListener={this.verifyIfExists.bind(this, "cpf")}
                                                                    name="cpf"
                                                                    type="text"
                                                                    value={this.state.cpf}
                                                                    validators={['required', 'isCpf']}
                                                                    errorMessages={['Obrigatório', 'CPF inválido']}
                                                                    attr={{
                                                                        mask: 'cpf',
                                                                        ref: 'cpf',
                                                                        className: 'form-control',
                                                                        id: 'cpf',
                                                                        placeholder: '___.___.___-__',
                                                                        erro: this.state.cpf || this.state.cpf == null ? false : true
                                                                    }} />
                                                            </Col>
                                                        </Row>


                                                        <Row style={styles.rowSpace} className="verticalAlign">
                                                            <Col xs={10} md={5}>
                                                                <div>
                                                                    <label htmlFor="email" className="form-control-label">Informe seu E-mail:</label>
                                                                </div>
                                                            </Col>

                                                            <Col xs={2} md={1}>
                                                                <NeoTooltip position="top" text="O e-mail servirá de login de acesso" />
                                                            </Col>

                                                            <Col xs={12} md={6}>

                                                                <NeoInputRequired
                                                                    changeCallback={this.updateValue.bind(this, "email")}
                                                                    validatorListener={this.verificaValidacao.bind(this, "email")}
                                                                    name="email"
                                                                    type="text"
                                                                    value={this.state.email}
                                                                    validators={['required', 'isEmail']}
                                                                    errorMessages={['Obrigatório', 'E-mail inválido']}
                                                                    attr={{
                                                                        mask: 'email',
                                                                        ref: 'email',
                                                                        className: 'form-control',
                                                                        id: 'email',
                                                                        placeholder: 'E-mail',
                                                                        erro: this.state.email || this.state.email == null ? false : true
                                                                    }}

                                                                />

                                                            </Col>
                                                        </Row>

                                                        <Row style={styles.rowSpace} className="verticalAlign">

                                                            <Col xs={12} md={6}>
                                                                <label htmlFor="telelphone" className="form-control-label">Qual o seu telefone para contato?</label>
                                                            </Col>

                                                            <Col xs={12} md={6}>

                                                                <NeoInputRequired
                                                                    changeCallback={this.onlyNumber.bind(this, "telephone")}
                                                                    blurCallback={this.formatSpecialTypes.bind(this, 'telephone')}
                                                                    name="telephone"
                                                                    type="text"
                                                                    value={this.state.telephone}
                                                                    validators={['required', 'isTel']}
                                                                    maxLength='15'
                                                                    errorMessages={['Obrigatório', 'Telefone inválido']}
                                                                    attr={{
                                                                        mask: 'tel',
                                                                        ref: 'telelphone',
                                                                        className: 'form-control',
                                                                        id: 'telelphone',
                                                                        placeholder: '(__) _____-____',
                                                                        erro: this.state.telephone || this.state.telephone == null ? false : true
                                                                    }}

                                                                />
                                                            </Col>
                                                        </Row>

                                                        <Row style={styles.rowSpace} className="verticalAlign">
                                                            <Col xs={10} md={5}>
                                                                <label htmlFor="password" className="form-control-label">Cadastre uma senha para acesso:</label>
                                                            </Col>

                                                            <Col xs={2} md={1}>
                                                                <NeoTooltip position="top" text="Mínimo de 6 caracteres" />
                                                            </Col>

                                                            <Col xs={12} md={3}>

                                                                <NeoInputRequired
                                                                    changeCallback={this.updateValue.bind(this, "password")}
                                                                    showPassword={this.showPassword.bind(this, "passwordType")}
                                                                    name="password"
                                                                    type={this.state.passwordType}
                                                                    minLength="6"
                                                                    value={this.state.password}
                                                                    valorOutroCampo={this.state.confirmPassword}
                                                                    validators={['required', 'minStringLength: 6', 'confirmPass']}
                                                                    errorMessages={['Obrigatório', 'A senha deve ter pelo menos 6 caracteres', 'Senhas não coincidem']}
                                                                    attr={{
                                                                        mask: 'text',
                                                                        ref: 'password',
                                                                        className: 'form-control',
                                                                        id: 'password',
                                                                        placeholder: 'Senha',
                                                                        erro: this.state.confirmPassword == this.state.password ? false : true
                                                                    }}
                                                                />
                                                            </Col>
                                                            <Col xs={12} md={3} >

                                                                <NeoInputRequired
                                                                    changeCallback={this.updateValue.bind(this, "confirmPassword")}
                                                                    showPassword={this.showPassword.bind(this, "confirmPasswordType")}
                                                                    name="confirmPassword"
                                                                    type={this.state.confirmPasswordType}
                                                                    value={this.state.confirmPassword}
                                                                    valorOutroCampo={this.state.password}
                                                                    validators={['required', 'minStringLength: 6', 'confirmPass']}
                                                                    errorMessages={['Obrigatório', 'A senha deve ter pelo menos 6 caracteres', 'Senhas não coincidem']}
                                                                    attr={{
                                                                        mask: 'text',
                                                                        ref: 'confirmPassword',
                                                                        className: 'form-control',
                                                                        id: 'confirmPassword',
                                                                        placeholder: 'Confirmar senha',

                                                                    }}
                                                                    valid={{
                                                                        pass: this.state.password
                                                                    }}
                                                                />
                                                            </Col>
                                                        </Row>


                                                        <Row style={styles.rowSpace} className="verticalAlign">

                                                            <Col xs={12} md={6}>
                                                                <label htmlFor="vendasMensais" className="form-control-label">Realiza vendas mensalmente? </label>
                                                            </Col>
                                                            <Col xs={12} md={6}>
                                                                <NeoRadio
                                                                    opt1="Sim"
                                                                    opt2="Não"
                                                                    value={this.checkboxControlVendas}
                                                                    defaultValue={this.state.vendasMensais}
                                                                />
                                                            </Col>
                                                        </Row>

                                                        <Row style={styles.rowSpace} className="verticalAlign">
                                                            <Col xs={10} md={5}>
                                                                <label htmlFor="username" className="form-control-label">Opera em quais corretoras? </label>
                                                            </Col>
                                                            <Col xs={2} md={1}>
                                                                <NeoTooltip position="top" text="Digite o nome das corretoras nas quais atua, separados por (,)" />
                                                            </Col>
                                                            <Col xs={12} md={6}>
                                                                <NeoInputRequired
                                                                        changeCallback={this.updateValue.bind(this, "corretoras")}
                                                                        name="corretoras"
                                                                        type="text"
                                                                        value={this.state.corretoras}
                                                                        attr={{
                                                                            mask: 'text',
                                                                            ref: 'corretoras',
                                                                            className: 'form-control',
                                                                            id: 'corretoras',
                                                                            placeholder: 'Nome corretoras',
                                                                            
                                                                        }} />
                                                            </Col>
                                                        </Row>

                                                        <Row style={styles.rowSpace} className="verticalAlign">

                                                            <Col xs={12} md={6}>
                                                                <label htmlFor="decImpostRenda" className="form-control-label">Fez a declaração de imposto de renda deste ano? </label>
                                                            </Col>
                                                            <Col xs={12} md={6}>
                                                                <NeoRadio
                                                                    opt1="Declarei"
                                                                    opt2="Não Declarei"

                                                                    value={this.checkboxControlDec_Ir}
                                                                    defaultValue={this.state.decImpostRenda}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </div >
                                                {/* Begin Register Form */}

                                            </div>
                                        </Row>
                                    </div>
                                </div>
                            </Row>

                        </div>

                    </div>
                    <NeoButtonNav text="Voltar" callback={this.alertDecisionControl.bind(this, 'abrir', 'Atenção', 'Ao voltar os dados preenchidos serão perdidos, deseja continuar?')} />
                    <NeoSubmit text="Continuar" color="green" loading={this.state.loadingButton} />

                </ValidatorForm>
            </div>
        );
    }
}
export default Step_Inves;