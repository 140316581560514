import $ from 'jquery';
import { CONFIG } from './config/index';
import { GLOBAL } from './config/global';

var authControl = {
    session: {
        username: undefined,
        email: undefined,
        password: undefined,
        signUp: {
            success: undefined,
            message: undefined
        },
        logged: false,
        users: undefined,
        error: undefined,
    },
    isAuthenticated: function () {
        return this.session.logged
    },
    logout: function (cb) {
        localStorage.setItem(CONFIG.TOKEN_NAME, '');
        this.session.logged = false
        cb(0);
    },
    login: function (info, cb) {
        var self = this;
        let url = CONFIG.API_PREFIX + '/auth/login';

        fetch(url, {
            method: "POST",
            body: JSON.stringify(info),
            headers: {
                "Content-Type": "application/json"
            }
        }).then(response => response.json())
            .then(responseJson => {
                if (responseJson.success) {
                    localStorage.setItem(CONFIG.TOKEN_NAME, responseJson.token);
                    self.session.logged = true;
                    self.session.username = responseJson.userInfo.name;
                    self.session.email = responseJson.userInfo.email;
                    //salvar o userId no cookie do navegador com validade de 30 dias
                    GLOBAL.setCookie('_uf_neoId', responseJson.userInfo["userId"], 30);
                    cb(0, 'Usuário logado com sucesso')
                } else {
                    cb(1, 'Falha na autenticação')
                }
            }).catch(err => {
                console.error(err)
                cb(2, err.message)
            });


    },
    verifytoken: function () {
        let url = CONFIG.API_PREFIX + '/auth/verifytoken';
        let token = localStorage.getItem(CONFIG.TOKEN_NAME);
        if (!token) {
            return false
        }
        var retorno = $.ajax({
            url: url,
            async: false,
            type: 'GET',
            data: JSON.stringify({}),
            headers: {
                "Content-Type": "application/json",
                "authorization": `Bearer ${token}`
            }
        })
        localStorage.setItem(CONFIG.TOKEN_NAME, JSON.parse(retorno.responseText).token)
        return JSON.parse(retorno.responseText).success
    }

}

export const auth = {
    isAuthenticated: authControl.isAuthenticated,
    session: authControl.session,
    login: authControl.login
};