
import '../../assets/css/style.css';
import './styles.css'
import React, { Component, Fragment, useRef } from 'react';
import styles from './style'
import { Col, Row } from 'reactstrap';
import InputMask from 'react-input-mask';
import { ValidatorComponent, ValidatorForm } from 'react-form-validator-core';
import validarCpf from 'validar-cpf'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import Select from 'react-select'
import moment from 'moment'
import PropTypes from "prop-types";


class NeoInput extends Component {

        componentDidMount() {
        }

        constructor() {
                super()
                this.state = {
                        validado: true,
                        val: '',
                }

                this.changeState = this.changeState.bind(this);
                this.updateValue = this.updateValue.bind(this);
        }
        changeState = function () {

                let type = this.props.attr.type;
                let value = this.state.val
                this.setState(state => ({ validado: this.validate(type, value) }))

        }

        updateValue = function (evt) {
                let valor = evt.target.value
                this.setState(state => ({ val: valor }))
        }

        dynamicMask = function (type) {
                let mask = null;
                switch (type) {
                        case 'cpf':
                                mask = "999.999.999-99"
                                break;
                        case 'tel':
                                mask = "(99) 99999-9999"
                                break;
                        case 'date':
                                mask = "99/99/9999"
                                break;
                        default:
                                mask = ""
                }
                return mask;

        }


        render() {
                return (
                        <InputMask mask={this.dynamicMask(this.props.attr.type)} placeholder={this.props.attr.placeholder}
                                className={this.props.attr.className} id={this.props.attr.id} value={this.state.value} disabled={this.props.attr.disabled}
                        >
                        </InputMask>
                )
        }
}


class NeoInputFile extends Component {

        constructor() {
                super()
                this.returnValue = this.returnValue.bind(this);
                this.attachFile = this.attachFile.bind(this);
                this.removeFile = this.removeFile.bind(this);
                this.state = {
                        fileName: '',
                        originalFileName: '',
                        backgroundColor: 'rgb(184, 184, 184)',
                        base64: null,
                        arquivoValido: true,
                        value: null,
                        maxLength: 23,
                }
        }


        componentDidMount() {
                this.updateDimensions();
                window.addEventListener("resize", this.updateDimensions.bind(this));

                if (this.props.data && this.props.data.nomeOriginal) {
                        this.setState({
                                fileName: this.props.data.nomeOriginal,
                                originalFileName: '',
                                backgroundColor: "#1587B9"
                        })
                } else {
                        this.setState({
                                fileName: this.props.attr.texto,
                                originalFileName: '',
                        })
                }
        }
        componentWillUnmount() {
                window.removeEventListener("resize", this.updateDimensions.bind(this));
        }

        updateFileName(length) {
                let self = this;
                try {
                        
                        if(this.state.originalFileName){
                                let extensao = this.state.extensao;
                                let fileSize = this.state.fileSize;
                                let smallName = this.state.originalFileName.split(`.${extensao}`)[0].split('(...)')[0];
                                let reticencias = (smallName.length > length || this.state.originalFileName.includes('(...)'))?'(...)':''
                                
                                smallName = `${smallName.substring(0, length)}${reticencias}.${extensao} - ${this.formatBytes(fileSize)}`
                                this.setState({
                                        fileName: smallName
                                })
                        }
                       
                } catch (e) {
                        console.error(e)
                }

        }

        updateDimensions() {
                let self = this;
                if (!this.props.maxLength) {
                        if (window.innerWidth < 991 && window.innerWidth > 768) {
                                this.setState({ maxLength: 12 }, () => self.updateFileName(this.state.maxLength));
                        } else if (window.innerWidth > 991) {
                                this.setState({ maxLength: 23 }, () => self.updateFileName(this.state.maxLength));
                        } else if (window.innerWidth < 768 && window.innerWidth > 600) {
                                this.setState({ maxLength: 23 }, () => self.updateFileName(this.state.maxLength));
                        } else if (window.innerWidth < 500 && window.innerWidth > 350) {
                                this.setState({ maxLength: 10 }, () => self.updateFileName(this.state.maxLength));
                        } else {
                                this.setState({ maxLength: 10 }, () => self.updateFileName(this.state.maxLength));
                        }
                        // console.log(this.state.maxLength)
                } else {
                        this.setState({ maxLength: self.props.maxLength });
                }
        }

        attachFile = (nome) => {
                document.getElementById(nome).click()
        }

        removeFile(value) {
                this.setState({
                        fileName: this.props.attr.texto,
                        originalFileName: '',
                        extensao: '',
                        fileSize: '',
                        backgroundColor: 'rgb(184, 184, 184)',
                        base64: null,
                        value: null
                })
                this.props.value({
                        error: false,
                        size: null,
                        base64: null,
                        name: null,
                        extensao: null,
                        id: this.props.id
                });

                //único jeito que eu consegui remover o value do campo
                //se vc, programador do futuro, souber outro jeito, muda aí
                document.getElementById(this.props.id).value = ""
        }

        //converte o file em base64
        getBase64(file, callback) {
                let reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = function () {
                        let result = reader.result;
                        result = result.split(',')[1];
                        return callback({ error: false, data: result });
                };
                reader.onerror = function (error) {
                        return callback({ error: true, data: error })
                };
        }

        //converte o tamanho do arquivo de bytes para outras unidades
        formatBytes = function (bytes, decimals = 0) {
                if (bytes === 0) return '0 Bytes';

                const k = 1024;
                const dm = decimals < 0 ? 0 : decimals;
                const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

                const i = Math.floor(Math.log(bytes) / Math.log(k));

                return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
        }


        //Faz as validações do arquivo recebido e retorna esses dados
        returnValue = function (e) {

                try {
                        if (e.target.files[0]) {
                                let acceptedTypes = ['application/pdf', 'image/jpeg', 'image/png', 'image/jpg'];
                                let size = e.target.files[0].size;
                                let name = e.target.value.split('\\');
                                let originalName = name[name.length-1];
                                let self = this;
                                let maxSize = 10000000;
                                let maxLength = this.state.maxLength;
                                name = name[name.length - 1];

                                let file = e;




                                let extensao = name.split('.')[name.split('.').length - 1];

                                //só deixa anexar imagem e pdf com menos de 10bm
                                if (size < maxSize && acceptedTypes.includes(e.target.files[0].type)) {
                                        
                                        //não deixa aparecer no input nomes muito grandes
                                        if (name.length > this.state.maxLength) {

                                                name = `${name.substring(0, this.state.maxLength)}(...).${extensao}`
                                        }
                                        this.setState({
                                                extensao: extensao,
                                                fileSize: e.target.files[0].size,
                                                fileName: `${name} - ${this.formatBytes(e.target.files[0].size)}`,
                                                originalFileName: originalName,
                                                backgroundColor: '#1587B9',
                                                arquivoValido: true,
                                                value: e.target.value
                                        })


                                        this.getBase64(e.target.files[0], function (content) {

                                                //força um erro de arquivo invalido pro usuario
                                                if (content.erro) {
                                                        self.props.value({
                                                                error: true,
                                                        });
                                                        self.setState({
                                                                arquivoValido: false
                                                        })
                                                        self.removeFile();
                                                } else {
                                                        //se der certo
                                                        self.props.value({
                                                                error: false,
                                                                size: size,
                                                                base64: content.data,
                                                                name: self.state.fileName,
                                                                nomeOriginal: self.state.fileName,
                                                                extensao: extensao,
                                                                id: self.props.id,
                                                                file: file
                                                        });
                                                }
                                        });

                                } else {

                                        if (acceptedTypes.includes(e.target.files[0].type) && size > maxSize) {

                                                self.props.value({
                                                        error: false,
                                                        size: size,
                                                        base64: null,
                                                        name: self.state.fileName,
                                                        extensao: extensao,
                                                        id: self.props.id
                                                });
                                                this.setState({
                                                        arquivoValido: true,
                                                        extensaoValida: true
                                                })
                                        } else {
                                                this.setState({
                                                        arquivoValido: false,
                                                        extensaoValida: false
                                                })
                                        }

                                        this.removeFile(e);
                                }
                        }
                }
                catch (e) {
                        console.error('erro ao anexar aquivo');
                        console.error(e);
                        this.setState({
                                arquivoValido: false
                        })
                        this.removeFile();
                }
        }


        render() {
                return (
                        <Col xs={12} style={Object.assign({}, styles.pointer, styles.removePadding)}>
                                <div style={styles.displayNone}>
                                        <input type="file" className="km-btn-file"
                                                id={this.props.id}
                                                accept="application/pdf, image/jpeg, image/png"
                                                onChange={this.returnValue}
                                        >
                                        </input>
                                </div>
                                <Row style={Object.assign({}, styles.alignCenter, styles.removeMargin, { marginTop: '5px' })}>

                                        <Col xs={this.state.fileName != this.props.attr.texto ? 11 : 12} style={Object.assign({}, styles.removePadding, styles.inputFile)}>
                                                <div style=
                                                        {
                                                                Object.assign({ 'maxHeight': '34px', 'overflow': 'hidden' }, styles.alignCenter,
                                                                        styles.inputFileBtn,
                                                                        { borderRight: '0px' },
                                                                        { backgroundColor: this.state.backgroundColor })
                                                        }
                                                        onClick={this.attachFile.bind(this, this.props.id)}>

                                                        <span style={Object.assign({ 'whiteSpace': 'nowrap' },
                                                                styles.alignCenter, styles.inputFileText)
                                                        }>{this.state.fileName}
                                                        </span>

                                                </div>
                                        </Col>
                                        {this.state.fileName != this.props.attr.texto ?
                                                <Col xs={1} style={styles.removePadding}>
                                                        <div style=
                                                                {
                                                                        Object.assign({}, styles.alignCenter,
                                                                                styles.inputFileBtn,
                                                                                { borderLeft: '0px' },
                                                                                { backgroundColor: this.state.backgroundColor })
                                                                }
                                                                onClick={this.removeFile}
                                                        >

                                                                <span style={Object.assign({},
                                                                        styles.alignCenter, styles.inputFileText)
                                                                }>x
                                        </span>

                                                        </div>
                                                </Col>

                                                : null
                                        }
                                </Row>

                                {
                                        !this.state.arquivoValido ?
                                                <div>
                                                        <span style={{ color: 'red' }}>Arquivo Inválido</span>
                                                </div>
                                                : null
                                }

                        </Col>
                )
        }
}

class NeoSelect extends Component {

        constructor() {
                super()
                this.returnValue = this.returnValue.bind(this);
                this.state = {
                        inputValue: ''
                }
        }

        componentDidMount() {
                this.setState({
                        inputValue: this.props.defaultLabel ? this.props.defaultLabel : ''
                })
        }
        dynamicOptions = function () {

                let itens = [];
                let options = this.props.options;
                options.map((item, index) => ({ value: index, label: item }))
                return itens
        }

        returnValue = function (e) {
                this.setState({
                        inputValue: e.value
                })
                this.props.value(e.value, e.label)
        }

        render() {

                let itens = [];
                let options = this.props.options;
                itens = options.map((item, index) => ({ value: index, label: item }))

                return (

                        <div>
                                <Select
                                        placeholder={this.props.placeholder}
                                        options={itens}
                                        maxMenuHeight={this.props.maxHeight}
                                        noOptionsMessage={() => 'Nenhum Resultado Encontrado'}
                                        defaultValue={this.props.defaultLabel ? { label: this.props.defaultLabel, value: this.props.defaultValue } : ''}
                                        onChange={this.returnValue} />
                                <NeoInputRequired
                                        name={this.props.name}
                                        type="hidden"
                                        value={this.state.inputValue}
                                        validators={['required']}
                                        errorMessages={['Obrigatório']}
                                        attr={{
                                                mask: 'text',
                                                ref: 'text',
                                                className: 'form-control',
                                                id: this.props.name,
                                                placeholder: '',
                                        }}
                                />
                        </div>
                )
        }
}

class NeoInputRequired extends ValidatorComponent {

        dynamicMask = function (type) {
                let mask = null;
                switch (type) {
                        case 'cpf':
                                mask = "999.999.999-99"
                                break;
                        case 'date':
                                mask = "99/99/9999"
                                break;
                        case 'cep':
                                mask = "99999-999"
                                break;
                        case 'numTituloEleitor':
                                mask = "9999 9999 9999"
                                break;
                        case 'zonaTituloEleitor':
                                mask = "999";
                                break;
                        case 'secaoTituloEleitor':
                                mask = "9999"
                                break;
                        case 'pis':
                                mask = "999.99999.99-9";
                                break;
                        case 'irpf':
                                mask = "99.99.99.99.99–99"
                                break;
                        case 'validadeCartao':
                                mask = "99/9999";
                                break;
                        case 'cartao':
                                mask = "9999 9999 9999 9999"
                                break;
                        case 'cvv':
                                mask = "999";
                                break;
                        case 'codAcesso':
                                mask = "999999999999";
                                break;
                        default:
                                mask = ""
                }
                return mask;

        }

        render() {
                const { errorMessages, validators, requiredError, validatorListener, ...rest } = this.props;

                return (

                        !this.props.name.toLowerCase().includes('password') ?
                                <div >
                                        <InputMask mask={this.dynamicMask(this.props.attr.mask)} type={this.props.type} placeholder={this.props.attr.placeholder} style={this.props.attr.erro ?/*styles.error*/null : null}
                                                className={this.props.attr.className} id={this.props.attr.id} disabled={this.props.attr.disabled}
                                                onChange={this.props.changeCallback} onBlur={this.props.blurCallback}
                                                autoComplete={this.props.attr.autocomplete}
                                                name={this.props.name}
                                                onFocus={this.props.focusCallback}
                                                value={this.props.value} maxLength={this.props.maxLength} ref={(r) => { this.input = r; }}>
                                        </InputMask>
                                        {this.errorText()}
                                </div>
                                : <div style={styles.positionRelative}>

                                        <InputMask mask={this.dynamicMask(this.props.attr.mask)} type={this.props.type} placeholder={this.props.attr.placeholder}
                                                style={styles.passInput} /*{this.props.attr.erro?styles.errornull:null}*/
                                                className={this.props.attr.className} id={this.props.attr.id} disabled={this.props.attr.disabled}
                                                onChange={this.props.changeCallback} onBlur={this.props.blurCallback}
                                                value={this.props.value} maxLength={this.props.maxLength} ref={(r) => { this.input = r; }}>
                                        </InputMask>
                                        <span style={styles.passIcon} onClick={this.props.showPassword}>
                                                {
                                                        this.props.type == 'password' ?
                                                                <FontAwesomeIcon icon={faEyeSlash} />
                                                                : <FontAwesomeIcon icon={faEye} />
                                                }

                                        </span>
                                        {this.errorText()}
                                </div>
                );
        }

        errorText() {

                if ((this.props.attr.id == 'password' || this.props.attr.id == 'confirmPassword') && this.props.value.length > 5 && this.props.value == this.props.valorOutroCampo) {

                        return null;
                }

                const { isValid } = this.state;
                if (isValid) {
                        return null;
                }

                return (
                        <div style={{ color: 'red' }}>
                                {this.getErrorMessage()}
                        </div>
                );
        }
}


class NeoInputRequiredSpecial extends ValidatorComponent {



        updateValue = function (evt) {
                let valor = evt.target.value
                this.setState(state => ({ val: valor }))
        }


        dynamicMask = function (type) {
                let mask = null;
                switch (type) {
                        case 'cpf':
                                mask = "999.999.999-99"
                                break;
                        case 'date':
                                mask = "99/99/9999"
                                break;
                        case 'cep':
                                mask = "99999-999"
                                break;
                        case 'numTituloEleitor':
                                mask = "9999 9999 9999"
                                break;
                        case 'zonaTituloEleitor':
                                mask = "999";
                                break;
                        case 'secaoTituloEleitor':
                                mask = "9999"
                                break;
                        case 'pis':
                                mask = "999.99999.99-9";
                                break;
                        case 'irpf':
                                mask = "99.99.99.99.99–99"
                                break;
                        case 'validadeCartao':
                                mask = "99/9999";
                                break;
                        case 'cartao':
                                mask = "9999 9999 9999 9999"
                                break;
                        case 'cvv':
                                mask = "999";
                                break;
                        default:
                                mask = ""
                }
                return mask;

        }


        render() {
                const { errorMessages, validators, requiredError, validatorListener, ...rest } = this.props;

                return (

                        <div>
                                <div className="NeoDesign" >
                                        <span>
                                                {this.props.firstText}
                                        </span>
                                        <InputMask mask={this.dynamicMask(this.props.attr.mask)} type={this.props.type} placeholder={this.props.attr.placeholder} style={this.props.attr.erro ?/*styles.error*/null : null}
                                                className={this.props.attr.className} id={this.props.attr.id} disabled={this.props.attr.disabled}
                                                onChange={this.props.changeCallback} onBlur={this.props.blurCallback}
                                                autoComplete={this.props.attr.autocomplete}
                                                value={this.props.value} maxLength={this.props.maxLength} ref={(r) => { this.input = r; }}>
                                        </InputMask>
                                        <span>
                                                {this.props.secondText}
                                        </span>
                                </div>
                                {this.errorText()}
                        </div>
                );
        }

        errorText() {
                const { isValid } = this.state;

                if (isValid) {
                        return null;
                }

                return (
                        <div style={{ color: 'red' }}>
                                {this.getErrorMessage()}
                        </div>
                );
        }
}


ValidatorForm.addValidationRule('isCpf', (value) => {
        if (validarCpf(value)) {
                return true;
        }
        return false;
});

ValidatorForm.addValidationRule('isTel', (value) => {
        let onlyNumbers = value.replace(/[(,), ,-]/g, '');

        if (onlyNumbers.length == 11) {
                if (onlyNumbers[2] != '9') return false
        }

        if (onlyNumbers.length > 11 || onlyNumbers.length < 10) {
                return false;
        }
        return true;
});

ValidatorForm.addValidationRule('isDate', (value) => {
        let date = moment(value, 'DD/MM/YYYY');
        if (value.replace(/[_,\/]/g, '').length != 8) return false
        if (Number(date.format('YYYY')) < 1900) return false
        if (date.isAfter(moment())) return false
        return date._isValid
});

ValidatorForm.addValidationRule('isAdmissionDate', (value) => {
        let date = moment(value, 'DD/MM/YYYY');
        if (value.replace(/[_,\/]/g, '').length != 8) return false
        if (Number(date.format('YYYY')) < 1900) return false
        if (moment(date, 'DD/MM/YYYY').diff(moment(), 'days') > 30) return false
        return date._isValid
});

ValidatorForm.addValidationRule('isCupom', (value) => {
        if (value === 'NEOCONTADOR' || !value) return true
        return true
});

ValidatorForm.addValidationRule('isCep', (value) => {
        if (value.replace(/[_,-]/g, '').length == 0) return true
        if (value.replace(/[_,-]/g, '').length != 8) return false
        return true
});

ValidatorForm.addValidationRule('isTitulo', (value) => {
        if (value.replace(/[_, ]/g, '').length < 12 && value) return false
        return true
});

ValidatorForm.addValidationRule('isSecao', (value) => {
        if (value.replace(/[_, ]/g, '').length < 3 && value) return false
        return true
});

ValidatorForm.addValidationRule('isZona', (value) => {
        if (value.replace(/[_, ]/g, '').length < 4 && value) return false
        return true
});

ValidatorForm.addValidationRule('isPIS', (value) => {


        var multiplicadorBase = "3298765432";
        var total = 0;
        var resto = 0;
        var multiplicando = 0;
        var multiplicador = 0;
        var digito = 99;
        var pis = value;

        // Retira a mascara
        var numeroPIS = pis.replace(/[^\d]+/g, '');

        if (numeroPIS.length !== 11 ||
                numeroPIS === "00000000000" ||
                numeroPIS === "11111111111" ||
                numeroPIS === "22222222222" ||
                numeroPIS === "33333333333" ||
                numeroPIS === "44444444444" ||
                numeroPIS === "55555555555" ||
                numeroPIS === "66666666666" ||
                numeroPIS === "77777777777" ||
                numeroPIS === "88888888888" ||
                numeroPIS === "99999999999") {
                return false;
        } else {
                for (var i = 0; i < 10; i++) {
                        multiplicando = parseInt(numeroPIS.substring(i, i + 1));
                        multiplicador = parseInt(multiplicadorBase.substring(i, i + 1));
                        total += multiplicando * multiplicador;
                }

                resto = 11 - total % 11;
                resto = resto === 10 || resto === 11 ? 0 : resto;

                digito = parseInt("" + numeroPIS.charAt(10));
                return resto === digito;
        }


});


ValidatorForm.addValidationRule('isIRPF', (value) => {
        if (value.replace(/[_,\.,–, ]/g, '').length < 12 &&
                value &&
                value.replace(/[_,\.,–, ]/g, '').length > 0) return false
        return true
});
ValidatorForm.addValidationRule('isMaiorQueZero', (value) => {
        var valorNumber = Number(value.replace(/\./g, '').replace(',', '.'));

        if (!valorNumber || valorNumber < 1) return false;

        return true;
});

ValidatorForm.addValidationRule('isCodEsocial', (value) => {
        if (value.replace(/[_,\.,–, ]/g, '').length < 12 &&
                value &&
                value.replace(/[_,\.,–, ]/g, '').length > 0) return false
        return true
})


ValidatorForm.addValidationRule('validadeCartao', (value) => {
        let date = moment(value, 'MM/YYYY');
        // console.log(date);
        if (value.replace(/[_,\/]/g, '').length != 6) return false
        if (Number(date.format('YYYY')) < 1900) return false
        return date._isValid

});

export {
        NeoInput,
        NeoInputFile,
        NeoInputRequired,
        NeoSelect,
        NeoInputRequiredSpecial,
}