import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';

import { CONFIG } from '../../config/index';
import { GLOBAL } from '../../config/global';
import styles from './styles/styles';
import { NeoAlert, NeoLoading, NeoAlertDecision } from '../NeoAlert/NeoModal';
import './styles/styles.css';
import '../../assets/css/style.css';
import StepTitle from './StepTitle';
import { NeoInputRequired } from '../NeoInputs/NeoInputs'
import { ValidatorForm } from 'react-form-validator-core';
import { NeoButtonNav, NeoSubmit, NeoRadio, NeoLink } from '../NeoButtons/NeoButtons';
import { NeoPercent } from '../NeoPercent/NeoPercent';
import NeoTooltip from '../NeoTooltip/NeoTooltip'
import $ from 'jquery'


//const Teste = (props) => <h3>AAAA</h3> 
const stepAtual = 'D'

class Step_D extends Component {
    constructor() {
        super()
        this.handlSubmit = this.handlSubmit.bind(this);
        this.updateValue = this.updateValue.bind(this);
        this.formatSpecialTypes = this.formatSpecialTypes.bind(this);
        this.onlyNumber = this.onlyNumber.bind(this);
        this.showPassword = this.showPassword.bind(this);
        this.checkboxControl = this.checkboxControl.bind(this);
        this.alertControl = this.alertControl.bind(this);
        this.esqueciSenha = this.esqueciSenha.bind(this);
        this.alertDecisionControl = this.alertDecisionControl.bind(this);

        this.state = {
            userId: GLOBAL.getCookie('_uf_neoId'),
            telaCarregou: false,
            codAcesso: '',
            eSocialpassword: '',
            funcionariosTemCadastro: 1,
            quantFuncFaltaCadastrarOriginal: '',
            quantFuncFaltaCadastrar: '',
            quantidadeFuncionarios: '',
            passwordType: 'password',
            modalEsconderBotao: false,
        }
    }

    static displayName = 'ui-RegisterForm'


    //roda assim q a tela abre
    componentDidMount() {
        document.getElementById('s3-cadastro').classList.add('specialSize')

        //verifica se o id tá salvo no cookie
        let userId = this.state.userId
        if (userId && userId != "undefined") {

            this.loadingControl(true, 'Aguarde');
            //Consulta pra preencher os dados da tela assim que entra       
            let url = CONFIG.API_PREFIX + '/users/finduser';
            fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    userLogin: {
                        _id: userId
                    }
                })
            }).then(response => response.json())
                .then(responseJson => {
                    if (responseJson.success) {
                        let dados = responseJson.data;
                        // console.log(dados.quantidadeFuncionarios)
                        this.setState({
                            userId: dados['_id'],
                            declarouIR: dados.declarouIR ? dados.declarouIR : '',
                            quantidadeFuncionarios: dados.quantidadeFuncionarios ? dados.quantidadeFuncionarios : '',
                            codAcesso: dados.esocial ? dados.esocial.codAcesso : '',
                            eSocialpassword: dados.esocial ? dados.esocial.eSocialpassword : '',
                            funcionariosTemCadastro: dados.esocial ? dados.esocial.funcionariosTemCadastro : 1,
                            quantFuncFaltaCadastrar: dados.esocial && dados.quantFuncFaltaCadastrar ? dados.quantFuncFaltaCadastrar : '',
                            quantFuncFaltaCadastrarOriginal: dados.esocial && dados.quantFuncFaltaCadastrar ? dados.quantFuncFaltaCadastrar : '',
                            telaCarregou: true
                        }, () => {
                            // console.log(this.state)
                        })

                        //controla o histórico de steps
                        let historicoStep = responseJson.data.historicoStep;
                        if (historicoStep[historicoStep.length - 1] === stepAtual) historicoStep.pop();
                        this.setState({
                            historicoStep: historicoStep,
                        })

                    } else {
                        // console.log("Deu ruim");
                        // console.log(responseJson.success);
                        this.setState({
                            telaCarregou: true,
                            funcionariosTemCadastro: 1
                        })
                    }
                    this.loadingControl(false, '');
                }).catch(err => {
                    console.error(err);
                    this.loadingControl(false, '');
                    this.setState({
                        telaCarregou: true,
                        funcionariosTemCadastro: 1,
                        modalTitle: "Ops!",
                        modalText: "Ocorreu um erro ao buscar os dados. Entre em contato com o administrador do sistema",
                        modalEsconderBotao: true
                    })
                    this.alertControl();
                });
        }
    }

    formatSpecialTypes = function (name, evt) {
        let valorFormatado = evt.target.value;
        let length = valorFormatado.replace(/[(,), ,-]/g, '').length
        if (name == "telephone") {
            // console.log(length)
            if (length >= 11) {
                valorFormatado = valorFormatado.replace(/[(,), ,-]/g, '').replace(/^(\d{2})(\d{5})(\d{4}).*/, '($1) $2-$3');
            } else if (length == 10) {
                valorFormatado = valorFormatado.replace(/[(,), ,-]/g, '').replace(/^(\d{2})(\d{4})(\d{4}).*/, '($1) $2-$3');
            }
        }
        this.setState(state => ({ [name]: valorFormatado }))
        // console.log(this.state)
    }

    updateValue = function (name, evt) {
        let valor = evt.target.value
        this.setState(state => ({ [name]: valor }))
    }

    deletaFuncionario(callback) {
        let url = CONFIG.API_PREFIX + '/funcionarios/deletaFuncionario';
        let self = this;
        this.loadingControl(true, 'Aguarde');
        fetch(url, {
            method: "DELETE",
            headers: {
                "Origin": "*",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                idEmpregador: self.state.userId,
            })
        }).then(response => response.json())
            .then(responseJson => {

                if (responseJson.success) {

                    // console.log(responseJson);

                    callback(false, responseJson);

                    self.loadingControl(false, '');
                }

                else {
                    // console.log(responseJson.success);

                    self.loadingControl(false, '');

                    self.setState({
                        modalTitle: 'Ops!',
                        modalText: 'Ocorreu um erro ao salvar os dados',
                    })
                    self.alertControl();
                    callback(true, 'err');

                }

                self.setState({
                    loadingButton: false,
                })

            }).catch(err => {

                console.error(err);

                self.loadingControl(false, '');
                callback(true, 'err');

                self.setState({
                    modalTitle: 'Ops!',
                    modalText: 'Ocorreu um erro ao salvar os dados',
                    loadingButton: false,
                })

                self.alertControl();
            });
    }

    onlyNumber = function (name, evt) {
        let valor = evt.target.value

        // Campo de quantidade de funcionários não pode ser 0
        if (name == 'quantFuncFaltaCadastrar' && Number(valor) == 0) {

            this.setState(state => ({ [name]: '' }));
        }

        else {

            this.setState(state => ({ [name]: valor.replace(/\W+/g, "").replace(/[_A-Z-a-z]/g, '') }));
        }
    }

    confirmPass = function () {
        return ($('[name="confirmPassword"]').val() == $('[name="password"]').val() ? true : false)
    }

    showPassword = function (name, evt) {
        if (this.state[name] == 'password') {
            this.setState(state => ({
                [name]: 'text'
            }))
        } else {
            this.setState(state => ({
                [name]: 'password'
            }))
        }
    }

    //Controla o valor do checkbox
    checkboxControl(e) {
        this.setState({ 'funcionariosTemCadastro': e });
        // console.log(this.state.quantidadeFuncionarios);
    }

    //Controla a visibilidade do alerta
    alertControl(classe = '') {
        this.setState(state => ({
            modalClass: classe,
            showAlert: !state.showAlert,
        }))
    }

    //Controla o loading
    loadingControl(visible, title) {
        this.setState({
            loading: visible,
            modalTitle: title
        })
    }

    gravaDados(params, callback) {
        let self = this;
        let historicoStep = self.state.historicoStep;
        historicoStep.push(stepAtual);

        let dataToSend = {
            _id: this.state.userId,
            params: params,
            historicoStep: historicoStep
        }

        let url = CONFIG.API_PREFIX + '/users/updateUser';
        self.loadingControl(true, 'Aguarde, salvando dados');
        fetch(url, {
            method: "PUT",
            headers: {
                "Origin": "*",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                data: dataToSend
            })

        }).then(response => response.json())
            .then(responseJson => {
                if (responseJson.success) {
                    callback(false, 'Dados Gravados')
                } else {
                    callback(true, 'Erro ao gravar dados')
                }
                self.loadingControl(false, '');

            }).catch(err => {
                console.error(err);
                self.loadingControl(false, '');
            });
    }

    handlSubmit(e) {
        let self = this;
        let funcFaltaCadastrar = this.state.quantFuncFaltaCadastrar
        //Mesmo se o usuário esvaziar
        if ((this.state.quantFuncFaltaCadastrar == '' && this.state.funcionariosTemCadastro != 1) || this.state.funcionariosTemCadastro == 2) {
            funcFaltaCadastrar = this.state.quantidadeFuncionarios

        }

        let params = {
            step: self.state.funcionariosTemCadastro == 1 ? 'Z' : 'G',
            historicoStep: this.state.historicoStep,
            esocial: {
                codAcesso: this.state.codAcesso,
                eSocialpassword: this.state.eSocialpassword,
                funcionariosTemCadastro: this.state.funcionariosTemCadastro,
            },
            quantFuncFaltaCadastrar: self.state.funcionariosTemCadastro == 1 ? 0 : Number(funcFaltaCadastrar)
        }

        if (self.state.quantFuncFaltaCadastrarOriginal != self.state.quantFuncFaltaCadastrar
            || self.state.funcionariosTemCadastro != 3) {
            self.deletaFuncionario(function (err, result) {
                if (!err) {
                    self.gravaDados(params, function (err, response) {
                        if (!err) {
                            if (self.state.funcionariosTemCadastro == 1) {
                                self.props.nextScreen('Z')
                            } else {
                                self.props.nextScreen('G')
                            }
                        } else {
                            self.alertControl("Ops!", "Ocorreu um erro ao salvar os dados");
                        }
                    })
                } else {
                    self.alertControl("Ops!", "Ocorreu um erro ao salvar os dados");
                }
            })
        } else {
            self.gravaDados(params, function (err, response) {
                if (!err) {
                    if (self.state.funcionariosTemCadastro == 1) {
                        self.props.nextScreen('Z')
                    } else {
                        self.props.nextScreen('G')
                    }
                } else {
                    self.alertControl("Ops!", "Ocorreu um erro ao salvar os dados");
                }
            })
        }
    }

    esqueciSenha() {

        let self = this;
        let step = this.state.declarouIR == '1' ? 'F2' : 'F1';
        this.gravaDados({ step: step, historicoStep: self.state.historicoStep, }, function (err, response) {

            if (!err) {
                self.props.nextScreen(step);
            }
        })
    }

    //Controla a visibilidade do alerta decisivo
    alertDecisionControl(acao, titulo, mensagem, evt) {

        this.setState(state => ({
            showAlertDecision: !state.showAlertDecision,
            modalTitle: titulo,
            modalText: mensagem,
        }))

        if (acao == 'continuar') this.gravarDadosVoltar();

    }

    gravarDadosVoltar = function () {

        var self = this;

        self.loadingControl(true, 'Aguarde');

        let dataToSend = {
            _id: self.state.userId,
            params: {
                step: this.state.historicoStep[this.state.historicoStep.length - 1],
            }
        }

        let url = CONFIG.API_PREFIX + '/users/updateUser';

        fetch(url, {
            method: "PUT",
            headers: {
                "Origin": "*",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                data: dataToSend
            })
        }).then(response => response.json())
            .then(responseJson => {

                if (responseJson.success) {

                    // console.log(responseJson);

                    self.props.lastScreen(this.state.historicoStep[this.state.historicoStep.length - 1]);

                    self.loadingControl(false, '');
                }

                else {

                    // console.log(responseJson.success);

                    self.loadingControl(false, '');

                    self.setState({
                        modalTitle: 'Ops!',
                        modalText: 'Ocorreu um erro ao buscar os dados',
                    })
                    self.alertControl();
                }

                self.setState({
                    loadingButton: false,
                })

            }).catch(err => {

                console.error(err);

                self.loadingControl(false, '');

                self.setState({
                    modalTitle: 'Ops!',
                    modalText: 'Ocorreu um erro ao buscar os dados',
                    loadingButton: false,
                })

                self.alertControl();
            });
    }

    handleKeyDown = (event) => {

        if (event.key === 'Enter') {

            event.preventDefault();
        }
    };

    render() {

        const textoTitulo = {
            titulo: 'Ótimo, você já está no     ',
            produto: 'eSocial!',
            subtitulo: 'Então nos informe seus dados cadastrais para validarmos as informações'
        }


        return (


            <div>
                <NeoAlert
                    show={this.state.showAlert}
                    title={this.state.modalTitle}
                    text={this.state.modalText}
                    close={this.alertControl}
                    modalEsconderBotao={this.state.modalEsconderBotao}
                    class={this.state.modalClass}
                />

                <NeoLoading title={this.state.modalTitle} show={this.state.loading} />

                <NeoAlertDecision
                    show={this.state.showAlertDecision}
                    title={this.state.modalTitle}
                    text={this.state.modalText}
                    sim={this.alertDecisionControl.bind(this, "continuar")}
                    nao={this.alertDecisionControl.bind(this, "cancelar")}
                />

                <ValidatorForm ref="form" onSubmit={this.handlSubmit} onKeyDown={e => { this.handleKeyDown(e) }}>

                    <div className="s3-step" data-step="D">
                        <div className="s3-wrapper">

                            {/* //className = "s3-signup s3-body"> */}
                            <Row onClick={this.sendData}>
                                <StepTitle texto={textoTitulo} />
                                <div className="s3-content col-md-12" style={{ padding: "0" }}>
                                    <div className="s3-signup" style={{ margin: "0" }}>
                                        <Row>
                                            <div className="s3-form-item clearfix col-md-12" style={{ padding: "0" }}>

                                                {/* Begin Register Form */}
                                                < div className="register-content" style={{ marginTop: "10px" }}>

                                                    <div className="register-body">


                                                        <Row style={styles.rowSpace} className="verticalAlign">
                                                            <Col xs={10} md={5}>
                                                                <label htmlFor="codAcesso" className="form-control-label">Informe seu Cód. de Acesso:</label>
                                                            </Col>

                                                            <Col xs={2} md={1}>
                                                                <NeoTooltip position="top" text="O código de acesso é formado por 12 números" />
                                                            </Col>

                                                            <Col xs={12} md={6}>
                                                                <NeoInputRequired
                                                                    changeCallback={this.updateValue.bind(this, "codAcesso")}
                                                                    name="codAcesso"
                                                                    type="text"
                                                                    value={this.state.codAcesso}
                                                                    validators={['required', 'isCodEsocial']}
                                                                    errorMessages={['Obrigatório', 'Código inválido']}
                                                                    attr={{
                                                                        mask: 'codAcesso',
                                                                        ref: 'codAcesso',
                                                                        className: 'form-control',
                                                                        id: 'codAcesso',
                                                                        placeholder: 'Código de Acesso',
                                                                    }} />
                                                            </Col>
                                                        </Row>

                                                        <Row style={styles.rowSpace} className="verticalAlign">
                                                            <Col xs={12} md={6}>
                                                                <label htmlFor="password" className="form-control-label">Informe sua senha de acesso:</label>
                                                            </Col>

                                                            <Col xs={12} md={6} style={styles.rowSpace}>

                                                                <NeoInputRequired
                                                                    changeCallback={this.updateValue.bind(this, "eSocialpassword")}
                                                                    showPassword={this.showPassword.bind(this, "passwordType")}
                                                                    name="eSocialpassword"
                                                                    type={this.state.passwordType}
                                                                    minLength="8"
                                                                    value={this.state.eSocialpassword}
                                                                    validators={['required']}
                                                                    errorMessages={['Obrigatório']}
                                                                    attr={{
                                                                        mask: 'text',
                                                                        ref: 'eSocialpassword',
                                                                        className: 'form-control',
                                                                        id: 'eSocialpassword',
                                                                        placeholder: 'Senha',
                                                                    }}
                                                                />
                                                            </Col>
                                                        </Row>

                                                        <Row style={styles.rowSpace} className="verticalAlign">
                                                            <Col xs={12} md={6}>
                                                                <div>
                                                                    <label htmlFor="email" className="form-control-label">Seus funcionários já estão cadastrados no eSocial?</label>
                                                                </div>
                                                            </Col>
                                                            <Col xs={12} md={6}>
                                                                {this.state.telaCarregou ?
                                                                    <NeoRadio opt1="Sim, todos" opt2="Nenhum" opt3="Falta Cadastrar" value={this.checkboxControl} defaultValue={this.state.funcionariosTemCadastro} />
                                                                    : null
                                                                }

                                                            </Col>
                                                        </Row>
                                                        {
                                                            this.state.funcionariosTemCadastro === 3 ?
                                                                <Row style={styles.rowSpace} className="verticalAlign">
                                                                    <Col xs={12} md={6}>
                                                                        <label htmlFor="quantFuncFaltaCadastrar" className="form-control-label">Quantos funcionários faltam cadastrar?</label>
                                                                    </Col>
                                                                    <Col xs={6} md={2}>
                                                                        <NeoInputRequired
                                                                            changeCallback={this.onlyNumber.bind(this, "quantFuncFaltaCadastrar")}
                                                                            name="quantFuncFaltaCadastrar"
                                                                            type="text"
                                                                            value={this.state.quantFuncFaltaCadastrar}
                                                                            maxLength='4'
                                                                            validators={['required', `maxNumber:${this.state.quantidadeFuncionarios}`, 'minNumber:0']}
                                                                            errorMessages={['Obrigatório', `Digite um valor igual ou menor que ${this.state.quantidadeFuncionarios}`, 'Digite um valor maior que 0']}
                                                                            attr={{
                                                                                mask: 'text',
                                                                                ref: 'quantFuncFaltaCadastrar',
                                                                                className: 'form-control',
                                                                                id: 'quantFuncFaltaCadastrar',
                                                                                placeholder: '',
                                                                            }} />
                                                                    </Col>
                                                                </Row>
                                                                : null
                                                        }
                                                        <Row style={styles.rowSpace} className="verticalAlign">
                                                            <Col xs={12} md={7}></Col>
                                                            <Col xs={12} md={5}>
                                                                <NeoLink texto="Esqueci meu código e/ou senha de acesso" callback={this.esqueciSenha} />
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </div >
                                                {/* Begin Register Form */}


                                            </div>
                                        </Row>
                                    </div>
                                </div>
                            </Row>

                        </div>

                    </div>
                    <NeoButtonNav text="Voltar" callback={this.alertDecisionControl.bind(this, 'abrir', 'Atenção', 'Ao voltar os dados preenchidos serão perdidos, deseja continuar?')} />
                    <NeoSubmit text="Continuar" color="green" />
                    <NeoPercent text="50%" />

                </ValidatorForm>
            </div>
        );
    }
}





export default Step_D;