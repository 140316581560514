import '../../assets/css/style.css';
import './style.css'
import React from 'react'

const NeoPercent = (props) => {
    let classe = `s3-step-navigation inline externalSpace ${props.classe ? props.classe : ''}`
    return (
        <div className={classe}>

            <span className="percentText space pointer floatRight" > {props.text} </span>

        </div>
    )
}

export {
    NeoPercent
}