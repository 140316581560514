import styles from './styles/styles'
import './styles/styles.css'
import '../../assets/css/style.css';
import { Container, Row, Col } from 'reactstrap';
import React from 'react';

const StepTitle = function(props) {
    return (
        <Container className="s3-step-header" style={styles.alignContentCenter}>
            <div className="register-header" style={styles.alignCenter}>
                <h3 className="register-title" id="signupModelLabel" style={styles.registerHeader}>
                    {props.texto.titulo}&nbsp;
                </h3>
                <h3 className = "strong900 register-header" style={styles.alignCenter}>{props.texto.produto}</h3>
                <h4 style={styles.registerHeader}>{props.texto.subtitulo}</h4>
                {
                    props.texto.observacao?<h5>{props.texto.observacao}</h5>
                    :null
                }
                
            </div>
        </Container>
        
    )
} 

export default StepTitle;